
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'NConfirm',
  components: { NModalWindow, NButton },
  emits: ['confirm', 'close']
})
export default class NConfirm extends Vue {
  @Prop({ type: String, default: NModalWindowSize.Medium })
  readonly size!: NModalWindowSize;

  @Prop({ type: [String, Number], required: true })
  readonly confirmLabel!: string;

  @Prop({ type: [String, Number], required: true })
  readonly cancelLabel!: string;

  @Prop({ type: [String] })
  readonly title?: string;

  @Prop({ type: [String, Number], required: true })
  readonly text!: string;

  @Prop({ type: Boolean, default: true })
  readonly display!: true;

  @Prop({ type: Boolean, default: true })
  readonly insertIntoBody!: true;

  currentDisplay: any = true;

  closeHandler() {
    this.currentDisplay = false;
    this.$emit('close', this.currentDisplay);
  }
  confirmHandler() {
    this.currentDisplay = false;
    this.$emit('confirm', this.currentDisplay);
  }

  setCurrentDisplay() {
    this.currentDisplay = this.display;
  }

  created() {
    this.setCurrentDisplay();
  }
}
