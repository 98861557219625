import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createBlock(_component_NSelect, {
    clearIcon: "",
    prefixIcon: _ctx.prefixIcon,
    placeholder: _ctx.$t('common.card_name', 'f'),
    loadItems: _ctx.loadItems,
    filtered: "",
    searchItems: _ctx.searchItems,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _ctx.updateValue
  }, null, 8, ["prefixIcon", "placeholder", "loadItems", "searchItems", "modelValue", "onUpdate:modelValue"]))
}