import { alphabeticalSort, computeFieldI18nItems, computeFieldItems } from '@/common/utilsFilterSchema';
import { configModule } from '@/store/config';
import {
  configButtonGroup,
  configCarModel,
  configLicensePlateInput,
  configLicensePlateInputSmall,
  configSelect,
  configSelectAsTags,
  configSelectAsTagsSmall, configSmallCarModel,
  configureAge,
  configureAngle,
  configureRange,
  configureSmallAge,
  SimpleFilterOptions
} from '@/store/filters/types';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { isNumber, isNil } from 'lodash';
import { carModels } from '@/store/filters/car-models';

export class AttributeFilterSchemaModule {
  get faceFeatures() {
    return configModule.config.objects?.faces?.features;
  }

  get bodyFeatures() {
    return configModule.config.objects?.bodies?.features;
  }

  get carFeatures() {
    return configModule.config.objects?.cars?.features;
  }

  getFaceAttributes(options: SimpleFilterOptions): (IFormLayoutItem | undefined)[] {
    return [
      this.getFaceAge(options),
      this.getFaceBeard(options),
      this.getFaceEmotions(options),
      this.getFaceGender(options),
      this.getFaceGlasses(options),
      this.getFaceLiveness(options),
      this.getFaceMedmask(options)
    ].filter((v) => this.isFeaturePresentInConfigByFormItem(v, this.faceFeatures));
  }

  getBodyAttributes(options: SimpleFilterOptions): (IFormLayoutItem | undefined)[] {
    return [
      this.getBodyGender(options),
      this.getBodyAgeGroup(options),
      this.getBodyHeadwear(options),
      this.getBodyVestType(options),
      this.getBodyVestTypeScore(options),
      this.getBodyHelmetType(options),
      this.getBodyHelmetTypeScore(options),
      this.getBodyTopColor(options),
      this.getBodyBottomColor(options),
      this.getBodyDetailedUpperClothes(options),
      this.getBodyLowerClothes(options),
      this.getBodyUpperClothes(options),
      this.getBodyBagBack(options),
      this.getBodyBagHand(options)
    ].filter((v) => this.isFeaturePresentInConfigByFormItem(v, this.bodyFeatures));
  }

  getCarAttributes(options: SimpleFilterOptions): (IFormLayoutItem | undefined)[] {
    return [
      this.getCarMake(options),
      this.getCarModel(options),
      this.getCarBody(options),
      this.getCarColor(options),
      this.getCarLicensePlateCountry(options),
      this.getCarLicensePlateNumberContains(options),
      this.getCarLicensePlateRegion(options),
      this.getCarLicensePlateNumberColor(options),
      this.getCarSpecialVehicleType(options),
      this.getCarCategoryType(options),
      this.getCarWeightType(options)
    ].filter((v) => this.isFeaturePresentInConfigByFormItem(v, this.carFeatures));
  }

  isFeaturePresentInConfigByFormItem(formItem: IFormLayoutItem | undefined, config: Record<string, any> | undefined): boolean {
    if (!formItem) return false;
    const pathKey = formItem?.path;
    const pathKeyWithoutContains = pathKey?.replace('_contains', '');
    const nameKey = formItem?.name;
    const nameWithoutGteKey = nameKey?.replace('_score_gteRange', '');
    const items = [pathKey, pathKeyWithoutContains, nameKey, nameWithoutGteKey];
    const isPresent = items.reduce<boolean>((m, keyName: string | undefined) => {
      m = m || !!(keyName && config?.[keyName] !== undefined);
      return m;
    }, false);

    if (!isPresent) {
      console.log('[filters] Filter of ' + pathKey + '/' + formItem?.name + '/' + formItem?.i18n_label + '/' + formItem?.component?.name + ' is not configured.');
    }
    return isPresent;
  }

  getFaceAge(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'features.faces_age'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configureSmallAge(resultOptions) : configureAge(resultOptions);
  }

  getFaceBeard(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'beard',
      i18n_label: 'features.faces_beard',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.faceFeatures?.beard, 'features.faces_beard')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceEmotions(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'emotions',
      i18n_label: 'features.faces_emotions',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.faceFeatures.emotions, 'features.faces_emotions')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceGender(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'gender',
      i18n_label: 'features.faces_gender',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.gender, 'features.faces_gender')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceGlasses(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'glasses',
      i18n_label: 'features.faces_glasses',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.glasses, 'features.faces_glasses')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceHeadposeYaw(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      name: 'headpose_yaw',
      i18n_label: 'features.faces_headpose_yaw'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureAngle(resultOptions, 'headpose_yaw_angle_gte', 'headpose_yaw_angle_lte');
  }

  getFaceHeadposePitch(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      name: 'headpose_pitch',
      i18n_label: 'features.faces_headpose_pitch'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureAngle(resultOptions, 'headpose_pitch_angle_gte', 'headpose_pitch_angle_lte');
  }

  getFaceLiveness(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'liveness',
      i18n_label: 'features.faces_liveness',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.liveness, 'features.faces_liveness')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceMedmask(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'medmask',
      i18n_label: 'features.faces_medmask',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.medmask, 'features.faces_medmask')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  // Body

  getBodyTopColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'top_color',
      i18n_label: 'features.bodies_top_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.top_color, 'features.bodies_top_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBottomColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bottom_color',
      i18n_label: 'features.bodies_bottom_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.bottom_color, 'features.bodies_bottom_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyDetailedUpperClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'detailed_upper_clothes',
      i18n_label: 'features.bodies_detailed_upper_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.detailed_upper_clothes, 'features.bodies_detailed_upper_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHeadwear(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'headwear',
      i18n_label: 'features.bodies_headwear',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.headwear, 'features.bodies_headwear')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyLowerClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'lower_clothes',
      i18n_label: 'features.bodies_lower_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.lower_clothes, 'features.bodies_lower_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyUpperClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'upper_clothes',
      i18n_label: 'features.bodies_upper_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.upper_clothes, 'features.bodies_upper_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBagBack(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bag_back',
      i18n_label: 'features.bodies_bag_back',
      anyItem: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures?.bag_back, 'features.bodies_bag_back')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBagHand(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bag_hand',
      i18n_label: 'features.bodies_bag_hand',
      anyItem: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures?.bag_hand, 'features.bodies_bag_hand')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyAgeGroup(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'age_group',
      i18n_label: 'features.bodies_age_group',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.age_group, 'features.bodies_age_group')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyGender(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'body_gender',
      name: 'gender',
      i18n_label: 'features.bodies_gender',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.gender, 'features.bodies_gender')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyVestType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'vest_type',
      i18n_label: 'features.bodies_vest_type',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.vest_type, 'features.bodies_vest_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyVestTypeScore(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'vest_type_score',
      i18n_label: 'features.bodies_vest_type_score'
    };
    const resultOptions = Object.assign(baseOptions, options);
    const gteObject = {
      fieldName: 'vest_type_score_gte',
      min: 0,
      max: 100
    };
    const lteObject = {
      fieldName: 'vest_type_score_lte',
      min: 0,
      max: 100
    };
    return configureRange(resultOptions, gteObject, lteObject, decodeScore, entcodeScore);
  }

  getBodyHelmetType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'helmet_type',
      i18n_label: 'features.bodies_helmet_type',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.helmet_type, 'features.bodies_helmet_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHelmetTypeScore(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'helmet_type_score',
      i18n_label: 'features.bodies_helmet_type_score'
    };

    const resultOptions = Object.assign(baseOptions, options);
    const gteObject = {
      fieldName: 'helmet_type_score_gte',
      min: 0,
      max: 100
    };
    const lteObject = {
      fieldName: 'helmet_type_score_lte',
      min: 0,
      max: 100
    };
    return configureRange(resultOptions, gteObject, lteObject, decodeScore, entcodeScore);
  }

  // Car

  getCarMake(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'make',
      i18n_label: 'features.cars_make',
      i18n_placeholder: 'common.choose_make',
      multiple: true,
      items: computeFieldItems(this.carFeatures.make)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelectAsTags(resultOptions);
  }

  getCarModel(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'model',
      name: 'make',
      i18n_label: 'features.cars_model',
      i18n_placeholder: 'common.choose_model',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSmallCarModel(resultOptions) : configCarModel(resultOptions);
  }

  getCarBody(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'body',
      i18n_label: 'features.cars_body',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.body, 'features.cars_body')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'color',
      i18n_label: 'features.cars_color',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.color, 'features.cars_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateCountry(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_country',
      i18n_label: 'features.cars_license_plate_country',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: alphabeticalSort(computeFieldI18nItems(this.carFeatures.license_plate_country, 'features.cars_license_plate_country')),
      i18n_placeholder: 'common.select_country'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateNumberContains(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number_contains',
      i18n_label: 'features.cars_license_plate_number',
      i18n_placeholder: 'common.enter_number_plate',
      i18n_tooltip: 'common.enter_number_plate_description'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configLicensePlateInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
  }

  getCarLicensePlateRegion(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_region',
      i18n_label: 'features.cars_license_plate_region',
      i18n_placeholder: 'common.select_region',
      items: computeFieldI18nItems(this.carFeatures.license_plate_region, 'features.cars_license_plate_region'),
      anyItem: true,
      readonly: false
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelect(resultOptions);
  }

  getCarLicensePlateNumberColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number_color',
      i18n_label: 'features.cars_license_plate_number_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.carFeatures.license_plate_number_color, 'features.cars_license_plate_number_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarSpecialVehicleType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'special_vehicle_type',
      i18n_label: 'features.cars_special_vehicle_type',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.special_vehicle_type, 'features.cars_special_vehicle_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarCategoryType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'category_type',
      i18n_label: 'features.cars_category',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.category, 'features.cars_category')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarWeightType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'weight_type',
      i18n_label: 'features.cars_weight_type',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.weight_type, 'features.cars_weight_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarOrientation(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'orientation',
      i18n_label: 'features.cars_orientation',
      anyItem: true,
      items: computeFieldI18nItems(this.carFeatures.orientation, 'features.cars_orientation')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }
}

export const attributeFilterSchemaModule = new AttributeFilterSchemaModule();

function decodeScore(value: number | null | undefined) {
  return isNumber(value) ? value / 100 : value;
}

function entcodeScore(v: number | null) {
  return isNil(v) ? v : Math.round(v * 100);
}

/*

get(options: SimpleFilterOptions): IFormLayoutItem {
  const baseOptions: SimpleFilterOptions = {
    // _
  };
  const resultOptions = Object.assign(baseOptions, options);
  return options?.small ? config_(resultOptions) : config_(resultOptions);
}


 */
