import { UsersService } from '@/api';
import { Debounce } from '@/common/debounce-decorator';
import { reactive } from 'vue';

const ItemTypes = {
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  CardsCars: 'cards_cars',
  CardsHumans: 'cards_humans',
  ClustersBodies: 'clusters_bodies',
  ClustersCars: 'clusters_cars',
  ClustersFaces: 'clusters_faces',
  Counters: 'counters',
  EpisodesCars: 'episodes_cars',
  EpisodesHumans: 'episodes_humans',
  EventsBodies: 'events_bodies',
  EventsCars: 'events_cars',
  EventsFaces: 'events_faces',
  ExternalDetectors: 'external_detectors',
  ExternalVms: 'external_vms',
  Groups: 'groups',
  Searches: 'searches',
  Users: 'users',
  Videos: 'videos',
  WatchLists: 'watch-lists',
  Webhooks: 'hooks',
  AuditLogs: 'audit-logs'
};

export type Filter = {
  source: string;
  filters: string[];
};

const EventsSmallFilters = ['acknowledged', 'no_match', 'no_face_match', 'no_body_match', 'no_car_match'];
const ClusterSmallFilters = ['has_card', 'matched_lists'];

const VideoSmallFilters = ['name_contains', 'camera_groups'];

const AuditLogs = ['object_type', 'action_type'];

const DefaultSmallFiltersMap: Record<string, string[]> = {
  [ItemTypes.Counters]: ['id_in'],
  [ItemTypes.EpisodesHumans]: EventsSmallFilters,
  [ItemTypes.EpisodesCars]: EventsSmallFilters,
  [ItemTypes.EventsFaces]: EventsSmallFilters,
  [ItemTypes.EventsBodies]: EventsSmallFilters,
  [ItemTypes.EventsCars]: EventsSmallFilters,
  [ItemTypes.CardsHumans]: ['has_face_objects', 'has_body_objects', 'name_contains'],
  [ItemTypes.CardsCars]: ['has_car_objects', 'license_plate_number_contains'],
  [ItemTypes.ClustersFaces]: ClusterSmallFilters,
  [ItemTypes.ClustersBodies]: ClusterSmallFilters,
  [ItemTypes.ClustersCars]: ClusterSmallFilters,
  [ItemTypes.Cameras]: VideoSmallFilters,
  [ItemTypes.ExternalDetectors]: VideoSmallFilters,
  [ItemTypes.Videos]: VideoSmallFilters,
  [ItemTypes.AuditLogs]: AuditLogs
};

export class SmallFilterManagerModule {
  static Name = 'SmallFilterManagerModule';
  static StorageKey = 'smallFilters';

  public readonly items: Record<string, string[]> = { ...DefaultSmallFiltersMap };
  loaded = false;

  async setFiltersBySource(source: string, items: string[], save: boolean = false) {
    this.items[source] = items;
    if (save) await this.saveUserFilters();
  }

  getFiltersBySource(source: string): string[] {
    return this.items[source] || DefaultSmallFiltersMap[source] || [];
  }

  async loadUserFilters() {
    try {
      const smallFiltersData = await UsersService.usersMeDataRetrieve(SmallFilterManagerModule.StorageKey);
      const savedFields = JSON.parse(smallFiltersData?.value);
      Object.assign(this.items, savedFields);
    } catch (e) {
      console.warn('[users.service]:getFilters error', e);
    } finally {
      this.loaded = true;
    }
  }

  @Debounce(1000)
  async saveUserFilters() {
    try {
      await UsersService.usersMeDataUpdate('smallFilters', { key: SmallFilterManagerModule.StorageKey, value: JSON.stringify(this.items) ?? '' });
    } catch (e) {
      console.warn('[users.service]:saveFilters error', e);
    }
  }
}

export const smallFilterManagerModule = reactive(new SmallFilterManagerModule());
