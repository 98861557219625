export type ArrowType = typeof ArrowTypes[keyof typeof ArrowTypes];

export const ArrowTypes = {
  Middle: 'middle',
  TopLeft: 'top-left'
} as const;

export type ScaleOptions = {
  default: number;
  fit: number;
  fill: number;
  min: number;
  max: number;
  wheelStep: number;
};

export type EventBinding = {
  target: Element;
  eventName: string;
  callback: (...args: any[]) => void;
  options?: boolean | AddEventListenerOptions;
};

export type FitFillResult = {
  fit: number;
  fill: number;
  min: number;
  wheelStep: number;
};
