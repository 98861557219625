export default {
  create_hooks: 'Add new webhook',
  hooks_no_items: 'You haven\'t added any webhooks',
  webhooks: 'webhooks',
  webhook_title: 'Webhook header',
  batch_size: 'Number of notifications in webhook batch',
  send_attempts: 'Number of attempts to send',
  send_attempts_tip: '0 - unlimited',
  filters: 'filters',
  webhook_id: 'Webhook ID:',
  clipboard_success: 'Copied to clipboard',
  copy_id: 'Copy ID'
};
