import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["simple-text", _ctx.classes]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createTextVNode(_toDisplayString(_ctx.text), 1),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.copy)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          type: "link",
          icon: _ctx.copyComplete ? 'done-all' : 'copy',
          onAction: _ctx.copyToClipboard
        }, null, 8, ["icon", "onAction"]))
      : _createCommentVNode("", true)
  ], 2))
}