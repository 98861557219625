import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckboxIcon = _resolveComponent("NCheckboxIcon")!
  const _component_NFormError = _resolveComponent("NFormError")!
  const _component_NTooltip = _resolveComponent("NTooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-form-item", _ctx.item.classes])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "n-form-item__label",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.labelClickHandler && _ctx.labelClickHandler(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.label || ' '), 1),
          (_ctx.hasEnabledFields && _ctx.fieldId)
            ? (_openBlock(), _createBlock(_component_NCheckboxIcon, {
                key: 0,
                class: "n-form-item__checkbox",
                name: 'pin',
                size: 16,
                "model-value": _ctx.isFieldEnabled,
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = (v) => _ctx.$emit('update:fieldEnabled', { id: _ctx.fieldId, enabled: v }))
              }, null, 8, ["model-value"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "n-form-item__control",
      ref: _ctx.referenceChange
    }, [
      (_ctx.isUseModel)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({
            key: 0,
            ref: "control"
          }, _ctx.props, {
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue) = $event))
          }, _toHandlers(_ctx.handlers), {
            path: _ctx.item.path
          }), null, 16, ["modelValue", "path"]))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps({
            key: 1,
            ref: "control"
          }, _ctx.props, _toHandlers(_ctx.handlers)), null, 16)),
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_NFormError, {
            key: 2,
            reference: _ctx.reference,
            error: _ctx.error
          }, null, 8, ["reference", "error"]))
        : _createCommentVNode("", true),
      (_ctx.tooltip && _ctx.reference)
        ? (_openBlock(), _createBlock(_component_NTooltip, {
            key: 3,
            reference: _ctx.reference,
            placement: _ctx.tooltipPlacement,
            mode: "hover"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
            ]),
            _: 1
          }, 8, ["reference", "placement"]))
        : _createCommentVNode("", true)
    ], 512)
  ], 2)), [
    [_vShow, !_ctx.hidden]
  ])
}