
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';
import { ISelectItem } from './types';
import NTag from '@/uikit/tag/NTag.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import NHint from '@/uikit/hint/NHint.vue';

const maxDrawCount = 50;

@Options({
  name: 'NSelectTags',
  components: { NHint, NTooltip, NTag }
})
export default class NSelectTags extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly modelValue!: any;

  @Prop({ type: Boolean, default: false })
  readonly multiline?: boolean;

  @Prop({ type: Boolean, default: true })
  readonly collapsed?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeable?: boolean;

  @Ref('list')
  readonly list!: HTMLDivElement;

  @Prop({ type: String })
  readonly dataQa?: string;

  get items() {
    return this.modelValue.slice(0, maxDrawCount);
  }

  clickHandler(item: ISelectItem) {
    this.$emit('click', item);
  }

  closeHandler(item: ISelectItem) {
    this.$emit('close', item);
  }

  update() {
    if (!this.list) return;
    let hiddenCount = 0;
    const maxRows = this.multiline ? 3 : 1;
    const wrapperRect = this.list.getBoundingClientRect();
    Array.from(this.list.children).forEach((element) => {
      const rect = element.getBoundingClientRect();
      const containerBottomPosition = Math.round(wrapperRect.top + rect.height * maxRows);
      const clientTopPosition = Math.round(rect.top);
      if (containerBottomPosition <= clientTopPosition) {
        hiddenCount++;
      }
    });
    if (this.modelValue.length > maxDrawCount) {
      hiddenCount += this.modelValue.length - maxDrawCount;
    }
    this.$emit('hiddenCountChange', hiddenCount);
  }

  mounted() {
    nextTick(() => {
      this.update();
      nextTick(() => {
        this.update();
        nextTick(this.update);
      });
    });
  }

  @Watch('modelValue', { deep: true })
  watcher() {
    nextTick(() => {
      this.update();
      nextTick(() => {
        this.update();
        nextTick(this.update);
      });
    });
  }
}
