import TimelineObject, { IObjectItem } from '../timeline-object';
import { VmsService } from '@/api';
import SimpleTimer from '@/components/video-player/utils/simple-timer';

export default class TimelineChunks extends TimelineObject {
  items: IObjectItem[] = [];
  private timer = new SimpleTimer();

  /* protected */ async load(cameras: number[], timeFrom: number, timeTo: number): Promise<void> {
    const currentTime = new Date().getTime();
    const isoTimeFrom = new Date(0).toISOString();
    const isoTimeTo = new Date(currentTime).toISOString();
    const response = await VmsService.vmsTimelineRetrieve(isoTimeFrom, isoTimeTo, cameras[0], '1s', null);
    this.items = [];
    for (const item of response.timeline) {
      const id = `id_${item.time_from}`;
      const from = new Date(item.time_from).getTime() / 1000;
      const to = new Date(item.time_to).getTime() / 1000;
      const chunk: IObjectItem = {
        id,
        line: 0,
        color: 'rgba(181,189,215,0.5)',
        timeFrom: from,
        timeTo: to,
        metadata: {}
      };
      this.items.push(chunk);
    }

    if (this.items.length) {
      const lastItem = this.items[this.items.length - 1];
      const isRecording = Math.abs(lastItem.timeTo - currentTime / 1000) < 10;
      if (isRecording) {
        this.timer.setInterval(() => {
          lastItem.timeTo = new Date().getTime() / 1000;
        }, 1000);
      } else {
        this.clearAutoContinue();
      }
    }
    this.isLoadedAll = true;
  }

  protected draw(timeFrom: number, timeTo: number) {
    super.draw(timeFrom, timeTo);
  }

  clearAutoContinue() {
    this.timer.clear();
  }
}
