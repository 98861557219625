
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import NCounter from '@/uikit/counters/NCounter.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { MultisidebarGroup as Group } from '@/store/multisidebar/types';
import NDropdown from '@/uikit/NDropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/NDropdown/NDropdownItem.vue';
import { IMultitoolAction } from '@/uikit';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

const GroupHeight = 40;

@Options({
  name: 'MultisidebarGroup',
  components: { NDropdownItem, NDropdown, NButton, NCounter, NIcon },
  emits: ['action']
})
export default class MultisidebarGroup extends Vue {
  @Prop({ type: Number, required: true })
  readonly currentIndex!: number;

  @Prop({ type: Number, required: true })
  readonly totalCount!: number;

  @Prop({ type: Object, required: true })
  readonly group!: Group;

  @Prop({ type: Array, default: () => [] })
  readonly actions!: IMultitoolAction[];

  get itemsCount() {
    return this.group.items.length < 999 ? this.group.items.length.toString() : '0_o';
  }

  get style() {
    const top = this.currentIndex * GroupHeight + this.currentIndex;
    const bottom = (this.totalCount - 1) * GroupHeight + (this.totalCount - 1);
    return {
      top: `${top}px`,
      bottom: `${bottom - top}px`
    };
  }

  close() {
    this.$emit('action', ItemsActionNames.CloseGroup);
  }

  scrollToAnchor() {
    this.$refs.anchor?.scrollIntoView({ behavior: 'smooth' });
  }
}
