import { CancelablePromise } from '@/api';
import { DocumentWithFullscreen } from '@/components/video-player/FullscreenButton.vue';

type ResponseBodyStreamChunk = Uint8Array;

type ResponseBodyStreamChunkLength = Uint8Array['length'];

type ResponseBodyStreamChunkReceivedHandler = (length: ResponseBodyStreamChunkLength) => void;

type ResponseBodyStreamLength = number;

type ResponseBodyStreamReader = ReadableStreamDefaultReader<ResponseBodyStreamChunk>;

export function asDefined<T>(value: T | null | undefined, message = 'The provided value is null or undefined'): T {
  return value ?? exception(message);
}

export function downloadAsBlob(url: string, progress?: (percentage: number) => void): CancelablePromise<Blob> {
  return new CancelablePromise<Blob>((resolve, reject, onCancel) => {
    const controller = new AbortController();
    fetch(url, { signal: (onCancel(() => controller.abort()), controller.signal) })
      .then(tryResponseBodyStreamReading)
      .then(resolve)
      .catch(reject);
  });

  async function readResponseBodyStream(reader: ResponseBodyStreamReader, onChunkReceived: ResponseBodyStreamChunkReceivedHandler) {
    const chunks: ResponseBodyStreamChunk[] = [];
    let received = 0;

    async function read(): Promise<void> {
      const chunk = await reader.read();
      !chunk.done && (save(chunk.value), await read());
    }

    function save(chunk: ResponseBodyStreamChunk): void {
      chunks.push(chunk);
      onChunkReceived((received += chunk.length));
    }

    return await read(), chunks;
  }

  async function tryResponseBodyStreamReading(response: Response): Promise<Blob> {
    responseIsOk(response);
    const length = getResponseBodyStreamLength(response);
    const reader = getResponseBodyStreamReader(response);
    return new Blob(await readResponseBodyStream(reader, (received) => progress?.(received / length)));
  }

  function getResponseBodyStreamLength(response: Response): ResponseBodyStreamLength {
    const length = Number(response.headers.get('Content-Length'));
    return Number.isFinite(length) ? length : 0;
  }

  function getResponseBodyStreamReader(response: Response): ResponseBodyStreamReader {
    return asDefined(response.body?.getReader(), 'Unable to read the response body stream.');
  }

  function responseIsOk(response: Response): void {
    !response.ok && exception(`Unable to download the file from ${response.url}.`);
  }
}

export function exception(message: string): never {
  throw new Error(message);
}

export function firstOf<T>(items: T[], message = 'The target array does not contain items.'): T {
  return items[0] ?? exception(message);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value != undefined;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isStringWithValue(value: unknown): value is string {
  return isString(value) && value !== '';
}

export function hasParentElementDataName(target: HTMLElement | null, name: string, depth: number = 5): boolean {
  let parent = (target as HTMLElement)?.parentElement;
  let result = false;
  for (let i = 0; i < depth; i++) {
    const parentName = parent?.dataset?.name;
    result = parentName === name;
    if (result) break;
    else parent = parent?.parentElement || null;
  }
  return result;
}

export function openFullscreen(element: any /* HTMLElement in different browsers */): Promise<void> {
  let result;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    /* Safari */
    result = element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE11 */
    result = element.msRequestFullscreen();
  }
  return result || Promise.resolve();
}

export function closeFullscreen() {
  const typedDocument = document as DocumentWithFullscreen;
  if (typedDocument.exitFullscreen) {
    typedDocument.exitFullscreen();
  } else if (typedDocument.webkitExitFullscreen) {
    /* Safari */
    typedDocument.webkitExitFullscreen();
  } else if (typedDocument.msExitFullscreen) {
    /* IE11 */
    typedDocument.msExitFullscreen();
  }
}