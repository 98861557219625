
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import { Prop } from 'vue-property-decorator';
import { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import { aclModule, AclPrefixNames, AclPuppeteerModelNames } from '@/store/acl';
import { IModelAclResult } from '@/store/acl/types';

@Options({
  name: 'ExternalSearchRequestActions',
  components: { NButton }
})
export default class ExternalSearchRequestActions extends Vue {
  @Prop({ type: Object })
  item!: EnrichedSearchRequest;

  get modelAcl(): IModelAclResult {
    return aclModule.getModelAclByName(AclPuppeteerModelNames.ExternalSearch, AclPrefixNames.Puppeteer);
  }
}
