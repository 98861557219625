export const Colors = {
  PointFill: 'rgba(255, 255, 255, 0.9)',
  PointBorder: 'rgb(0, 255, 0)',
  LineDefault: 'rgb(0, 200, 0)',
  LineActive: 'rgb(169,255,169)',
  PolygonFill: 'rgba(0, 0, 0, 0.3)',
  PointDraggedFill: 'rgba(55, 255, 55, 0.9)',
  PointOverFill: 'rgba(255, 55, 55, 0.9)',
  RotFill: 'rgba(0, 0, 0, 0.7)',
  Transparent: 'rgba(0, 0, 0, 0)'
};

export const Sizes = {
  PointSize: 12,
  LineWidth: 2
};

export const ActionTypes = {
  None: 'none',
  DrawRect: 'drawRect',
  Close: 'close',
  Move: 'move',
  MoveAll: 'moveAll',
  AddPoint: 'addPoint',
  DeletePoint: 'deletePoint'
};

export const ModeNames = {
  Poly: 'poly',
  Rect: 'rect'
};
