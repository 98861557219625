
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { Point, Size } from '@/uikit/bbox/types';
import { BboxConfig } from '@/uikit/bbox/types';
import NBbox from '@/uikit/bbox/NBbox.vue';

function orderBySquare(a: BboxConfig, b: BboxConfig) {
  let aSquare = a.box.w * a.box.h,
    bSquare = b.box.w * b.box.h;
  if (aSquare < bSquare) return 1;
  if (aSquare > bSquare) return -1;
  return 0;
}

export default defineComponent({
  name: 'NImageViewerBboxLayer',
  components: { NBbox },
  props: {
    selectable: { type: Boolean, default: true },
    size: { type: Object as PropType<Size>, required: true },
    scale: { type: Number, default: 1 },
    offset: { type: Object as PropType<Point>, default: () => ({ x: 0, y: 0 }) },
    bboxes: { type: Array as PropType<BboxConfig[]>, default: () => [] }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const style = computed(() => {
      return {
        transform: `translate(calc(-50% + ${props.offset.x}px), calc(-50% + ${props.offset.y}px))`,
        width: `${props.size.width * props.scale}px`,
        height: `${props.size.height * props.scale}px`
      };
    });
    const orderedBboxes = computed(() => props.bboxes.slice().sort(orderBySquare));

    function handleSelect(payload: BboxConfig) {
      emit('select', payload);
    }

    return {
      ...toRefs(props),
      style,
      handleSelect,
      orderedBboxes
    };
  }
});
