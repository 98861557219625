import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!

  return (_ctx.module.loaded)
    ? (_openBlock(), _createBlock(_component_NTable, {
        key: 0,
        columns: _ctx.columns,
        content: _ctx.items,
        "disabled-items-ids": _ctx.disabledItemsIds,
        disabled: _ctx.disabled,
        "selected-items-ids": _ctx.module.selectedItemIds,
        onAction: _ctx.handleAction,
        onSort: _ctx.handleSort
      }, null, 8, ["columns", "content", "disabled-items-ids", "disabled", "selected-items-ids", "onAction", "onSort"]))
    : _createCommentVNode("", true)
}