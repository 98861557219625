
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { SearchRequest } from '@/api/models/SearchRequest';
import { viewModelRepository } from '@/api/common';
import { externalSearchRequestsTableSchema } from './external-search-requests-table-schema';
import NTable from '@/uikit/table/NTable.vue';
import enrichSearchRequests, { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NInput from '@/uikit/input/NInput.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import ExternalSearchRequestForm from '@/pages/external-search/requests/ExternalSearchRequestForm.vue';
import CardSelect from '@/pages/external-search/requests/CardSelect.vue';
import FilterDateRange from '@/components/common/filter/FilterDateRange.vue';
import { request } from '@/api/core/request';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import UserSelect from '@/pages/external-search/requests/UserSelect.vue';

@Options({
  name: 'ExternalSearchRequests',
  components: { NInfiniteScroll, FilterDateRange, CardSelect, ExternalSearchRequestForm, NButton, NInput, NModalWindow, NTable, UserSelect }
})
export default class ExternalSearchRequests extends Vue {
  tableSchema = externalSearchRequestsTableSchema;
  enrichedItems: EnrichedSearchRequest[] = [];
  currentEnrichedItem: EnrichedSearchRequest | null = null;

  get module() {
    return viewModelRepository.getPuppeteerSearchListViewModel();
  }

  @Watch('module.items')
  async moduleItemsWatcher(items: SearchRequest[]) {
    this.enrichedItems = await enrichSearchRequests(items, this.enrichedItems);
  }

  @Watch('module.filter.current', { deep: true })
  async reloadList() {
    await this.module.get();
  }

  async mounted() {
    await this.reloadList();
  }

  async refreshResults(id: number) {
    await request({
      method: 'POST',
      path: `/puppeteer/search/${id}/update_search/`
    });
  }

  async closeRequestForm() {
    if (this.currentEnrichedItem) {
      try {
        await this.refreshResults(this.currentEnrichedItem.id);
        await this.reloadList();
        this.$emit('showResultsById', this.currentEnrichedItem.id);
      } catch (e) {
        console.warn(e);
      } finally {
        this.currentEnrichedItem = null;
      }
    }
  }

  handleCellClick(item: EnrichedSearchRequest) {
    this.$emit('showResultsById', item.id);
  }

  async handleAction({ item, action, src }: { item: EnrichedSearchRequest; action: string; src?: string }) {
    switch (action) {
      case 'refresh': {
        await this.refreshResults(item.id);
        await this.reloadList();
        break;
      }
      case 'edit': {
        this.currentEnrichedItem = item;
        break;
      }
      case 'delete': {
        await this.module.delete(item.id);
        await this.reloadList();
        break;
      }
      case 'show-results': {
        this.$emit('showResultsById', item.id);
        break;
      }
      case 'fullframe':
        this.$emit('display-card-image', src);
        break;
    }
  }
}
