import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "camera-zones" }
const _hoisted_2 = {
  key: 1,
  class: "camera-zones__text"
}
const _hoisted_3 = { class: "camera-zones__desc label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraZonesHeader = _resolveComponent("CameraZonesHeader")!
  const _component_PolygonDrawTool = _resolveComponent("PolygonDrawTool")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CameraZonesHeader, {
      items: _ctx.tabs,
      "active-tab": _ctx.tab,
      disabled: _ctx.disabled,
      onAction: _ctx.handleAction,
      onChange: _ctx.changeActiveTab
    }, null, 8, ["items", "active-tab", "disabled", "onAction", "onChange"]),
    (_ctx.image)
      ? (_openBlock(), _createBlock(_component_PolygonDrawTool, {
          key: 0,
          "only-rect": "",
          color: _ctx.color,
          image: _ctx.image,
          "rot-points": _ctx.rotPoints,
          fill: false,
          points: _ctx.points,
          onChange: _cache[0] || (_cache[0] = (v) => (_ctx.points = v)),
          disabled: _ctx.disabled
        }, null, 8, ["color", "image", "rot-points", "points", "disabled"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
                key: 0,
                class: "camera-zones__loading"
              }))
            : (_ctx.error)
              ? (_openBlock(), _createBlock(_component_NIcon, {
                  key: 1,
                  name: "file-broken",
                  width: "30",
                  height: "30"
                }))
              : _createCommentVNode("", true)
        ])),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.description), 1)
  ]))
}