
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Counter, ProximityCalibrationStatusEnum } from '@/api';
import CommonStatus from '@/components/common/CommonStatus.vue';
import { CalibrationStatuses, CalibrationStatusesI18n } from '@/components/counters/CalibrationForm.vue';

@Options({
  name: 'CounterInfo',
  components: { CommonStatus }
})
export default class CounterInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Counter;

  get distanceEnabled() {
    return this.item.detect_proximity;
  }

  get calibrationStatus() {
    return (CalibrationStatuses as any)[this.item.proximity_calibration_status];
  }

  get translatedCalibrationStatus() {
    return this.$t(CalibrationStatusesI18n[this.calibrationStatus]);
  }
}
