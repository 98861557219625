import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NInfiniteScroll = _resolveComponent("NInfiniteScroll")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        "create-label-i18-n": "external_vms.create_new",
        "total-count": _ctx.items.length,
        "has-create": _ctx.modelAcl.add,
        onSearch: _ctx.handleSearch,
        onCreate: _ctx.handleCreate
      }, null, 8, ["total-count", "has-create", "onSearch", "onCreate"]),
      _createVNode(_component_NTable, {
        columns: _ctx.columns,
        content: _ctx.computedVMSItems,
        selected: _ctx.selectedEnrichedItems,
        "onUpdate:selectedChanges": _ctx.handleSelect,
        hoverable: "",
        selections: ""
      }, null, 8, ["columns", "content", "selected", "onUpdate:selectedChanges"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
            key: 0,
            class: "external-vms_loading"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_NInfiniteScroll, {
        filters: _ctx.module.filter.current,
        "next-page": _ctx.module.next_page,
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.append()))
      }, null, 8, ["filters", "next-page"])
    ]),
    _: 1
  }))
}