export default class SimpleTimer {
  private timeoutTimer: ReturnType<typeof setTimeout> | null = null;
  private intervalTimer: ReturnType<typeof setInterval> | null = null;

  setTimeout(callback: any, timeout: number) {
    this.clear();
    this.timeoutTimer = setTimeout(callback, timeout);
  }

  setInterval(callback: any, interval: number) {
    this.clear();
    this.intervalTimer = setInterval(callback, interval);
  }

  isActive() {
    return this.timeoutTimer !== null || this.intervalTimer !== null;
  }

  clear() {
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer);
      this.timeoutTimer = null;
    }
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }
  }
}
