
import { computed, defineComponent, PropType } from 'vue';
import { string } from 'vue-types';
import { NButton } from '@/uikit';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import NIcon from '@/uikit/icons/NIcon.vue';

export type IThumbnailAction = { icon: string; name: string; position: ThumbnailPositions; selected?: boolean };
export type IThumbnailActions = IThumbnailAction[];

export const defaultActions: IThumbnailActions = [
  { icon: 'check', name: EventTypes.Select, position: ThumbnailPositions.TopLeft },
  { icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight },
  { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight },
  { icon: 'play', name: EventDetails.ShowPlayer, position: ThumbnailPositions.BottomLeft }
];

export default defineComponent({
  name: 'NThumbnail',
  components: { NButton, NIcon },
  props: {
    item: { type: Object, required: true },
    size: string().def('medium'),
    defaultAction: string().def(EventDetails.ShowInfo),
    actions: { type: Array as PropType<IThumbnailActions>, default: () => defaultActions }
  },
  setup(props, { emit }) {
    const item = props.item! as any;
    const size = props.size as string;
    const eventsPreviewArray = computed(() => {
      const count = Math.min(item.events_count || 1, 3);
      return new Array(count).fill(0).map((v, k) => k);
    });

    const event = computed(() => {
      return getEvent();
    });

    const isEpisode = computed(() => {
      return item.open !== undefined;
    });

    const url = computed(() => {
      return !!(isEpisode.value ? getEvent() : item.thumbnail);
    });

    const imageStyle = (i: number) => {
      const thumbnailSize = size === 'medium' ? 160 : 120,
        padding = 10,
        imageSize = thumbnailSize - padding * i,
        opacity = Math.max(1 - i * 0.3, 0.1),
        count = Math.min(item.events_count, 3);

      return {
        opacity,
        left: i * padding * 0.5 + 'px',
        height: imageSize + 'px',
        width: imageSize + 'px',
        top: i * padding * 1.3 + 'px',
        'z-index': count - i
      };
    };

    const getEvent = () => {
      const { last_face_event, last_body_event, last_car_event, best_face_event, best_body_event, best_car_event } = item;
      const last_event = last_face_event ?? last_body_event ?? last_car_event ?? '';
      const best_event = best_face_event ?? best_body_event ?? best_car_event;

      return item.open ? last_event : best_event;
    };

    const emitAction = (actionName: string) => {
      actionName === EventTypes.Select ? emit(EventTypes.Select) : emit('action', actionName);
    };

    return { isEpisode, event, imageStyle, eventsPreviewArray, url, EventDetails, emitAction };
  }
});
