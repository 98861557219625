
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import CameraLabelItem from './CameraLabelItem.vue';
import { Camera, CameraGroup } from '@/api';

@Options({
  name: 'CameraLabels',
  components: { CameraLabelItem }
})
export default class CameraLabels extends Vue {
  @Prop({ type: Array, required: true })
  items!: number[];

  @Prop({ type: Boolean, default: true })
  hasIcon!: boolean;

  get cameras(): Camera[] {
    const dataItems = dataModule.camerasModule.items;
    return dataItems.filter((v) => this.items.indexOf(v.id) > -1);
  }

  getCameraGroup(id: number): CameraGroup | undefined {
    const dataItems = dataModule.cameraGroupsModule.items;
    return dataItems.find((v) => v.id === id);
  }

  clickHandler(id: number) {
    this.$emit('click', id);
  }
}
