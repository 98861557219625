
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import debounce from './utils/debounce';
import ElapsedTimer from './utils/elapsed-timer';
import AutoOffsetTimer from './utils/auto-offset-timer';
import AutoUpdateTimer from './utils/auto-update-timer';
import MultilineFormatter from './utils/multiline-formatter';

import VideoPlayerTimelineExport from './VideoPlayerTimelineExport';
import VideoPlayerTimelineRender from './VideoPlayerTimelineRender';

import TimelineObject, { IObjectItem, ObjectItemType } from '@/components/video-player/objects/timeline-object';
import TimelineEpisodesCars from '@/components/video-player/objects/episodes/cars';
import TimelineEpisodesHumans from '@/components/video-player/objects/episodes/humans';
import TimelineEventsCars from '@/components/video-player/objects/events/cars';
import TimelineEventsFaces from '@/components/video-player/objects/events/faces';
import VideoPlayerThumb from '@/components/video-player/VideoPlayerThumbnail.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { configModule } from '@/store/config';
import TimelineEventsBodies from '@/components/video-player/objects/events/bodies';
import { Debounce } from '@/common/debounce-decorator';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';

const defaultMinPixelsToTime = 0.2;
const defaultMaxPixelsToTime = 200;

@Options({
  name: 'VideoPlayerTimeline',
  components: { NIcon, VideoPlayerThumb }
})
export default class VideoPlayerTimeline extends Vue {
  @Prop({ type: Number, required: true })
  cameraId!: number;

  @Prop({ type: String, required: true })
  eventType!: string;

  @Prop({ type: Boolean, required: true })
  readonly exportMode!: boolean;

  @Prop({ type: VideoPlayerState, required: true })
  readonly state!: VideoPlayerState;

  @Ref('timeline')
  timeline!: HTMLDivElement;

  @Ref('canvas')
  canvas!: HTMLCanvasElement;

  isMouseEnter = false;
  isMouseMove = false;

  oldMouseX!: number;
  oldTimeOffset!: number;
  mousePosX = 0;
  mousePosY = 0;

  mouseTimer = new ElapsedTimer();
  autoOffsetTimer = new AutoOffsetTimer(); // отключение авто прокрутки timeOffset при ручной прокрутке
  autoUpdateTimer = new AutoUpdateTimer();

  timelineExport = new VideoPlayerTimelineExport();
  timelineRender = new VideoPlayerTimelineRender();

  pixelsToTime = 1; // из пикселей в секунды
  timeOffset = 0; // сдвиг в секундах
  timePosition = 0; // позиция курсора в секундах
  mouseTime = 0; // время под курсором мыши

  forceAutoOffset = false;

  minPixelsToTime = configModule.timeline_min_zoom || defaultMinPixelsToTime;
  maxPixelsToTime = configModule.timeline_max_zoom || defaultMaxPixelsToTime;

  private objects: TimelineObject[] = [];
  private timelineEventsFaces = new TimelineEventsFaces();
  private timelineEventsBodies = new TimelineEventsBodies();
  private timelineEventsCars = new TimelineEventsCars();
  private timelineEpisodesHumans = new TimelineEpisodesHumans();
  private timelineEpisodesCars = new TimelineEpisodesCars();

  dataLoaderStatus = '';
  isLoadedAll = true;

  @Watch('exportMode')
  toggleExportMode(enabled: boolean) {
    const canvasEndTime = this.timeOffset + this.canvas.width * this.pixelsToTime;
    this.timelineExport.toggleExportMode(enabled, this.timeOffset, canvasEndTime);
    this.redraw();
  }

  @Watch('timelineExport.timeFrom')
  @Watch('timelineExport.timeTo')
  exportTimeFromChange() {
    this.$emit('exportTimeChange', { timeFrom: this.timelineExport.timeFrom, timeTo: this.timelineExport.timeTo });
  }

  // @Watch('isShowCurrentPos')
  @Watch('timePosition')
  timePositionWatcher() {
    this.redraw();
  }

  @Watch('timeOffset')
  timeOffsetWatcher() {
    this.updateObjectsWithDebounce();
  }

  // ********** canvas functions **************

  resizeCanvas() {
    const rect = this.timeline.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    this.canvas = this.$refs.canvas as HTMLCanvasElement;

    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height + 'px';

    const ratio = 1;

    this.canvas.width = Math.ceil(width * ratio);
    this.canvas.height = Math.ceil(height * ratio);

    // TODO: fix this ts error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: problem
    this.timelineRender.ctx = this.canvas.getContext('2d');
    this.timelineRender.ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
    this.timelineRender.canvasWidth = this.canvas.width;
    this.timelineRender.canvasHeight = this.canvas.height;
  }

  getTextStatus() {
    // if (this.dataLoaderStatus === 'waiting') {
    //   return this.$t('video_player.status_waiting');
    // }
    if (this.dataLoaderStatus === 'loading') {
      return this.$t('video_player.status_loading');
    }
    if (!this.isLoadedAll) {
      return this.$t('video_player.zoom_helper');
    }
    return false;
  }

  redrawCanvas() {
    this.checkLimits();
    this.timelineRender.pixelsToTime = this.pixelsToTime;
    this.timelineRender.timeOffset = this.timeOffset;
    this.timelineRender.clearCanvas();

    this.state.timelineChunks.items.forEach((item) => {
      this.timelineRender.drawObject(item.timeFrom, item.timeTo, ObjectItemType.Chunk, item.line, item.color);
    });

    this.timelineRender.drawTimeLabels();

    if (this.isLoadedAll) {
      this.objects.forEach((object) => {
        if (object.type === ObjectItemType.Event) {
          object.items.forEach((item) => {
            this.timelineRender.drawObject(item.timeFrom, item.timeTo, ObjectItemType.Event, item.line, item.color);
          });
        }
        if (object.type === ObjectItemType.Episode) {
          object.items.forEach((item) => {
            this.timelineRender.drawObject(item.timeFrom, item.timeTo, ObjectItemType.Episode, item.line, item.color);
          });
        }
      });
    }

    if (this.timelineExport.enabled) {
      this.timelineRender.drawObject(this.timelineExport.timeFrom, this.timelineExport.timeTo, ObjectItemType.Export, 0, 'rgba(121,204,233,0.3)');
      this.timelineRender.drawExportBorder(this.timelineExport.timeFrom, 1);
      this.timelineRender.drawExportBorder(this.timelineExport.timeTo, -1);
    }

    this.timelineRender.drawTimeLabel(this.timePosition, 'yellow', this.mousePosY);
    if (this.isShowCurrentPos) {
      this.timelineRender.drawTimeLabel(this.mouseTime, '#ebf0ff', this.mousePosY);
    }

    const text = this.getTextStatus();
    if (text) {
      this.timelineRender.drawEventHelper(text);
    }
  }

  checkLimits() {
    if (this.timeOffset < 0) {
      this.timeOffset = 0;
    }
    const maxTimeOffset = new Date().getTime() / 1000 - (this.canvas?.width * this.pixelsToTime) / 2;
    if (this.timeOffset > maxTimeOffset) {
      this.timeOffset = maxTimeOffset;
    }
    if (this.pixelsToTime < this.minPixelsToTime) {
      this.pixelsToTime = this.minPixelsToTime;
    }
    if (this.pixelsToTime > this.maxPixelsToTime) {
      this.pixelsToTime = this.maxPixelsToTime;
    }
    this.timelineExport.checkLimits();
  }

  autoOffsetForceNext() {
    this.forceAutoOffset = true;
  }

  autoOffset(time: number) {
    if (this.forceAutoOffset || (this.autoOffsetTimer.isEnabledAutoScrollTimeline && !this.timelineExport.enabled)) {
      const canvasTimeAll = this.canvas.width * this.pixelsToTime;
      const canvasTimePad = canvasTimeAll / 10;
      if (time > this.timeOffset + canvasTimeAll - canvasTimePad || time < this.timeOffset) {
        this.timeOffset = time - canvasTimePad;
      }
    }
    this.forceAutoOffset = false;
  }

  getMousePosition(e: MouseEvent) {
    const rect = this.canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    return { x, y, w: rect.width, h: rect.height };
  }

  updateMouseTime(e: MouseEvent) {
    const mousePosition = this.getMousePosition(e);
    this.mousePosX = mousePosition.x;
    this.mousePosY = mousePosition.y;
    this.mouseTime = this.timeOffset + mousePosition.x * this.pixelsToTime;
    this.timelineExport.setMouseTime(this.mouseTime);
    this.timelineExport.setThresholdTime(this.pixelsToTime * 16);
  }

  setPosition(time: number) {
    this.timePosition = time;
    this.redraw();
  }

  redraw() {
    window.requestAnimationFrame(this.redrawCanvas);
  }

  resize() {
    this.resizeCanvas();
    this.redraw();
  }

  mouseenter() {
    this.isMouseEnter = true;
  }

  mouseleave() {
    this.isMouseEnter = false;
  }

  mousedown(e: MouseEvent) {
    this.isMouseMove = true;
    this.oldMouseX = e.clientX;
    this.oldTimeOffset = this.timeOffset;
    this.mouseTimer.start();

    if (this.timelineExport.enabled) {
      this.updateMouseTime(e);
      this.timelineExport.mousedown();
    }
  }

  mousemove(e: MouseEvent) {
    this.updateMouseTime(e);
    if (this.isMouseMove) {
      const deltaTime = (e.clientX - this.oldMouseX) * this.pixelsToTime;
      if (this.timelineExport.mousemove(deltaTime)) {
        this.redraw();
        return;
      }
      this.timeOffset = this.oldTimeOffset - (e.clientX - this.oldMouseX) * this.pixelsToTime;
    }
    this.autoOffsetTimer.stopForTimeout();
    this.redraw();
  }

  mouseup(e: MouseEvent) {
    this.isMouseMove = false;
    this.timelineExport.mouseup();
    // проверка длительности нажатия, можно проверить что мышь не была сдвинута
    if (this.mouseTimer.elapsed() < 200) {
      this.updateMouseTime(e);
      this.$emit('positionChange', this.mouseTime);
    }
  }

  mousewheel(e: WheelEvent) {
    const oldPixelToTime = this.pixelsToTime;
    this.pixelsToTime += (this.pixelsToTime * e.deltaY) / 500;
    this.checkLimits();
    const mousePosition = this.getMousePosition(e);
    this.timeOffset += mousePosition.x * oldPixelToTime - mousePosition.x * this.pixelsToTime;
    this.redraw();
  }

  init() {
    this.timeline.addEventListener('mouseenter', this.mouseenter);
    this.timeline.addEventListener('mouseleave', this.mouseleave);
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseup);
    window.addEventListener('resize', this.resize);
  }

  clear() {
    this.state.timelineChunks.clearAutoContinue();
    this.objects = [];
    this.timeline.removeEventListener('mouseenter', this.mouseenter);
    this.timeline.removeEventListener('mouseleave', this.mouseleave);
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
    window.removeEventListener('resize', this.resize);
  }

  initObjects(eventType: string) {
    this.objects = [this.state.timelineChunks];
    if (eventType === 'events') {
      if (configModule.timeline_objects?.events.faces.enabled) {
        this.objects.push(this.timelineEventsFaces);
      }
      if (configModule.timeline_objects?.events.bodies.enabled) {
        this.objects.push(this.timelineEventsBodies);
      }
      if (configModule.timeline_objects?.events.cars.enabled) {
        this.objects.push(this.timelineEventsCars);
      }
    }
    if (eventType === 'episodes') {
      if (configModule.timeline_objects?.episodes.humans.enabled) {
        this.objects.push(this.timelineEpisodesHumans);
      }
      if (configModule.timeline_objects?.episodes.cars.enabled) {
        this.objects.push(this.timelineEpisodesCars);
      }
    }
  }

  async updateObjects() {
    if (!this.state.isCameraRemoved) {
      return;
    }
    const cameras = [this.cameraId];
    const loadAll = this.objects.map((object) => object.loadWrap(cameras, this.timeOffset, this.timeOffset + this.canvas.width * this.pixelsToTime));
    this.dataLoaderStatus = 'loading';
    await Promise.all(loadAll);
    this.dataLoaderStatus = 'loaded';
    MultilineFormatter.format(
      this.objects.reduce((allItems: IObjectItem[], object) => {
        if (object.type === ObjectItemType.Episode) {
          return allItems.concat(object.items);
        }
        return allItems;
      }, [])
    );
    this.isLoadedAll = this.objects
      .map((object: TimelineObject) => object.isLoadedAll)
      .reduce((value: boolean, lastValue: boolean) => {
        return value && lastValue;
      }, true);
    this.redraw();
  }

  @Debounce(3000)
  updateObjectsWithDebounce() {
    this.dataLoaderStatus = 'waiting';
    this.updateObjects();
  }

  created() {
    this.initObjects(this.eventType);
    this.autoUpdateTimer.setCallback(this.updateObjectsWithDebounce);
    this.autoUpdateTimer.setAutoUpdate(60 * 1000);
    this.autoOffsetForceNext();
  }

  async mounted() {
    this.init();
    this.resize();
  }

  beforeUnmount() {
    this.clear();
  }

  @Watch('eventType')
  eventTypeWatcher(value: string) {
    this.initObjects(value);
    this.updateObjectsWithDebounce();
  }

  moveTimeline(delta: number) {
    this.timeOffset += this.canvas.width * this.pixelsToTime * delta;
    this.redraw();
  }

  get isShowCurrentPos() {
    const sideButtonsWidth = 40; // кнопки на таймлайне
    if (this.mousePosX < sideButtonsWidth || this.mousePosX > this.canvas.width - sideButtonsWidth) {
      return false;
    }
    return this.isMouseEnter && !this.timelineExport.enabled && !this.isMouseMove;
  }

  get thumbSrc() {
    if (this.isShowCurrentPos) {
      const objectItemsAll: IObjectItem[] = this.objects.reduce((allItems: IObjectItem[], object) => {
        return allItems.concat(object.items);
      }, []);

      for (let i = 0; i < objectItemsAll.length; i++) {
        if (!objectItemsAll[i].metadata?.thumbnail) {
          continue;
        }
        let timeFrom = objectItemsAll[i].timeFrom;
        let timeTo = objectItemsAll[i].timeTo;
        if (timeFrom === timeTo) {
          timeFrom = timeFrom - 4 * this.pixelsToTime;
          timeTo = timeTo + 4 * this.pixelsToTime;
        }
        if (this.mouseTime >= timeFrom && this.mouseTime <= timeTo && this.isLoadedAll) {
          if (objectItemsAll[i].line) {
            const y = 10 + objectItemsAll[i].line * 10;
            const h = 9;
            if (this.mousePosY >= y && this.mousePosY <= y + h) {
              return objectItemsAll[i].metadata?.thumbnail;
            }
          } else {
            return objectItemsAll[i].metadata?.thumbnail;
          }
        }
      }
    }
    return '';
  }
  get thumbCss() {
    return {
      top: this.mousePosY - 140 + 'px',
      left: this.mousePosX - 20 + 'px'
    };
  }

  @Watch('thumbSrc')
  @Watch('thumbCss')
  thumbWatcher() {
    this.$emit('thumbChanged', { src: this.thumbSrc, css: this.thumbCss });
  }
}
