
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import Stopwatch from '@/components/video-player/utils/stopwatch';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { getLogger } from 'loglevel';
import { applicationModule } from '@/store/application';
const logger = getLogger('[player]');
logger.setLevel('warn');

// Use ts enum in the future
const SeekDirection = {
  Forward: 'forward',
  Backward: 'backward'
};

export interface IFrameObject {
  bbox: number[];
  quality: number;
  track_id: string;
  objectsType?: string; // added in process
  ts?: number;
}

export type IFrameObjects = Record<string, IFrameObject[]>;

export interface IWebsocketEvent {
  data: any;
}

export type IVideoBuffer = any;

export interface ITimeSyncBuffer {
  timestamp: number;
  real_timestamp: number;
}

export interface IWebsocketBuffers {
  video: IVideoBuffer[];
  timesync: ITimeSyncBuffer[];
  json: IFrameObject[];
}

export enum SockState {
  Connecting = 'connecting',
  Connected = 'connected',
  Ended = 'ended',
  Error = 'error',
  Closed = 'closed'
}

export enum VideoState {
  Stopped = 'stopped',
  Playing = 'playing',
  Seeking = 'seeking',
  Paused = 'paused'
}

@Options({
  name: 'VideoPlayerRender'
})
export default class VideoPlayerRender extends Vue {
  @Prop({ type: String, required: true })
  readonly wsUrl!: string;

  @Prop({ type: Boolean, default: false })
  readonly reconnectOnClose!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly positionStartTime!: number;

  @Prop({ type: Number })
  readonly restartIntervalSec: number = 0;

  @Prop({ type: Number })
  readonly syncLiveIntervalSec: number = 0;


  @Ref('video')
  video!: HTMLVideoElement;

  websocket: WebSocket | null = null;
  mediaSource = new MediaSource();
  sourceBuffer!: SourceBuffer;
  buffers: IWebsocketBuffers = {
    video: [],
    timesync: [],
    json: []
  };

  playing = false;
  seeking = false;
  paused = false;

  private openTimeout = new SimpleTimer();
  private seekTimer = new SimpleTimer();
  private realtimeTimer = new SimpleTimer();

  realTimestamp = 0;

  stopwatch = new Stopwatch();

  count = 0;
  logsCount = 0;
  canAppendToMediaSource = false;
  stateText = 'loading';
  seekOnConnected = 0;

  private syncLiveIntervalIndex: number = 0;
  private logIntervalIndex: number = 0;
  private restartIntervalIndex: number = 0;

  get app() {
    return applicationModule;
  }

  getDiffTime() {
    return (this.getSourceBufferLength() || 0) - (this.video?.currentTime || 0);
  }

  @Watch('wsUrl')
  watch_wsUrl() {
    this.playing && this.stop();
    this.$nextTick(() => this.play());
  }

  mounted() {
    if (this.wsUrl) {
      this.play();
    }

    this.setLogInterval();
    this.setRestartInterval();
    this.setSyncLiveInterval();
  }

  setLogInterval() {
    clearInterval(this.logIntervalIndex);
    this.logIntervalIndex = setInterval(() => this.printLogs(), 5000) as any as number;
  }

  @Watch('restartIntervalSec')
  setRestartInterval() {
    logger.info(`Init restart interval: ${this.restartIntervalSec}sec`);
    clearInterval(this.restartIntervalIndex);
    if (this.restartIntervalSec) this.restartIntervalIndex = setInterval(() => this.restart(), this.restartIntervalSec * 1000) as any as number;
  }

  @Watch('syncLiveIntervalSec')
  setSyncLiveInterval() {
    logger.info(`Init sync live interval: ${this.syncLiveIntervalSec}sec`);
    clearInterval(this.syncLiveIntervalIndex);
    if (this.syncLiveIntervalSec) this.syncLiveIntervalIndex = setInterval(() => this.syncLive(), this.syncLiveIntervalSec * 1000) as any as number;
  }

  syncLive() {
    const diffTime = this.getDiffTime();
    if (diffTime > 5 && this.getSourceBufferLength() > 1) {
      const newTime = this.getSourceBufferLength() - 1;
      logger.warn(`Sync live interval, has difference ${diffTime}, try to update position. Seeking: ${this.video?.seeking}, paused ${this.video?.paused}`);
      if (this.video && !this.video.seeking && !this.video.paused) {
        this.video.currentTime = newTime;
      }
    }
  }

  getQuality() {
    return this.video?.getVideoPlaybackQuality() || {};
  }

  getSourceBufferLength(): number {
    const ranges = this.mediaSource?.sourceBuffers || [];
    let result = 0;
    for (let i = 0, len = ranges.length; i < len; i += 1) {
      const range = ranges[i];
      if(range.buffered.length) result += range.buffered.end(0) - range.buffered.start(0);
    }
    return result;
  }

  getActiveSourceBufferLength(): number {
    const ranges = this.mediaSource?.sourceBuffers || [];
    let result = 0;
    for (let i = 0, len = ranges.length; i < len; i += 1) {
      const range = ranges[i];
      if(range.buffered.length) result += range.buffered.end(0) - range.buffered.start(0);
    }
    return result;
  }

  printLogs() {
    this.logsCount++;
    const quality = this.getQuality();
    const sourceBufferLength = this.getSourceBufferLength().toFixed(2);
    const currentTime = this.video?.currentTime?.toFixed(2);
    const diff = this.getDiffTime().toFixed(2);
    logger.info('Debug info: ', this.wsUrl);
    logger.info(`Current time: ${currentTime}, source buffer: ${sourceBufferLength}, diff: ${diff}`);
    logger.info(`Quality dropped: ${quality.droppedVideoFrames}, total frames: ${quality.totalVideoFrames} `);
  }

  restart() {
    this.stop();
    this.$emit('frameObjectsChange', { faces: [], bodies: [], cars: [] });
    this.play();
  }

  activated() {
    this.video?.play();
    if (this.syncLiveIntervalSec) this.syncLive();
  }

  deactivated() {
    this.video?.pause();
  }

  beforeUnmount() {
    clearInterval(this.logIntervalIndex);
    clearInterval(this.restartIntervalIndex);
    clearInterval(this.syncLiveIntervalIndex);
    this.stop();
  }

  initWebSocket() {
    this.openTimeout.clear();
    const websocket = new WebSocket(this.wsUrl);
    websocket.binaryType = 'arraybuffer';
    websocket.onopen = () => {
      this.openTimeout.clear();
      this.stateText = 'connected';
      if (this.seekOnConnected) {
        this.seek(this.seekOnConnected);
        this.seekOnConnected = 0;
      }
    };
    websocket.onclose = (e) => {
      this.clear();
      this.stateText = 'closed';
      this.$emit('stateChange', 'closed');
      if (this.realTimestamp) {
        this.seekOnConnected = this.realTimestamp;
        this.realTimestamp = 0;
      }
      this.$emit('pausedChange', true);
      if (this.reconnectOnClose) {
        this.openTimeout.setTimeout(this.play, 1000);
      }
    };
    websocket.onerror = (e) => {
      this.stateText = 'error';
      this.$emit('stateChange', 'closed');
    };
    websocket.onmessage = (e) => this.messageHandler(e);
    this.websocket = websocket;
  }

  messageHandler(event: IWebsocketEvent) {
    const isString = typeof event.data === 'string';

    if (isString) {
      let data = JSON.parse(event.data);
      const type = data.type;

      if (type === 'timesync') {
        this.buffers.timesync.push(data.data);
      } else if (type === 'begin') {
        if (data.data && data.data.width) {
          this.$emit('streamPropsChange', data.data);
        } else {
          logger.warn('[stream-player] has no stream data information (width, height): ', data);
        }
        this.stateText = 'started';
      } else if (type === 'end') {
        this.stateText = 'ended';
        this.stop();
      } else if (type === 'frame') {
        this.buffers.json.push(data.data);
      } else {
        logger.warn('[stream-player] Error, unknown type: ', data);
      }
      return;
    }

    let data = new Uint8Array(event.data);
    if (this.canAppendToMediaSource) {
      const buffered = this.sourceBuffer.buffered;
      try {
        this.sourceBuffer.appendBuffer(data);
      } catch (e) {
        logger.warn('[player] appendBuffer:error ', e, buffered);
      }
      this.canAppendToMediaSource = false;
    } else {
      this.buffers.video.push(data);
    }
  }

  initMediaSource() {
    this.video.src = URL.createObjectURL(this.mediaSource);
    this.mediaSource.addEventListener('sourceopen', () => {
      URL.revokeObjectURL(this.video.src);
      this.sourceBuffer = this.mediaSource.addSourceBuffer('video/mp4;codecs="avc1.4D4001"');
      this.sourceBuffer.mode = 'segments';
      this.sourceBuffer.addEventListener('updateend', () => {
        if (this.buffers.video.length) {
          this.sourceBuffer.appendBuffer(this.buffers.video.shift());
        } else {
          this.canAppendToMediaSource = true;
        }
      });
      this.sourceBuffer.addEventListener('error', (e) => {
        logger.warn('source buffer error:', e);
      });
      this.canAppendToMediaSource = true;
    });
  }

  play() {
    if (this.websocket && this.paused) {
      this.websocket.send('{"type":"pause", "data": false}');
      this.$refs.video?.play();
      this.playing = true;
      this.paused = false;
      this.$emit('stateChange', 'playing');
      this.stopwatch.start();
      return;
    }

    this.clear();
    if (!this.wsUrl) {
      return;
    }
    this.initWebSocket();
    this.initMediaSource();
    this.playing = true;
    this.paused = false;
    this.$emit('stateChange', 'playing');
  }

  pause() {
    if (this.websocket && this.playing) {
      this.websocket.send('{"type":"pause", "data": true}');
      this.$refs.video?.pause();
      this.playing = false;
      this.paused = true;
      this.$emit('stateChange', 'paused');
      this.stopwatch.pause();
    }
  }

  getVideoTimeByRealTime(realTime: number, seekDirection: any, lastPlayedRealTime: number) {
    for (let i = 0; i < this.buffers.timesync.length; i++) {
      const bufferRealTime = this.buffers.timesync[i].real_timestamp;
      if (
        (seekDirection === SeekDirection.Forward && bufferRealTime >= realTime) ||
        (seekDirection === SeekDirection.Backward && bufferRealTime >= realTime && bufferRealTime < lastPlayedRealTime)
      ) {
        return this.buffers.timesync[i].timestamp;
      }
    }
    return -1;
  }

  updateRealTimestamp(videoTime: number) {
    // для live режима курсор двигается таймером
    // positionStartTime - временная отметка начала проигрываемого видео
    if (this.positionStartTime) {
      if (!this.stopwatch.active) {
        this.stopwatch.start();
      }
      this.realTimestamp = this.positionStartTime + this.stopwatch.elapsed();
      this.$emit('positionChange', this.realTimestamp);
      return;
    }
    // для архивного видео курсор двигается временем из фреймов
    let i: number;
    for (i = 0; i < this.buffers.timesync.length; i++) {
      let v = this.buffers.timesync[i];
      if (v.timestamp >= videoTime) {
        this.realTimestamp = v.real_timestamp;
        this.$emit('positionChange', this.realTimestamp);
        break;
      }
    }
    this.buffers.timesync = this.buffers.timesync.slice(i);
  }

  seek(targetRealTimestamp: number) {
    if (this.stateText === 'closed' && !this.seekOnConnected) {
      this.play();
      this.seekOnConnected = targetRealTimestamp;
    } else {
      if (!this.seeking) {
        if (this.paused) {
          this.play();
        }

        if (this.seekTimer.isActive()) {
          return;
        }

        this.seeking = true;
        this.$emit('stateChange', 'seeking');

        this.$refs.video.pause();
        const seekDirection = targetRealTimestamp > this.realTimestamp ? SeekDirection.Forward : SeekDirection.Backward;
        const lastPlayedRealTime = this.realTimestamp;

        this.seekTimer.setInterval(() => {
          if (this.stateText === 'ended' || this.stateText === 'closed') {
            this.seeking = false;
            this.seekTimer.clear();
          }

          let targetVideoTs = this.getVideoTimeByRealTime(targetRealTimestamp, seekDirection, lastPlayedRealTime);
          if (targetVideoTs == -1) {
            return;
          }
          const sk = this.$refs.video.seekable;
          if (sk.length > 0) {
            const tsEnd = sk.end(sk.length - 1);
            if (targetVideoTs <= tsEnd) {
              this.$refs.video.currentTime = targetVideoTs;

              this.$refs.video.play();
              this.seeking = false;
              this.$emit('seeked');
              this.$emit('stateChange', 'playing');

              this.seekTimer.clear();
            }
          }
        }, 100);

        this.websocket && this.websocket.send(`{"type":"seek", "data": ${targetRealTimestamp}}`);
      }
    }
  }

  stop() {
    this.playing = false;
    this.websocket && this.websocket.close();
  }

  clear() {
    if (this.websocket) {
      this.websocket.onmessage = null;
      this.websocket.onclose = this.websocket.onopen = this.websocket.onerror = null;
      this.websocket = null;
    }
    this.mediaSource = new MediaSource();

    this.buffers = {
      video: [],
      timesync: [],
      json: []
    };
    this.openTimeout.clear();
    this.realtimeTimer.clear();
  }

  updateOverlays(videoTime: number) {
    let i;
    for (i = 0; i < this.buffers.json.length; i++) {
      let data = this.buffers.json[i];
      // TODO: fix this ts error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: problem
      if (data.ts >= videoTime) {
        const { ts, ...objects } = data;
        this.$emit('frameObjectsChange', objects);
        break;
      }
    }
    this.buffers.json = this.buffers.json.slice(i);
  }

  timeupdate() {
    const videoTime = this.$refs.video?.currentTime;
    if (videoTime) {
      this.updateRealTimestamp(videoTime);
      this.updateOverlays(videoTime);
    }
  }
}
