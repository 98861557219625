import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = { class: "n-select-items__content" }
const _hoisted_3 = ["onClick", "data-qa"]
const _hoisted_4 = { class: "n-select-items__checkbox" }
const _hoisted_5 = {
  key: 0,
  class: "n-select-items__icon"
}
const _hoisted_6 = {
  key: 0,
  class: "n-select-items__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "n-select-items",
    "data-qa": `${_ctx.dataQa}.select-items`
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "n-select-items__item",
          key: item.id,
          onClick: ($event: any) => (_ctx.handleClick(item)),
          "data-qa": `${item.value}`
        }, [
          _createElementVNode("div", _hoisted_4, [
            (item.checked)
              ? (_openBlock(), _createBlock(_component_NIcon, {
                  key: 0,
                  name: "select_checkbox"
                }))
              : _createCommentVNode("", true)
          ]),
          (item.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_NIcon, {
                  width: "16",
                  height: "16",
                  name: item.icon
                }, null, 8, ["name"])
              ]))
            : _createCommentVNode("", true),
          (item.color)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "n-select-items__color",
                style: _normalizeStyle({ backgroundColor: item.color })
              }, null, 4))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["n-select-items__label", { 'n-select-items__capitalize': _ctx.capitalizeLabels }])
          }, _toDisplayString(item.label), 3)
        ], 8, _hoisted_3))
      }), 128)),
      (!_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('common.no_data', 'f')), 1))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}