import { NButton, NButtonType, NIcon } from '@/uikit';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import NText from '@/uikit/NText/NText.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NTableProgressCell from '@/uikit/table/NTableProgressCell.vue';
import { get } from 'lodash';

type ITextFieldOptions = {
  width?: string;
  header_icon?: string;
  content_classes?: string;
  i18n_label: string;
  path: string;
  align?: string;
  align_content?: string;
  default?: string | number;
  sortable?: boolean;
  formatter?: (v: any) => string;
  propsHandler?: (v: any) => any;
};

export const columns: ITableColumn[] = [
  {
    width: '40px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        name: 'play'
      }
    },
    content: {
      path: 'play',
      component: NButton,
      props: (item: any) => ({ icon: 'play-outline', type: NButtonType.Link })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_name',
        name: 'name',
        sortable: true
      }
    },
    content: {
      path: 'name',
      classes: ['videos-table__name-column'],
      component: NButton,
      props: (item: any) => ({ label: item.name, type: NButtonType.Link })
    }
  },
  getProgressColumn({ width: '220px', i18n_label: 'common.column_status', path: 'health_status.status', default: 'unknown' }),
  getTextColumn({
    width: '120px',
    i18n_label: 'common.column_size',
    path: 'file_size',
    default: 0,
    formatter: sizeFormatter,
    sortable: false,
    content_classes: 'n-text_primary-400'
  }),
  getTextColumn({ width: '80px', header_icon: 'face', i18n_label: 'common.column_faces_posted', path: 'face_cluster_count', default: 0, align: 'center' }),
  //  todo: disabled for CIBR; restore & make optional in 5.4
  // getTextColumn({ width: '80px', header_icon: 'car2', i18n_label: 'common.column_cars_posted', path: 'car_cluster_count', default: 0, align: 'center' }),
  getDateTime({ width: '100px', i18n_label: 'common.created', path: 'created_date', align: 'center', align_content: 'left', sortable: true })
];

function sizeFormatter(value: number): string {
  return (value / (1024 * 1024)).toFixed(2) + ' Mb';
}

function getTextColumn(options: ITextFieldOptions): ITableColumn {
  const formatter = options.formatter || ((v) => v);
  const alignClass = 'align-' + (options.align || 'left');
  const alignContentClass = 'align-' + (options.align_content || options.align || 'left');
  const classes = ['bottom-border', alignClass];
  const propsHandler = (item: any) => ({
    label: get(item, options.path) ? String(formatter(get(item, options.path))) : String(formatter(options.default ?? ''))
  });

  return {
    width: options.width,
    header: {
      classes,
      component: options.header_icon ? NIcon : NTableColumnCell,
      props: {
        name: options.header_icon ?? options.path,
        i18n_label: options.i18n_label,
        sortable: options.sortable
      }
    },
    content: {
      classes: [alignContentClass, options.content_classes],
      component: NText,
      props: options.propsHandler ?? propsHandler
    }
  };
}

function getProgressColumn(options: ITextFieldOptions): ITableColumn {
  const alignClass = 'align-' + (options.align || 'left');
  const classes = ['bottom-border', alignClass];

  return {
    width: options.width,
    header: {
      classes,
      component: options.header_icon ? NIcon : NTableColumnCell,
      props: {
        name: options.header_icon ?? options.path,
        i18n_label: options.i18n_label,
        sortable: options.sortable
      }
    },
    content: {
      classes: [alignClass],
      component: NTableProgressCell,
      props: statusPropsHandler
    }
  };
}

function statusPropsHandler(item: any) {
  if (item.progress && item.progress !== 1) {
    return { i18n_label: 'videos.processing', floatValue: item.progress };
  } else if (item.__upload_progress && item.__upload_progress !== 100) {
    return { i18n_label: 'videos.uploading', floatValue: item.__upload_progress / 100 };
  } else if (item.health_status.status === 'DISABLED' && item.finished) {
    return { label: 'COMPLETED' };
  } else if (item.health_status.status === 'NO_SOURCE') {
    return { label: 'FILE DELETED' };
  } else {
    return { label: get(item, 'health_status.status') };
  }
}

function getDateTime(options: ITextFieldOptions): ITableColumn {
  const alignClass = 'align-' + (options.align || 'left');
  const alignContentClass = 'align-' + (options.align_content || options.align || 'left');
  const classes = ['bottom-border', alignClass];

  return {
    width: options.width,
    header: {
      classes,
      component: options.header_icon ? NIcon : NTableColumnCell,
      props: {
        name: options.header_icon ?? options.path,
        i18n_label: options.i18n_label,
        sortable: options.sortable
      }
    },
    content: {
      classes: [alignContentClass, options.content_classes],
      path: options.path,
      component: NDateTimeLabel,
      props: {
        multiline: true,
        size: '2xs'
      }
    }
  };
}
