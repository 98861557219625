
import { NInput, NIcon } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NInputBase from '@/uikit/input-base/NInputBase.vue';

@Options({
  components: {
    NInputBase,
    NInput,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallInput extends Vue {
  @Prop({ type: [String, Number], required: true })
  readonly modelValue!: string | number;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: String })
  readonly placeholder?: string;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }
}
