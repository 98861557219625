import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, {
    onCommand: _ctx.selectHandler,
    class: "n-input-date-time-range__intervals",
    placement: "bottom-start"
  }, {
    toggle: _withCtx(({ toggle, hide }) => [
      _createVNode(_component_NButton, {
        type: "link",
        class: "n-input-date-time-range__intervals-toggler",
        onClick: toggle,
        onKeydown: _withKeys(hide, ["tab"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NIcon, { name: "chevron-down" })
        ]),
        _: 2
      }, 1032, ["onClick", "onKeydown"])
    ]),
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intervalLabels, (label, i) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          command: i,
          key: label
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label), 1)
          ]),
          _: 2
        }, 1032, ["command"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["onCommand"]))
}