
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NInputNumber } from '@/uikit';
import { NButton } from '@/uikit';
import { LteOrGteObject } from '@/store/filters/types';
import { isNil, isNumber } from 'lodash';

@Options({
  name: 'NFilterRange',
  components: {
    NInputNumber,
    NButton
  }
})
export default class NFilterRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Object, required: true })
  gteObject!: LteOrGteObject;

  @Prop({ type: Object, required: true })
  lteObject!: LteOrGteObject;

  @Prop({ type: Function, required: false })
  decode?: (v: any) => any;

  @Prop({ type: Function, required: false })
  entcode?: (v: any) => any;

  @Prop({ type: Boolean })
  plain?: boolean;

  @Prop({ type: Boolean, default: true })
  hasClearable?: boolean;

  private gteValue: number | null = null;
  private lteValue: number | null = null;

  get gteMin() {
    return this.gteObject.min;
  }

  get gteMax() {
    return this.lteValue ?? this.gteObject.max;
  }

  get lteMin() {
    return this.gteValue ?? this.lteObject.min;
  }

  get lteMax() {
    return this.lteObject.max;
  }

  created() {
    [this.gteValue, this.lteValue] = [
      this.entcode ? this.entcode(this.model[this.gteObject.fieldName]) : this.model[this.gteObject.fieldName],
      this.entcode ? this.entcode(this.model[this.lteObject.fieldName]) : this.model[this.lteObject.fieldName]
    ];
  }

  get clearable() {
    return this.hasClearable && (isNumber(this.gteValue) || isNumber(this.lteValue));
  }

  reset() {
    this.gteValue = null;
    this.lteValue = null;
  }

  changeHandlerGte(scoreValue: number | null) {
    const res = isNil(scoreValue) ? null : this.lteValue ? (scoreValue <= this.lteValue ? scoreValue : this.gteObject.min) : scoreValue;
    this.gteValue = res;
    Object.assign(this.model, { [this.gteObject.fieldName]: this.decode ? this.decode(res) : res });
  }
  changeHandlerLte(scoreValue: number | null) {
    const res = isNil(scoreValue) ? null : this.gteValue ? (scoreValue >= this.gteValue ? scoreValue : this.lteObject.max) : scoreValue;
    this.lteValue = res;
    Object.assign(this.model, { [this.lteObject.fieldName]: this.decode ? this.decode(res) : res });
  }
}
