
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NLoadingDots from '@/uikit/loading/NLoadingDots.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NCounter, { NCounterType } from '@/uikit/counters/NCounter.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { NLoaderType } from '@/uikit/loading/types';

export enum NButtonSize {
  Big = 'big',
  Normal = 'normal',
  Small = 'small'
}

export enum NButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryAlt = 'secondary-alt',
  Text = 'text',
  LinkRegular = 'link-regular',
  Link = 'link',
  Red = 'red',
  OnBackground = 'on-background'
}

export enum NButtonIconSize {
  Big = '30',
  Normal = '20',
  Small = '16',
  XSmall = '12'
}

export enum NButtonLoader {
  Dots = 'dots',
  Circle = 'circle'
}

@Options({
  name: 'NButton',
  components: { NLoadingCircle, NCounter, NIcon, NLoadingDots }
})
export default class NButton extends Vue {
  @Prop({ type: String, default: 'button' })
  readonly tag!: string;

  @Prop({ type: String, default: NButtonType.Primary })
  readonly type!: NButtonType;

  @Prop({ type: String, default: 'center' })
  readonly align!: string;

  @Prop({ type: String, default: 'button' })
  readonly nativeType!: string;

  @Prop({ type: String, default: NButtonSize.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: String, default: '' })
  readonly label?: string;

  @Prop({ type: String })
  readonly color?: string;

  @Prop({ type: [String, Number], default: NButtonIconSize.Normal })
  readonly iconSize!: NButtonIconSize;

  @Prop({ type: String })
  readonly icon?: string;

  @Prop({ type: String })
  readonly suffixIcon?: string;

  @Prop({ type: [Number, String] })
  readonly counter?: number | string;

  @Prop({ type: Boolean, default: false })
  readonly round!: false;

  @Prop({ type: Boolean, default: false })
  readonly transparent!: false;

  @Prop({ type: Boolean, default: false })
  readonly loading!: false;

  @Prop({ type: String, default: NButtonLoader.Dots })
  readonly loader!: NButtonLoader;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly widen!: false;

  @Prop({ type: String })
  readonly dataQa?: string;

  get colorIcon() {
    return this.size === NButtonSize.Normal ? 'circle' : 'circle-big';
  }

  get loaderComponent() {
    return this.loader === NButtonLoader.Dots ? 'NLoadingDots' : 'NLoadingCircle';
  }

  get loaderType() {
    switch (this.type) {
      case NButtonType.Link:
      case NButtonType.Text:
      case NButtonType.Secondary:
      case NButtonType.SecondaryAlt:
        return NLoaderType.Primary;
      default:
        return NLoaderType.Secondary;
    }
  }

  get labelClassMap() {
    return {
      [NButtonSize.Big]: 'control-l',
      [NButtonSize.Normal]: 'control-m',
      [NButtonSize.Small]: 'control-s'
    };
  }

  get counterType() {
    switch (this.type) {
      case NButtonType.Link:
      case NButtonType.Text:
      case NButtonType.Secondary:
      case NButtonType.SecondaryAlt:
        return NCounterType.Primary;
      default:
        return NCounterType.Secondary;
    }
  }

  get counterEnabled() {
    return this.counter !== undefined;
  }

  hasLabel = false;

  // todo: replace this method to CSS combination :has & :not & :empty when :has will be implemented in browsers.
  //  or change paddings to use empty() on prefix & suffix
  getHasLabel() {
    if (this.label) return true;
    if (this.$slots.default) {
      let nodes = this.$slots.default();
      for (let i in nodes) {
        const node = nodes[i];
        if (node && node.children) {
          return true;
        }
      }
    }
    return false;
  }

  clickHandler() {
    if (this.disabled || this.loading) return;
    this.$emit('action');
  }

  get classes() {
    const root = 'n-button';
    return {
      [root]: true,
      [`${root}_round`]: this.round,
      [`${root}_align-${this.align}`]: true,
      [`${root}_disabled`]: this.disabled,
      [`${root}_loading`]: this.loading,
      [`${root}__pl_low`]: this.icon || !this.hasLabel,
      [`${root}__pr_low`]: this.counterEnabled || this.suffixIcon || !this.hasLabel,
      [`${root}__size_${this.size}`]: this.size,
      [`${root}__type_${this.type}`]: this.type,
      [`${root}_transparent`]: this.transparent,
      [`${root}_widen`]: this.widen,
      [this.labelClassMap[this.size || NButtonSize.Normal]]: this.size
    };
  }

  mounted() {
    this.hasLabel = this.getHasLabel();
  }
}
