export const ActionAcknowledge = 'acknowledge';
export const ActionActivateAll = 'activate-all';
export const ActionArchiveAll = 'archive-all';
export const ActionBlock = 'block';
export const ActionClose = 'close';
export const ActionCounter = 'counter';
export const ActionDeactivateAll = 'deactivate-all';
export const ActionDeleteAll = 'delete-all';
export const ActionDeleteCardsByWatchlists = 'delete-cards-in-all';
export const ActionDeleteCardsInCurrentWatchlist = 'delete-cards-in-current';
export const ActionDeleteCurrent = 'delete-current';
export const ActionDeleteVideoFile = 'delete-video-file';
export const ActionDeselect = 'deselect';
export const ActionDeselectAll = 'deselect-all';
export const ActionOpenAll = 'open-all';
export const ActionProcess = 'process';
export const ActionProcessAll = 'process-all';
export const ActionResetCurrent = 'reset-current';
export const ActionResetStreamSettings = 'reset-stream-settings';
export const ActionSaveAll = 'save-all';
export const ActionSaveCurrent = 'save-current';
export const ActionSelectAll = 'select-all';
export const ActionShowPlayer = 'show-player';
export const ActionStopProcess = 'stop-process';
export const ActionStopProcessAll = 'stop-process-all';
export const ActionToggleSidebar = 'toggle-sidebar';

export type IMultitoolActionName =
  | typeof ActionActivateAll
  | typeof ActionDeactivateAll
  | typeof ActionSelectAll
  | typeof ActionDeselectAll
  | typeof ActionDeselect
  | typeof ActionSaveAll
  | typeof ActionSaveCurrent
  | typeof ActionDeleteAll
  | typeof ActionDeleteCurrent
  | typeof ActionDeleteCardsByWatchlists
  | typeof ActionDeleteCardsInCurrentWatchlist
  | typeof ActionArchiveAll
  | typeof ActionOpenAll
  | typeof ActionToggleSidebar
  | typeof ActionClose
  | typeof ActionCounter
  | typeof ActionResetCurrent
  | typeof ActionBlock
  | typeof ActionProcess
  | typeof ActionProcessAll
  | typeof ActionStopProcess
  | typeof ActionStopProcessAll
  | typeof ActionDeleteVideoFile
  | typeof ActionResetStreamSettings
  | typeof ActionShowPlayer
  | string;
