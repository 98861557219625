
import { Options, Vue } from 'vue-class-component';
import WorkspaceLayout from '@/components/layouts/WorkspaceLayout.vue';
import CenterLayout from '@/components/layouts/CenterLayout.vue';
import { languageModule } from '@/store/languages';
import SessionMonitor from '@/pages/login/SessionMonitor.vue';
import { workspaceModule } from './store/application/workspace';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import PhotoViewer from '@/components/photo-viewer/PhotoViewer.vue';
import ReportsForm from '@/components/reports-form/ReportsForm.vue';
import { websocketModule, websocketPuppeteerModule } from '@/store/ws/websocket.module';
import { Watch } from 'vue-property-decorator';
import { alertModule } from './store/application/alert.module';
import WorkspaceProxyView from '@/components/common/WorkspaceProxyView.vue';
import VideoPlayerDialog from '@/components/video-player/VideoPlayerDialog.vue';

@Options({
  components: { VideoPlayerDialog, PhotoViewer, NModalWindow, SessionMonitor, CenterLayout, WorkspaceLayout, ReportsForm }
})
export default class App extends Vue {
  get isLoginPage() {
    return this.$route.name === 'LoginPage';
  }

  getProxyComponent(Component: any, tab: any) {
    const props = { tab };
    return (
      <WorkspaceProxyView {...props}>
        <Component {...props} />
      </WorkspaceProxyView>
    );
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get languageModule() {
    return languageModule;
  }

  get websocketPuppeteerModule() {
    return websocketPuppeteerModule;
  }

  get websocketModule() {
    return websocketModule;
  }

  @Watch('websocketPuppeteerModule.notify')
  @Watch('websocketModule.notify')
  handlerWebsocketPuppeteerModule(n: any, o: any) {
    if (!n) {
      alertModule.stop();
    } else {
      alertModule.play();
    }
  }

  @Watch('isLoginPage')
  changeLoginPage(v: boolean, p: boolean) {
    if (v) alertModule.stop();
  }
}
