import { DataService } from '@/definitions/services/data.services';
import { BaseListState, DefaultAclPrefix, AutoUpdateIntervalInMs, getPageFromNextPage, IIntervalData, PartialWithId } from '@/definitions/common/base';
import { cloneDeep, debounce } from 'lodash';
import { ItemViewModel } from '@/definitions/view-models/item.view.model';
import { Filter } from '@/definitions/view-models/filter';
import { Debounce } from '@/common/debounce-decorator';

const DefaultUpdateInterval = 5 * 60 * 1000;
const UpdateStatisticsIntervalMs = Math.max(+(localStorage?.debugUpdateInterval || DefaultUpdateInterval), 1000);
let IntervalItems = 0;
let ModuleItems = 0;

setInterval(
  () =>
    localStorage?.debugUpdateInterval &&
    console.log(`Active intervals: ${IntervalItems}, interval ${UpdateStatisticsIntervalMs}ms, total modules ${ModuleItems}`),
  3000
);

export class ListViewModel<T, F> implements BaseListState<T, F> {
  name = 'base';
  routeName = 'baseRouteName';
  aclPrefix = DefaultAclPrefix;
  aclModelName: string | undefined;
  excludedChangeKeys: string[] = [];
  version = 2;
  syncFiltersToRoute = false;

  playing = false;
  loading = false;
  saving = false;
  deleting = false;
  appending = false;
  loaded = false;
  loadError: any | null = null;
  items: T[] = [];
  virtualItems: T[] = [];
  selectedItemIds: (string | number)[] = [];
  emptyItem?: T;
  currentItemId: number | string | undefined;
  protected _wrappedSelectedItemsMap: Record<string, ItemViewModel<T>> = {};

  firstLoadingPromise!: Promise<void>;
  protected resolveFirstLoadingPromise!: () => void;
  protected rejectFirstLoadingPromise!: () => void;

  count = 0;
  updateStatisticsInterval = 0;

  itemHandler?: (...args: any[]) => any;
  protected _dataService: DataService<T, F> | undefined;

  autoUpdate: IIntervalData = {
    enabled: false,
    intervalInMs: AutoUpdateIntervalInMs,
    intervalIndex: 0
  };

  page = '';
  next_page: string | null = null;
  prev_page: string[] = [];
  limits: number[] = [10, 20, 50, 100, 200, 500];

  filter = new Filter<F>();

  constructor() {
    ModuleItems++;
    this.getStatistics = debounce<any>(this.getStatistics, 500);
    this.firstLoadingPromise = new Promise<void>((resolve, reject) => {
      this.resolveFirstLoadingPromise = resolve;
      this.rejectFirstLoadingPromise = reject;
    });
  }

  get filterChanges() {
    return this.filter.changes;
  }

  get hasFilterChanges() {
    return this.filter.hasChanges;
  }

  get dataService(): DataService<T, F> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  set dataService(v: DataService<T, F>) {
    this._dataService = v;
  }

  protected get _currentItem(): T | undefined {
    return this.currentItemId ? this.allItems.find((v: T) => (v as unknown as PartialWithId<T>).id === this.currentItemId) : undefined;
  }

  protected get _selectedItems(): T[] {
    const mapFnc = (id: string | number) => this.allItems.find((v) => (v as unknown as PartialWithId<T>).id === id);
    const mapResult = (this.selectedItemIds as any[]).map(mapFnc);
    return mapResult.filter((v: T | undefined) => v !== undefined) as T[];
  }

  setCurrentItem(id: string | number): void {
    this.currentItemId = id;
    if (!this.getIsSelectedItem(id)) this.toggleSelectedItem(id);
  }

  get currentItem(): ItemViewModel<T> | undefined {
    return this.currentItemId ? this.selectedItems.find((v: any) => v.item.id === this.currentItemId) : undefined;
  }

  get allItems(): T[] {
    const result: T[] = [];
    return result.concat(this.virtualItems, this.items);
  }

  getItemViewModelByItem(item: T): ItemViewModel<T> {
    const wrappedItem: ItemViewModel<T> = new ItemViewModel();
    wrappedItem.setItemsState(item);
    wrappedItem.dataService = this.dataService;
    wrappedItem.excludedChangeKeys = this.excludedChangeKeys;
    wrappedItem.aclModelName = this.aclModelName;
    return wrappedItem;
  }

  get selectedItems(): ItemViewModel<T>[] {
    const items = this._selectedItems.map((v: T) => {
      const id = String((v as unknown as PartialWithId<T>).id);
      let result: ItemViewModel<T> = this._wrappedSelectedItemsMap[id] as any;
      if (!result) {
        const wrappedItem: ItemViewModel<T> = this.getItemViewModelByItem(v);
        this._wrappedSelectedItemsMap[id] = wrappedItem;
        result = wrappedItem;
      }
      return result;
    });

    return items;
  }

  get changedSelectedItemsCount(): number {
    return this.selectedItems.filter((v) => v.hasChanges).length;
  }

  get hasChangedSelectedItems(): boolean {
    return !!this.selectedItems.find((v) => v.hasChanges);
  }

  get hasSelectedItems(): boolean {
    return !!this.selectedItemIds?.length;
  }

  get hasAcl(): boolean {
    return !!this.aclModelName;
  }

  get aclViewPermissionName(): string {
    return `${this.aclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${this.aclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${this.aclPrefix}.change_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${this.aclPrefix}.delete_${this.aclModelName}`;
  }

  public resetFilters(): void {
    this.filter.reset();
  }

  init(emptyItem: T, emptyFilter: F, filterSchema?: any) {
    this.emptyItem = cloneDeep(emptyItem);
    this.filter = new Filter<F>(emptyFilter, filterSchema);
  }

  @Debounce(700)
  async debouncedGet(options?: any): Promise<boolean> {
    return await this.get(options);
  }

  async get(options = { resetState: false }): Promise<boolean> {
    if (this.loading || this.appending) return false;
    if (options.resetState) this.items = [];
    this.setLoading(true);

    try {
      const responseData = await this.dataService.getList(this.filter.final);
      this.items = responseData.results || [];
      this.next_page = responseData.next_page;
      this.resolveFirstLoadingPromise();
    } catch (e) {
      this.loadError = e;
      this.items = [];
      this.next_page = null;
      this.rejectFirstLoadingPromise();
    }

    this.setLoading(false);
    this.setAutoUpdate(this.autoUpdate.enabled);
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setLoading(value: boolean) {
    this.loading = value;

    if (this.loading) {
      this.loadError = null;
    } else {
      this.loaded = true;
    }
  }

  setAutoUpdate(value: boolean, intervalInMs?: number) {
    clearInterval(this.autoUpdate.intervalIndex);
    this.autoUpdate.enabled = value;
    this.autoUpdate.intervalInMs = intervalInMs || this.autoUpdate.intervalInMs;
    if (value) {
      this.autoUpdate.intervalIndex = window.setInterval(() => this.get({ resetState: false }), this.autoUpdate.intervalInMs);
    }
  }

  async append(): Promise<boolean> {
    const nextPage = this.next_page && getPageFromNextPage(this.next_page);
    if (!nextPage) return false;
    if (this.loading || this.appending) return false;

    this.appending = true;
    this.loadError = null;

    try {
      const responseData = await this.dataService.getList({ ...this.filter.final, page: nextPage });
      const itemsIdMap: Record<string, boolean> = this.items.reduce<Record<string, boolean>>((m, v:any) => ( m[String(v.id)] = true, m), {});
      const filteredAppendedItems = responseData.results?.filter((v: any) => !itemsIdMap[v.id]) ?? [];
      this.items = [ ...this.items, ...filteredAppendedItems ];
      this.next_page = responseData.next_page;
    } catch (e) {
      this.loadError = e;
    }

    this.appending = false;
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  async create(data: Partial<T>): Promise<T> {
    const result = await this.dataService.create(data);
    return result;
  }

  async update(data: PartialWithId<T>): Promise<T> {
    const id: any = data.id;
    const result = await this.dataService.update(id, data);
    return result;
  }

  async delete(id: string | number): Promise<boolean> {
    const result = await this.dataService.delete(id);
    return true;
  }

  reset(): void {
    this.items = [];
    this.next_page = null;
    this.loaded = false;
  }

  async getStatistics() {
    try {
      const responseData = await this.dataService.getStatistics(this.filter.final);
      this.count = responseData.count;
    } catch (e) {
      this.count = 0;
      this.loadError = e;
    }
  }

  startStatisticsSync() {
    this.stopStatisticsSync();
    IntervalItems++;
    this.updateStatisticsInterval = window.setInterval(() => this.getStatistics(), UpdateStatisticsIntervalMs);
  }

  stopStatisticsSync() {
    if (this.updateStatisticsInterval) IntervalItems--;
    clearInterval(this.updateStatisticsInterval);
    this.updateStatisticsInterval = 0;
  }

  dispose() {
    this.setAutoUpdate(false);
    this.stopStatisticsSync();
    this._dataService = undefined;
    ModuleItems--;
  }

  getIsSelectedItem(id: string | number): boolean {
    return !!(this.selectedItemIds as any[]).find((v: string | number) => v === id);
  }

  getIsCurrentItem(id: string | number): boolean {
    return this.currentItemId === id;
  }

  toggleSelectedItem(id: string | number) {
    const isSelected = this.getIsSelectedItem(id);
    if (!isSelected) {
      (this.selectedItemIds as any[]).push(id);
    } else {
      this.syncCurrentSelectedIdOnDelete(id);
      const index = (this.selectedItemIds as any[]).indexOf(id);
      this.selectedItemIds.splice(index, 1);
      delete this._wrappedSelectedItemsMap[id];
    }
  }

  syncCurrentSelectedIdOnDelete(id: string | number): void {
    const index = (this.selectedItemIds as any[]).indexOf(id);
    this.currentItemId = this.selectedItemIds[index - 1] || this.selectedItemIds[index + 1] || undefined;
  }

  syncCurrentSelectedId(): void {
    if (this.selectedItemIds.length && !(this.currentItemId && this.selectedItemIds.includes(this.currentItemId))) {
      this.currentItemId = this.selectedItemIds[0];
    }
  }

  selectAllItems(): void {
    this.selectedItemIds = [...this.selectedItemIds.filter((id) => id < -1000), ...this.items.map((v: any) => v.id)];
  }

  deselectAllItems(): void {
    this.selectedItemIds = [];
    this.currentItemId = undefined;
    this._wrappedSelectedItemsMap = {};
  }

  deselectItem(id: string | number): void {
    const isSelected = this.getIsSelectedItem(id);
    if (isSelected) this.toggleSelectedItem(id);
  }

  deselectCurrentItem(): void {
    this.deselectItem((this._currentItem as any).id);
  }

  async saveCurrentItem(): Promise<T | null | undefined> {
    const key = this.selectedItemIds.findIndex((v) => v === this.currentItemId);
    const result = await this.currentItem?.save();
    this.afterSaveItem(result, key);
    return result;
  }

  async resetCurrentItem(): Promise<void> {
    return this.currentItem?.reset();
  }

  async deleteCurrentItem(): Promise<boolean | undefined> {
    let success = await this.currentItem?.delete((this.currentItem.item as any).id);
    if (success) {
      this.selectedItemIds = this.selectedItemIds.filter((v) => v !== this.currentItemId);
      this.syncCurrentSelectedId();
    }
    return success;
  }

  async saveAllSelectedItems(): Promise<(T | null)[]> {
    let results: (T | null)[] = [];
    try {
      this.saving = true;
      results = await Promise.all(this.selectedItems.map((v) => (v.hasChanges ? v.save() : null)));
      results.forEach((v, k) => this.afterSaveItem(v, k));
    } catch (e) {
      console.error(`[save] error: ${e}`);
    } finally {
      this.saving = false;
    }
    return results;
  }

  private afterSaveItem(v: T | null | undefined, key: number) {
    if (!v) return;
    const prevId = this.selectedItemIds[key];
    const newId = (v as any).id as any;
    if (prevId < -1000 && newId) {
      this.items.unshift(v as any);
      this.selectedItemIds[key] = newId;
      if (this.currentItemId === prevId) this.currentItemId = newId;
      this.virtualItems = this.virtualItems.filter((v) => (v as any).id === prevId);
    }
  }

  async deleteAllSelectedItems(): Promise<(string | number)[]> {
    let results: (string | number)[] = [];
    try {
      this.deleting = true;
      const deleted = await Promise.allSettled(this.selectedItems.map((v) => v.delete((v.item as any).id)));
      deleted.forEach((v, i) => v.status === 'fulfilled' && results.push((this.selectedItems[i].item as any).id));
    } catch (e) {
      console.error(`[save] error: ${e}`);
    } finally {
      this.selectedItemIds = this.selectedItemIds.filter((v) => !results.includes(v));
      this.syncCurrentSelectedId();
      this.deleting = false;
    }
    return results;
  }

  async resetAllSelectedItems(): Promise<void> {
    await this.selectedItems.forEach((v) => v.reset());
  }

  getHasChangedSelectedItem(id: string | number): boolean {
    const item = this.selectedItems.find((v: any) => v.item.id === id);
    return item ? item.hasChanges : false;
  }

  addNewItem() {
    let item: any = cloneDeep(this.emptyItem);
    item.id = (this.virtualItems.length ? ((this.virtualItems[this.virtualItems.length - 1] as any).id as number) : -1000) - 1;
    this.virtualItems.push(item);
    this.selectedItemIds.push(item.id);
    this.setCurrentItem(item.id);
  }
}
