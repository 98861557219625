
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'NTableNameCell'
})
export default class NTableNameCell extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  handleClick() {
    this.$emit('update:modelValue', this.modelValue);
  }
}
