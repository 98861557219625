
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import Sidebar from '@/components/common/sidebar/Sidebar.vue';
import { ListViewModel } from '@/definitions/view-models';
import ListPage from '@/pages/ListPage.vue';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import NButton from '@/uikit/buttons/NButton.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { auditLogsTableSchema } from './audit-logs-table-schema';
import { getAuditLogsFilters } from '@/pages/audit-logs/forms/AuditLogsFilterBuilder';
import { smallFilterManagerModule } from '@/store/filter-manager/SmallFiltersModule';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

@Options({
  name: 'AuditLogsPage',
  components: {
    FiltersSmall,
    FiltersBig,
    NIcon,
    NButton,
    Sidebar,
    ListPage,
    NTable
  }
})
export default class AuditLogsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  isFilterVisible = false;

  get pageSidebarType() {
    return MultisidebarItemTypes.AuditLogs;
  }

  get smallFilterFields() {
    return smallFilterManagerModule.items[this.pageSidebarType];
  }

  setSmallFilterFields(v: string[]) {
    smallFilterManagerModule.setFiltersBySource(this.pageSidebarType, v, true);
  }

  get tableSchema() {
    return auditLogsTableSchema;
  }

  get smallFilterSchema() {
    return getAuditLogsFilters({ small: true });
  }

  get bigFilterSchema() {
    return getAuditLogsFilters({ small: false });
  }

  get state(): PageState {
    return pageModule.getPageStateByTab(PageName.audit_logs, this.tab);
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get items() {
    return this.module.items;
  }

  itemContent = '';
  visibleNoty = false;

  get auditLogsItemContent() {
    return this.itemContent;
  }

  handleTableAction(row: any, cell: ITableCell, rowIndex: any, cellIndex: any) {
    if (!hasUserLoginCellBeenClicked(cell)) {
      this.state.showSidebar = true;
      this.itemContent = JSON.stringify(row, undefined, 2);
    }
  }

  copyText() {
    copyTextToClipboard(this.itemContent).then(() => {
      this.visibleNoty = true;
      setTimeout(() => {
        this.visibleNoty = false;
      }, 3000);
    });
  }

  closeSideBar() {
    this.state.showSidebar = false;
    this.itemContent = '';
  }

  resetFilters() {
    this.module.resetFilters();
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  @Watch('module.filter.current', {
    deep: true
  })
  filterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    if (v?.limit !== p.limit) return;
    this.module.get();
  }
}

function hasUserLoginCellBeenClicked(cell: ITableCell): boolean {
  return cell.path === 'user_login';
}
