import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-size":"10px"}
}
const _hoisted_2 = { class: "n-page__statistics" }
const _hoisted_3 = { class: "n-page__tiles n-page__tiles-compensation-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SortDropdown = _resolveComponent("SortDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ClusterItemProxy = _resolveComponent("ClusterItemProxy")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_ctx.state)
    ? (_openBlock(), _createBlock(_component_ListPage, {
        key: 0,
        ref: "listPage",
        state: _ctx.state,
        "onUpdate:scrollBottom": _ctx.scrollBottomHandler
      }, {
        filters: _withCtx(() => [
          (_ctx.debug)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("pre", null, _toDisplayString(_ctx.module.filter.current), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_FiltersSmall, {
            modelValue: _ctx.module.filter.current,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.filter.current) = $event)),
            "has-changes": _ctx.module.filter.hasChanges,
            "form-layout": _ctx.smallFilterLayout,
            "has-report": "",
            state: _ctx.state,
            onOpenFilters: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFilterVisible = true)),
            onResetFilters: _ctx.resetFilters,
            enabledFields: _ctx.smallFilterFields,
            "onUpdate:enabledFields": _cache[3] || (_cache[3] = (v) => _ctx.setSmallFilterFields(v))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NButtonGroup, {
                items: _ctx.dataAssetsModule.clustersTypeButtonItems,
                modelValue: _ctx.state.objectType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.objectType) = $event))
              }, null, 8, ["items", "modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue", "has-changes", "form-layout", "state", "onResetFilters", "enabledFields"]),
          (_ctx.isFilterVisible)
            ? (_openBlock(), _createBlock(_component_FiltersBig, {
                key: 1,
                "form-layout": _ctx.bigFilterLayout,
                modelValue: _ctx.module.filter.current,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.module.filter.current) = $event)),
                "has-report": "",
                state: _ctx.state,
                onResetFilters: _ctx.resetFilters,
                onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isFilterVisible = false)),
                enabledFields: _ctx.smallFilterFields,
                "onUpdate:enabledFields": _cache[6] || (_cache[6] = (v) => _ctx.setSmallFilterFields(v))
              }, null, 8, ["form-layout", "modelValue", "state", "onResetFilters", "enabledFields"]))
            : _createCommentVNode("", true)
        ]),
        statistic: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"]),
            _createVNode(_component_SortDropdown, {
              items: _ctx.sortTypes,
              modelValue: _ctx.module.filter.current.ordering,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.module.filter.current.ordering) = $event))
            }, null, 8, ["items", "modelValue"])
          ]),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: _ctx.togglePlaying,
            icon: _ctx.pageVM.state.playing ? 'pause' : 'play'
          }, null, 8, ["onClick", "icon"])
        ]),
        items2: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.items, (item) => {
              return (_openBlock(), _createBlock(_component_ClusterItemProxy, {
                display: _ctx.intersectionResultItem?.displayItemsMap[item.id] ?? false,
                key: item.id,
                "data-id": item.id,
                objects: _ctx.state.objectType || '',
                item: item,
                type: _ctx.state.pageType,
                displayType: _ctx.state.displayType,
                "default-action": _ctx.state.defaultAction,
                useDefaultActions: "",
                "cluster-selected": !!_ctx.sidebarModule.getItem(_ctx.pageSidebarType, item.id),
                "card-selected": !!_ctx.sidebarModule.getItem(_ctx.cardsSidebarType, item.card),
                "opened-item": _ctx.getOpenedTypeByCluster(item),
                onSelect: () => _ctx.sidebarModule.toggleItemSelect(_ctx.pageSidebarType, item),
                onSelectCard: _ctx.navigateToCard,
                onAction: (v) => _ctx.actionHandler(item.id, v, item)
              }, null, 8, ["display", "data-id", "objects", "item", "type", "displayType", "default-action", "cluster-selected", "card-selected", "opened-item", "onSelect", "onSelectCard", "onAction"]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["state", "onUpdate:scrollBottom"]))
    : _createCommentVNode("", true)
}