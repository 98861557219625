
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'NPageIcon'
})
export default class PageIcon extends Vue {
  @Prop({ type: String, required: true })
  icon!: string;

  get classes() {
    return [`n-page-icon__${this.icon}`];
  }
}
