
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow, { NModalWindowSize, NModalWindowFooterType } from '@/uikit/modal-window/NModalWindow.vue';

export type ModalWindow = {
  id: number;
  size?: NModalWindowSize;
  label?: string;
  insertIntoBody?: boolean;
  layout?: boolean;
  footerType?: NModalWindowFooterType;
};

const MaxItemsLength = 3;

@Options({
  name: 'NModalWindowList',
  components: { NButton, NModalWindow }
})
export default class NModalWindowList extends Vue {
  @Prop({ type: Array, required: true, validator: (v: ModalWindow[]) => Array.isArray(v) && v.length <= MaxItemsLength })
  readonly items!: ModalWindow[];

  @Prop({ type: Number, required: true })
  modelValue!: number;

  @Prop({ type: Boolean, default: true })
  insertIntoBody!: number;

  counter = MaxItemsLength - 1;

  get cssClass() {
    const base = 'n-modal-window_list';
    return {
      [base]: true,
      [`${base}__size_${this.size}`]: this.size
    };
  }
  get size() {
    const item = this.items.find((item) => item.id === this.modelValue);
    return item?.size ?? NModalWindowSize.Medium;
  }
  get stackPlugs() {
    return this.items.map((_item, index) => index).filter((item) => item < this.counter);
  }

  insertListIntoBody() {
    return this.items.some((item) => item.insertIntoBody) || this.insertIntoBody;
  }

  closeHandler() {
    if (this.modelValue + 1 <= this.items.length) {
      this.counter--;
      this.$emit('update:modelValue', this.modelValue + 1);
    }
  }
}
