import { PicturesEnum, ReportFormatEnum, ReportMeta, TypeEnum } from '@/api';
import { CardsSingleToMulti, EpisodesSingleToMulti, ObjectsSingleToMulti } from '@/store/application/data.assets';
import { PageState, PageType } from '@/store/application/page.definitions';
import { languageModule } from '@/store/languages';
import { reactive, ref } from 'vue';
import { getTimezoneString } from '@/store/kyc/helpers';

export type ReportFormModelType = {
  name?: string;
  pictures: PicturesEnum;
  report_format: ReportFormatEnum[];
  type: TypeEnum;
  filters: any;
  meta?: ReportMeta;
};

export class ReportsFormController {
  name = '';
  type = TypeEnum.FACE_EVENTS;
  pictures = PicturesEnum.LINK;
  report_format = [ReportFormatEnum.XLS];
  filters = null;
  cameras_in = [];
  cameras_out = [];

  active = ref<boolean>(false);

  model: ReportFormModelType = reactive({
    name: this.name,
    type: this.type,
    pictures: this.pictures,
    report_format: this.report_format,
    filters: this.filters,
    meta: {
      cameras_in: this.cameras_in,
      cameras_out: this.cameras_out
    }
  });

  show(pageState: PageState, filters: any) {
    this.model.filters = { ...filters };
    this.model.type = this.getType(pageState);
    if (this.model.type === TypeEnum.KYC) {
      this.model.filters.timezone = getTimezoneString();
    }
    this.model.name = languageModule.getTranslatedToken(`reports.report_by_${this.model.type.replace(/-/g, '_')}`, 'f');
    this.active.value = true;
  }

  hide() {
    this.active.value = false;
  }

  getType(pageState: PageState): TypeEnum {
    const pageType = pageState.pageType;
    const objectType = pageState.objectType;
    const episodeType = pageState.episodeType;
    const cardType = pageState.cardType;

    if (pageType === PageType.episodes) {
      if (episodeType === EpisodesSingleToMulti.car) {
        return TypeEnum.CAR_EPISODES;
      } else {
        return TypeEnum.HUMAN_EPISODES;
      }
    }
    if (pageType === PageType.cards) {
      if (cardType === CardsSingleToMulti.car) {
        return TypeEnum.CAR_CARDS;
      } else {
        return TypeEnum.HUMAN_CARDS;
      }
    } else if (pageType === PageType.clusters || pageType === PageType.cluster_events) {
      if (objectType === ObjectsSingleToMulti.car) {
        return TypeEnum.CAR_CLUSTERS;
      } else if (objectType === ObjectsSingleToMulti.body) {
        return TypeEnum.BODY_CLUSTERS;
      } else {
        return TypeEnum.FACE_CLUSTERS;
      }
    } else if (pageType === PageType.audit_logs) {
      return TypeEnum.AUDIT_LOGS;
    } else if (pageType === PageType.areas) {
      return TypeEnum.AREAS;
    } else if (pageType === PageType.area_activations) {
      return TypeEnum.AREA_ACTIVATIONS;
    } else if (pageType === PageType.area_records) {
      return TypeEnum.AREA_RECORDS;
    } else if (pageType === PageType.kyc) {
      return this.model.type === TypeEnum.FACE_CLUSTER_EVENTS ? TypeEnum.FACE_CLUSTER_EVENTS : TypeEnum.KYC;
    } else if (pageType === PageType.cameras || pageType === PageType.external_detectors) {
      return TypeEnum.CAMERAS;
    } else if (pageType === PageType.counter_records) {
      return TypeEnum.COUNTERS;
    } else if (pageType === PageType.counters) {
      return TypeEnum.COUNTERS;
    } else {
      if (objectType === ObjectsSingleToMulti.car) {
        return TypeEnum.CAR_EVENTS;
      } else if (objectType === ObjectsSingleToMulti.body) {
        return TypeEnum.BODY_EVENTS;
      } else {
        return TypeEnum.FACE_EVENTS;
      }
    }
  }
}

export const reportsFormController = new ReportsFormController();
