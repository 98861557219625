import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NMultitool = _resolveComponent("NMultitool")!

  return (_openBlock(), _createBlock(_component_NMultitool, {
    actions: _ctx.availableActions,
    "all-selected": _ctx.isAllSelected,
    "selected-items": _ctx.selectedItems,
    "collapsed-sidebar": _ctx.collapsedSidebar,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      (_ctx.visibleMainAction)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "secondary",
            onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('action', _ctx.visibleMainAction.name, { value: true }))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.visibleMainAction.i18n_label, 'f')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["actions", "all-selected", "selected-items", "collapsed-sidebar", "onClose"]))
}