import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfidenceLabel = _resolveComponent("ConfidenceLabel")!
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_ClusterSidebarContent = _resolveComponent("ClusterSidebarContent")!
  const _component_NConfirm = _resolveComponent("NConfirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SidebarHeader, {
      "model-acl": {},
      label: _ctx.headerLabel,
      changed: false,
      actions: _ctx.actions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, {
      subLabel: _withCtx(() => [
        (_ctx.currentClusterItem.confidence)
          ? (_openBlock(), _createBlock(_component_ConfidenceLabel, {
              key: 0,
              objects: _ctx.objectType,
              value: _ctx.currentClusterItem.confidence,
              class: "confidence-label_align-start"
            }, null, 8, ["objects", "value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["label", "actions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ClusterSidebarContent, {
        ref: "content",
        item: _ctx.currentItem,
        "cluster-view": _ctx.currentClusterItem,
        "card-type": _ctx.cardType,
        "object-type": _ctx.objectType,
        onAction: _ctx.actionHandler
      }, null, 8, ["item", "cluster-view", "card-type", "object-type", "onAction"])
    ]),
    (_ctx.showMergeConfirm)
      ? (_openBlock(), _createBlock(_component_NConfirm, {
          key: 0,
          "confirm-label": _ctx.$t('clusters.agree'),
          "cancel-label": _ctx.$t('clusters.cancel'),
          text: _ctx.$t('clusters.alert_text'),
          onConfirm: _ctx.mergeConfirmHandler,
          onClose: _ctx.mergeCancelHandler
        }, null, 8, ["confirm-label", "cancel-label", "text", "onConfirm", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}