
import { Options, Vue } from 'vue-class-component';
import { InteractionsModule } from '@/store/interactions';
import { Prop, Watch } from 'vue-property-decorator';
import InteractionClusterItem from '@/pages/interactions/InteractionClusterItem.vue';
import InteractionItem from '@/pages/interactions/InteractionItem.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import InteractionSection from '@/pages/interactions/InteractionSection.vue';
import { getClusterContactsFiltersBuilder, getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { pageModule } from '@/store/application/page.module';
import { PageName } from '@/store/application/page.definitions';

@Options({
  components: { InteractionSection, NButton, InteractionItem, InteractionClusterItem, FiltersBig }
})
export default class InteractionsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  interactionModule = new InteractionsModule();
  filtersVisible = false;
  filtersVisible1 = false;
  filtersVisible2 = false;

  get clusterBigFilterLayout() {
    const pageType = 'clusters';
    const objectType = 'faces';
    return getClusterFiltersBuilder({ small: false }).getFilterByType(pageType, objectType);
  }

  get clusterContactsBigFilterLayout() {
    const pageType = 'clusters';
    const objectType = 'faces';
    return getClusterContactsFiltersBuilder({ small: false }).getFilterByType(pageType, objectType);
  }

  get pageState() {
    return pageModule.getPageStateByTab(PageName.interactions, this.tab);
  }

  async mounted() {
    const hasFilters = !!this.pageState.filter;
    hasFilters && Object.assign(this.interactionModule.clustersModule.filter.current, this.pageState.filter);
    await this.interactionModule.clustersModule.get();
    hasFilters && this.setFirstItemSelected();
  }

  setFirstItemSelected() {
    const firstItem = this.interactionModule.clustersModule.items[0];
    firstItem && this.interactionModule.clustersModule.setCurrentItem(firstItem.id);
  }

  displayFilters() {
    this.filtersVisible = true;
  }

  displayFilters1() {
    this.filtersVisible1 = true;
  }

  displayFilters2() {
    this.filtersVisible2 = true;
  }

  @Watch('interactionModule.clustersModule.filter.current', { deep: true })
  changeClusterFilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clustersModule.get();
  }

  @Watch('interactionModule.clusterContactsModule1.filter.current', { deep: true })
  changeCluster1FilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clusterContactsModule1?.get();
  }

  @Watch('interactionModule.clusterContactsModule2.filter.current', { deep: true })
  changeCluster2FilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clusterContactsModule2?.get();
  }

  @Watch('interactionModule.clustersModule.currentItem')
  changeCluster_currentItemId(v: any, p: any) {
    if (v?.item?.id) {
      const module = this.interactionModule.getClusterContactsModule(v?.item?.id, this.interactionModule._clusterContactsModule1);
      this.interactionModule.clusterContactsModule1 = this.interactionModule._clusterContactsModule1 = module;
      module?.get();
    } else {
      this.interactionModule.clusterContactsModule1 = undefined;
      this.interactionModule.clusterContactsModule2 = undefined;
    }
  }

  @Watch('interactionModule.clusterContactsModule1.currentItem')
  changeClusterContacts1_currentItemId(v: any, p: any) {
    if (v?.item?.id) {
      const module = this.interactionModule.getClusterContactsModule(v?.item?.id, this.interactionModule._clusterContactsModule2);
      this.interactionModule.clusterContactsModule2 = this.interactionModule._clusterContactsModule2 = module;
      module?.get();
    } else {
      this.interactionModule.clusterContactsModule2 = undefined;
    }
  }
}
