import { ITableColumn } from '@/uikit/table/NTable.vue';
import { languageModule } from '@/store/languages';
import { capitalize } from 'lodash';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import GroupItem from './GroupItem.vue';
import { Group } from '@/api';

const t = languageModule.getTranslatedToken;

export const columns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        label: capitalize(t('common.column_name')),
        name: 'name'
      }
    },
    content: {
      path: 'name',
      component: GroupItem,
      props: (item: Group) => ({ name: item.name })
    }
  }
];
