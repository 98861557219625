import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-form-item n-big-filter__switch-block"
}
const _hoisted_2 = { class: "n-big-filter__label heading-m" }
const _hoisted_3 = { class: "n-big-filter__value" }
const _hoisted_4 = {
  key: 1,
  class: "n-form-item n-big-filter__switch-block n-big-filter_mb-0"
}
const _hoisted_5 = { class: "n-big-filter__label heading-m" }
const _hoisted_6 = { class: "n-big-filter__value" }
const _hoisted_7 = {
  key: 2,
  class: "n-form-item n-big-filter__switch-block n-big-filter_mb-0"
}
const _hoisted_8 = { class: "n-big-filter__label heading-m" }
const _hoisted_9 = { class: "n-big-filter__value" }
const _hoisted_10 = { class: "n-big-filter__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    layout: true,
    size: "large",
    footerType: "outlined",
    onClose: _ctx.closeFilter,
    label: _ctx.headerText
  }, {
    content: _withCtx(() => [
      (_ctx.state && (_ctx.isEpisodes || _ctx.isEvents) && _ctx.isSwitchVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.type', 'f')) + ":", 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_NButtonGroup, {
                items: _ctx.dataAssetsModule.eventPageTypeButtonItems,
                modelValue: _ctx.state.pageType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.pageType) = $event))
              }, null, 8, ["items", "modelValue"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.state && (_ctx.isEpisodes || _ctx.isClusters || _ctx.isEvents) && _ctx.isSwitchVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('common.object', 'f')), 1),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.isEvents || _ctx.isClusters)
                ? (_openBlock(), _createBlock(_component_NButtonGroup, {
                    key: 0,
                    items: _ctx.dataAssetsModule.eventObjectTypeButtonItems,
                    modelValue: _ctx.state.objectType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.objectType) = $event))
                  }, null, 8, ["items", "modelValue"]))
                : _createCommentVNode("", true),
              (_ctx.isEpisodes)
                ? (_openBlock(), _createBlock(_component_NButtonGroup, {
                    key: 1,
                    items: _ctx.dataAssetsModule.episodeTypeButtonItems,
                    modelValue: _ctx.state.episodeType,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.episodeType) = $event))
                  }, null, 8, ["items", "modelValue"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.state && _ctx.isCards && _ctx.isSwitchVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('common.card_type', 'f')), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_NButtonGroup, {
                items: _ctx.dataAssetsModule.cardTypeButtonItems,
                modelValue: _ctx.state.cardType,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.cardType) = $event))
              }, null, 8, ["items", "modelValue"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_NForm, {
        layout: _ctx.formLayout,
        model: _ctx.model,
        "enabled-fields": _ctx.enabledFields,
        "onUpdate:enabledFields": _cache[4] || (_cache[4] = (v) => _ctx.$emit('update:enabled-fields', v)),
        ref: "form"
      }, null, 8, ["layout", "model", "enabled-fields"])
    ]),
    footer: _withCtx(() => [
      (_ctx.canCreateReport)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "secondary",
            onClick: _ctx.submitAndShowReportDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.submit_and_create_report', 'f')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_NButton, {
          type: "link",
          onClick: _ctx.resetFilters
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.reset_filters', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_NButton, {
          type: "primary",
          style: {"margin-left":"20px"},
          onClick: _ctx.submitAndClose
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.apply_filters', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "label"]))
}