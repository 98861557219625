
import { computed, defineComponent, toRefs } from 'vue';
import NButton from '../buttons/NButton.vue';
import NDropdown from '../NDropdown/NDropdown.vue';
import NDropdownItem from '../NDropdown/NDropdownItem.vue';

export default defineComponent({
  components: { NDropdownItem, NDropdown, NButton },
  props: {
    activeYear: {
      type: Number,
      default: () => new Date().getFullYear()
    },
    pastOffset: { type: [Number, String], default: 5 },
    futureOffset: { type: [Number, String], default: 5 }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const years = computed(() => {
      let start = props.activeYear - Number(props.pastOffset);
      let end = props.activeYear + Number(props.futureOffset);
      let years: number[] = [];
      for (let i = start; i <= end; i++) {
        years.push(i);
      }
      return years;
    });

    const selectHandler = (year: number) => emit('change', year);

    return {
      ...toRefs(props),
      years,
      selectHandler
    };
  }
});
