import { PageName, PageState, PageType } from '../application/page.definitions';
import { RouteLocationRaw } from 'vue-router';
import { pickBy } from 'lodash';
import router from '@/router';
import { CaseParticipant } from '@/api';

export const ExcludedFilterFields: Array<string> = ['limit'];
export const ExportedPageStateFields: Array<keyof PageState> = [
  'pageType',
  'objectType',
  'cardType',
  'episodeType',
  'id',
  'searchFrom',
  'subPage',
  'additionalId'
];

export type IRouteOptions = {
  name: string;
  id?: any;
  filter?: any;
  tab?: any;
  pageState?: Partial<PageState>;
};

export class RouterModule {
  static pageStateToQuery(value: Partial<PageState>): Record<string, any> {
    const filter = value?.filter || {};
    const exportedPageStateFields = pickBy(value, (v: any, k: any) => ExportedPageStateFields.indexOf(k) > -1 && checkHasValue(v));
    const exportedFilterFields = pickBy(filter, (v: any, k: any) => ExcludedFilterFields.indexOf(k) === -1 && checkHasValue(v));
    const hasFilter = Object.keys(exportedFilterFields).length;
    if (hasFilter) exportedPageStateFields.filter = exportedFilterFields;

    const query: any = Object.assign({}, router.currentRoute.value.query, {
      tab: value.tab,
      pageState: Object.keys(exportedPageStateFields).length ? JSON.stringify(exportedPageStateFields) : null
    });
    return query;
  }

  static queryToPageState(query: Record<string, any>): Partial<PageState> {
    const result: Partial<PageState> = { tab: query.tab, id: query.id };
    if (query?.pageState) Object.assign(result, JSON.parse(query.pageState as string));
    return result;
  }

  static getRouteLocation(options: IRouteOptions): RouteLocationRaw {
    const tab = options.tab ?? getRandomId();
    const pageState: Partial<PageState> = options.pageState || { id: options.id };
    pageState.tab = tab;
    return {
      path: '/' + options.name,
      query: this.pageStateToQuery(pageState)
    };
  }

  static navigateToCard(id: number, type: string): void {
    const location = RouterModule.getRouteLocation({ name: PageName.cards, pageState: { cardType: type, id } });
    router.push(location);
  }

  static navigateToInteractions(filter: Record<string, any>): void {
    const location = RouterModule.getRouteLocation({
      name: PageName.interactions,
      pageState: { pageType: PageType.clusters, objectType: 'faces', filter }
    });
    router.push(location);
  }

  static navigateToUser(id: number): void {
    const location = RouterModule.getRouteLocation({ name: PageName.users, pageState: { id } });
    router.push(location);
  }

  static navigateToCase(id: number, pageStateOptions?: any): void {
    const location = RouterModule.getRouteLocation({ name: PageName.case, pageState: { id, ...pageStateOptions } });
    router.push(location);
  }

  static navigateToParticipants(item: CaseParticipant): void {
    const location = RouterModule.getRouteLocation({
      name: PageName.case,
      pageState: { subPage: 'participants', id: item.case, additionalId: item.id }
    });
    router.push(location);
  }

  static navigateToRole(id: number): void {
    const location = RouterModule.getRouteLocation({ name: PageName.roles, pageState: { id } });
    router.push(location);
  }

  static navigateToEpisode(id: number, type: string): void {
    const location = RouterModule.getRouteLocation({ name: PageName.events, pageState: { pageType: PageType.episodes, episodeType: type, id } });
    router.push(location);
  }

  static navigateToEvents(type: string, filter: any): void {
    const location = RouterModule.getRouteLocation({
      name: PageName.events,
      pageState: { pageType: PageType.events, objectType: type, filter }
    });
    router.push(location);
  }

  static navigateToWatchList(id: number): void {
    const location = RouterModule.getRouteLocation({ name: PageName.watch_lists, pageState: { id } });
    router.push(location);
  }

  static navigateToCameraGroup(id: number): void {
    const location = RouterModule.getRouteLocation({ name: PageName.camera_groups, pageState: { id } });
    router.push(location);
  }

  static navigateToExternalSearch(id: number): void {
    const location = RouterModule.getRouteLocation({ name: PageName.external_search, pageState: { id } });
    router.push(location);
  }
}

function checkHasValue(v: any): boolean {
  return Array.isArray(v) ? !!v.length : v === false || v === 0 ? true : !!v;
}

export function getRandomId() {
  return Math.round(Math.random() * Number.MAX_SAFE_INTEGER).toString(36);
}
