
import { nextTick, reactive, computed, defineComponent } from 'vue';
import { string, bool, oneOfType } from 'vue-types';
import { Position } from '@/uikit/input-color/helpers/types';
import { Events } from '@/uikit/input-color/enums';
import { defaultColorBox, displayedRgb, hexToRgb, regRegExp, rgbaRegExp, hexRegExp, rgbToHex } from '@/uikit/input-color/helpers/utils';
import ClickOutside from '@/directives/click-outside';
import { createStickDirective } from '@/directives/stick';

export default defineComponent({
  name: 'NColorPicker',
  props: {
    modelValue: string().def(''),
    defaultColor: oneOfType([String, Number]),
    disabled: bool().def(false),
    colorFormat: string().def('hex')
  },
  emits: [Events.Change, Events.Confirm, Events.Clear, Events.ActiveChange],
  directives: { ClickOutside, Stick: createStickDirective<HTMLDivElement, HTMLDivElement>() },
  setup(props, context) {
    const ids = {
      canvas: 'canvas-' + Date.now(),
      cur: 'cur-' + Date.now(),
      bar: 'bar-' + Date.now()
    };
    const picker: any = reactive({
      canvas: null,
      ctx: null,
      currentColor: '',
      activeColor: '',
      alpha: 1,
      width: 286,
      height: 200,
      showPanel: false
    });

    const displayedColor = computed(() => {
      if (!hexRegExp.test(`#${props.modelValue}`)) return 'transparent';

      return displayedRgb(`#${props.modelValue}`);
    });

    function handleChange() {
      context.emit(Events.Change, picker.currentColor);
    }

    function handleConfirm() {
      context.emit(Events.Confirm);
    }

    function handleClear() {
      context.emit(Events.Clear);
    }

    function hidePanel() {
      picker.showPanel = false;
      picker.currentColor = props.defaultColor;
      handleChange();
    }

    function confirm() {
      picker.showPanel = false;
      handleConfirm();
    }

    function clear() {
      picker.showPanel = false;
      picker.currentColor = '#';
      handleClear();
    }

    function makeColorBar() {
      const gradientBar = picker.ctx.createLinearGradient(0, 0, 0, picker.height);
      gradientBar.addColorStop(0, '#f00');
      gradientBar.addColorStop(1 / 6, '#f0f');
      gradientBar.addColorStop(2 / 6, '#00f');
      gradientBar.addColorStop(3 / 6, '#0ff');
      gradientBar.addColorStop(4 / 6, '#0f0');
      gradientBar.addColorStop(5 / 6, '#ff0');
      gradientBar.addColorStop(1, '#f00');

      picker.ctx.fillStyle = gradientBar;
      picker.ctx.fillRect(0, 0, 20, picker.height);
    }

    function makeColorBox(color: string = defaultColorBox) {
      if (!hexRegExp.test(`#${props.modelValue}`)) color = defaultColorBox;

      const gradientBase = picker.ctx.createLinearGradient(30, 0, picker.width, 0);
      gradientBase.addColorStop(1, color);
      gradientBase.addColorStop(0, 'rgba(255,255,255,1)');
      picker.ctx.fillStyle = gradientBase;
      picker.ctx.fillRect(30, 0, picker.width, picker.height);

      const gradientSecondary = picker.ctx.createLinearGradient(0, 0, 0, picker.height);
      gradientSecondary.addColorStop(0, 'rgba(0,0,0,0)');
      gradientSecondary.addColorStop(1, 'rgba(0,0,0,1)');
      picker.ctx.fillStyle = gradientSecondary;
      picker.ctx.fillRect(30, 0, picker.width, picker.height);
    }

    function onCanvasClick(event: PointerEvent) {
      const eventPos = {
        x: event.offsetX,
        y: event.offsetY
      };
      let rgba: string[] = [];

      if (eventPos.x >= 0 && eventPos.x < 20) {
        rgba = getRgbaAtPoint(eventPos, 'bar');
        const barBlock = document.getElementById(ids.bar);

        if (barBlock) barBlock.style.top = eventPos.y + 'px';

        makeColorBox('rgb(' + rgba.slice(0, 3).join() + ')');
        context.emit(Events.ActiveChange, rgbToHex('rgba(' + rgba.slice(0, 3).join() + ',' + picker.alpha + ')'));
      } else if (eventPos.x >= 30) {
        rgba = getRgbaAtPoint(eventPos, 'box');
        const cur = document.getElementById(ids.cur);

        if (cur) {
          cur.style.left = eventPos.x + 'px';
          cur.style.top = eventPos.y + 'px';
        }
      } else {
        return;
      }
      setCurrentColor(rgba);
    }

    function onCanvasMouseUp() {
      window.onmousedown = null;
      window.onmousemove = null;
    }

    function onCanvasMousedown(event: MouseEvent, type: string) {
      const eventPos = {
        x: event.offsetX,
        y: event.offsetY
      };

      const { offsetTop, offsetLeft } = event.target as HTMLCanvasElement;

      document.onmouseup = () => (document.onmouseup = document.onmousemove = null);

      if (type === 'cur' || (eventPos.x >= 30 && eventPos.x < 30 + picker.width && eventPos.y >= 0 && eventPos.y < picker.height)) {
        const cur = document.getElementById(ids.cur);

        document.onmousemove = (e) => {
          try {
            const pos = {
              x: e.clientX - event.clientX + event.offsetX + offsetLeft,
              y: e.clientY - event.clientY + event.offsetY + offsetTop
            };

            pos.x = pos.x <= 30 ? 30 : pos.x >= 282 ? 282 : pos.x && (pos.x > picker.width ? picker.width : pos.x);
            pos.y = pos.y <= 0 ? 0 : pos.y >= 196 ? 196 : pos.y && (pos.y > picker.height ? picker.height : pos.y);

            const rgbaStr = getRgbaAtPoint(pos, 'box');
            setCurrentColor(rgbaStr);

            if (cur) {
              cur.style.left = pos.x + 'px';
              cur.style.top = pos.y + 'px';
            }
          } catch (e) {
            console.warn(e);
          }
        };
      } else if (eventPos.x <= 20 && eventPos.y <= picker.height) {
        const bar = document.getElementById(ids.bar);

        document.onmousemove = (e) => {
          try {
            const pos = {
              x: 0,
              y: e.clientY - event.clientY + event.offsetY + offsetTop
            };

            pos.y = pos.y <= 0 ? 0 : pos.y && (pos.y > picker.height ? picker.height : pos.y);

            const rgbaStr = getRgbaAtPoint(pos, 'box');
            if (bar) bar.style.top = pos.y + 'px';
            picker.activeColor = rgbToHex('rgb(' + rgbaStr.slice(0, 3).join() + ')');
            makeColorBox('rgb(' + rgbaStr.slice(0, 3).join() + ')');
            context.emit(Events.ActiveChange, picker.activeColor);
          } catch (e) {
            console.warn(e);
          }
        };
      }
    }

    function initCanvas(el: HTMLElement) {
      if (el && !picker.ctx) {
        picker.canvas = el;
        picker.ctx = picker.canvas.getContext('2d');
        makeColorBar();
        makeColorBox(`#${props.modelValue}`);
      } else {
        if (!picker.showPanel) {
          picker.ctx = null;
        }
      }
    }

    function resetCurrentColor() {
      let currentColor = props.modelValue;
      if (!regRegExp.test(currentColor)) currentColor = hexToRgb(currentColor.slice(0, 7));
      const color = currentColor.replace(rgbaRegExp, '').split(',');
      color[3] = picker.alpha.toString();
      setCurrentColor(color);
    }

    function setCurrentColor(rgba: Array<number | string>) {
      let alfa = 'a';
      if (rgba.length === 4 && rgba[3] == '1') {
        rgba = rgba.slice(0, 3);
        alfa = '';
      }
      if (props.colorFormat === 'hex') {
        picker.currentColor = rgbToHex('rgb' + alfa + '(' + rgba.join() + ')');
      } else if (props.colorFormat === 'rgb') {
        picker.currentColor = 'rgb' + alfa + '(' + rgba.join() + ')';
      }

      if (rgba.every((v: unknown) => typeof v === 'string' || typeof v === 'number')) handleChange();
    }

    function getWidthImageData(area: string) {
      return area === 'bar' ? 20 : picker.width;
    }

    function getRgbaAtPoint(pos: Position, area: string) {
      const imageData = picker.ctx.getImageData(0, 0, getWidthImageData(area), picker.height);
      const data = imageData.data;
      const dataIndex = (pos.y * imageData.width + pos.x) * 4;

      if (pos.x >= 30 && pos.y > picker.height - 3) {
        return [0, 0, 0, picker.alpha];
      }
      if (pos.x >= 30 && pos.y <= 1) {
        data[dataIndex] = 255;
      }
      if (pos.x >= 30 && pos.x <= 31) {
        return [data[dataIndex], data[dataIndex], data[dataIndex], picker.alpha];
      }
      if (pos.x >= picker.width - 1) {
        return [data[dataIndex], 0, 0, picker.alpha];
      }

      return [data[dataIndex], data[dataIndex + 1], data[dataIndex + 2], picker.alpha];
    }

    function toggleShowPanel() {
      if (props.disabled) return;
      picker.showPanel = !picker.showPanel;
    }

    nextTick(() => {
      picker.currentColor = props.modelValue;
      resetCurrentColor();
    });

    function closeByOutside() {
      hidePanel();
    }

    return {
      ids,
      picker,
      displayedColor,
      toggleShowPanel,
      onCanvasMousedown,
      onCanvasMouseUp,
      onCanvasClick,
      clear,
      confirm,
      initCanvas,
      closeByOutside
    };
  }
});
