
import { Options, Vue } from 'vue-class-component';
import { NButton, NForm, NTabs } from '@/uikit';
import { informationLayout, attachmentsLayout } from './schema';
import Sidebar from '@/components/common/sidebar/Sidebar.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import { Prop } from 'vue-property-decorator';
import { CarCard, CarClusterEvent, CaseParticipant, FaceClusterEvent, HumanCard } from '@/api';
import { ItemViewModel } from '@/definitions/view-models';
import ParticipantRelatedItems from './ParticipantRelatedItems.vue';
import ParticipantClusterEvents from './ParticipantClusterEvents.vue';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { DefaultItemAclResult } from '@/store/acl';
import ClusterWizard from '@/components/cases/ClusterWizard.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';

const Tabs = {
  Information: 'information',
  Cluster: 'cluster',
  Connections: 'connections'
};

@Options({
  name: 'ParticipantSidebar',
  components: { ClusterWizard, ParticipantRelatedItems, ParticipantClusterEvents, Sidebar, SidebarHeader, SidebarTab, NButton, NForm, NTabs }
})
export default class ParticipantSidebar extends Vue {
  activeTab = Tabs.Information;
  tabs = Tabs;
  wizardParticipant!: EnrichedParticipant;
  wizardClusterEnabled = false;

  @Prop({ type: Boolean, default: true })
  readonly hasChanges!: boolean;

  @Prop({ type: Object, default: () => DefaultItemAclResult })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Object, default: () => DefaultItemAclResult })
  readonly modelAcl!: IModelAclResult;


  @Prop({ type: Object, required: true })
  readonly currentItem!: CaseParticipant;

  @Prop({ type: Object })
  readonly thumbnailsMap?: Record<number, string>;

  @Prop({ type: Array, default: [] })
  readonly selectedItems!: ItemViewModel<CaseParticipant>[];

  @Prop({ type: Array, default: [] })
  readonly clusterEvents!: Array<FaceClusterEvent | CarClusterEvent>;

  @Prop({ type: Array, default: [] })
  readonly relatedCards!: Array<HumanCard | CarCard>;

  @Prop({ type: Array, default: [] })
  readonly relatedParticipants!: CaseParticipant[];

  get relatedItemsCount() {
    return this.relatedParticipants.length + this.relatedCards.length;
  }

  get tabItems() {
    return [
      {
        name: Tabs.Information,
        label: this.$t(`cases.${Tabs.Information}`, 'f')
      },
      {
        name: Tabs.Cluster,
        label: this.$t(`cases.${Tabs.Cluster}`, 'f'),
        counter: this.clusterEvents.length
      },
      {
        name: Tabs.Connections,
        label: this.$t(`cases.${Tabs.Connections}`, 'f'),
        counter: this.relatedItemsCount
      }
    ];
  }

  get itemForm() {
    return [...informationLayout, ...attachmentsLayout];
  }

  async editConnections() {
    [this.wizardParticipant] = await enrichParticipants([this.currentItem]);
    this.wizardClusterEnabled = true;
  }
}
