
import { NIcon } from '@/uikit';
import { NDropdown, NDropdownItem } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import { Prop } from "vue-property-decorator";

export interface IListItem {
  label: string;
  name: string;
  icon?: string;
}

@Options({
  name: 'MoreActions',
  components: { NButton, NIcon, NDropdown, NDropdownItem }
})
export default class MoreActions extends Vue {
  @Prop({ required: true })
  actions!: IListItem[];

  async actionHandler(name: string) {
    this.$emit('action', name);
  }
}
