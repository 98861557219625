import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { getLogger } from 'loglevel';
const logger = getLogger('[bundle-request]');
logger.setLevel('warn');

const BundleTypes = {
  Default: 'default',
  CardObjects: 'card-objects'
} as const;

type BundleTypeKeys = keyof typeof BundleTypes;

type BundleConfig = {
  config: AxiosRequestConfig;
  path: string;
  key: string;
  model: string;
  type: string;
  index: string;
  bundleType: typeof BundleTypes[BundleTypeKeys]
  resolveHandler: any;
  rejectHandler: any;
  promise: any;
};

const DefaultAdapter = axios.defaults.adapter!;
const BundleIntervalMs = 320;


class AxiosBundleRequestAdapter {
  bundleMap: Record<string, BundleConfig[]> = {};
  interval = 0;

  constructor() {
    this.handleRequest = this.handleRequest.bind(this);
    this.handleBundles = this.handleBundles.bind(this);
    this.interval = setInterval(this.handleBundles, BundleIntervalMs) as any;
  }

  getBundleConfig(config: AxiosRequestConfig): BundleConfig | undefined {
    const supportedModels = ['cards', 'objects'];
    const pathParts = config.url?.split('/').filter((v) => !!v) as string[];
    const isSupportedMethod = config.method?.toLowerCase() === 'get';

    const [model, type, id ] = pathParts;
    const path = '/' + model + '/' + type + '/';


    const isSupportedId = id ? parseInt(id) > 0 : false;
    const isSupportedPath = supportedModels.includes(model) && pathParts.length === 3;
    const hasParams = Object.entries(config.params || {}).length > 0;
    const isDefaultBundleSupport = isSupportedMethod && isSupportedPath && isSupportedId && !hasParams;
    const card = config.params?.card;
    const isCardObjectBundleSupport = isSupportedMethod && model === 'objects' && !id && config.params?.limit === 1 && (Array.isArray(card) ? card.length : card);
    const bundleType = isCardObjectBundleSupport ? BundleTypes.CardObjects : BundleTypes.Default;
    const index = id || config.params?.card;

    const key = path + '::' + bundleType;

    if (!isDefaultBundleSupport && !isCardObjectBundleSupport) return undefined;
    logger.info('[bundle] has support: ', key, isCardObjectBundleSupport, isDefaultBundleSupport, config.params, model, type, id, config.params?.card, config.params?.limit);

    let resolveHandler = null;
    let rejectHandler = null;
    const promise = new Promise((r, j) => {
      resolveHandler = r;
      rejectHandler = j;
    });

    const bundleConfig: BundleConfig = {
      config,
      path,
      bundleType,
      key,
      model,
      type,
      index,
      resolveHandler,
      rejectHandler,
      promise
    };

    return bundleConfig;
  }

  async handleBundles() {
    for (let key in this.bundleMap) {
      const items = this.bundleMap[key];
      if (!items?.length) continue;
      logger.info('[bundle] handle ', key, ', count ', items.length);
      const indexes = [ ...new Set(items.map((v) => v.index)) ];
      const defaultBundleConfig = this.bundleMap[key][0];
      const config = defaultBundleConfig.config;
      const path = defaultBundleConfig.path;
      const bundleType = defaultBundleConfig.bundleType;
      const getBundleKeyFunction = bundleType === BundleTypes.Default ? (v: any) => v.id : (v: any) => v.card;
      const getResultFunction = bundleType === BundleTypes.Default ? (v: any) => v : (v: any) => ({ results: [v] });

      this.bundleMap[key] = [];
      config.url = path;
      config.params = bundleType === BundleTypes.Default ? { id_in: indexes } : { card: indexes, limit: 200 };

      DefaultAdapter(config)
        .then((v) => this.defaultSuccessHandler(v, items, getBundleKeyFunction, getResultFunction))
        .catch((e) => this.defaultRejectHandler(e, items));
    }
  }

  defaultSuccessHandler(v: AxiosRequestConfig, items: BundleConfig[], getBundleKeyFunction: any, getResultFunction: any) {
    const loadedItems = JSON.parse(v.data)?.results || [];
    logger.info('[bundle] resolve ', items[0].key, ', result ', loadedItems);
    const loadedItemsMap = loadedItems.reduce((m: Record<string, any>, v: any) => {
      m[getBundleKeyFunction(v)] = v;
      return m;
    }, {});
    items.forEach((bundleConfig) => {
      const loadedItem = loadedItemsMap[bundleConfig.index];
      if (loadedItem) {
        const response = { ...v };
        response.data = getResultFunction(loadedItem);
        bundleConfig.resolveHandler(response);
      } else {
        bundleConfig.rejectHandler('not_found');
      }
    });
  }

  defaultRejectHandler(e: Error, items: BundleConfig[]) {
    items.forEach((bundleConfig) => {
      bundleConfig.rejectHandler(e);
    });
  }

  handleRequest(config: AxiosRequestConfig): AxiosPromise {
    const bundleConfig = this.getBundleConfig(config);

    if (bundleConfig) {
      const key = bundleConfig.key;
      if (!this.bundleMap[key]) this.bundleMap[key] = [];
      this.bundleMap[key].push(bundleConfig);
      return bundleConfig.promise;
    }

    return DefaultAdapter(config).then(async (v) => {
      return v;
    });
  }
}

export const axiosBundleAdapter = new AxiosBundleRequestAdapter();
