import { request } from '@/api/core/request';
import blobToBase64 from '@/common/blobToBase64';
import { aclModule } from '@/store/acl';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import { PageState } from '@/store/application/page.definitions';
import { configModule } from '@/store/config';
import { getMsbStateFromUrl, getPageStateByMsbType, parseMultisidebarId } from '@/store/multisidebar/helpers';
import type { NAttachment, NAttachmentFileBlob } from '@/uikit/NAttachments';

export function getCurrentItemStateFromUrl(url: string): Partial<PageState> {
  const msbState = getMsbStateFromUrl(url);
  if (msbState.currentId) {
    const { type, rawItem } = parseMultisidebarId(msbState.currentId);
    return { ...getPageStateByMsbType(type), id: rawItem };
  }
  return {};
}

export function objectFromFile(files: NAttachment[]) {
  if (!files.length) return {};
  return blobToBase64(files[0].file as NAttachmentFileBlob).then((base64File) => ({ url: base64File }));
}

export const SearchModes = {
  Faces: 'faces',
  Bodies: 'bodies',
  Cars: 'cars'
};

export function getAvailableObjects(): Record<string, boolean> {
  let entries = ['face', 'body', 'car'].map((objectType) => {
    let multiObjectType = ObjectsSingleToMulti[objectType];
    let enabled = configModule.config.objects[multiObjectType]?.enabled;
    let hasAccess = aclModule.getAccess(`ffsecurity.view_${objectType}object`);
    return [objectType, Boolean(enabled && hasAccess)];
  });
  return Object.fromEntries(entries);
}

export async function getExternalImage(url: string): Promise<Blob> {
  return request({
    method: 'POST',
    path: '/download/',
    headers: {
      Accept: 'image/jpeg'
    },
    body: {
      url
    },
    responseType: 'blob'
  });
}
