
import { Options, Vue } from 'vue-class-component';
import NLauncher from '@/uikit/launcher/NLauncher.vue';
import { getItems } from '@/store/menu';
import { workspaceModule } from '@/store/application/workspace';
import { MenuItem } from '@/uikit/launchpad/NLaunchpad.vue';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  name: 'LauncherPage',
  components: { NLauncher }
})
export default class LauncherPage extends Vue {
  get formattedItems(): MenuItem[] {
    return this.items.map((item) => {
      return {
        path: item.path!,
        icon: item.icon!,
        title: this.$t(item.i18n),
        description: item.i18n_description && this.$t(item.i18n_description)
      };
    });
  }

  get items() {
    return getItems();
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  openNewTab(path: string) {
    workspaceModule.addItem(path);
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  mounted() {
    this.sidebarModule.contentOverlap = true;
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }
}
