
import { HumanEpisode } from '@/api';
import { isDefined } from '@/common/utils';
import EventItemV2Full from '@/components/events/EventItemV2Full.vue';
import { websocketModule } from '@/store/ws/websocket.module';
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import type { CameraId, Nullable } from '../../types';
import { fetchHumanEpisodes } from '../../utils';
import EventsPage from '@/pages/events/EventsPage.vue';

@Options({
  components: { EventsPage, EventItemV2Full }
})
export default class VideoWallEpisodeList extends Vue {
  @Prop({ required: true })
  readonly tab: string = '';

  @Prop({ required: true, type: Array })
  readonly cameraIds!: CameraId[];

  get filters() {
    return { cameras: this.cameraIds };
  }
}
