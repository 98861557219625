import { reactive, watch } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { IWorkspaceTab } from '@/uikit/workspace-tabs/definitions';
import { filter } from 'lodash';
import { localStorageModule } from '@/store/application/local.storage';
import { languageModule } from '@/store/languages';
import { PageState } from '@/store/application/page.definitions';
import { RouterModule } from '@/store/router';
import { getPageStateByName } from '@/store/application/page.state';
import { tabLabelModule } from '@/store/application/tab.label';
import { additionalItems, launcherItems } from '../menu/launcher.items';
import { getItems } from '@/store/menu';
import { settingsItems } from '@/store/menu/settings.items';
import { IMenuItem } from '@/definitions/config/menu';

function fillEmptyIcon(items: IMenuItem[], defaultIcon = 'settings') {
  return items.map((v) => ({ ...v, icon: v.icon ?? defaultIcon }));
}

export class WorkspaceModule {
  static Name = 'WorkspaceModule';
  static StoredItems = ['items'];

  items: PageState[] = [];
  current?: string;

  constructor() {
    this.swapTabs = this.swapTabs.bind(this);
  }

  supportPageState(value: string): boolean {
    return value !== 'launcher';
  }

  changeRouteHandler(route: RouteLocationNormalized) {
    const { path, query } = route;
    const tab: string | undefined = query?.tab ? String(query.tab) : undefined;
    if (tab && this.supportPageState(tab) && !this.hasItemByTab(tab)) {
      const options: any = RouterModule.queryToPageState(query);
      this.addItem(path, options);
    }
    this.current = tab;
  }

  getTabItemByTab(value: string): IWorkspaceTab | undefined {
    return this.computedTabItems.find((tab) => tab.tab === value);
  }

  getItemByTab(value: string): PageState | undefined {
    return this.filteredItems.find((tab) => tab.tab === value);
  }

  get filteredItems() {
    return this.items.filter((v) => !!v);
  }

  get currentItem(): PageState | undefined {
    return this.current && this.supportPageState(this.current) ? this.getItemByTab(this.current) : undefined;
  }

  getTabLabelAndIcon(pageState: PageState) {
    const { pageName } = pageState;
    const menuItem = this.translatedItems.find((item) => item.path === '/' + pageName);
    return {
      label: tabLabelModule.get(pageState) || menuItem?.title || 'Empty title',
      icon: menuItem?.icon || 'empty'
    };
  }

  get computedTabItems(): IWorkspaceTab[] {
    return this.filteredItems.map((v: PageState) => {
      const { tab, id, pageName } = v;
      const path = '/' + pageName;
      const { label, icon } = this.getTabLabelAndIcon(v);
      const query = filter({ tab, id, pageState: v ? JSON.stringify(v) : null }, (v: any) => !!v);
      return { label, icon, tab, path, query, pageName };
    });
  }

  hasItemByTab(value: string): boolean {
    return this.filteredItems.filter((v) => !!v).findIndex((v) => v.tab === value) > -1;
  }

  removeItemsWithoutViewRights() {
    this.items = this.items.filter((v) => this.hasItemByPageName(v.pageName));
  }

  hasItemByPageName(value: string): boolean {
    return !!getItems(false, true).find((item) => item.path === `/${value}`);
  }

  get translatedItems() {
    return [...launcherItems, ...fillEmptyIcon(settingsItems)].map((item) => {
      return {
        ...item,
        title: languageModule.getTranslatedToken(item.i18n, 'f'),
        description: item.i18n_description && languageModule.getTranslatedToken(item.i18n_description, 'f'),
        locale: languageModule.locale
      };
    });
  }

  addItem(path: string, options?: Partial<PageState>): void {
    const tab = options?.tab || String(Math.random().toString(36).substring(2, 15));
    const pageName = path.substring(1) as any;
    const item: PageState = getPageStateByName(pageName);
    item.tab = tab;
    if (options) Object.assign(item, options);
    this.items.push(item);
    this.current = tab;
  }

  removeItem(i: number) {
    const index = this.filteredItems.findIndex((item: PageState) => item.tab === this.current);
    if (i === index) {
      let after, before;
      after = this.items[i + 1];
      before = this.items[i - 1];
      this.current = after?.tab || before?.tab || 'launcher';
    }
    this.items.splice(i, 1);
  }

  swapTabs(payload: number[]): void {
    const [firstIndex, secondIndex] = payload;
    const firstItem = this.items[firstIndex];
    const secondItem = this.items[secondIndex];
    this.items[firstIndex] = secondItem;
    this.items[secondIndex] = firstItem;
  }

  reset() {
    this.items = [];
    this.current = '';
  }
}

export const workspaceModule = reactive(new WorkspaceModule());

export function initWorkspaceModule() {
  localStorageModule.registerInstance({ instance: workspaceModule, name: WorkspaceModule.Name, tokens: WorkspaceModule.StoredItems });
  localStorageModule.syncFromStorageByName(WorkspaceModule.Name);
  watch(() => workspaceModule.items, syncWorkspaceModuleToStorage, { deep: true });
}

export function syncWorkspaceModuleToStorage() {
  localStorageModule.syncToStorageByName(WorkspaceModule.Name);
}
