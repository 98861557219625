export default {
  delete_full_frames_of_matched_events_older_than: 'Delete full frames of matched events older than',
  delete_full_frames_of_unmatched_events_older_than: 'Delete full frames of unmatched events older than',
  delete_matched_events_older_than: 'Delete matched events older than',
  delete_unmatched_events_older_than: 'Delete unmatched events older than',
  events_cleanup: 'Events cleanup',
  generic_confidence_threshold: 'Generic confidence threshold',
  hours: 'hours',
  thumbnail_jpeg_quality: 'Thumbnail JPEG quality',
  update: 'update'
};
