import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar__tabs" }
const _hoisted_3 = { class: "sidebar__content label-m" }
const _hoisted_4 = { class: "sidebar__content-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "sidebar-tabs")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "header")
      ]),
      _renderSlot(_ctx.$slots, "tabs"),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}