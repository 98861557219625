
import { Case } from '@/api';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import SettingsMultitool from '@/components/common/SettingsMultitool.vue';
import Permissions from '@/components/permissions/Permissions.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { columns } from '@/pages/cases/case-table-schema';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import EmptyCasePage from '@/pages/cases/EmptyCasePage.vue';
import ListPage from '@/pages/ListPage.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { dataModule } from '@/store/data';
import { RouterModule } from '@/store/router';
import { NLoadingCircle } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  components: {
    EditCasePage,
    EmptyCasePage,
    FiltersSmall,
    ListPage,
    NButton,
    NButtonGroup,
    NForm,
    NInput,
    NLoadingCircle,
    NTable,
    PageContentLayout,
    Permissions,
    SettingsMultitool
  }
})
export default class CasesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  get columns() {
    return columns;
  }

  get state(): PageState {
    const tab = this.tab;
    const state = pageModule.getPageStateByTab(PageName.cases, tab);
    return state;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get module() {
    const state = this.state;
    const module = pageModule.getPageModule(state) as unknown as ListViewModel<any, any>;
    module.aclModelName = 'case';
    return module;
  }

  get selectedItems() {
    return this.module.selectedItems.map((v) => v.item);
  }

  get currentItem() {
    return this.module.currentItem?.item;
  }

  get showNewCase() {
    return this.module.loaded && !this.module.items.length && !this.module.filter.current?.status && !this.module.filter.current?.name_contains;
  }

  get statusFilterItems() {
    const items = [
      { name: undefined, i18n_label: 'cases.filter_all' },
      { name: 'open', i18n_label: 'cases.filter_open' },
      { name: 'archived', i18n_label: 'cases.filter_archived' }
    ];
    return items.map((v) => ({ name: v.name, label: this.$t(v.i18n_label, 'f') }));
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  @Watch('showNewCase', { immediate: true })
  showNewCaseHandler(v: boolean) {
    this.state.showOverlay = v;
  }

  @Watch('currentItem')
  currentItemHandler(v: any) {
    this.state.showOverlay = !!v;
  }

  @Watch('selectedItems')
  selectedItemsHandler(v: any[]) {
    this.state.showMultitool = !!v.length;
  }

  @Watch('module.filter.current.status')
  statusHandler() {
    this.module.get();
  }

  @Watch('module.filter.current.name_contains')
  searchHandler() {
    this.module.get();
  }

  mounted() {
    this.module.get();
  }

  async saveHandler() {
    const currentItem = this.module.currentItem?.item;
    if (currentItem) currentItem.author = dataModule.currentUserModule.item?.id;
    await this.module.saveCurrentItem();
    await this.module.get({ resetState: false });
    this.module.currentItemId = undefined;
  }

  cellClickHandler(row: Case, cell: ITableCell) {
    if (cell.path === 'name') RouterModule.navigateToCase(row.id);
    else if (cell.path === 'author') RouterModule.navigateToUser(row.author);
  }

  createHandler() {
    this.module.addNewItem();
  }

  async updateStatusOfSelectedItems(v: 'archived' | 'open') {
    for (const itemModel of this.module.selectedItems) {
      await itemModel.update({ id: itemModel.item.id, status: v });
    }
  }

  async handleAction(actionName: string) {
    switch (actionName) {
      case ItemsActionNames.SelectAll:
        this.module.selectAllItems();
        break;

      case ItemsActionNames.DeselectAll:
        this.module.deselectAllItems();
        break;

      case ItemsActionNames.DeleteAll:
        await this.module.deleteAllSelectedItems();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Delete:
        await this.module.deleteCurrentItem();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.ArchiveAll:
        await this.updateStatusOfSelectedItems('archived');
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.OpenAll:
        await this.updateStatusOfSelectedItems('open');
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Deselect:
        this.module.deselectCurrentItem();
        break;

      case ItemsActionNames.Close:
        await this.module.deleteCurrentItem();
        break;

      default:
        console.warn(`Unknown action "${actionName}"`);
    }
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  handleTableSort(columnName: string) {
    if (columnName === 'name') {
      this.module.filter.current.ordering = this.module.filter.current.ordering === columnName ? `-${columnName}` : columnName;
      this.module.get();
    }
  }
}
