import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportsPageDownloader = _resolveComponent("ReportsPageDownloader")!
  const _component_ReportsPageTable = _resolveComponent("ReportsPageTable")!
  const _component_ReportsPageMultitool = _resolveComponent("ReportsPageMultitool")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, {
    "has-multitool": !!_ctx.selectedIds.length,
    onFetch: _ctx.loadNextPage
  }, {
    multitool: _withCtx(() => [
      _createVNode(_component_ReportsPageMultitool, {
        "selected-count": _ctx.selectedIds.length,
        onDelete: _ctx.deleteSelected,
        onDownload: _ctx.handleDownloadSelected,
        onSelectAll: _ctx.selectAll,
        onUnselectAll: _ctx.clearSelections,
        onUpdate: _ctx.updateSelected
      }, null, 8, ["selected-count", "onDelete", "onDownload", "onSelectAll", "onUnselectAll", "onUpdate"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ReportsPageDownloader, { ref: "downloader" }, null, 512),
      _createVNode(_component_ReportsPageTable, {
        items: _ctx.reports,
        "selected-ids": _ctx.selectedIds,
        onDownload: _ctx.handleDownload,
        onSort: _ctx.sort
      }, null, 8, ["items", "selected-ids", "onDownload", "onSort"])
    ]),
    _: 1
  }, 8, ["has-multitool", "onFetch"]))
}