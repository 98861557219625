import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarTab = _resolveComponent("SidebarTab")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_ParticipantClusterEvents = _resolveComponent("ParticipantClusterEvents")!
  const _component_ParticipantRelatedItems = _resolveComponent("ParticipantRelatedItems")!
  const _component_ClusterWizard = _resolveComponent("ClusterWizard")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_ctx.currentItem)
    ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }, {
        "sidebar-tabs": _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (model) => {
            return (_openBlock(), _createBlock(_component_SidebarTab, {
              key: model.item.id,
              label: model.item.name,
              changed: model.hasChanges,
              selected: model.item.id === _ctx.currentItem.id,
              "image-url": _ctx.thumbnailsMap?.[model.item.id],
              onClick: ($event: any) => (_ctx.$emit('select', model.item))
            }, null, 8, ["label", "changed", "selected", "image-url", "onClick"]))
          }), 128))
        ]),
        default: _withCtx(() => [
          _createVNode(_component_SidebarHeader, {
            label: _ctx.currentItem.name,
            changed: _ctx.hasChanges,
            onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', $event))),
            "current-item-acl": _ctx.currentItemAcl
          }, {
            beforeActions: _withCtx(() => [
              _createVNode(_component_NButton, {
                onClick: _ctx.editConnections,
                type: "secondary",
                icon: "search",
                "icon-size": 16
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.connections', 'f')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["label", "changed", "current-item-acl"]),
          _createVNode(_component_NTabs, {
            items: _ctx.tabItems,
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
            class: "multisidebar-content__tabs control-m"
          }, null, 8, ["items", "modelValue"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.activeTab === _ctx.tabs.Information)
              ? (_openBlock(), _createBlock(_component_NForm, {
                  key: 0,
                  disabled: !_ctx.currentItemAcl.update,
                  layout: _ctx.itemForm,
                  model: _ctx.currentItem,
                  ref: "itemForm",
                  onSelect: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["disabled", "layout", "model"]))
              : (_ctx.activeTab === _ctx.tabs.Cluster)
                ? (_openBlock(), _createBlock(_component_ParticipantClusterEvents, {
                    key: 1,
                    "cluster-events": _ctx.clusterEvents
                  }, null, 8, ["cluster-events"]))
                : (_ctx.activeTab === _ctx.tabs.Connections)
                  ? (_openBlock(), _createBlock(_component_ParticipantRelatedItems, {
                      key: 2,
                      "related-participants": _ctx.relatedParticipants,
                      "related-cards": _ctx.relatedCards
                    }, null, 8, ["related-participants", "related-cards"]))
                  : _createCommentVNode("", true)
          ]),
          (_ctx.wizardClusterEnabled)
            ? (_openBlock(), _createBlock(_component_ClusterWizard, {
                key: 0,
                caseId: _ctx.wizardParticipant.case,
                cluster: _ctx.wizardParticipant.faceClusterObject,
                participant: _ctx.wizardParticipant,
                "participants-acl": _ctx.modelAcl,
                onClose: _cache[3] || (_cache[3] = ($event: any) => {
          _ctx.wizardClusterEnabled = false;
          _ctx.$emit('refresh');
        })
              }, null, 8, ["caseId", "cluster", "participant", "participants-acl"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}