import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cameras-container label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraLabelItem = _resolveComponent("CameraLabelItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (item) => {
      return (_openBlock(), _createBlock(_component_CameraLabelItem, {
        key: item.id,
        "group-name": _ctx.getCameraGroup(item.group)?.name,
        name: item.name,
        "has-icon": _ctx.hasIcon,
        class: "cameras-container__item",
        onClick: _withModifiers(($event: any) => (_ctx.clickHandler(item.id)), ["stop"])
      }, null, 8, ["group-name", "name", "has-icon", "onClick"]))
    }), 128))
  ]))
}