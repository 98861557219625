import { IMenuItem, IMenuItemMeta } from '@/definitions/config/menu';
import { aclModule } from '@/store/acl';
import { configAclModule } from '@/store/config/acl';
import { configModule } from '@/store/config';
import { additionalItems, launcherItems } from '@/store/menu/launcher.items';
import { settingsItems } from '@/store/menu/settings.items';
import { RouterModule } from '@/store/router';
import { PageName } from '@/store/application/page.definitions';
import router from '@/router';

export function fillQuery(menuItem: IMenuItem) {
  const settingsLocation = RouterModule.getRouteLocation({ name: PageName.settings, tab: router.currentRoute?.value?.query.tab });
  if (typeof settingsLocation !== 'string') {
    settingsLocation.query && (menuItem.query = settingsLocation.query);
  }
  return menuItem;
}

function fillServerPath(menuItem: IMenuItem) {
  const serverUrl = configModule.config.server?.url ?? '/';
  if (menuItem.serverPath && !menuItem.serverPath.startsWith(serverUrl)) {
    menuItem.serverPath = serverUrl + menuItem.serverPath;
  }
  return menuItem;
}

export function canShowMenuItem(menuItem: IMenuItem): boolean {
  const { meta, name, enabled } = menuItem;
  return (
    aclModule.getAccess(meta.permissions, 'or') &&
    configAclModule.hasPlugins(meta.plugins) &&
    configAclModule.hasServices(meta.services) &&
    !configAclModule.isDisabledMenuItem(name) &&
    computeEnabled(enabled)
  );
}

function computeEnabled(enabled?: boolean | (() => boolean)) {
  if (enabled === undefined) return true;
  return typeof enabled === 'function' ? enabled() : enabled;
}

export function getItems(includeAdditional = false, includeSettings = false) {
  const menuItems = [...launcherItems];
  includeSettings && menuItems.push(...settingsItems);
  includeAdditional && menuItems.push(...additionalItems);
  return menuItems.filter(canShowMenuItem).map(fillServerPath);
}

export function getSettingsItems() {
  return settingsItems.filter(canShowMenuItem).map(fillServerPath).map(fillQuery);
}
