
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import { viewModelRepository } from '@/api/common';

@Options({
  name: 'VideoPlayerPage',
  components: { VideoPlayer, PageContentLayout }
})
export default class VideoPlayerPage extends Vue {
  playModel = '';
  playId = 0;

  play(model: string, id: number) {
    this.playModel = model;
    this.playId = id;
  }

  camerasModule = viewModelRepository.getCamerasListViewModel();
  videosModule = viewModelRepository.getVideosListViewModel();

  get cameras() {
    return this.camerasModule.items;
  }
  get videos() {
    return this.videosModule.items;
  }
  async mounted() {
    await this.camerasModule.get();
    await this.videosModule.get();
  }
}
