import NInput from '@/uikit/input/NInput.vue';

function configInput(params: any, props: any) {
  return {
    component: NInput,
    ...params,
    props: {
      ...props
    }
  };
}

export default [];

const blockListFilters = [
  configInput(
    {
      path: 'uuid_contains',
      value: undefined
    },
    {
      i18n_placeholder: 'common.uuid'
    }
  ),
  configInput(
    {
      path: 'reason_contains',
      value: undefined
    },
    {
      i18n_placeholder: 'common.reason'
    }
  )
];

export function getFilterByType(pageType: string) {
  let filterSchema = [];
  if (pageType === 'blacklist') {
    filterSchema = blockListFilters;
  }
  return filterSchema;
}
