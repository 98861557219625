import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-item2" }
const _hoisted_2 = { class: "card-item2__thumbnail" }
const _hoisted_3 = { class: "card-item2__name heading-s" }
const _hoisted_4 = { class: "card-item2__role" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.thumbnail,
        corners: "circle",
        onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v)),
        onClick: _withModifiers(_ctx.thumbnailClickHandler, ["stop"])
      }, null, 8, ["modelValue", "onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.name), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_WatchListsGroup, {
        type: "short",
        items: _ctx.item.watch_lists,
        style: {"width":"160px"}
      }, null, 8, ["items"])
    ])
  ]))
}