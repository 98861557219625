import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDoubleButton = _resolveComponent("NDoubleButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NMultitool = _resolveComponent("NMultitool")!

  return (_openBlock(), _createBlock(_component_NMultitool, {
    actions: _ctx.availableActions,
    "all-selected": _ctx.isAllSelected,
    "selected-items": _ctx.selectedItems,
    "show-sidebar": _ctx.showSidebar,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      (_ctx.actionSaveAll && _ctx.updateEnabled)
        ? (_openBlock(), _createBlock(_component_NDropdown, {
            key: 0,
            placement: "top-end"
          }, {
            toggle: _withCtx(({ toggle }) => [
              _createVNode(_component_NDoubleButton, _mergeProps(_ctx.actionSaveAll, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', _ctx.actionSaveAll.name))),
                onToggle: toggle
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionSaveAll.label), 1)
                ]),
                _: 2
              }, 1040, ["onToggle"])
            ]),
            body: _withCtx(() => [
              (_openBlock(), _createBlock(_component_NDropdownItem, {
                key: _ctx.actionSaveCurrent.name,
                icon: _ctx.actionSaveCurrent.icon,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action', _ctx.actionSaveCurrent.name)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.actionSaveCurrent.label), 1)
                ]),
                _: 1
              }, 8, ["icon"]))
            ]),
            _: 1
          }))
        : (_ctx.actionSaveCurrent)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('action', _ctx.actionSaveCurrent.name)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.actionSaveCurrent.label), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["actions", "all-selected", "selected-items", "show-sidebar", "onClose"]))
}