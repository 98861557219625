
import './button.css';
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({
  name: 'Button'
})
export default class Button extends Vue {
  @Prop({ type: Boolean, default: false })
  selected = false;

  @Prop({ type: Boolean })
  primary = false;

  @Prop({ type: Boolean })
  secondary = false;

  @Prop({ type: String })
  label = '';

  @Prop({ type: String, default: 'medium' })
  size = 'medium';

  onClick(): void {
    this.$emit('click');
  }

  get classes(): Record<string, boolean> {
    return {
      'storybook-button': true,
      'storybook-button--primary': this.primary,
      'storybook-button--secondary': this.secondary,
      [`storybook-button--${this.size}`]: true
    };
  }

  get style(): string {
    return '';
  }
}
