/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { BsTypeEnum } from './BsTypeEnum';
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type CarEvent = {
    readonly id: string;
    readonly created_date: string;
    readonly bs_type: BsTypeEnum;
    /**
     * Related `Camera` id
     */
    readonly camera: number;
    /**
     * Related `CameraGroup` id
     */
    readonly camera_group: number;
    /**
     * Related `Case` id
     */
    readonly case: number;
    readonly thumbnail: string;
    readonly fullframe: string;
    readonly matched_card: number | null;
    readonly matched_cluster: number | null;
    readonly matched_object: string;
    readonly matched_lists: Array<number | null>;
    readonly episode: number | null;
    readonly video_archive: number | null;
    acknowledged?: boolean;
    readonly acknowledged_date: string;
    readonly acknowledged_by: number | null;
    acknowledged_reaction?: string;
    readonly frame_coords_left: number;
    readonly frame_coords_top: number;
    readonly frame_coords_right: number;
    readonly frame_coords_bottom: number;
    readonly matched: boolean;
    readonly detector_params: Record<string, str_int_float_bool_object_array_null>;
    readonly looks_like_confidence: number | null;
    readonly confidence: number;
    readonly cluster_confidence: number;
    readonly quality: number;
    readonly features: Record<string, str_int_float_bool_object_array_null>;
    readonly external_detector: boolean;
};
export const EmptyCarEvent: CarEvent = {
    id: '',
    created_date: '',
    bs_type: BsTypeEnum.OVERALL,
    /**
     * Related `Camera` id
     */
    camera: 0,
    /**
     * Related `CameraGroup` id
     */
    camera_group: 0,
    /**
     * Related `Case` id
     */
    case: 0,
    thumbnail: '',
    fullframe: '',
    matched_card: 0,
    matched_cluster: 0,
    matched_object: '',
    matched_lists: [],
    episode: 0,
    video_archive: 0,
    acknowledged: false,
    acknowledged_date: '',
    acknowledged_by: 0,
    acknowledged_reaction: '',
    frame_coords_left: 0,
    frame_coords_top: 0,
    frame_coords_right: 0,
    frame_coords_bottom: 0,
    matched: false,
    detector_params: {},
    looks_like_confidence: 0,
    confidence: 0,
    cluster_confidence: 0,
    quality: 0,
    features: {},
    external_detector: false,
};
