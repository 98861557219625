
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NForm from '@/uikit/forms/NForm.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import SimpleText from '@/uikit/simple/SimpleText.vue';

@Options({
  name: 'DataSourcesUploadNext',
  components: { NForm, NButton }
})
export default class DataSourcesUploadNext extends Vue {
  @Prop({ type: Object, required: true })
  readonly files!: Record<string, any>;

  @Ref('form')
  readonly form?: NForm;

  model = {
    face_detector: true,
    body_detector: false,
    car_detector: false,
    camera_group: -1
  };

  schema = [
    {
      path: 'camera_group',
      classes: 'n-form-w-3 n-form-pad-10',
      i18n_label: 'ds.camera_group',
      component: CameraGroupName,
      props: { excludeVideoArchive: false },
      validators: [{ name: 'required' }]
    },
    {
      classes: 'n-form-w-2 n-form-pad-10',
      component: SimpleText,
      props: {
        i18n_modelValue: 'ds.detectors'
      }
    },
    [
      {
        path: 'face_detector',
        classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
        component: NSwitch,
        props: { i18n_label: 'ds.faces' },
        hidden: () => !dataAssetsModule.isObjectAvailable('face')
      },
      {
        path: 'body_detector',
        classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
        component: NSwitch,
        props: { i18n_label: 'ds.bodies' },
        hidden: () => !dataAssetsModule.isObjectAvailable('body')
      },
      {
        path: 'car_detector',
        classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
        component: NSwitch,
        props: { i18n_label: 'ds.cars' },
        hidden: () => !dataAssetsModule.isObjectAvailable('car')
      }
    ]
  ];

  get filesCount() {
    const { urls, attachments } = this.files;
    return urls.length + attachments.length;
  }

  createAndConfig() {
    const result = this.form?.validateAndDisplayErrors();
    if (!result) return;
    this.$emit('upload', { params: { ...this.model, open: true }, ...this.files });
  }
  onlyCreateCameras() {
    const result = this.form?.validateAndDisplayErrors();
    if (!result) return;
    this.$emit('upload', { params: this.model, ...this.files });
  }
}
