import {
  EmptyAuditLogsPageState,
  EmptyBlockListPageState,
  EmptyCardPageState,
  EmptyCasesPageState,
  EmptyClusterPageState,
  EmptyDatasourcePageState,
  EmptyDefaultPageState,
  EmptyEventPageState,
  EmptyExternalMonitoringEventsPage,
  EmptyExternalVMSPageState,
  EmptyParticipantsPageState,
  EmptySearchPageState,
  EmptySessionPageState,
  EmptyVideoWallPageState,
  EmptyWebhooksPageState,
  PageName,
  PageState
} from '@/store/application/page.definitions';
import { cloneDeep } from 'lodash';

export function getPageStateByName(name: PageName): PageState {
  let result = null;
  switch (name) {
    case PageName.events:
      result = cloneDeep(EmptyEventPageState);
      break;
    case PageName.cards:
      result = cloneDeep(EmptyCardPageState);
      break;
    case PageName.clusters:
      result = cloneDeep(EmptyClusterPageState);
      break;
    case PageName.cases:
      result = cloneDeep(EmptyCasesPageState);
      break;
    case PageName.audit_logs:
      result = cloneDeep(EmptyAuditLogsPageState);
      break;
    case PageName.sessions:
      result = cloneDeep(EmptySessionPageState);
      break;
    case PageName.search:
      result = cloneDeep(EmptySearchPageState);
      break;
    case PageName.block_list:
      result = cloneDeep(EmptyBlockListPageState);
      break;
    case PageName.remote_monitoring_events:
      result = cloneDeep(EmptyExternalMonitoringEventsPage);
      break;
    case PageName.participants:
      result = cloneDeep(EmptyParticipantsPageState);
      break;
    case PageName.external_vms:
      result = cloneDeep(EmptyExternalVMSPageState);
      break;
    case PageName.hooks:
      result = cloneDeep(EmptyWebhooksPageState);
      break;
    case PageName.datasources:
      result = cloneDeep(EmptyDatasourcePageState);
      break;
    case PageName.VideoWall:
      result = cloneDeep(EmptyVideoWallPageState);
      break;
    default:
      console.warn(`[pageModule] Cant get page module of ${name}, use Empty/None`);
      result = cloneDeep(EmptyDefaultPageState);
      result.pageName = name;
      break;
  }

  return result;
}
