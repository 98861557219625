
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs, watch } from 'vue';
import { NInput, NButton, NButtonType, NHint } from '@/uikit';

const Errors = {
  Invalid: 'common.login_or_password_invalid',
  Required: 'common.login_and_password_required'
};

export default defineComponent({
  name: 'LoginPasswordForm',
  components: { NButton, NInput, NHint },
  props: {
    isSubmitButtonActive: { type: Boolean, default: false },
    hasCredentialsError: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const state = reactive({
      login: '',
      password: '',
      error: ''
    });

    function validate() {
      state.error = '';
      if (state.login === '' || state.password === '') {
        state.error = Errors.Required;
        return false;
      }
      return true;
    }

    function handleSubmit() {
      validate() && emit('submit', { login: state.login, password: state.password });
    }

    onMounted(bindHideErrorOnOutClick);
    function bindHideErrorOnOutClick() {
      document.addEventListener('click', hideError);
    }

    onBeforeUnmount(unbindHideErrorOnOutClick);
    function unbindHideErrorOnOutClick() {
      document.removeEventListener('click', hideError);
    }

    function hideError() {
      state.error = '';
    }

    watch(() => props.hasCredentialsError, handleCredentialsError, { immediate: true });
    function handleCredentialsError(v: boolean) {
      if (v) state.error = Errors.Invalid;
    }

    const submitButtonType = computed(() => (props.isSubmitButtonActive ? NButtonType.Primary : NButtonType.Secondary));

    return {
      ...toRefs(state),
      handleSubmit,
      submitButtonType
    };
  }
});
