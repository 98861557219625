
import { defineComponent } from 'vue';
import { string, array, bool, oneOfType } from 'vue-types';
import NRadio from '@/uikit/radio/NRadio.vue';
import { NRadioGroupDirection } from '@/uikit/radio/enums';

export default defineComponent({
  name: 'NRadioGroup',
  components: { NRadio },
  props: {
    modelValue: oneOfType([String, Number]).def(''),
    items: array(),
    direction: string().def(NRadioGroupDirection.Row),
    disabled: bool().def(false)
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange(value: string | number) {
      this.$emit('update:modelValue', value);
    }
  }
});
