import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-select-tags", { 'n-select-tags__line3-height': _ctx.collapsed, 'n-select-tags__fixed-height': !_ctx.multiline }]),
    ref: "list"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_NHint, {
        key: item.id,
        content: item.label,
        placement: "top",
        "delay-visible": 600,
        "delay-hidden": 600
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, {
            closeable: _ctx.closeable,
            color: item.color,
            label: item.label,
            "data-qa": `${_ctx.dataQa}.tag`,
            onClose: ($event: any) => (_ctx.closeHandler(item)),
            onAction: ($event: any) => (_ctx.clickHandler(item))
          }, null, 8, ["closeable", "color", "label", "data-qa", "onClose", "onAction"])
        ]),
        _: 2
      }, 1032, ["content"]))
    }), 128))
  ], 2))
}