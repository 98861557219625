import { ItemsActionNames, ItemsActionIcons, ItemsActionName } from '@/definitions/app/item.actions.name';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { configModule } from '@/store/config';
import { languageModule } from '@/store/languages';
import { IMultitoolAction } from '@/uikit';
import { aclModule } from '../acl';

export type ActionsModuleOptions = Partial<Record<ActionsModuleOptionName, boolean>>;
export type ActionsModuleOptionName = typeof ActionsModuleOptionsNames[keyof typeof ActionsModuleOptionsNames];

export const ActionsModuleOptionsNames = {
  currentActive: 'currentActive',
  hasActive: 'hasActive',
  hasCameraReset: 'hasCameraReset',
  hasVideoReset: 'hasVideoReset',
  hasCardFilterEvent: 'hasCardFilterEvent',
  hasChanges: 'hasChanges',
  hasDelete: 'hasDelete',
  hasDeleteCard: 'hasDeleteCard',
  hasClusterMerge: 'hasClusterMerge',
  hasCreateCard: 'hasCreateCard',
  hasInteractions: 'hasInteractions',
  hasPuppeteer: 'hasPuppeteer',
  hasPuppeteerMonitoringRecord: 'hasPuppeteerMonitoringRecord',
  hasRestart: 'hasRestart',
  hasSave: 'hasSave',
  hasSearch: 'hasSearch',
  hasDeleteFile: 'hasDeleteFile',
  hasShowPlayer: 'hasShowPlayer',
  isArchivable: 'isArchivable',
  hasProcess: 'hasProcess',
  hasStopProcess: 'hasStopProcess',
  updateSupported: 'updateSupported'
} as const;

const ActionAdditionalTranslationsMap: Partial<Record<ItemsActionName, string>> = {
  [ItemsActionNames.AddAllToMonitoring]: 'remote_monitoring.add_all_selected_to_monitoring',
  [ItemsActionNames.AddToMonitoring]: 'remote_monitoring.add_to_monitoring',
  [ItemsActionNames.CameraResetAdvanced]: 'ds.camera_reset_advanced',
  [ItemsActionNames.CameraResetAll]: 'videos.reset_params_all_selected',
  [ItemsActionNames.CameraResetFull]: 'ds.camera_reset_all',
  [ItemsActionNames.CameraResetDetectors]: 'ds.camera_reset_detectors',
  [ItemsActionNames.CameraResetMap]: 'ds.camera_reset_map',
  [ItemsActionNames.CameraResetZone]: 'ds.camera_reset_zone',
  [ItemsActionNames.CameraReset]: 'ds.camera_reset',
  [ItemsActionNames.VideoReset]: 'ds.camera_reset',
  [ItemsActionNames.ClusterMergeAll]: 'clusters.merge_all_selected',
  [ItemsActionNames.ExternalSearch]: 'remote_monitoring.external_search',
  [ItemsActionNames.ShowInteractions]: 'clusters.interactions',
  [ItemsActionNames.MonitoringEvents]: 'remote_monitoring.monitoring_events',
  [ItemsActionNames.ProcessAll]: 'videos.process_all_selected',
  [ItemsActionNames.Process]: 'videos.process_current',
  [ItemsActionNames.RemoveAllFromMonitoring]: 'remote_monitoring.remove_all_selected_from_monitoring',
  [ItemsActionNames.RemoveFromMonitoring]: 'remote_monitoring.remove_from_monitoring',
  [ItemsActionNames.StopProcessAll]: 'videos.stop_process_all_selected',
  [ItemsActionNames.StopProcess]: 'videos.stop_process_current',
  [ItemsActionNames.DeleteVideoFile]: 'ds.delete_video_files_only',
  [ItemsActionNames.DeleteVideoFileAll]: 'ds.delete_video_files_only'
};

export class ActionsModule {
  public getMultipleItemsActions(modelAcl: IModelAclResult, options: ActionsModuleOptions) {
    const acl = modelAcl;
    const result: ItemsActionName[] = [];
    const updateSupported: boolean = !(options?.updateSupported === false);

    if (acl.update && updateSupported) {
      if (acl.update && options.hasProcess) {
        result.push(ItemsActionNames.ProcessAll);
      }

      if (acl.update && options.hasStopProcess) {
        result.push(ItemsActionNames.StopProcessAll);
      }

      if (acl.update && options.hasCameraReset) {
        result.push(ItemsActionNames.CameraResetAll);
      }

      if (options.hasChanges) {
        result.push(ItemsActionNames.SaveAll);
      }

      if (options.hasActive) {
        result.push(ItemsActionNames.ActivateAll);
        result.push(ItemsActionNames.DeactivateAll);
      }

      if (options.hasDeleteFile) {
        result.push(ItemsActionNames.DeleteVideoFileAll);
      }
    }

    if (options.isArchivable) {
      result.push(ItemsActionNames.OpenAll);
      result.push(ItemsActionNames.ArchiveAll);
    }

    if (options.hasPuppeteer && configModule.plugins.puppeteer) {
      result.push(ItemsActionNames.AddAllToMonitoring);
      result.push(ItemsActionNames.RemoveAllFromMonitoring);
    }

    if (options.hasClusterMerge && acl.update) {
      result.push(ItemsActionNames.ClusterMergeAll);
    }

    if (options.hasDelete && acl.delete) {
      result.push(ItemsActionNames.DeleteAll);
    }

    if (options.hasDeleteCard) {
      result.push(ItemsActionNames.DeleteCardsInAll);
    }

    return result;
  }

  public getItemActions(modelAcl: IModelAclResult, itemAcl: IItemAclResult | null | undefined, options: ActionsModuleOptions) {
    const currentAcl = itemAcl || modelAcl;
    const result: ItemsActionName[] = [];

    if (options.hasSave && currentAcl.update && options.hasChanges) {
      result.push(ItemsActionNames.Save);
    }

    if (options.hasSearch) {
      result.push(ItemsActionNames.Search);
    }

    if (options.hasCreateCard) {
      result.push(ItemsActionNames.CreateCard);
    }

    if (options.hasCardFilterEvent && !configModule.config.extra_options?.includes('hide_events')) {
      result.push(ItemsActionNames.FilterCardEvent);
    }

    if (currentAcl.update && options.hasProcess) {
      result.push(ItemsActionNames.Process);
    }

    if (currentAcl.update && options.hasStopProcess) {
      result.push(ItemsActionNames.StopProcess);
    }

    if (currentAcl.update && options.hasActive) {
      if (options.currentActive) {
        result.push(ItemsActionNames.Deactivate);
      } else {
        result.push(ItemsActionNames.Activate);
      }
    }

    if (options.hasClusterMerge && currentAcl.update) {
      result.push(ItemsActionNames.ClusterMergeAll);
    }

    if (options.hasInteractions) {
      result.push(ItemsActionNames.ShowInteractions);
    }

    if (currentAcl.update && options.hasDeleteFile) {
      result.push(ItemsActionNames.DeleteVideoFile);
    }

    if (options.hasDelete && currentAcl.delete) {
      result.push(ItemsActionNames.Delete);
    }

    if (options.hasDeleteCard) {
      result.push(ItemsActionNames.DeleteCards);
    }

    if (options.hasRestart && modelAcl.add) {
      result.push(ItemsActionNames.Restart);
    }

    if (currentAcl.update && options.hasCameraReset) {
      result.push(ItemsActionNames.CameraReset);
    }

    if (currentAcl.update && options.hasVideoReset) {
      result.push(ItemsActionNames.VideoReset);
    }

    if (options.hasPuppeteer && configModule.plugins.puppeteer) {
      if (options.hasPuppeteerMonitoringRecord) {
        if (arePermissionsGrantedForMonitoringRemoving()) {
          result.push(ItemsActionNames.RemoveFromMonitoring);
          result.push(ItemsActionNames.MonitoringEvents);
        }
      } else {
        if (arePermissionsGrantedForMonitoringAdding()) {
          result.push(ItemsActionNames.AddToMonitoring);
        }
      }
      result.push(ItemsActionNames.ExternalSearch);
    }

    if (options.hasShowPlayer) {
      result.push(ItemsActionNames.ShowPlayer);
    }

    return result;
  }

  public computeActionByName(name: ItemsActionName): IMultitoolAction {
    const defaultPrefix = 'common.';
    const defaultI18NLabel = defaultPrefix + name.replace(/-/g, '_').replace('_current', '').replace('_all', '_all_selected');
    const i18nLabel = ActionAdditionalTranslationsMap[name] || defaultI18NLabel;
    const action = { name, label: languageModule.getTranslatedToken(i18nLabel, 'f'), hidden: true };
    if (name === ItemsActionNames.CameraReset) computeCameraResetChildren(action);
    if (name === ItemsActionNames.VideoReset) computeVideoResetChildren(action);
    return action;
  }

  public addIconToAction(action: IMultitoolAction) {
    action.icon = ItemsActionIcons[action.name as ItemsActionName] ?? '';
    return action;
  }

  public makeActionVisible(action: IMultitoolAction) {
    action.hidden = false;
    return action;
  }
}

function computeCameraResetChildren(cameraAction: IMultitoolAction) {
  cameraAction.children = [
    ItemsActionNames.CameraResetFull,
    ItemsActionNames.CameraResetAdvanced,
    ItemsActionNames.CameraResetZone,
    ItemsActionNames.CameraResetDetectors,
    ItemsActionNames.CameraResetMap
  ].map((v) => actionsModule.computeActionByName(v));
}

function computeVideoResetChildren(cameraAction: IMultitoolAction) {
  cameraAction.children = [
    ItemsActionNames.CameraResetFull,
    ItemsActionNames.CameraResetAdvanced,
    ItemsActionNames.CameraResetZone,
    ItemsActionNames.CameraResetDetectors
  ].map((v) => actionsModule.computeActionByName(v));
}

function arePermissionsGrantedForMonitoringAdding(): boolean {
  return aclModule.getAccess('ffsecurity_puppeteer.add_remotemonitoringrecord');
}

function arePermissionsGrantedForMonitoringRemoving(): boolean {
  return aclModule.getAccess('ffsecurity_puppeteer.delete_remotemonitoringrecord');
}

export const actionsModule = new ActionsModule();
