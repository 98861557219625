import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NMultitool = _resolveComponent("NMultitool")!

  return (_openBlock(), _createBlock(_component_NMultitool, {
    actions: _ctx.actions,
    "selected-items": _ctx.selectedCountArrayWorkaround,
    onAction: _ctx.handleMultitoolActionEvent,
    onClose: _ctx.dispatchUnselectAllEvent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NButtonGroup, {
        type: "separated",
        items: _ctx.actions
      }, null, 8, ["items"])
    ]),
    _: 1
  }, 8, ["actions", "selected-items", "onAction", "onClose"]))
}