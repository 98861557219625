import { ITableColumn } from '@/uikit/table/NTable.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { WatchList } from '@/api';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import WatchListItem from '@/components/common/WatchListItem.vue';
import { WatchListItemTextTypes } from '@/components/common/watch-list-types';

export const columns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_name',
        name: 'name',
        sortable: true
      }
    },
    content: {
      path: 'name',
      component: WatchListItem,
      props: (item: WatchList) => ({ color: `#${item.color}`, name: item.name, textType: WatchListItemTextTypes.Link })
    }
  },
  {
    width: '100px',
    header: {
      classes: ['align-right', 'bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.column_active' }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch,
      props: (item: WatchList) => ({ disabled: item.id === -1 })
    }
  }
];
