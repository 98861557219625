export default {
  delete_full_frames_of_matched_events_older_than: 'Удалять полные кадры событий с совпадениями старше',
  delete_full_frames_of_unmatched_events_older_than: 'Удалять полные кадры событий без совпадений старше',
  delete_matched_events_older_than: 'Удалять события с совпадениями старше',
  delete_unmatched_events_older_than: 'Удалять события без совпадений старше',
  events_cleanup: 'Очистка событий',
  generic_confidence_threshold: 'Универсальный порог срабатывания',
  hours: 'часов',
  thumbnail_jpeg_quality: 'Качество JPEG миниатюр',
  update: 'обновить'
};
