/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventsFacesFilter = {
  /**
   * `true` - select only acknowledged events (do not require acknowledgement), `false` - select only unacknowledged events
   */
  acknowledged?: boolean;
  /**
   * Select events where `features.age` is **greater than or equal** to this value
   */
  age_gte?: number;
  /**
   * Select events where `features.age` is **less than or equal** to this value
   */
  age_lte?: number;
  /**
   * Select events where `features.beard.name` takes one of these values
   */
  beard?: Array<string>;
  /**
   * Select events where `features.beard.confidence` is **greater than or equal** to this value
   */
  beard_score_gte?: number;
  /**
   * Select events where `features.beard.confidence` is **less than or equal** to this value
   */
  beard_score_lte?: number;
  /**
   * Select objects where `bs_type` takes one of these values
   */
  bs_type?: 'overall' | 'realtime';
  /**
   * Get events from these `camera groups`
   */
  camera_groups?: Array<number>;
  /**
   * Get events from these `cameras`
   */
  cameras?: Array<number>;
  /**
   * Select events related to these `cases`
   */
  case_in?: Array<number>;
  /**
   * Select objects with `created_date` **greater than or equal** to this value
   */
  created_date_gte?: string;
  /**
   * Select objects with created in last N days
   */
  created_date_last_n_days?: number;
  /**
   * Select objects with `created_date` **less than or equal** to this value
   */
  created_date_lte?: string;
  /**
   * Select objects with created in last Nth week (including Sunday and Saturday)
   */
  created_date_nth_full_week?: number;
  /**
   * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   */
  created_date_nth_work_week?: number;
  /**
   * Select events where `features.emotions.name` takes one of these values
   */
  emotions?: Array<string>;
  /**
   * Select events where `features.emotions.confidence` is **greater than or equal** to this value
   */
  emotions_score_gte?: number;
  /**
   * Select events where `features.emotions.confidence` is **less than or equal** to this value
   */
  emotions_score_lte?: number;
  /**
   * Select events that matched this episode
   */
  episode?: number;
  /**
   * Select events that created by external detectors
   */
  external_detector?: boolean;
  /**
   * Select events where `features.gender.name` takes one of these values
   */
  gender?: Array<string>;
  /**
   * Select events where `features.gender.confidence` is **greater than or equal** to this value
   */
  gender_score_gte?: number;
  /**
   * Select events where `features.gender.confidence` is **less than or equal** to this value
   */
  gender_score_lte?: number;
  /**
   * Select events where `features.glasses.name` takes one of these values
   */
  glasses?: Array<string>;
  /**
   * Select events where `features.glasses.confidence` is **greater than or equal** to this value
   */
  glasses_score_gte?: number;
  /**
   * Select events where `features.glasses.confidence` is **less than or equal** to this value
   */
  glasses_score_lte?: number;
  /**
   * `true` - select only events relates to case. `false` - select only events without case.
   */
  has_case?: boolean;
  /**
   * Exact filter by ID
   */
  id?: number;
  /**
   * Select objects with `id` **greater than or equal** to this value
   */
  id_gte?: number;
  /**
   * Select objects with `id` **less than or equal** to this value
   */
  id_lte?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * Select events where `features.liveness.name` takes one of these values
   */
  liveness?: Array<string>;
  /**
   * Select events where `features.liveness.confidence` is **greater than or equal** to this value
   */
  liveness_score_gte?: number;
  /**
   * Select events where `features.liveness.confidence` is **less than or equal** to this value
   */
  liveness_score_lte?: number;
  /**
   * Select events where `features.look.name` takes one of these values
   */
  look?: Array<string>;
  /**
   * Select events where `features.look.confidence` is **greater than or equal** to this value
   */
  look_score_gte?: number;
  /**
   * Select events where `features.look.confidence` is **less than or equal** to this value
   */
  look_score_lte?: number;
  /**
   * Select events with faces similar to: (`detection:<detection id>`, `{face, body or car}object:<object id>`, `{face, body or car}event:<event id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`). You can pass several search sources to search for. Pagination works if there is only one face to search for and if the sorting order is id. In all other cases, only the first page of results is returned
   */
  looks_like?: string;
  /**
   * Select events that matched this card
   */
  matched_card?: number;
  /**
   * Select events that match a card included in these watch lists
   */
  matched_lists?: Array<number>;
  /**
   * Select events where `features.medmask.name` takes one of these values
   */
  medmask?: Array<string>;
  /**
   * Select events where `features.medmask.confidence` is **greater than or equal** to this value
   */
  medmask_score_gte?: number;
  /**
   * Select events where `features.medmask.confidence` is **less than or equal** to this value
   */
  medmask_score_lte?: number;
  /**
   * `true` - select only events without matches. `false` - select events only with matches.
   */
  no_match?: boolean;
  /**
   * Which field to use for ordering. Available values: id, -id. Available values for looks_like: -confidence, -looks_like_confidence, id, -id, confidence and looks_like_confidence ordering are equivalents
   */
  ordering?: string;
  /**
   * Pagination cursor value.
   */
  page?: string;
  /**
   * Select events where `features.headpose.pitch` is **greater than or equal** to this value
   */
  headpose_pitch_angle_gte?: number;
  /**
   * Select events where `features.headpose.pitch` is **less than or equal** to this value
   */
  headpose_pitch_angle_lte?: number;
  /**
   * Select events where `features.headpose.roll` is **greater than or equal** to this value
   */
  roll_angle_gte?: number;
  /**
   * Select events where `features.headpose.roll` is **less than or equal** to this value
   */
  roll_angle_lte?: number;
  /**
   * Select events where `temperature` is **greater than or equal** to this value
   */
  temperature_gte?: number;
  /**
   * Select events where `temperature` is **less than or equal** to this value
   */
  temperature_lte?: number;
  /**
   * Similarity threshold for `looks_like`
   */
  threshold?: number;
  /**
   * Get events from these video archives
   */
  video_archive?: Array<number>;
  /**
   * Select events where `features.headpose.yaw` is **greater than or equal** to this value
   */
  headpose_yaw_angle_gte?: number;
  /**
   * Select events where `features.headpose.yaw` is **less than or equal** to this value
   */
  headpose_yaw_angle_lte?: number;
};
export const EmptyEventsFacesFilter: EventsFacesFilter = {
  acknowledged: undefined,
  age_gte: undefined,
  age_lte: undefined,
  beard: [],
  beard_score_gte: undefined,
  beard_score_lte: undefined,
  bs_type: undefined,
  camera_groups: [],
  cameras: [],
  case_in: [],
  created_date_gte: undefined,
  created_date_last_n_days: undefined,
  created_date_lte: undefined,
  created_date_nth_full_week: undefined,
  created_date_nth_work_week: undefined,
  emotions: [],
  emotions_score_gte: undefined,
  emotions_score_lte: undefined,
  episode: undefined,
  external_detector: undefined,
  gender: [],
  gender_score_gte: undefined,
  gender_score_lte: undefined,
  glasses: [],
  glasses_score_gte: undefined,
  glasses_score_lte: undefined,
  has_case: undefined,
  id: undefined,
  id_gte: undefined,
  id_lte: undefined,
  limit: 20,
  liveness: [],
  liveness_score_gte: undefined,
  liveness_score_lte: undefined,
  look: [],
  look_score_gte: undefined,
  look_score_lte: undefined,
  looks_like: undefined,
  matched_card: undefined,
  matched_lists: [],
  medmask: [],
  medmask_score_gte: undefined,
  medmask_score_lte: undefined,
  no_match: undefined,
  ordering: undefined,
  page: undefined,
  headpose_pitch_angle_gte: undefined,
  headpose_pitch_angle_lte: undefined,
  roll_angle_gte: undefined,
  roll_angle_lte: undefined,
  temperature_gte: undefined,
  temperature_lte: undefined,
  threshold: undefined,
  video_archive: [],
  headpose_yaw_angle_gte: undefined,
  headpose_yaw_angle_lte: undefined
};
