import { RoleEnum } from '@/api';
import { computeFieldItems, mapObjectToSelectItem } from '@/common/utilsFilterSchema';
import FilterTagsToSelect from '@/components/common/filter/FilterTagsToSelect.vue';
import { dataModule } from '@/store/data';
import {
  configButtonGroup,
  configCardSelect,
  configCardInput,
  configCheckbox,
  configDateTimeRange,
  configDateTimeRangeSmall,
  configInput,
  configLicensePlateInput,
  configReturnIntervalSelect,
  configReturnIntervalSelectSmall,
  configSelect,
  configSelectAsTags,
  configSelectAsTagsSmall,
  configSelectSmall,
  configTextSmall,
  configureDivider,
  SimpleFilterOptions, configMultipleInputSmall, configCardSelectSmall
} from '@/store/filters/types';
import { IFormContext, IFormLayoutItem, IFormModel } from '@/uikit/forms/NForm.vue';
import {
  fetchAuditLogsObjectActionsMap,
  fetchAuditLogsObjectTypes,
  matchAuditLogsObjectTypesWithActions
} from '@/pages/audit-logs/forms/utils';
import { languageModule } from '@/store/languages';
import { AuditLogsFilter } from '@/api/models/AuditLogsFilter';
import { isStringWithValue } from '@/common/utils';

export class CommonFilterSchemaModule {
  // COMMON EVENTS EPISODES

  getMatchedLists(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'matched_lists',
      i18n_label: 'common.watch_lists',
      i18n_placeholder: 'common.watch_lists',
      items: mapObjectToSelectItem(dataModule.watchListsWithoutUnknown)
    };
    const resultOptions = Object.assign(baseOptions, options);

    return options?.small ? configSelectAsTagsSmall(resultOptions) : this._getBigMatchedList(resultOptions);
  }

  private _getBigMatchedList(options: SimpleFilterOptions): IFormLayoutItem {
    return {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: FilterTagsToSelect,
      path: options.path,
      i18n_label: options.i18n_label,
      props: {
        i18n_placeholder: options.i18n_placeholder,
        items: options.items
      }
    };
  }

  getAcknowledged(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'acknowledged',
      anyItem: true,
      i18n_label: 'common.acknowledged',
      i18n_placeholder: 'common.acknowledged',
      items: [
        { value: 'False', i18n_label: 'common.only_unacknowledged' },
        { value: 'True', i18n_label: 'common.only_acknowledged' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCameraGroups(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'camera_groups',
      i18n_label: 'common.camera_groups',
      i18n_placeholder: 'common.select_camera_group',
      items: mapObjectToSelectItem(dataModule.cameraGroupsModule.items)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelectAsTags(resultOptions);
  }

  getCameras(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'cameras',
      i18n_label: 'common.cameras',
      i18n_placeholder: 'common.select_camera',
      items: mapObjectToSelectItem(dataModule.camerasModule.items)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelectAsTags(resultOptions);
  }

  getDateTimeRange(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.date_and_time'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configDateTimeRangeSmall(resultOptions) : configDateTimeRange(resultOptions);
  }

  getFirstClusterEventDateTimeRange(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      name: 'firstEventDateRange',
      i18n_label: 'clusters.first_event_date'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configDateTimeRangeSmall(resultOptions, 'first_event_date_gte', 'first_event_date_lte') : configDateTimeRange(resultOptions, 'first_event_date_gte', 'first_event_date_lte');
  }

  getCreatedDateTimeRange(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.date_and_time'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configDateTimeRangeSmall(resultOptions, 'created_date_gte', 'created_date_lte') : configDateTimeRange(resultOptions, 'created_date_gte', 'created_date_lte');
  }

  getClusterEventDateTimeRange(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      name: 'clusterEventDateRange',
      i18n_label: 'clusters.event_date'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configDateTimeRangeSmall(resultOptions, 'event_date_gte', 'event_date_lte') : configDateTimeRange(resultOptions, 'event_date_gte', 'event_date_lte');
  }

  getVideoArchive(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'video_archive',
      i18n_label: 'common.video_archive_id',
      i18n_placeholder: 'common.enter_video_archive_id',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getMatchedCardSelect(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'matched_card',
      i18n_label: 'common.card_name',
      i18n_placeholder: 'common.card_name'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options.small ? configCardSelectSmall(resultOptions) : configCardSelect(resultOptions);
  }

  getCardSelect(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'card',
      i18n_label: 'common.card_name',
      i18n_placeholder: 'common.card_name'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options.small ? configCardSelectSmall(resultOptions) : configCardSelect(resultOptions);
  }

  // EVENTS

  getMatches(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'no_match',
      i18n_label: 'common.matches',
      i18n_placeholder: 'common.matches',
      anyItem: true,
      closeIcon: false,
      items: [
        { value: 'False', i18n_label: 'common.only_with_matches' },
        { value: 'True', i18n_label: 'common.only_without_matches' }
      ]
    };

    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getEpisodeId(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'episode',
      i18n_label: 'common.episode_id',
      i18n_placeholder: 'common.enter_episode_id'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getEventId(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'id_in',
      i18n_label: 'common.event_id',
      i18n_placeholder: 'common.enter_event_id'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  // HUMAN EPISODES

  getFaceMatches(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'no_face_match',
      i18n_label: 'common.face_matches',
      i18n_placeholder: 'common.face_matches',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_with_matches' },
        { value: 'True', i18n_label: 'common.only_without_matches' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyMatches(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'no_body_match',
      i18n_label: 'common.body_matches',
      i18n_placeholder: 'common.body_matches',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_with_matches' },
        { value: 'True', i18n_label: 'common.only_without_matches' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getEpisodeIdInEpisodes(options: SimpleFilterOptions): IFormLayoutItem {
    return this.getEpisodeId({ ...options, path: 'id_in', multiple: true });
  }

  getEventsCount(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'events_count_gte',
      i18n_label: 'common.count_events',
      i18n_placeholder: 'common.count_events',
      labeled: true,
      anyItem: true,
      items: computeFieldItems(['1', '2', '3', '5', '10'])
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getAttributesDivider(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.attributes'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureDivider(resultOptions);
  }

  getFaceAttributesDivider(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.face_attributes'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureDivider(resultOptions);
  }

  getBodyAttributesDivider(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.body_attributes'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureDivider(resultOptions);
  }

  // CAR EPISODES

  getCarMatches(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'no_car_match',
      i18n_label: 'common.matches',
      i18n_placeholder: 'common.matches',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_with_matches' },
        { value: 'True', i18n_label: 'common.only_without_matches' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarAttributesDivider(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.car_attributes'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? undefined : configureDivider(resultOptions);
  }

  // FACE EVENTS

  getBsType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bs_type',
      i18n_label: 'common.bs_type',
      i18n_placeholder: 'common.bs_type',
      anyItem: true,
      items: [
        { value: 'overall', i18n_label: 'common.bs_type_overall' },
        { value: 'realtime', i18n_label: 'common.bs_type_realtime' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  // CARDS

  getHasFaceObjects(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'has_face_objects',
      i18n_label: 'common.has_face_objects',
      i18n_placeholder: 'common.has_face_objects',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_without_faces' },
        { value: 'True', i18n_label: 'common.only_with_faces' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getHasBodyObjects(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'has_body_objects',
      i18n_label: 'common.has_body_objects',
      i18n_placeholder: 'common.has_body_objects',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_without_bodies' },
        { value: 'True', i18n_label: 'common.only_with_bodies' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getHasCarObjects(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'has_car_objects',
      i18n_label: 'common.has_car_objects',
      i18n_placeholder: 'common.has_car_objects',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_without_cars' },
        { value: 'True', i18n_label: 'common.only_with_cars' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateNumber(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number_contains',
      i18n_label: 'features.cars_license_plate_number',
      i18n_placeholder: 'common.enter_number_plate',
      i18n_tooltip: 'common.enter_number_plate_description'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
  }

  getIsFilled(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'filled',
      i18n_label: 'common.filled',
      i18n_placeholder: 'common.filled',
      anyItem: true,
      items: [
        { value: 'False', i18n_label: 'common.only_not_filled' },
        { value: 'True', i18n_label: 'common.only_filled' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getWatchLists(options: SimpleFilterOptions): IFormLayoutItem {
    return this.getMatchedLists({
      ...options,
      path: 'watch_lists',
      i18n_label: 'common.watch_lists',
      i18n_placeholder: 'common.watch_lists'
    });
  }

  getNameContains(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'name_contains',
      i18n_label: 'common.name_contains',
      i18n_placeholder: 'common.name_contains'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configCardInput(resultOptions);
  }

  getId(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'id_in',
      i18n_label: 'common.id',
      i18n_placeholder: 'common.id',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  // Clusters

  getHasCard(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'has_card',
      i18n_label: 'common.matches',
      i18n_placeholder: 'common.matches',
      anyItem: true,
      items: [
        { value: 'True', i18n_label: 'common.only_with_matches' },
        { value: 'False', i18n_label: 'common.only_without_matches' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getHasCaseParticipants(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'has_caseparticipants',
      i18n_label: 'common.participant',
      i18n_placeholder: 'common.participant',
      anyItem: true,
      items: [
        { value: 'True', i18n_label: 'common.only_with_participant' },
        { value: 'False', i18n_label: 'common.only_without_participant' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  // PARTICIPANTS

  getCaseRole(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'role',
      i18n_label: 'cases.role',
      i18n_placeholder: 'cases.role',
      multiple: true,
      anyItem: true,
      items: [
        { value: RoleEnum.VICTIM, i18n_label: 'cases.victim' },
        { value: RoleEnum.WITNESS, i18n_label: 'cases.witness' },
        { value: RoleEnum.SUSPECT, i18n_label: 'cases.suspect' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCaseNameContains(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'name_contains',
      i18n_label: 'cases.participant_name',
      i18n_placeholder: 'cases.enter_participant_name'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getCaseDateTime(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    return this.getDateTimeRange({ ...options, i18n_label: 'cases.date_time_participant' });
  }

  getCaseIn(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'case_in',
      i18n_label: 'cases.case_id',
      i18n_placeholder: 'cases.enter_case_id',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getContactThreshold(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'contact_threshold_seconds',
      i18n_label: 'clusters.contact_threshold_seconds',
      anyItem: false,
      closeIcon: false,
      items: [
        { value: 1, label: '1s' },
        { value: 3, label: '3s' },
        { value: 5, label: '5s' },
        { value: 10, label: '10s' },
        { value: 20, label: '20s' },
        { value: 30, label: '30s' },
        { value: 60, label: '60s' },
        { value: 120, label: '120s' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  // KYC
  getPrecision(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      items: [],
      path: 'precision',
      i18n_label: 'kyc.precision'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small
      ? configSelectSmall({ ...resultOptions, clearIcon: false, labeled: true, classes: 'kyc-filter-precision' })
      : configSelect(resultOptions);
  }

  getReturnInterval(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      items: [],
      path: 'return_interval',
      i18n_label: 'kyc.return_interval'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small
      ? configReturnIntervalSelectSmall({ ...resultOptions, labeled: true, classes: 'kyc-filter-return-interval' })
      : configReturnIntervalSelect(resultOptions);
  }

  getReturnsNumber(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      items: [],
      path: 'returns_number',
      i18n_label: 'kyc.returns_number'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small
      ? configSelectSmall({ ...resultOptions, clearIcon: false, labeled: true, classes: 'kyc-filter-returns-number' })
      : configSelect(resultOptions);
  }

  getLimit(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      items: [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: 200, label: '200' },
        { value: 500, label: '500' }
      ],
      path: 'limit',
      i18n_label: 'common.limit',
      i18n_placeholder: 'common.limit'
    };
    const resultOptions = Object.assign(baseOptions, options);
    const result = options?.small
      ? configSelectSmall({ ...resultOptions, clearIcon: false, labeled: true })
      : configSelect(resultOptions);

    result.decode = function (this: IFormContext) {
      return Number(this.model.limit);
    };

    return result;
  }

  getUseLastEvent(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'use_last_event',
      i18n_label: 'clusters.use_last_event'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configCheckbox(resultOptions) : configCheckbox(resultOptions);
  }

  getActiveStatus(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'active',
      i18n_label: 'common.status',
      i18n_placeholder: 'common.active',
      anyItem: true,
      items: [
        { value: 'True', i18n_label: 'common.only_active' },
        { value: 'False', i18n_label: 'common.only_inactive' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getDeviceUIDInput(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'device_uid',
      i18n_label: 'audit_logs.device_uid',
      i18n_placeholder: 'audit_logs.input_device_uid',
      multiple: false
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getAuditObjectTypeSelect(options: SimpleFilterOptions): IFormLayoutItem {
    const loadItems = async () =>
      (await fetchAuditLogsObjectTypes()).map((item) => {
        return { label: languageModule.getTranslatedToken(`audit_logs.objectTypes.${item}`, 'f'), value: item };
      });

    const baseOptions: SimpleFilterOptions = {
      path: 'object_type',
      i18n_label: 'audit_logs.object_type',
      i18n_placeholder: 'audit_logs.select_object',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions, loadItems) : configSelectAsTags(resultOptions, loadItems);
  }

  getAuditActionTypeSelect(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'action_type',
      i18n_label: 'audit_logs.action_type',
      i18n_placeholder: 'audit_logs.select_action',
      multiple: false
    };
    const resultOptions = Object.assign(baseOptions, options);
    const layoutItem = options?.small ? configSelectAsTagsSmall(resultOptions) : configSelectAsTags(resultOptions);
    const layoutItemProps = layoutItem.props;
    const layoutItemPropsFunction = (filter: AuditLogsFilter) => {
      const loadItems = async () => {
        const items = await fetchAuditLogsObjectActionsMap();
        const matchedItems = matchAuditLogsObjectTypesWithActions(items, []);
        return matchedItems.map((action) => {
          return { label: languageModule.getTranslatedToken(`audit_logs.actionTypes.${action}`, 'f'), value: action };
        });
      };
      return { ...layoutItemProps, loadItems };
    };
    layoutItem.props = layoutItemPropsFunction;
    return layoutItem;
  }

  getObjectIdInput(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'object_id',
      i18n_label: 'audit_logs.object_id',
      i18n_placeholder: 'audit_logs.input_object_id'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
  }

  getUserLogin(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'user_login',
      i18n_label: 'audit_logs.user',
      i18n_placeholder: 'audit_logs.input_user'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
  }

  getRequestIPAddress(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'request_ip',
      i18n_label: 'audit_logs.request_ip',
      i18n_placeholder: 'audit_logs.input_request_ip'
    };
    const resultOptions = Object.assign(baseOptions, options);
    const layoutItem = options?.small ? configMultipleInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
    layoutItem.validators = [
      {
        handler: (context: any) => {
          return isStringWithValue(context.model.request_ip)
            ? isValidIpV4Address(context.model.request_ip) || isValidIpV6Address(context.model.request_ip)
            : true;
        },
        i18n_message: 'audit_logs.request_ip_is_invalid'
      }
    ];
    return layoutItem;
  }
}

export const commonFilterSchemaModule = new CommonFilterSchemaModule();

function isValidIpV4Address(address: string): boolean {
  return /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/.test(address);
}

function isValidIpV6Address(address: string): boolean {
  return /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/.test(
    address
  );
}

