export default {
  source_group: 'source group',
  videos_and_media: 'Videos and media',
  videos_and_media_description_1: 'Please upload media files',
  videos_and_media_description_2: 'Photos format “.jpg, .png, etc”',
  videos_and_media_description_3: 'Videos files “.mp4, .mov, .avi, etc”',
  upload_first_media: 'upload first video',
  drag_and_drop_files: 'Drag and drop files to upload or',
  or: 'or',
  select_files: 'select files',
  next: 'next',
  upload: 'upload',
  cancel: 'cancel',
  process_immediately: 'Process video immediately after upload',
  detectors: 'detectors',
  process_current: 'Process current',
  process_all_selected: 'Process selected',
  stop_process_current: 'Stop processing current',
  stop_process_all_selected: 'Stop processing selected',
  reset_params_all_selected: 'Reset video processing parameters',
  processing: 'Processing',
  uploading: 'Uploading',
  file_urls: 'File URLs',
  file_urls_placeholder: 'Enter file URLs',
  file_select_description: 'Use Enter to add multiple URLs',
  processing_speed: 'Processing speed',
  maximum: 'maximum',
  time: 'time',
  episodes: 'episodes',
  in_total: 'total',
  back: 'back',
  status__completed: 'Completed',
  status__uploading: 'Uploading',
  status__processing: 'Processing',
  status__awaiting: 'Waiting',
  status__waiting_for_sync: 'Waiting',
  status__starting: 'Start processing',
  status__no_source: 'No source',
  status__inprogress: 'Processing',
  status__interrupted: 'Interrupted',
  status__misconfigured: 'Configuration error',
  status__to_many_cameras: 'License error',
  status__disabled: 'Ready to processing',
  status__not_started: 'Start error',
  status__license_error: 'License error'
};
