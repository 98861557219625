import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "event-item-proxy__empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventItemV2Short = _resolveComponent("EventItemV2Short")!
  const _component_EventItemV2Full = _resolveComponent("EventItemV2Full")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["event-item-proxy", _ctx.classes])
  }, [
    (_ctx.display)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isShort)
            ? (_openBlock(), _createBlock(_component_EventItemV2Short, _mergeProps({ key: 0 }, _ctx.$props, {
                onAction: _cache[0] || (_cache[0] = (v, p) => _ctx.$emit('action', v, p)),
                onSelect: _cache[1] || (_cache[1] = (p) => _ctx.$emit('select', p))
              }), null, 16))
            : (_openBlock(), _createBlock(_component_EventItemV2Full, _mergeProps({ key: 1 }, _ctx.$props, {
                onAction: _cache[2] || (_cache[2] = (v, p) => _ctx.$emit('action', v, p)),
                onSelect: _cache[3] || (_cache[3] = (p) => _ctx.$emit('select', p))
              }), null, 16))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_1))
  ], 2))
}