
import NIcon from '@/uikit/icons/NIcon.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ISelectItem } from './types';

@Options({
  name: 'NSelectItems',
  components: { NIcon }
})
export default class NSelectItems extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: any[];

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: Boolean, default: false })
  readonly capitalizeLabels!: boolean;

  handleClick(item: ISelectItem) {
    if (this.multiple) {
      item.checked = !item.checked;
    }
    this.$emit('select', item);
  }
}
