import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "data-sources-page__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataSourcesPageFiltersBig = _resolveComponent("DataSourcesPageFiltersBig")!
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_DataSourcesPageFiltersSmall = _resolveComponent("DataSourcesPageFiltersSmall")!
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SortDropdown = _resolveComponent("SortDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_VideoProcessDialog = _resolveComponent("VideoProcessDialog")!
  const _component_AddNewWizard = _resolveComponent("AddNewWizard")!
  const _component_EntityBlank = _resolveComponent("EntityBlank")!
  const _component_ListPage = _resolveComponent("ListPage")!

  return (_openBlock(), _createBlock(_component_ListPage, {
    class: "data-sources-page",
    state: _ctx.state,
    "onUpdate:scrollBottom": _ctx.scrollBottomHandler,
    statistics: ""
  }, {
    filters: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        (_ctx.areCamerasFiltersVisible)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.areBigFiltersVisible)
                ? (_openBlock(), _createBlock(_component_DataSourcesPageFiltersBig, {
                    key: 0,
                    onClose: _ctx.closeBigFiltersWindow,
                    onReset: _ctx.resetFilterState,
                    modelValue: _ctx.module.filter.current,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.filter.current) = $event)),
                    "force-filter": _ctx.module.filter.force,
                    state: _ctx.state
                  }, null, 8, ["onClose", "onReset", "modelValue", "force-filter", "state"]))
                : _createCommentVNode("", true),
              _createVNode(_component_DataSourcesPageFiltersSmall, {
                key: "camera-filters",
                onExpand: _ctx.toggleBigFiltersVisibility,
                onReset: _ctx.resetFilterState,
                modelValue: _ctx.module.filter.current,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.module.filter.current) = $event)),
                "has-changes": _ctx.module.filter.hasChanges,
                "force-filter": _ctx.module.filter.force,
                state: _ctx.state
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NButtonGroup, {
                    items: _ctx.pageTypeItems,
                    modelValue: _ctx.state.pageType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.pageType) = $event))
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }, 8, ["onExpand", "onReset", "modelValue", "has-changes", "force-filter", "state"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.areVideoFiltersVisible)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_FiltersSmall, {
                class: "data-sources-page__videos-filters",
                key: "video-filters",
                state: _ctx.state,
                "form-layout": _ctx.getVideoFiltersLayout(true),
                "has-changes": _ctx.module.hasFilterChanges,
                modelValue: _ctx.module.filter.current,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.module.filter.current) = $event)),
                onResetFilters: _cache[5] || (_cache[5] = () => _ctx.module.resetFilters()),
                onOpenFilters: _cache[6] || (_cache[6] = ($event: any) => (_ctx.areBigFiltersVisible = true)),
                enabledFields: _ctx.smallFilterFields
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NButtonGroup, {
                    items: _ctx.pageTypeItems,
                    modelValue: _ctx.state.pageType,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.pageType) = $event))
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }, 8, ["state", "form-layout", "has-changes", "modelValue", "enabledFields"]),
              (_ctx.areBigFiltersVisible)
                ? (_openBlock(), _createBlock(_component_FiltersBig, {
                    key: 0,
                    "form-layout": _ctx.getVideoFiltersLayout(false),
                    modelValue: _ctx.module.filter.current,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.module.filter.current) = $event)),
                    state: _ctx.state,
                    onResetFilters: _cache[8] || (_cache[8] = () => _ctx.module.resetFilters()),
                    onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.areBigFiltersVisible = false)),
                    enabledFields: _ctx.smallFilterFields,
                    "onUpdate:enabledFields": _cache[10] || (_cache[10] = (v) => _ctx.setSmallFilterFields(v))
                  }, null, 8, ["form-layout", "modelValue", "state", "enabledFields"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    summary: _withCtx(() => [
      _createVNode(_component_NBaseBar, null, {
        start: _withCtx(() => [
          _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"]),
          _createVNode(_component_SortDropdown, {
            items: _ctx.sortTypes,
            modelValue: _ctx.module.filter.current.ordering,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.module.filter.current.ordering) = $event))
          }, null, 8, ["items", "modelValue"])
        ]),
        end: _withCtx(() => [
          (_ctx.modelAcl.add)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                onAction: _ctx.enableWizard,
                icon: _ctx.createIcon,
                type: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.createLabel), 1)
                ]),
                _: 1
              }, 8, ["onAction", "icon"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    items: _withCtx(() => [
      (_ctx.module.items.length !== 0)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tableComponent), {
            key: 0,
            items: _ctx.module.items,
            "selected-items": _ctx.sidebarSelectedItemIds,
            "model-acl": _ctx.modelAcl,
            onSelect: _ctx.handleSelect
          }, null, 8, ["items", "selected-items", "model-acl", "onSelect"]))
        : _createCommentVNode("", true),
      (_ctx.showVideoProcess)
        ? (_openBlock(), _createBlock(_component_VideoProcessDialog, {
            key: 1,
            model: "videos",
            cameraId: _ctx.module.currentItem.item.id,
            videoArchive: _ctx.module.currentItem.item,
            onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showVideoProcess = false))
          }, null, 8, ["cameraId", "videoArchive"]))
        : _createCommentVNode("", true),
      (_ctx.wizardEnabled)
        ? (_openBlock(), _createBlock(_component_AddNewWizard, {
            key: 2,
            modelValue: this.state.pageType,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((this.state.pageType) = $event)),
            onUpload: _ctx.uploadHandler,
            onClose: _ctx.closeWizard
          }, null, 8, ["modelValue", "onUpload", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    "items-empty": _withCtx(() => [
      (!_ctx.module.loading && _ctx.module.items.length === 0)
        ? (_openBlock(), _createBlock(_component_EntityBlank, {
            key: 0,
            "page-prefix": "ds",
            "page-type": _ctx.state.pageType,
            "create-icon": _ctx.state.pageType === 'videos' ? 'upload' : 'add',
            "has-create": _ctx.modelAcl.add,
            "is-empty": !_ctx.module.filter.hasChanges,
            onCreate: _ctx.enableWizard
          }, null, 8, ["page-type", "create-icon", "has-create", "is-empty", "onCreate"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["state", "onUpdate:scrollBottom"]))
}