import { reactive } from 'vue';
import { BboxConfig, SomeBox } from '@/uikit/bbox/types';
import { PhotoViewerControllerItem, PhotoViewerControllerOptions } from '@/components/photo-viewer/types';
import { convertSomeBoxToBox } from '@/uikit/bbox/helpers';
import { ArrowTypes } from '@/uikit/image-viewer/types';

function tryConvertToPhotoViewerControllerItems(items: any[]): items is PhotoViewerControllerItem[] {
  return items.every((item) => {
    item.fullframe = item.fullframe || item.source_photo;
    return item.fullframe && (typeof item.fullframe === 'string' || (typeof item.fullframe === 'object' && item.fullframe instanceof Blob));
  });
}

export class PhotoViewerController {
  options: PhotoViewerControllerOptions = reactive({
    key: 1,
    activeItemIndex: 0,
    visible: false,
    itemsButton: true,
    arrowTypes: ArrowTypes.TopLeft,
    items: [],
    bboxes: []
  });

  convertToBBoxConfigs(boxes: SomeBox[]): BboxConfig[] {
    return boxes.map((v) => {
      return {
        box: convertSomeBoxToBox(v),
        meta: {}
      };
    });
  }

  show(item: unknown[] | unknown, options: Partial<PhotoViewerControllerOptions> = {}) {
    const items = Array.isArray(item) ? item : [item];
    if (!tryConvertToPhotoViewerControllerItems(items)) {
      throw new Error('Unknown $photoViewerController items. `fullframe` property required!');
    }

    Object.assign(this.options, options);

    this.options.items = items.map((item) => ({
      fullframe: item.fullframe,
      thumbnail: item.thumbnail
    }));

    this.options.bboxes = items.map((item) => {
      if (item.bboxes) return this.convertToBBoxConfigs(item.bboxes);
      if (item.bbox) return this.convertToBBoxConfigs([item.bbox]);
      try {
        return this.convertToBBoxConfigs([item] as any);
      } catch (e) {
        //
      }
      return [];
    });
    this.options.visible = true;
  }

  hide() {
    this.options.visible = false;
    this.options.activeItemIndex = 0;
  }
}

export const photoViewerController = new PhotoViewerController();
