
import CardItemRow from '@/components/cards/deprecated/CardItemRow.vue';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RouterModule } from '@/store/router';

@Options({
  name: 'CardOrUnmatched',
  components: { ConfidenceDelimiter, CardItemRow }
})
export default class CardOrUnmatched extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedCard;

  @Prop({ type: Boolean, default: false })
  readonly deleted!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;

  handleAction(name: string) {
    switch (name) {
      case 'show-fullframe':
        this.$emit('display-card-image', this.item.faceObject?.source_photo);
        break;
      case 'card-navigate':
        RouterModule.navigateToCard(this.item.id, 'humans');
        break;
    }
  }
}
