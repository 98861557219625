
import NTabs from '@/uikit/tabs/NTabs.vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { AclModelNames, PageTab, Tabs } from './tabs-helper';
import { aclModule } from '@/store/acl';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { ListViewModel } from '@/definitions/view-models';
import { RouterModule } from '@/store/router';

@Options({
  components: {
    NTabs
  }
})
export default class SessionsAndBlocklistTabs extends Vue {
  tabName = '';

  get tabs(): PageTab[] {
    return [
      { name: Tabs.sessions, label: this.$t('common.sessions', 'f'), enabled: this.sessionsModelAcl.view },
      { name: Tabs.blocklist_records, label: this.$t('common.blocklist_records', 'f'), enabled: this.blocklistModelAcl.view }
    ];
  }

  get sessionsState(): PageState {
    const tab = 'sessions';
    return pageModule.getPageStateByTab(PageName.sessions, tab);
  }

  get blocklistState(): PageState {
    const tab = 'blacklist';
    return pageModule.getPageStateByTab(PageName.block_list, tab);
  }

  get enabledTabs(): PageTab[] {
    return this.tabs.filter((item) => item.enabled);
  }

  get sessionsModule(): ListViewModel<any, any> {
    pageModule.getPageModule(this.sessionsState).aclModelName = AclModelNames.sessions;
    return pageModule.getPageModule(this.sessionsState) as unknown as ListViewModel<any, any>;
  }

  get blocklistModule(): ListViewModel<any, any> {
    pageModule.getPageModule(this.blocklistState).aclModelName = AclModelNames.blocklist_records;
    return pageModule.getPageModule(this.blocklistState) as unknown as ListViewModel<any, any>;
  }

  get sessionsModelAcl() {
    return aclModule.getModelAcl(this.sessionsModule);
  }

  get blocklistModelAcl() {
    return aclModule.getModelAcl(this.blocklistModule);
  }

  @Watch('tabName')
  changeTabName(v: string) {
    const name = v === Tabs.blocklist_records ? PageName.block_list : PageName.sessions;
    this.$router.push(
      RouterModule.getRouteLocation({
        name,
        tab: this.$route.query.tab ?? ''
      })
    );
  }

  @Watch('$route.path', { immediate: true })
  changeRoutePath(v: string) {
    if (v === '/sessions') {
      this.tabName = Tabs.sessions;
    } else if (v === '/blocklist-records') {
      this.tabName = Tabs.blocklist_records;
    }
  }
}
