
import { Options, Vue } from 'vue-class-component';
import { NMultitool, IMultitoolActionI18N } from '@/uikit';
import { NDropdown, NDropdownItem, NButton } from '@/uikit';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import { Prop } from 'vue-property-decorator';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { DefaultModelAclResult, DefaultItemAclResult } from '@/store/acl';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

const ActionAcknowledgeConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.Acknowledge,
  i18n_label: 'common.acknowledge',
  hidden: true
};

type IMultitoolItem = any;

@Options({
  name: 'SettingsMultitool',
  components: { NButton, NMultitool, NDropdown, NDropdownItem, NDoubleButton }
})
export default class SettingsMultitool extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly extraActions!: IMultitoolActionI18N[];

  @Prop({ type: Array, default: () => [] })
  readonly items!: IMultitoolItem[];

  @Prop({ type: Object })
  readonly currentItem?: IMultitoolItem;

  @Prop({ type: Number, default: 0 })
  readonly changedCount!: number;

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: IMultitoolItem[];

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Boolean, default: true })
  readonly activable = true;

  @Prop({ type: Boolean, default: true })
  readonly updateEnabled = true;

  @Prop({ type: Boolean, default: false })
  readonly processable = false;

  @Prop({ type: Boolean, default: true })
  readonly showSidebar = true;

  @Prop({ type: Boolean, default: true })
  readonly hasCurrent = true;

  get isAllSelected() {
    return this.selectedItems?.length === this.items?.length;
  }

  get visibleMainAction() {
    return this.currentItem ? (this.currentItem.acknowledged ? null : ActionAcknowledgeConfig) : null;
  }

  get availableActions() {
    return [];
  }

  handleClose() {
    this.$emit('action', ItemsActionNames.DeselectAll);
  }
}
