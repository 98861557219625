
import { applicationModule } from '@/store/application';
import NButton from '@/uikit/buttons/NButton.vue';
import { Options, Vue } from 'vue-property-decorator';

@Options({
  components: { NButton }
})
export default class LoginPageSystemThemeChanger extends Vue {
  private readonly applicationModule = applicationModule;

  changeSystemTheme(): void {
    this.applicationModule.toggleTheme();
  }
}
