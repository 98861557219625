<template>
  <div>{{ modelValue }}</div>
</template>

<script>
export default {
  name: 'TestText',
  props: ['modelValue']
};
</script>

<style scoped></style>
