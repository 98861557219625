import SimpleText from '@/uikit/simple/SimpleText.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { IFormLayoutItem, IFormLayoutRow } from '@/uikit/forms/NForm.vue';

export type MetaFormField = typeof MetaFormFields[keyof typeof MetaFormFields];
export const MetaFormFields = {
  Id: 'id',
  Created: 'created',
  Updated: 'updated'
} as const;

export function getDefaultMetaFormFields(fields: MetaFormField[] = Object.values(MetaFormFields)) {
  return Object.entries(metaFields)
    .filter(([key]) => fields.includes(key as MetaFormField))
    .map(([_, value]) => value);
}

const metaFields: Record<MetaFormField, IFormLayoutItem> = {
  id: {
    label: 'ID',
    path: 'id',
    classes: 'label-xs n-form-label-horizontal-auto n-form-label-color-300 n-form-block-start n-form-pad-10',
    component: SimpleText,
    props: {
      class: 'label-mono-xs'
    }
  },
  created: {
    i18n_label: 'common.created',
    path: 'created_date',
    classes: 'label-xs n-form-label-horizontal-auto n-form-label-color-300 n-form-pad-10',
    component: NDateTimeLabel
  },
  updated: {
    i18n_label: 'common.updated',
    path: 'modified_date',
    classes: 'label-xs n-form-label-horizontal-auto n-form-label-color-300 n-form-pad-10',
    component: NDateTimeLabel
  }
};
