
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NLoadingDots',
  props: {
    type: {
      type: String,
      default: 'default'
    }
  }
});
