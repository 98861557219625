
import { Options, Vue } from 'vue-class-component';
import CardItem from '@/components/cards/deprecated/CardItem2.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';
import { Prop, Watch } from 'vue-property-decorator';
import { CaseParticipant, HumanCard } from '@/api';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';
import { RouterModule } from '@/store/router';
import { CardTypesMap } from '@/store/application/data.assets';

@Options({
  name: 'ParticipantRelatedItems',
  components: { CardItem, ParticipantItem },
  emits: []
})
export default class ParticipantRelatedItems extends Vue {
  public enrichRelatedParticipants: EnrichedParticipant[] = [];
  public enrichRelatedCards: EnrichedCard[] = [];

  @Prop({ type: Array, default: [] })
  readonly relatedCards!: Array<HumanCard>; // todo: add cards car

  @Prop({ type: Array, default: [] })
  readonly relatedParticipants!: CaseParticipant[];

  @Watch('relatedCards')
  async watchRelatedCards() {
    this.enrichRelatedCards = await enrichCards(this.relatedCards);
  }

  @Watch('relatedParticipants')
  async watchRelatedParticipants() {
    this.enrichRelatedParticipants = await enrichParticipants(this.relatedParticipants);
  }

  async mounted() {
    this.enrichRelatedParticipants = await enrichParticipants(this.relatedParticipants);
    this.enrichRelatedCards = await enrichCards(this.relatedCards);
  }

  participantClick(participant: EnrichedParticipant) {
    RouterModule.navigateToParticipants(participant);
  }

  cardClick(card: EnrichedCard) {
    RouterModule.navigateToCard(card.id, CardTypesMap.Humans);
  }
}
