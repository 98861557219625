/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type CarEpisode = {
    readonly id: number;
    /**
     * Episode creation date based on the creation date of the event
     */
    readonly created_date: string;
    /**
     * Episode closing date
     */
    readonly closed_date: string;
    readonly open: boolean;
    /**
     * The number of events in the episode
     */
    readonly events_count: number;
    /**
     * `true` if the episode is acknowledged
     */
    acknowledged?: boolean;
    /**
     * Acknowledgment date of the episode
     */
    readonly acknowledged_date: string;
    /**
     * User that acknowledged episode
     */
    readonly acknowledged_by: number;
    /**
     * Episode acknowledgement reaction
     */
    acknowledged_reaction?: string;
    /**
     * Camera list of the episode
     */
    readonly cameras: Array<number>;
    /**
     * Episode camera group list
     */
    readonly camera_groups: Array<number>;
    /**
     * Episode matched lists
     */
    readonly matched_lists: Array<number>;
    /**
     * Related video archive id
     */
    readonly video_archive: number;
    /**
     * Matched card
     */
    readonly matched_card: number;
    /**
     * Related case
     */
    readonly case: number;
    readonly last_car_event: Record<string, str_int_float_bool_object_array_null>;
    readonly matched_car_event: Record<string, str_int_float_bool_object_array_null>;
    readonly best_car_event: Record<string, str_int_float_bool_object_array_null>;
    readonly car_features: Record<string, str_int_float_bool_object_array_null>;
};
export const EmptyCarEpisode: CarEpisode = {
    id: 0,
    /**
     * Episode creation date based on the creation date of the event
     */
    created_date: '',
    /**
     * Episode closing date
     */
    closed_date: '',
    open: false,
    /**
     * The number of events in the episode
     */
    events_count: 0,
    /**
     * `true` if the episode is acknowledged
     */
    acknowledged: false,
    /**
     * Acknowledgment date of the episode
     */
    acknowledged_date: '',
    /**
     * User that acknowledged episode
     */
    acknowledged_by: 0,
    /**
     * Episode acknowledgement reaction
     */
    acknowledged_reaction: '',
    /**
     * Camera list of the episode
     */
    cameras: [],
    /**
     * Episode camera group list
     */
    camera_groups: [],
    /**
     * Episode matched lists
     */
    matched_lists: [],
    /**
     * Related video archive id
     */
    video_archive: 0,
    /**
     * Matched card
     */
    matched_card: 0,
    /**
     * Related case
     */
    case: 0,
    last_car_event: {},
    matched_car_event: {},
    best_car_event: {},
    car_features: {},
};
