
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export enum BlocklistItemTypes {
  Link = 'link',
  Short = 'short',
  Common = 'common', // todo: implement
  Small = 'small'
}

@Options({
  name: 'BlocklistItem'
})
export default class BlocklistItem extends Vue {
  @Prop({ type: String, required: true })
  color!: string;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, default: BlocklistItemTypes.Short })
  type!: BlocklistItemTypes;

  handleClick() {
    this.$emit('update:modelValue', this.name); // todo: on link type only for now
  }

  get classes() {
    return {
      [`blocklist-item_${this.type}`]: true,
      'control-m': this.type === BlocklistItemTypes.Link,
      'label-m': this.type === BlocklistItemTypes.Common,
      'label-xs': this.type === BlocklistItemTypes.Short,
      'label-mono-2xs': this.type === BlocklistItemTypes.Small
    };
  }
}
