import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import without from 'lodash/without';
import { computed, Ref, unref, VNode } from 'vue';
import {
  isTableCellClassFactory,
  isTableCellContentFactory,
  isTableCellSchema,
  isTableCellStyleFactory,
  NTableBodyCell,
  NTableCell,
  NTableCellClass,
  NTableCellContent,
  NTableCellContentSchema,
  NTableCellCustomClass,
  NTableCellCustomStyle,
  NTableCellStyle,
  NTableColumn,
  NTableContent,
  NTableSectionSchema,
  NTableSectionSchemaDecorator,
  NTableSectionSchemaDecoratorFactoryProps
} from './types';
import { isEqual, isEqualWith } from 'lodash';

export function computeTableCellClass<C extends NTableCell>(cell: C, customClass: NTableCellCustomClass<C>): NTableCellClass {
  return isTableCellClassFactory(customClass) ? customClass(cell) : customClass;
}

export function computeTableCellContent<C extends NTableCell>(cell: C, schema: NTableCellContentSchema<C>): NTableCellContent {
  return isTableCellContentFactory(schema) ? schema(cell) : schema;
}

export function computeTableCellStyle<C extends NTableCell>(cell: C, customStyle: NTableCellCustomStyle<C>): NTableCellStyle {
  return isTableCellStyleFactory(customStyle) ? customStyle(cell) : customStyle;
}

export function createCommonTableSelectionColumn<T extends NTableContent>(
  selected: Ref<Readonly<T[]>>,
  content: Ref<Readonly<T[]>>,
  update: (selected: T[]) => void,
  updateChanges: (selectedChanges: T[]) => void
): NTableColumn<T> {
  const contentSet = computed(() => new Set(unref(content)));
  const selectedSet = computed(() => new Set(unref(selected)));

  function renderBodyCheckbox({ model }: NTableBodyCell<T>): VNode {
    const props = {
      'onUpdate:modelValue': (state: boolean) => {
        updateChanges([model]);
        update(state ? [...unref(selected), model] : without(unref(selected), model));
      },
      modelValue: unref(selectedSet).has(model)
    };
    return <NCheckbox {...props} />;
  }

  function renderHeadCheckbox(): VNode {
    const props = {
      'onUpdate:modelValue': (state: boolean) => {
        if (state) {
          const changes = getSelectedDifference([...unref(content)], [...unref(selected)]);
          updateChanges(changes);
        } else {
          updateChanges([...unref(selected)]);
        }
        update(state ? [...unref(content)] : []);
      },
      modelValue: isEqualWith(unref(selectedSet), unref(contentSet), compareSetsOfObjects),
      indeterminate: unref(selectedSet).size > 0 && unref(contentSet).size !== unref(selectedSet).size
    };
    return <NCheckbox {...props} />;
  }

  return { width: 40, body: renderBodyCheckbox, head: renderHeadCheckbox };
}

export function createIdTableSelectionColumn<T extends { id: number | string }>(
  selected: Ref<Readonly<(string | number)[]>>,
  content: Ref<Readonly<T[]>>,
  update: (selected: (number | string)[]) => void,
  updateChanges: (selectedChanges: (number | string)[]) => void
): NTableColumn<any> {
  const contentSet = computed(() => new Set(unref(content).map((v) => v.id)));
  const selectedSet = computed(() => new Set(unref(selected)));

  function renderBodyCheckbox({ model }: NTableBodyCell<any>): VNode {
    const props = {
      'onUpdate:modelValue': (state: boolean) => {
        updateChanges([model.id]);
        update(state ? [...unref(selected), model.id] : without(unref(selected), model.id));
      },
      modelValue: unref(selectedSet).has(model.id)
    };
    return <NCheckbox {...props} />;
  }

  function renderHeadCheckbox(): VNode {
    const props = {
      'onUpdate:modelValue': (state: boolean) => {
        if (state) {
          const changes = getSelectedDifference(
            unref(content).map((v) => v.id),
            [...unref(selected)]
          );
          updateChanges(changes);
        } else {
          updateChanges([...unref(selected)]);
        }
        update(state ? unref(content).map((v) => v.id) : []);
      },
      modelValue: isEqual(unref(selectedSet), unref(contentSet)),
      indeterminate: unref(selectedSet).size > 0 && unref(contentSet).size !== unref(selectedSet).size
    };
    return <NCheckbox {...props} />;
  }

  return { width: 40, body: renderBodyCheckbox, head: renderHeadCheckbox };
}

export function createTableSectionSchemaDecorator<C extends NTableCell>(props: NTableSectionSchemaDecoratorFactoryProps<C>): NTableSectionSchemaDecorator<C> {
  return (schema) => {
    const existingClass = getTableCellCustomClass(schema);
    const existingStyle = getTableCellCustomStyle(schema);
    const decoratedClass = isDefined(props.class) ? decorateTableCellCustomClass(existingClass, props.class) : existingClass;
    const decoratedStyle = isDefined(props.style) ? decorateTableCellCustomStyle(existingStyle, props.style) : existingStyle;
    return { class: decoratedClass, content: getTableCellContentSchema(schema), style: decoratedStyle };
  };
}

export function decorate<C extends NTableCell>(decorators: NTableSectionSchemaDecorator<C>[]): NTableSectionSchemaDecorator<C> {
  return (schema) => decorators.reduce((schema, decorator) => decorator(schema), schema);
}

export function getTableCellContentSchema<C extends NTableCell>(schema: NTableSectionSchema<C>): NTableCellContentSchema<C> {
  return isTableCellSchema(schema) ? schema.content : schema;
}

export function getTableCellCustomClass<C extends NTableCell>(schema: NTableSectionSchema<C>): NTableCellCustomClass<C> {
  return isTableCellSchema(schema) ? schema.class ?? {} : {};
}

export function getTableCellCustomStyle<C extends NTableCell>(schema: NTableSectionSchema<C>): NTableCellCustomStyle<C> {
  return isTableCellSchema(schema) ? schema.style ?? {} : {};
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

function decorateTableCellCustomClass<C extends NTableCell>(existing: NTableCellCustomClass<C>, provided: NTableCellCustomClass<C>): NTableCellCustomClass<C> {
  return isTableCellClassFactory(provided)
    ? (cell) => [computeTableCellClass(cell, existing), computeTableCellClass(cell, provided)]
    : isTableCellClassFactory(existing)
    ? (cell) => [computeTableCellClass(cell, existing), computeTableCellClass(cell, provided)]
    : [existing, provided];
}

function decorateTableCellCustomStyle<C extends NTableCell>(existing: NTableCellCustomStyle<C>, provided: NTableCellCustomStyle<C>): NTableCellCustomStyle<C> {
  return isTableCellStyleFactory(provided)
    ? (cell) => ({ ...computeTableCellStyle(cell, existing), ...computeTableCellStyle(cell, provided) })
    : isTableCellStyleFactory(existing)
    ? (cell) => ({ ...computeTableCellStyle(cell, existing), ...computeTableCellStyle(cell, provided) })
    : { ...existing, ...provided };
}

function compareSetsOfObjects(a: Set<Record<any, any>>, b: Set<Record<any, any>>) {
  return !!a.size && [...a].every((v) => b.has(v));
}

function getSelectedDifference<T>(items: T[], selectedItems: T[]) {
  const selectedMap = new Map(selectedItems.map((v) => [v, true]));
  return items.filter((v) => !selectedMap.has(v));
}
