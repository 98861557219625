import { UnitType } from 'dayjs';
export type IDateTimeMaskerResult = { value: string; caretPosition: null | number };
export type I18NDateIntervalItem = { label_i18n: string; interval?: [Date, Date]; offset?: { value: number; unit: UnitType } };
export type IDateIntervalItem = { label: string; interval?: [Date, Date]; offset?: { value: number; unit: UnitType } };
export type IDateSource = number | string | Date;
export type IDateRangeSource = IDateSource | [IDateSource, IDateSource];
export type IPickerDate = { timestamp: number; label: string };
export type IWeek = IPickerDate[];
export type IDateTimePlaceholder = { datePlaceholder?: string; timePlaceHolder?: string };

// You can set any format.
// All available tokens are here: https://github.com/datejs/Datejs/wiki/Format-Specifiers
export const DateTimeFormats = {
  dateTimeFull: 'DD.MM.YYYY HH:mm:ss',
  dateTimeShort: 'DD.MM.YYYY HH:mm',
  date: 'DD.MM.YYYY',
  timeShort: 'HH:mm',
  timeFull: 'HH:mm:ss'
} as const;

export type DateTimeFormat = typeof DateTimeFormats[keyof typeof DateTimeFormats];
