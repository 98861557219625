
import { isStringWithValue } from '@/common/utils';
import { NHint } from '@/uikit/hint';
import { Options, Prop, Vue } from 'vue-property-decorator';
import { BodyFeatures, BodyFormattedFeatures, CarFeatures, FaceFeatures, FeatureObject } from './types';
import { getFeaturesFormatter } from './utils';

const BodiesSort = [
  'age_group',
  'gender',
  'detailed_upper_clothes',
  'upper_clothes',
  'top_color',
  'lower_clothes',
  'bottom_color',
  'headwear',
  'bag_back',
  'bag_hand',
  'bag_ground',
  'vest_type',
  'helmet_type'
];

const CarsSort = [
  'make',
  'model',
  'body',
  'color',
  'license_plate_country',
  'license_plate_number',
  'license_plate_region',
  'license_plate_number_color',
  'special_vehicle_type',
  'category',
  'weight_type',
  'orientation'
];

@Options({
  components: { NHint },
  methods: { isStringWithValue }
})
export default class Features extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly columns!: boolean;

  @Prop({ required: true, type: Object })
  readonly features!: Partial<BodyFeatures | CarFeatures | FaceFeatures>;

  @Prop({ default: false, type: Boolean })
  readonly header!: boolean;

  @Prop({ required: true, type: String })
  readonly objects!: FeatureObject;

  @Prop({ default: 'x-small', type: String })
  readonly size!: '2x-small' | 'small' | 'x-small';

  get classes() {
    return {
      'label-2xs': this.size === '2x-small',
      'label-s': this.size === 'small',
      'label-xs': this.size === 'x-small',
      features_columns: this.columns,
      features: true
    };
  }

  get formattedFeatures() {
    return getFeaturesFormatter(this.objects)(this.features, { t: this.$t, tm: this.$tm });
  }

  get formattedFeatureNames(): string[] {
    let featuresSort: string[] = [];
    switch (this.objects) {
      case 'cars':
        featuresSort = CarsSort;
        break;
      case 'bodies':
        featuresSort = BodiesSort;
        break;
    }

    return this.featuresSort(Object.keys(this.formattedFeatures), featuresSort);
  }

  featuresSort(sourceFeatures: string[], newOrder: string[]) {
    return sourceFeatures.sort((a, b) => {
      const firstIndex = newOrder.indexOf(a);
      const secondIndex = newOrder.indexOf(b);
      return firstIndex < 0 ? 1 : secondIndex < 0 ? -1 : firstIndex - secondIndex;
    });
  }
}
