import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "n-page__filters" }
const _hoisted_2 = { class: "n-page__statistics" }
const _hoisted_3 = {
  key: 0,
  class: "n-page__tiles n-page__tiles-compensation-5"
}
const _hoisted_4 = {
  key: 1,
  class: "page-items__container"
}
const _hoisted_5 = { class: "page__no-data-item label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_ParticipantItem = _resolveComponent("ParticipantItem")!
  const _component_ClusterItem = _resolveComponent("ClusterItem")!
  const _component_ClusterWizard = _resolveComponent("ClusterWizard")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, {
    onFetch: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clusterModule.append()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FilterSection, {
          modelValue: _ctx.clusterModule.filter.current,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.clusterModule.filter.current) = $event)),
          "has-filter-changes": _ctx.clusterModule.hasFilterChanges,
          state: _ctx.state,
          onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clusterModule.resetFilters()))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Statistics, { module: _ctx.clusterModule }, null, 8, ["module"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "has-filter-changes", "state"])
      ]),
      (_ctx.clusterModule.loaded && _ctx.clusterModule.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusters, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: item.id
              }, [
                (_ctx.participantByClusterIDMap[item.id])
                  ? (_openBlock(), _createBlock(_component_ParticipantItem, {
                      key: 0,
                      item: _ctx.participantByClusterIDMap[item.id],
                      onAction: (v) => _ctx.participantActionHandler(item.id, v, _ctx.participantByClusterIDMap[item.id])
                    }, null, 8, ["item", "onAction"]))
                  : (_openBlock(), _createBlock(_component_ClusterItem, {
                      key: 1,
                      item: item,
                      onAction: (v) => _ctx.clusterActionHandler(item.id, v, item),
                      objects: "faces"
                    }, null, 8, ["item", "onAction"]))
              ], 64))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.clusterModule.loaded && !_ctx.clusterModule.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('common.no_data', 'f')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.wizardCluster)
        ? (_openBlock(), _createBlock(_component_ClusterWizard, {
            key: 2,
            caseId: _ctx.item.id,
            cluster: _ctx.wizardCluster,
            "participants-acl": _ctx.participantsAcl,
            onClose: _ctx.closeWizard
          }, null, 8, ["caseId", "cluster", "participants-acl", "onClose"]))
        : _createCommentVNode("", true),
      (_ctx.wizardParticipant)
        ? (_openBlock(), _createBlock(_component_ClusterWizard, {
            key: 3,
            caseId: _ctx.wizardParticipant.case,
            cluster: _ctx.wizardParticipant.faceClusterObject,
            participant: _ctx.wizardParticipant,
            "participants-acl": _ctx.participantsAcl,
            onClose: _ctx.closeWizard
          }, null, 8, ["caseId", "cluster", "participant", "participants-acl", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}