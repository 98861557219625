
import { CamerasService, InlineStreamSettings, VideoArchive } from '@/api';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import SettingsMultitool from '@/components/common/SettingsMultitool.vue';
import Sidebar from '@/components/common/sidebar/Sidebar.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import DeletingVideoConfirm from '@/components/data-source/DeletingVideoConfirm.vue';
import ProcessingVideoConfirm from '@/components/data-source/ProcessingVideoConfirm.vue';
import dataSourceAdvanced from '@/pages/data-sources/forms/data-source-advanced';
import dataSourceAnalytics from '@/pages/data-sources/forms/data-source-analytics';
import { getDataSourceFileGeneralLayout } from '@/pages/data-sources/forms/data-source-general-file';
import dataSourceZones from '@/pages/data-sources/forms/data-source-zones';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import FirstVideoForm from '@/pages/videos/FirstVideoForm.vue';
import SelectVideoForm from '@/pages/videos/SelectVideoForm.vue';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { aclModule } from '@/store/acl';
import { IModelAclResult } from '@/store/acl/types';
import { applicationModule } from '@/store/application';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { uploadModule } from '@/store/application/upload.module';
import { configModule } from '@/store/config';
import { websocketModule } from '@/store/ws/websocket.module';
import { IMultitoolActionI18N, NButton, NInput, NLoadingCircle } from '@/uikit';
import NForm from '@/uikit/forms/NForm.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { cloneDeep } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import VideoProcessPreview from './process-preview/VideoProcessPreview.vue';
import { columns } from './video-table-schema';

const ActionProcessConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.Process,
  i18n_label: 'videos.process_current',
  hidden: true,
  enabled: ({ modelAcl, itemAcl }) => modelAcl.update && itemAcl.update
};

const ActionStopProcessConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.StopProcess,
  i18n_label: 'videos.stop_process_current',
  hidden: true,
  enabled: ({ modelAcl, itemAcl }) => modelAcl.update && itemAcl.update
};

const ActionDeleteCurrentVideoFileConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.DeleteVideoFile,
  i18n_label: 'common.delete_file',
  hidden: true,
  enabled: ({ modelAcl, itemAcl }) => itemAcl.delete && modelAcl.delete
};

const ActionResetStreamSettingsConfig: IMultitoolActionI18N = {
  name: '', // ItemsActionNames.ResetStreamSettings,
  i18n_label: 'ds.reset_params',
  hidden: true,
  enabled: ({ modelAcl, itemAcl }) => modelAcl.update && itemAcl.update
};

const ActionShowPlayerConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.ShowPlayer,
  icon: 'play'
};

function getDefaultVideoConfiguration() {
  const model: Record<string, number | boolean> = {
    camera_group: -1,
    face_detector: false,
    body_detector: false,
    car_detector: false
  };

  dataAssetsModule.availableObjects.forEach((v) => {
    model[`${v}_detector`] = true;
  });

  return model;
}

@Options({
  name: 'VideosPage',
  components: {
    DeletingVideoConfirm,
    FirstVideoForm,
    NButton,
    NForm,
    NInput,
    NLoadingCircle,
    NTable,
    NTabs,
    PageContentLayout,
    ProcessingVideoConfirm,
    SelectVideoForm,
    SettingsMultitool,
    Sidebar,
    SidebarHeader,
    SidebarTab,
    VideoProcessPreview
  },
  emits: ['create']
})
export default class VideosPage extends Vue {
  @Prop({ required: false })
  item?: any;

  @Prop({ required: false })
  externalAcl?: IModelAclResult;

  createEnabled = false;
  defaultParameters?: InlineStreamSettings;
  hasSidebar = false;
  showPlayer = false;
  playerItem: null | VideoArchive = null;
  videosForProcess: VideoArchive[] = [];
  showDeletingVideoConfirm = false;
  multipleDeleting = false;

  activeTab = 'general';

  get isMainTab() {
    const detectorTabs = ['face', 'body', 'car'];
    return detectorTabs.indexOf(this.activeTab) === -1;
  }

  get isDetectorTab() {
    return !this.isMainTab;
  }

  get sidebarVideoFormState() {
    return {
      defaultDetectorSettings: this.defaultParameters?.stream_settings?.detectors
    };
  }

  get processedCount() {
    return this.module.items.filter((v) => v.finished).length;
  }

  get notProcessedCount() {
    return this.module.items.length - this.processedCount;
  }

  get tabItems() {
    const detectors = this.currentItem?.stream_settings?.detectors as any;
    const result = [
      {
        name: 'general',
        label: this.$t('common.general', 'f')
      },
      {
        name: 'advanced',
        label: this.$t('common.advanced', 'f')
      },
      {
        name: 'zones',
        label: this.$t('common.zones', 'f')
      }
    ];

    if (detectors?.face) {
      result.push({
        name: 'face',
        label: this.$t('common.faces', 'f')
      });
    }

    if (detectors?.body) {
      result.push({
        name: 'body',
        label: this.$t('common.bodies', 'f')
      });
    }

    if (detectors?.car) {
      result.push({
        name: 'car',
        label: this.$t('common.cars', 'f')
      });
    }

    return result;
  }

  get websocketModule() {
    return websocketModule;
  }

  get appModule() {
    return applicationModule;
  }

  get module() {
    const result = viewModelRepository.getVideosListViewModel();
    result.aclModelName = 'videoarchive';
    result.excludedChangeKeys = [
      'health_status',
      'finished',
      'progress',
      'face_count',
      'face_cluster_count',
      'body_count',
      'body_cluster_count',
      'processing_start_date',
      'source_len',
      'active'
    ];
    result.filter.current.limit = '1000';
    (result.filter.current as any).case_in = this.item?.id;
    return result;
  }

  get columns() {
    return columns;
  }

  get selectedItems() {
    return this.module.selectedItems.map((v) => v.item);
  }

  get maxAcl() {
    return { view: this.modelAcl.view, update: this.modelAcl.update, delete: this.modelAcl.delete };
  }

  get currentItemAcl() {
    return this.maxAcl;
  }

  get modelAcl() {
    const currentModelAcl = aclModule.getModelAcl(this.module);
    return this.externalAcl ? aclModule.mergeAcl(currentModelAcl, this.externalAcl) : currentModelAcl;
  }

  get currentItem() {
    return this.module.currentItem?.item;
  }

  get currentDetectorItem() {
    const detectors = this.currentItem?.stream_settings?.detectors as any;
    return detectors ? detectors[this.activeTab] : null;
  }

  get showCreateNewForm() {
    return this.module.loaded && !this.module.items.length;
  }

  get formLayout() {
    switch (this.activeTab) {
      case 'general':
        return getDataSourceFileGeneralLayout(this.currentItem, dataAssetsModule.availableObjects);
      case 'advanced':
        return dataSourceAdvanced;
      case 'zones':
        return dataSourceZones;
      case 'face':
      case 'body':
      case 'car':
        return dataSourceAnalytics;
      default:
        return [];
    }
  }

  get sidebarActions() {
    const result = [];
    if (this.modelAcl.delete) {
      result.push(ActionDeleteCurrentVideoFileConfig);
    }
    if (this.modelAcl.update) {
      result.push(ActionProcessConfig, ActionStopProcessConfig, ActionResetStreamSettingsConfig);
    }
    result.push(ActionShowPlayerConfig);
    return result;
  }

  get clientId() {
    return `cases:${this.item.id}`;
  }

  get configuration() {
    const configuration = { ...getDefaultVideoConfiguration(), id: this.item.id, start_stream_timestamp: 0 };
    if (this.item.incident_date) {
      let dateTime = new Date(this.item.incident_date);
      dateTime.setHours(12, 0);
      configuration.start_stream_timestamp = Math.round(dateTime.getTime() / 1000);
    }
    return configuration;
  }

  get isVideoProcessPreviewEnabled() {
    return !configModule.config.extra_options?.includes('hide_video_process_preview') ?? true;
  }

  getIsItemFormValid() {
    const result = this.$refs.form ? this.$refs.form.validate() : true;
    if (!result) {
      this.$refs.form.displayErrors();
    }
    return result;
  }

  @Watch('module.filter.current.status')
  statusHandler() {
    this.module.get();
  }

  @Watch('module.filter.current.name_contains')
  searchHandler() {
    this.module.get();
  }

  @Watch('websocketModule.videoArchivesById', { deep: true })
  videoArchivesHandler(itemsById: Record<string, VideoArchive>) {
    this.module.items.forEach((item: any) => {
      const updatedItem = itemsById[String(item.id)];
      if (updatedItem) {
        delete item['progress'];
        Object.assign(item, updatedItem);

        const selectedItem = this.selectedItems.find((v) => v?.id === item.id);
        if (selectedItem) {
          selectedItem.finished = updatedItem.finished;
        }
      }
    });
  }

  async mounted() {
    await this.module.get();
    await this.loadDefaultParameters();
    this.module.setAutoUpdate(true);
    uploadModule.registerClient(this.clientId, this.module);
    uploadModule.syncClient(this.clientId);
    this.startAutoUpdate();
  }

  activated() {
    this.startAutoUpdate();
  }

  beforeUnmount() {
    this.pauseAutoUpdate();
    uploadModule.unregisterClient(this.clientId);
  }

  deactivated() {
    this.pauseAutoUpdate();
  }

  private startAutoUpdate(): void {
    !this.module.autoUpdate.enabled && this.module.setAutoUpdate(true);
  }

  private pauseAutoUpdate(): void {
    this.module.autoUpdate.enabled && this.module.setAutoUpdate(false);
  }

  uploadHandler({ urls, attachments }: any) {
    uploadModule.createVideos(this.clientId, urls, this.configuration, this.defaultParameters);
    uploadModule.uploadVideos(this.clientId, attachments, this.configuration, this.defaultParameters);
    this.createEnabled = false;
  }

  cellClickHandler(row: any, cell: ITableCell) {
    switch (cell.path) {
      case 'name':
        this.hasSidebar = true;
        this.module.setCurrentItem(row.id);
        break;
      case 'play':
        this.processOrShowPreview(row);
        break;
    }
  }

  createHandler() {
    this.createEnabled = true;
  }

  async loadDefaultParameters() {
    this.defaultParameters = await CamerasService.camerasDefaultParametersRetrieve();
  }

  async deleteCurrentVideoFile() {
    const axios = dataServiceFactory.getAxiosInstance();
    await axios.delete(`videos/${this.currentItem?.id || ''}/file/`);
  }

  async deleteSelectedVideoFiles() {
    const axios = dataServiceFactory.getAxiosInstance();
    await this.selectedItems.forEach((selectedItem) => {
      axios.delete(`videos/${selectedItem?.id || ''}/file/`);
    });
  }

  processOrShowPreview(item: VideoArchive) {
    if (this.isVideoProcessPreviewEnabled) {
      this.showPlayer = true;
      this.playerItem = item;
    } else {
      this.videosForProcess = [item];
      this.processVideos();
    }
  }

  processVideos(reprocess = false) {
    let completedVideoIndexes: number[] = [];
    this.videosForProcess.forEach((item, i) => {
      if (reprocess || !item.finished) {
        dataServiceRepository.VideosService.createItemSomethingByAction(item?.id || '', 'process');
        completedVideoIndexes.push(i);
      }
    });

    this.videosForProcess = this.videosForProcess.filter((_, i) => !completedVideoIndexes.includes(i));
  }

  async actionHandler(actionName: ItemsActionName) {
    switch (actionName) {
      case ItemsActionNames.SelectAll:
        this.module.selectAllItems();
        break;

      case ItemsActionNames.DeselectAll:
        this.module.deselectAllItems();
        this.hasSidebar = false;
        break;

      case ItemsActionNames.SaveAll:
        if (!this.getIsItemFormValid()) return;
        await this.module.saveAllSelectedItems();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Save:
        if (!this.getIsItemFormValid()) return;
        await this.module.saveCurrentItem();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.ToggleCurrent:
        this.hasSidebar = false;
        this.module.setCurrentItem(0);
        break;

      case ItemsActionNames.DeleteAll:
        this.multipleDeleting = true;
        this.showDeletingVideoConfirm = true;
        break;

      case ItemsActionNames.Delete:
        this.multipleDeleting = false;
        this.showDeletingVideoConfirm = true;
        break;

      case ItemsActionNames.Reset:
        await this.module.resetCurrentItem();
        break;

      case ItemsActionNames.Deselect:
        this.hasSidebar = false;
        this.module.deselectCurrentItem();
        break;

      case ItemsActionNames.Close:
        await this.module.deleteCurrentItem();
        break;

      case ItemsActionNames.Process:
        if (this.currentItem) {
          this.videosForProcess = [this.currentItem];
          this.processVideos();
        }
        break;

      case ItemsActionNames.ProcessAll:
        if (this.selectedItems?.length) {
          this.videosForProcess = [...this.selectedItems] as VideoArchive[];
          this.processVideos();
        }
        break;

      case ItemsActionNames.StopProcess:
        await dataServiceRepository.VideosService.createItemSomethingByAction(this.currentItem?.id || '', 'stop');
        break;

      case ItemsActionNames.StopProcessAll:
        this.selectedItems.forEach((item) => {
          dataServiceRepository.VideosService.createItemSomethingByAction(item?.id || '', 'stop');
        });
        break;

      case ItemsActionNames.DeleteVideoFile:
        this.multipleDeleting = false;
        this.showDeletingVideoConfirm = true;
        break;

      // case ItemsActionNames.ResetStreamSettings:
      //   this.currentItem!.stream_settings = cloneDeep(this.defaultParameters!.stream_settings);
      //   await this.module.currentItem?.save();
      //   break;

      case ItemsActionNames.CameraResetAll:
        for (let selectedItem of this.module.selectedItems) {
          selectedItem!.item!.stream_settings = cloneDeep(this.defaultParameters!.stream_settings);
          await selectedItem!.save();
        }
        break;

      case ItemsActionNames.ShowPlayer:
        this.currentItem && this.processOrShowPreview(this.currentItem);
        break;

      default:
        console.warn(`Unknown action "${actionName}"`);
        break;
    }
  }

  handleTableSort(columnName: string) {
    this.module.filter.current.ordering = this.module.filter.current.ordering === columnName ? `-${columnName}` : columnName;
    this.module.get();
  }

  async deleteSelectedVideoFilesHandler() {
    await this.deleteSelectedVideoFiles();
    await this.module.get();
  }

  async deleteCurrentVideoFileHandler() {
    await this.deleteCurrentVideoFile();
    await this.module.get();
  }

  async deleteAllSelectedHandler() {
    await this.module.deleteAllSelectedItems();
    await this.module.get({ resetState: false });
  }

  async deleteAllCurrentHandler() {
    await this.module.deleteCurrentItem();
    await this.module.get({ resetState: false });
  }
  deleteVideoFilesOnly() {
    this.showDeletingVideoConfirm = false;
    this.multipleDeleting ? this.deleteSelectedVideoFilesHandler() : this.deleteCurrentVideoFileHandler();
  }

  deleteAllHandler() {
    this.showDeletingVideoConfirm = false;
    this.multipleDeleting ? this.deleteAllSelectedHandler() : this.deleteAllCurrentHandler();
  }
}
