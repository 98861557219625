
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { VideoArchive } from '@/api';

@Options({
  name: 'ProcessingVideoConfirm',
  components: { NModalWindow, NButton }
})
export default class DeletingVideoConfirm extends Vue {
  @Prop({ type: Array, default: [] })
  targetItems!: VideoArchive[];

  get title() {
    return this.$t('ds.process', 'f') + ' ' + this.videoTitles + (this.videoTitles ? ' ' : '') + this.$t('ds.again') + '?';
  }

  get videoTitles() {
    return this.targetItems
      .map((item) => item?.name)
      .toString()
      .replace(',', ', ');
  }

  confirmHandler() {
    this.$emit('confirm');
  }
  cancelHandler() {
    this.$emit('cancel');
  }
}
