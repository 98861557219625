import { viewModelRepository } from '@/api/common';
import { reactive } from 'vue';

const AclModelName = 'case';

class DataState {
  public participantsModel = viewModelRepository.getCaseParticipantsListViewModel();
  public relatedParticipantsModel = viewModelRepository.getCaseParticipantsListViewModel();
  public clusterEventFaceModel = viewModelRepository.getClusterEventsFacesListViewModel();
  public clusterEventCarModel = viewModelRepository.getClusterEventsCarsListViewModel();
  public cardCarsModel = viewModelRepository.getCardsCarsListViewModel();
  public cardHumanModel = viewModelRepository.getCardsHumansListViewModel();

  constructor() {
    this.participantsModel.aclModelName = AclModelName;
  }

  public get participant() {
    return this.participantsModel.currentItem?.item;
  }

  public get clusterEvents() {
    return [...this.clusterEventFaceModel.items, ...this.clusterEventCarModel.items];
  }

  public get relatedCards() {
    return [...this.cardHumanModel.items, ...this.cardCarsModel.items];
  }

  public get relatedParticipants() {
    return this.relatedParticipantsModel.items;
  }

  public loadRelatedCards() {
    let participant = this.participant;
    if (participant?.related_car_cards?.length) {
      this.cardCarsModel.filter.force.limit = '1000';
      this.cardCarsModel.filter.force.id_in = participant.related_car_cards;
      this.cardCarsModel.get();
    } else {
      this.cardCarsModel.reset();
    }

    if (participant?.related_human_cards?.length) {
      this.cardHumanModel.filter.force.limit = '1000';
      this.cardHumanModel.filter.force.id_in = participant.related_human_cards;
      this.cardHumanModel.get();
    } else {
      this.cardHumanModel.reset();
    }
  }

  public loadRelatedParticipants() {
    let participant = this.participant;
    if (participant?.related_participants?.length) {
      this.relatedParticipantsModel.filter.force.limit = '1000';
      this.relatedParticipantsModel.filter.force.id_in = participant.related_participants;
      this.relatedParticipantsModel.get();
    } else {
      this.relatedParticipantsModel.reset();
    }
  }

  public loadClusterEvents() {
    let participant = this.participant;
    if (participant?.face_cluster) {
      this.clusterEventFaceModel.filter.force.limit = '1000';
      this.clusterEventFaceModel.filter.force.cluster_in = [participant.face_cluster];
      this.clusterEventFaceModel.get();
    } else {
      this.clusterEventFaceModel.reset();
    }

    if (participant?.car_cluster) {
      this.clusterEventCarModel.filter.force.limit = '1000';
      this.clusterEventCarModel.filter.force.cluster_in = [participant.car_cluster];
      this.clusterEventCarModel.get();
    } else {
      this.clusterEventCarModel.reset();
    }
  }

  public async loadCaseParticipants(caseId: number) {
    if (caseId) {
      this.participantsModel.filter.force.case_in = [caseId];
      this.participantsModel.filter.force.limit = '100';
      await this.participantsModel.get();
    }
  }

  async init(caseId: number) {
    await this.loadCaseParticipants(caseId);
  }
}

export const getParticipantDataModule = () => reactive(new DataState());
