
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';

@Options({
  name: 'CardItem',
  components: { WatchListsGroup, NThumbnail }
})
export default class CardItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedCard;

  @Prop({ type: Boolean, required: false })
  readonly showFullframe: boolean = false;

  get thumbnail() {
    return this.item.faceObject?.thumbnail;
  }

  thumbnailClickHandler() {
    if (this.showFullframe) {
      this.$photoViewer.show([this.item.faceObject as any].map(this.adaptObjectToEvent));
    }
  }

  adaptObjectToEvent(v: any): any {
    return {
      id: v.id,
      thumbnail: v.thumbnail,
      fullframe: v.source_photo,
      bbox: v
    };
  }
}
