
import { viewModelRepository } from '@/api/common';
import { isStringWithValue } from '@/common/utils';
import VideosPage from '@/pages/videos/VideosPage.vue';
import { aclModule } from '@/store/acl';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { applicationModule } from '@/store/application';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { NButton } from '@/uikit';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CaseClusterPage from './CaseClusterPage.vue';
import CaseForm from './CaseForm.vue';
import CaseParticipantsPage from './CaseParticipantsPage.vue';

const TabNames = {
  Info: 'information',
  Sources: 'sources',
  Clusters: 'clusters',
  Participants: 'participants'
};

@Options({
  name: 'EditCasePage',
  components: {
    CaseClusterPage,
    CaseForm,
    CaseParticipantsPage,
    NButton,
    NTabs,
    VideosPage
  },
  emits: ['create', 'close']
})
export default class EditCasePage extends Vue {
  @Prop({ type: Object, required: false })
  readonly item: any;
  @Prop({ required: false, type: String })
  readonly tab?: string;

  currentTab = TabNames.Info;

  get compId() {
    return this.state?.id ?? Number(this.$route.query?.id);
  }

  get compItem() {
    return this.item ?? this.module.item;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get isNew() {
    return this.compId < -1000;
  }

  get TabNames() {
    return TabNames;
  }

  get maxAcl(): IModelAclResult {
    return aclModule.getModelAclByName('case');
  }

  get currentItemAclEdit(): boolean {
    return this.currentItemAcl.update;
  }

  get currentItemAcl(): IItemAclResult {
    const result = aclModule.getCaseItemAclByUserGroupMap(this.compItem.permissions || {}, this.maxAcl);
    return result;
  }

  get relatedCaseVideosAcl(): IModelAclResult {
    return { ...this.currentItemAcl, add: this.currentItemAcl.update };
  }

  get relatedCaseParticipantsAcl(): IModelAclResult {
    return aclModule.mergeAcl(this.maxAcl, { ...this.currentItemAcl, add: this.currentItemAcl.update });
  }

  get tabItems() {
    return Object.values(TabNames).map((v) => ({ label: this.$t(`common.${v}`, 'f'), name: v }));
  }

  get module() {
    return viewModelRepository.getCasesItemViewModel();
  }

  get state(): PageState | null {
    return isStringWithValue(this.tab) ? pageModule.getPageStateByTab(PageName.case, this.tab) : null;
  }

  async created() {
    if (!this.item) {
      await this.module.get(this.compId);
      const { subPage } = JSON.parse(this.$route.query?.pageState as string);
      if (subPage) {
        this.currentTab = subPage;
      }
    }
  }

  saveHandler() {
    if (!this.item) {
      this.module.save();
    } else {
      this.$emit('save');
    }
  }
}
