
import { dataModule } from '@/store/data';
import { languageModule } from '@/store/languages';
import { NButton } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { NButton }
})
export default class ChangeLanguage extends Vue {
  @Prop({ default: true, type: Boolean })
  readonly syncCurrentUserLocale!: boolean;

  get languages(): Record<string, string> {
    return languageModule.languages;
  }

  get currentLocale() {
    return languageModule.currentLocale;
  }

  get languageName() {
    return this.visibleLanguageLabel ? this.languages[this.currentLocale] : '';
  }

  get visibleLanguageLabel() {
    return this.$route.name === 'LoginPage';
  }

  changeLang() {
    languageModule.toggleLocale();
    this.syncCurrentUserLocale && dataModule.syncCurrentUserLocale();
  }
}
