
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButton, NButtonType } from '@/uikit';
import { ISelectItem } from '../select/types';
import { NButtonSize } from '@/uikit/buttons/NButton.vue';
import { NDropdownItem, NDropdown } from '@/uikit/NDropdown';

@Options({
  name: 'NButtonSelect',
  components: { NButton, NButtonType, NDropdown, NDropdownItem },
  emits: ['update:modelValue']
})
export default class NButtonSelect extends Vue {
  @Prop({ type: [String, Number], default: '' })
  public readonly modelValue!: string | number;

  @Prop({ type: Array, default: [] })
  public readonly items!: ISelectItem[];

  @Prop({ type: String, default: NButtonType.Primary })
  public readonly type!: NButtonType;

  @Prop({ type: String, default: NButtonSize.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  get currentItem() {
    return this.items.find((v) => v.value === this.modelValue);
  }

  get color() {
    return this.currentItem?.color || '';
  }

  get label() {
    return this.currentItem?.label || '';
  }
}
