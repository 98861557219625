import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "event-item-short"
}
const _hoisted_2 = {
  key: 0,
  class: "event-item-short__reaction"
}
const _hoisted_3 = {
  key: 2,
  class: "license-plate-number label-mono-xs"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "event-item-short__confidence" }
const _hoisted_6 = { class: "event-item-short__user heading-m" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "event-item__watch-lists" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Acknowledge = _resolveComponent("Acknowledge")!
  const _component_DoubleThumbnail = _resolveComponent("DoubleThumbnail")!
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_ctx.isShort)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hideAcknowledge)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Acknowledge, {
                item: _ctx.item,
                style: {"width":"170px"},
                onAction: _cache[0] || (_cache[0] = (v, p) => v && _ctx.$emit('action', v, p)),
                disabled: !_ctx.acknowledgeAllowed
              }, null, 8, ["item", "disabled"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.eventItemContainerClasses)
        }, [
          (_ctx.isEpisode)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.counterClasses)
              }, _toDisplayString(_ctx.item.events_count), 3))
            : _createCommentVNode("", true),
          (_ctx.thumbnail)
            ? (_openBlock(), _createBlock(_component_DoubleThumbnail, {
                key: 1,
                actions: _ctx.thumbnailActions,
                defaultAction: _ctx.defaultAction,
                "model-value": _ctx.thumbnail,
                "optional-model-value": _ctx.licensePlate,
                onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select'))),
                onAction: _cache[2] || (_cache[2] = (v) => _ctx.$emit('action', v))
              }, null, 8, ["actions", "defaultAction", "model-value", "optional-model-value"]))
            : _createCommentVNode("", true),
          (_ctx.areCars)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.licencePlateNumber?.confidence > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.licencePlateNumber.name), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.eventView.confidence)
              ? (_openBlock(), _createBlock(_component_Confidence, {
                  key: 0,
                  value: _ctx.eventView.confidence,
                  objects: _ctx.objectsType
                }, null, 8, ["value", "objects"]))
              : _createCommentVNode("", true)
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.cardItemContainerClasses, "event-item-short__card"]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.item.matched_card && _ctx.navigateToCard()))
        }, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.eventView.matched_card)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formattedMatchedCard), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_WatchListsGroup, {
              type: "short",
              items: _ctx.eventView.matched_lists,
              style: {"width":"160px"},
              "text-color": _ctx.watchListsColor
            }, null, 8, ["items", "text-color"]),
            _createElementVNode("div", null, [
              (!_ctx.configAclModule.isDisabledMenuItem('cases') && _ctx.item.case)
                ? (_openBlock(), _createBlock(_component_NButton, {
                    key: 0,
                    icon: "cibr",
                    type: _ctx.cardItemContainerButtonsType,
                    "icon-size": "16",
                    onAction: _ctx.navigateToCase
                  }, null, 8, ["type", "onAction"]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}