import TimelineChunks from '@/components/video-player/objects/vms/chunks';
import { Camera, CameraGroup } from '@/api';
import { dataModule } from '@/store/data';

export default class VideoPlayerState {
  resolution = '800x600';
  resolutions = ['2560x1440', '1920x1080', '1280x720', '1024x576', '800x600', '640x480'];

  videoQuality = 80;
  brightness = 50;
  contrast = 50;
  saturation = 50;
  bboxObjects = { faces: true, bodies: true, cars: true };

  canChangeQuality = true;
  canChangeObjects = true;

  timelineChunks = new TimelineChunks();

  cameraId = -1;

  camera: Camera | undefined;
  cameraGroup: CameraGroup | undefined;

  isCameraRemoved = true;

  get cameraName() {
    return (this.camera && this.camera.name) || '[Camera not found]';
  }
  get cameraGroupName() {
    return (this.cameraGroup && this.cameraGroup.name) || '[Group not found]';
  }

  async fillCameraInfoById(cameraId: number) {
    if (this.cameraId !== -1) {
      return;
    }

    await dataModule.camerasModule.firstLoadingPromise;
    await dataModule.cameraGroupsModule.firstLoadingPromise;

    this.cameraId = cameraId;
    this.camera = dataModule.camerasModule.items.find((camera) => camera.id === cameraId);
    this.cameraGroup = dataModule.cameraGroupsModule.items.find((group) => group.id === this.camera?.group);
  }
}
