import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAttachments = _resolveComponent("NAttachments")!

  return (_openBlock(), _createBlock(_component_NAttachments, {
    "disallow-attach": _ctx.disabled,
    "disallow-remove": _ctx.disabled,
    "handle-attach": _ctx.handleUpload,
    "handle-remove": _ctx.handleDelete,
    i18n: _ctx.attachmentsI18n,
    attachments: _ctx.attachments,
    "onUpdate:attachments": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.attachments) = $event))
  }, null, 8, ["disallow-attach", "disallow-remove", "handle-attach", "handle-remove", "i18n", "attachments"]))
}