
import { NModalWindow, NButton } from '@/uikit';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { PageState, PageType } from '@/store/application/page.definitions';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { aclModule } from '@/store/acl';
import { carModels } from '@/store/filters/car-models';
import { configModule } from '@/store/config';

@Options({
  name: 'FiltersBig',
  components: {
    NModalWindow,
    NForm,
    NButtonGroup,
    NButton
  },
  emits: ['resetFields', 'close', 'update:modelValue']
})
export default class FiltersBig extends Vue {
  @Prop({ type: Array, required: true })
  formLayout!: IFormLayout;

  @Prop({ type: [Object, Array], required: true })
  readonly modelValue!: any;

  @Prop({ type: String, required: false })
  readonly headerText?: string;

  @Prop({ type: [Object, Array], default: () => ({}) })
  readonly state?: PageState;

  @Prop({ type: Boolean, default: false })
  readonly hasReport!: boolean;

  @Prop({ type: [Object, Array] })
  readonly reportFilter: any;

  @Prop({ type: [Array] })
  readonly enabledFields?: string[];

  model: any = {};

  get isEvents(): boolean {
    return this.state?.pageType === PageType.events;
  }

  get isEpisodes(): boolean {
    return this.state?.pageType === PageType.episodes;
  }

  get isCards(): boolean {
    return this.state?.pageType === PageType.cards;
  }

  get isClusters(): boolean {
    return this.state?.pageType === PageType.clusters;
  }

  get isAuditLogs(): boolean {
    return this.state?.pageType === PageType.audit_logs;
  }

  get isSwitchVisible(): boolean {
    const isSearchPage = this.$route.name === 'SearchPage';
    return !isSearchPage;
  }

  get canCreateReport() {
    const canAddReport = aclModule.getAccess('ffsecurity.add_report');
    return this.hasReport && canAddReport;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  submitAndClose() {
    this.submit() && this.closeFilter(true);
  }

  submit() {
    if (this.$refs.form.validate()) {
      this.$emit('update:modelValue', this.model);
      return true;
    } else {
      this.$refs.form.displayErrors();
      return false;
    }
  }

  resetFilters() {
    this.model = {};
    this.$emit('resetFilters');
    this.closeFilter(true);
  }

  closeFilter(value: any) {
    this.$emit('close', value);
  }

  submitAndShowReportDialog() {
    if (this.submit()) {
      this.state && this.$reportsForm.show(this.state, this.reportFilter || this.modelValue);
      this.closeFilter(true);
    }
  }

  @Watch('modelValue', { deep: true })
  syncModelValue() {
    this.model = cloneDeep(this.modelValue);
  }

  async created() {
    this.syncModelValue();
  }
}
