import { CameraGroup } from '@/api';
import { dataServiceRepository } from '@/api/common';

const FORBIDDEN_CAMERA_GROUP_ID = -1;

export function fetchCameraGroups(): Promise<CameraGroup[]> {
  const { CameraGroupsService: service } = dataServiceRepository;
  return service
    .getList({})
    .then(({ results }) => (results ?? []).filter(({ id }) => id !== FORBIDDEN_CAMERA_GROUP_ID))
    .catch(() => []);
}
