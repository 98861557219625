
import { NIcon } from '@/uikit';
import { NDropdown, NDropdownItem } from '@/uikit';
import { authModule } from '@/store/auth';
import { Routes } from '@/router/enums';
import { Options, Vue } from 'vue-class-component';
import { dataModule } from '@/store/data';
import NButton from '@/uikit/buttons/NButton.vue';
import { RouterModule } from '@/store/router';
import { PageName } from '@/store/application/page.definitions';

const UserActions = {
  Settings: 'settings',
  Logout: 'logout'
};

export interface IListItem {
  label: string;
  name: string;
  icon?: string;
}

@Options({
  name: 'UserMenu',
  components: { NButton, NIcon, NDropdown, NDropdownItem }
})
export default class UserMenu extends Vue {
  get actions(): IListItem[] {
    const items = [
      { name: UserActions.Settings, label: this.$t('common.preferences', 'f'), icon: 'preferences' },
      { name: UserActions.Logout, label: this.$t('common.logout', 'f'), icon: 'logout' }
    ];
    return items;
  }

  get currentUser() {
    return dataModule.currentUserModule.item?.real_name;
  }

  get firstNameCharacter() {
    return this.currentUser?.charAt(0).toUpperCase();
  }

  async actionHandler(name: string) {
    switch (name) {
      case UserActions.Settings:
        await this.$router.push(RouterModule.getRouteLocation({ name: PageName.settings }));
        break;
      case UserActions.Logout:
        await authModule.logout();
        await this.$router.push(Routes.Login.path);
        break;
    }
  }
}
