
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { MultisidebarGroupNames } from '@/store/multisidebar/types';
import { convertSomeBoxToBox } from '@/uikit/bbox/helpers';
import { BodyEvent, CarEvent, FaceEvent } from '@/api';
import { IItemAclResult } from '@/store/acl/types';

type ObjectEvent = FaceEvent | BodyEvent | CarEvent;

@Options({
  name: 'MultisidebarContentDebug'
})
export default class MultisidebarContentDebug extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<unknown>>;

  @Prop({ type: Object, required: true })
  readonly itemAcl!: IItemAclResult;

  get isEvent() {
    return this.sidebarItem.type.startsWith(`${MultisidebarGroupNames.Events}_`);
  }

  get item() {
    return this.sidebarItem.model.item;
  }

  get extraInfo() {
    if (this.isEvent && this.item) {
      const bbox = convertSomeBoxToBox(this.item as ObjectEvent);
      return {
        width: bbox.w,
        height: bbox.h,
        size: Math.round(Math.sqrt(bbox.w * bbox.h)),
        quality: (this.item as ObjectEvent).quality
      };
    }
    return null;
  }
}
