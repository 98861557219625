import { reactive } from 'vue';
import { EmptyKycFilter, KycChartType, KycFilter } from '@/api/models/KycFilter';
import { Filter } from '@/definitions/view-models/filter';
import { KycItem } from './types';
import { cloneDeep } from 'lodash';
import { getTimezoneString, KycChartTypes } from '@/store/kyc/helpers';
import { request as __request } from '@/api/core/request';
import { shortString } from '@/common/filters';

export const EmptyKycItem: KycItem = { type: KycChartTypes.Visits, data: {}, active: true, loading: false, i18n_label: '' };

export class KycModule {
  filter: Filter<KycFilter> = new Filter<KycFilter>(EmptyKycFilter);

  items: KycItem[] = [
    { ...cloneDeep(EmptyKycItem), type: KycChartTypes.Visitors, i18n_label: `kyc.${KycChartTypes.Visitors}` },
    { ...cloneDeep(EmptyKycItem), type: KycChartTypes.Gender, i18n_label: `kyc.${KycChartTypes.Gender}`, opacity: 0.6 },
    { ...cloneDeep(EmptyKycItem), type: KycChartTypes.Age, i18n_label: 'kyc.average_age' },
    { ...cloneDeep(EmptyKycItem), type: KycChartTypes.Visits, i18n_label: `kyc.${KycChartTypes.Visits}`, active: false },
    { ...cloneDeep(EmptyKycItem), type: KycChartTypes.Cameras, i18n_label: 'kyc.cameras' }
  ];

  protected getLoaders() {
    return [
      { type: KycChartTypes.Visitors, handler: this.loadAreaData.bind(this, KycChartTypes.Visitors) },
      { type: KycChartTypes.Age, handler: this.loadAreaData.bind(this, KycChartTypes.Age) },
      { type: KycChartTypes.Cameras, handler: this.loadCameras.bind(this) },
      { type: KycChartTypes.Gender, handler: this.loadDoubleAreaData.bind(this, KycChartTypes.Gender) },
      { type: KycChartTypes.Visits, handler: this.loadDoubleAreaData.bind(this, KycChartTypes.Visits) }
    ];
  }

  public load(type?: KycChartType) {
    this.getLoaders()
      .filter((v) => (type ? v.type === type : true))
      .map((v) => v.handler());
  }

  protected async loadAreaData(type: KycChartType) {
    const item = this.getItemByType(type);
    if (!item.active) return false;
    const data = await this.loadItemRawData(item);

    const xAxisData = data.axis1.values,
      yAxisNames = data.axis2.values || [type],
      yAxisData = [data.counts],
      { total, average, overall } = data;
    item.data = { xAxisData, yAxisData, yAxisNames, total, average, overall };
    return true;
  }

  protected async loadDoubleAreaData(type: KycChartType) {
    const item = this.getItemByType(type);
    if (!item.active) return false;
    const data = await this.loadItemRawData(item);
    const xAxisData = data.axis1.values,
      yAxisData = typeof data.counts[0][0] === 'object' ? data.counts.map((count: any) => count.map(({ percent }: any) => percent)) : data.counts,
      counterData = typeof data.counts[0][0] === 'object' ? data.counts.map((item: any) => item.map(({ count }: any) => count)) : null,
      yAxisNames = data.axis2.values,
      overall = typeof data.overall[0] === 'object' ? data.overall.map(({ percent }: any) => percent) : data.overall,
      { total, average } = data;

    item.data = { xAxisData, yAxisData, counterData, yAxisNames, total, average, overall };
    return true;
  }

  protected async loadCameras() {
    const item = this.getItemByType(KycChartTypes.Cameras);
    if (!item.active) return false;
    const data = await this.loadItemRawData(item);
    const xAxisData: any[] = [[], []],
      yAxisData: any[] = [],
      id: any[] = [];
    data.forEach((cam: any) => {
      yAxisData.unshift(shortString(cam.camera__name));
      xAxisData[0].unshift(cam.count_unique);
      xAxisData[1].unshift(cam.count_total);
      id.unshift(cam.camera_id);
    });
    item.data = { xAxisData, yAxisData, id };
    return true;
  }

  protected async loadItemRawData(item: KycItem) {
    const query = this.getItemFilter(item);
    let itemData;
    try {
      item.loading = true;
      const data = await __request<Record<KycChartType, Record<string, any>>>({
        method: 'GET',
        path: '/kyc/',
        query
      });
      if (item.type && data[item.type]) {
        itemData = data[item.type];
      } else {
        throw new Error('Can\'t get item data from response!');
      }
    } catch (e) {
      if (e.isAxiosError) {
        console.warn(e);
      } else {
        throw e;
      }
    } finally {
      item.loading = false;
    }

    return itemData as Record<string, any>;
  }

  protected getItemFilter(item: KycItem) {
    return { ...this.filter.current, chart_types: item.type, timezone: getTimezoneString() };
  }

  protected getItemByType(type: KycChartType) {
    const item = this.items.find((v) => v.type === type);
    if (item) return item;
    throw new Error('Unknown chart type: ' + type);
  }

  public static create() {
    return reactive(new this());
  }
}
