import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FiltersBig = _resolveComponent("FiltersBig")!

  return (_openBlock(), _createBlock(_component_FiltersBig, {
    onClose: _ctx.dispatchCloseEvent,
    onResetFilters: _ctx.dispatchResetEvent,
    "has-report": "",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    state: _ctx.state,
    "form-layout": _ctx.layout,
    "report-filter": _ctx.forceFilter,
    enabledFields: _ctx.smallFilterFields,
    "onUpdate:enabledFields": _cache[1] || (_cache[1] = (v) => _ctx.setSmallFilterFields(v))
  }, null, 8, ["onClose", "onResetFilters", "modelValue", "state", "form-layout", "report-filter", "enabledFields"]))
}