export default {
  sorry: 'Извините',
  page_notfound: 'Такой страницы не существует',
  json_invalid: 'Невалидный JSON',
  date: {
    greater_than_current: 'Значение должно быть больше текущей даты',
    date_gte_lte: 'Диапазон дат некорректен'
  },
  image: {
    not_found: 'Изображение не найдено',
    load: 'Не удалось загрузить изображение'
  },
  camera: {
    not_found: 'Камера не найдена'
  },
  camera_group: {
    not_found: 'Группа камер не найдена'
  },
  card: {
    not_found: 'Изображение из карточки не найдено'
  },
  webcam: {
    not_available: 'Нет доступных веб-камер',
    not_available_desc: 'Проверьте, подключена ли веб-камера к компьютеру',
    not_allowed: 'Доступ к веб-камере запрещен',
    not_allowed_desc: 'Для использования аутентификации по лицу разрешите доступ к веб-камере в браузере',
    not_readable: 'Видео с веб-камеры не читается',
    not_readable_desc: 'Не удалось прочитать видеопоток с веб-камеры. Возможно, она используется другой программой'
  },
  server: {
    no_response: 'Сервер не отвечает',
    no_response_desc: 'Возможно, возникли проблемы с сервером или сетью. Обратитесь к администратору',
    notfound: 'Ресурс не найден',
    notfound_desc: 'Запрашиваемый ресурс не найден на сервере',
    forbidden: 'Недостаточно прав',
    forbidden_desc: 'Недостаточно прав для выполнения данного действия',
    bad_request: 'Неверный запрос',
    bad_request_desc: 'Сервер не смог обработать полученный запрос',
    internal: 'Ошибка сервера',
    internal_desc: 'Внутренняя ошибка сервера. Обратитесь к администратору',
    unavailable: 'Сервис недоступен',
    unavailable_desc: 'Сервис временно недоступен. Обратитесь к администратору',
    timeout: 'Время ожидания ответа истекло',
    timeout_desc: 'Превышено время ожидания ответа от сервера. Обратитесь к администратору',
    check_onvif: 'Проверьте настройки камеры Onvif'
  },
  form: {
    validation: 'Ошибка валидации формы'
  },
  confirm: {
    password: 'Пароль и его подтверждение не совпадают'
  },
  field: {
    url: 'Неправильный URL',
    uint: 'Значение должно быть больше или равно 0',
    uint_max_min: 'Значение должно быть от {{min}} до {{max}}',
    ufloat: 'Значение должно быть целым числом или валидной десятичной дробью без конечных нулей от {{min}} до {{max}}',
    realtime_or_overall: 'Буферный режим или режим реального времени должен быть включен',
    cameras_are_not_selected: 'Камеры не выбраны',
    enter_a_valid_ip: 'Введите действительный IPv4- или IPv6-адрес'
  },
  required: {
    field: 'Поле обязательное',
    field_name: 'Поле обязательное. Минимум 2 символа',
    login: 'Имя пользователя обязательно',
    name: 'Имя обязательно',
    email: 'Email обязателен',
    password: 'Пароль обязателен',
    primary_group: 'Основная роль обязательна',
    all_params: 'Требуется заполнить все параметры'
  },
  number: {
    from_min: 'Значение должно быть целым числом от {{min}}',
    to_max: 'Значение должно быть целым числом до {{max}}',
    between: 'Значение должно быть целым числом от {{min}} до {{max}}',
    float_from_min: 'Значение должно быть от {{min}}',
    float_to_max: 'Значение должно быть до {{max}}',
    float_between: 'Значение должно быть от {{min}} до {{max}}',
    validator_error: 'Ошибка валидатора'
  },
  string: {
    from_min: 'Минимальное количество символов - {{min}}',
    to_max: 'Максимальное количество символов - {{max}}',
    between: 'Количество символов должно быть от {{min}} до {{max}}',
    validator_error: 'Ошибка валидатора',
    should_be_ascii: 'Допустимы только символы ASCII',
    shouldnt_contain_only_digits: 'Не может содержать только цифры'
  },
  age: {
    age_gte_lte: 'Диапазон возраста некорректен'
  },
  license: {
    file_too_large: 'Файл лицензии очень большой. Максимальный размер файла - 1MB',
    not_valid: 'Файл лицензии невалиден',
    not_found: 'Лицензия не найдена',
    expired: 'Срок действия лицензии истёк'
  },
  play_sound: 'Ошибка воспроизведения звука',
  crypto_pro: {
    plugin_install: 'Ошибка установки плагина браузера `ЭЦП КриптоПро`. Проверьте, установлен ли у вас плагин `ЭЦП КриптоПро`. Подробнее в документации',
    plugin_internal: 'Неизвестная ошибка плагина КриптоПро'
  },
  array: {
    empty: 'Должен быть выбран хотя бы один элемент'
  },
  unique: {
    default: 'Должно быть уникальным',
    login: 'Имя пользователя должно быть уникальным'
  }
};
