import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_component_NButton, {
    type: "link",
    icon: _ctx.icon,
    class: _normalizeClass(["camera-label-item", [`camera-item_${_ctx.type}`]])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.groupName ? _ctx.groupName + ' / ' : '') + " " + _toDisplayString(_ctx.name), 1)
    ]),
    _: 1
  }, 8, ["icon", "class"]))
}