
import { viewModelRepository } from '@/api/common';
import ClusterWizard from '@/components/cases/ClusterWizard.vue';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import Statistics from '@/components/common/Statistics.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import enrichParticipants, { EnrichedParticipant } from '@/components/participants/enrich-participants';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import { IModelAclResult } from '@/store/acl/types';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import Button from '@/stories/Button.vue';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'CaseClusterPage',
  components: {
    Button,
    ClusterItem,
    ClusterWizard,
    FilterSection,
    PageContentLayout,
    ParticipantItem,
    Statistics
  }
})
export default class CaseClusterPage extends Vue {
  @Prop({ type: Object, required: true })
  readonly item: any;

  @Prop({ required: false })
  participantsAcl?: IModelAclResult;
  private readonly clusterModule = viewModelRepository.getClustersFacesListViewModel();
  private readonly participantsModule = viewModelRepository.getCaseParticipantsListViewModel();
  private participantsItems: EnrichedParticipant[] = [];

  private wizardCluster: any = null;
  private wizardParticipant: EnrichedParticipant | null = null;

  private itemTypes = {
    Participant: 'participant',
    Cluster: 'cluster'
  };

  get state(): PageState {
    const tab = this.$route.query.tab as string;
    const s = pageModule.getPageStateByTab(PageName.clusters, tab + '_cluster');
    return reactive({
      ...s,
      objectType: 'faces',
      episodeType: ''
    });
  }

  get clusters() {
    return this.clusterModule.items;
  }

  get participants() {
    return this.clusterModule.items
      .filter(({ id }) => {
        return !!this.participantByClusterIDMap[id];
      })
      .map(({ id }) => this.participantByClusterIDMap[id]);
  }

  get participantByClusterIDMap() {
    return Object.fromEntries(this.participantsItems.map((v) => [v.face_cluster, v]));
  }

  @Watch('participantsModule.items')
  async participantsWatcher() {
    this.participantsItems = await enrichParticipants(this.participantsModule.items, this.participantsItems);
  }

  @Watch('clusterModule.filter.current', { deep: true })
  filterWatcher() {
    this.reloadData();
  }

  async created() {
    await this.reloadData();
  }

  async clusterActionHandler(id: string | number, action: string, item: any) {
    if (action === 'click') {
      this.wizardCluster = item;
    }
  }

  async participantActionHandler(id: string | number, action: string, item: any) {
    if (action === 'click') {
      this.wizardParticipant = item;
    }
  }

  async reloadData() {
    this.clusterModule.filter.force.case_in = [this.item.id];
    this.clusterModule.filter.force.ordering = '-id';
    this.clusterModule.filter.current.limit = '30';
    await this.clusterModule.get();

    this.participantsModule.filter.current.limit = '1000';
    this.participantsModule.filter.current.case_in = [this.item.id];
    await this.participantsModule.get();
  }

  async closeWizard() {
    this.wizardCluster = null;
    this.wizardParticipant = null;
    await this.reloadData();
  }
}
