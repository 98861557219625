
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'SidebarTab'
})
export default class SidebarTab extends Vue {
  @Prop({ type: String, default: '' })
  readonly id!: string;

  @Prop({ type: [String, Number], default: '' })
  readonly label!: string | number;

  @Prop({ type: String, default: '' })
  readonly imageUrl!: string;

  @Prop({ type: String, default: '' })
  readonly color!: string;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly changed!: boolean;

  get src() {
    return this.imageUrl;
  }

  get firstChars() {
    return String(this.label).substr(0, 1);
  }

  get classes() {
    let base = 'sidebar-tab';
    return {
      [base]: true,
      [`${base}_selected`]: this.selected
    };
  }
}
