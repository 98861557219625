
// todo: delete whole 'multitool' folder after migration on new multisidebar
import { Prop } from 'vue-property-decorator';
import { Vue, Options } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NDropdown from '@/uikit/NDropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/NDropdown/NDropdownItem.vue';
import { ActionClose, ActionCounter, ActionSelectAll } from './MultitoolActionNames';
import { IMultitoolAction } from './types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

const MaxNumberOfDisplayedActionsWithLabel = 2;
const MaxNumberOfDisplayedActionsWithoutLabel = 5;

@Options({
  name: 'NMultitool',
  components: { NDropdown, NDropdownItem, NButtonGroup, NButton }
})
export default class NMultitool extends Vue {
  @Prop({ type: Array, required: true })
  readonly selectedItems!: any[];

  @Prop({ type: Boolean, required: false })
  readonly allSelected = false;

  @Prop({ type: Array, required: true })
  readonly actions!: IMultitoolAction[];

  @Prop({ type: Boolean, default: true })
  readonly hasSidebar!: true;

  @Prop({ type: Boolean })
  readonly showSidebar!: boolean;

  get selectedBasicActions(): string[] {
    const item = this.basicActions.find(({ name }) => name === 'counter');
    const result = [];
    if (item) result.push('counter');
    if (this.allSelected) result.push(ActionSelectAll);
    return result;
  }

  get chevron(): string {
    return this.showSidebar ? 'chevron-right' : 'chevron-left';
  }

  get basicActions(): IMultitoolAction[] {
    return [
      { icon: 'done-all', handler: this.selectAllHandler, name: ActionSelectAll },
      { label: this.selectedItems.length.toString(10), name: ActionCounter },
      { label: '', icon: 'close', handler: this.closeHandler, name: ActionClose }
    ];
  }

  get hiddenActions(): IMultitoolAction[] {
    return this.actions.filter(({ hidden }) => hidden);
  }

  get displayedActions(): IMultitoolAction[] {
    return this.actions.filter(({ hidden }) => !hidden);
  }

  get displayedActionsWithLabel(): IMultitoolAction[] {
    return this.displayedActions.filter((action) => action.label && !action.hidden);
  }

  get displayedActionsWithoutLabel(): IMultitoolAction[] {
    return this.displayedActions.filter((action) => !action.label && !action.hidden);
  }

  get isButtonGroupDisplayed(): boolean {
    return (
      this.displayedActionsWithLabel.length <= MaxNumberOfDisplayedActionsWithLabel &&
      this.displayedActionsWithoutLabel.length <= MaxNumberOfDisplayedActionsWithoutLabel
    );
  }

  selectAllHandler() {
    this.$emit('action', ActionSelectAll);
  }

  closeHandler() {
    this.$emit('close');
  }

  toggleHandler() {
    this.$emit('action', ItemsActionNames.ToggleCurrent);
  }
}
