import { TabItemsMap } from '@/definitions/app/tab.items';
import { CardTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { languageModule } from '@/store/languages';
import { NDateInput, NInput, NSelect } from '@/uikit';
import { NButtonGroupItem } from '@/uikit/buttons/NButtonGroup.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { configAclModule } from '../config/acl';
import { aclModule } from '@/store/acl';
import {
  configCheckbox, configDateTime,
  configDateTimeRange,
  configDateTimeRangeSmall, configInput,
  configSelect,
  configSelectSmall, configSmallCheckbox, configSmallDateTime,
  configSmallInput,
  SimpleFilterOptions
} from '@/store/filters/types';

export type TabItem = {
  value: string;
  name: string;
  label: string;
};

export const MetaFieldTypes = {
  ObjectList: 'objectlist',
  List: 'list',
  ValueList: 'valuelist',
  Boolean: 'boolean',
  Datetime: 'datetime',
  Date: 'date'
};

export type MetaFieldItemType = typeof MetaFieldTypes[keyof typeof MetaFieldTypes];
export type MetaFieldDisplayType = 'list' | 'form';

export type IMetaFieldItem = {
  type?: MetaFieldItemType;
  name: string;
  tab?: string;
  items?: any;
  custom_id?: string;
  label: string;
  description?: string;
  itemsData?: any[];
  multiple?: boolean;
  display: MetaFieldDisplayType[];
  editable?: boolean;
};

export class CardAssetsModule {
  get dicts() {
    return configModule.config.dicts;
  }

  getCardTabsItems(cardType: string): NButtonGroupItem[] {
    let tabs = [TabItemsMap.Info];

    const viewPermissions = {
      case: aclModule.getAccess('ffsecurity.view_case'),
      faceluster: aclModule.getAccess('ffsecurity.view_facecluster'),
      faceobject: aclModule.getAccess('ffsecurity.view_faceobject'),
      bodycluster: aclModule.getAccess('ffsecurity.view_bodycluster'),
      bodyobject: aclModule.getAccess('ffsecurity.view_bodyobject'),
      carcluster: aclModule.getAccess('ffsecurity.view_carcluster'),
      carobject: aclModule.getAccess('ffsecurity.view_carobject')
    };

    switch (cardType) {
      case CardTypesMap.Humans:
        if (!configAclModule.isDisabledMenuItem('cases') && viewPermissions.case) {
          tabs.push(TabItemsMap.HumanParticipants);
          // tabs.push(TabItemsMap.CaseHumanCards); // i'll be back
        }
        if (dataAssetsModule.isObjectAvailable('face')) {
          viewPermissions.faceobject && tabs.push(TabItemsMap.FaceObjects);
          if (configModule.services.clusters && viewPermissions.faceluster) {
            tabs.push(TabItemsMap.FaceClusterEvents);
          }
        }
        if (dataAssetsModule.isObjectAvailable('body')) {
          viewPermissions.bodyobject && tabs.push(TabItemsMap.BodyObjects);
          if (configModule.services.clusters && viewPermissions.bodycluster) {
            tabs.push(TabItemsMap.BodyClusterEvents);
          }
        }
        // tabs.push(TabItemsMap.Location); //@todo wait VNS v2
        break;
      case CardTypesMap.Cars:
        if (dataAssetsModule.isObjectAvailable('car')) {
          viewPermissions.carobject && tabs.push(TabItemsMap.CarObjects);
          if (configModule.services.clusters && viewPermissions.carcluster) {
            tabs.push(TabItemsMap.CarClusterEvents);
          }
        }
        break;
    }

    // if (aclModule.getAccess(PermissionsMap.ViewRelation)) tabs.push(TabItemsMap.Connections); //@todo waiting for v1.4
    const result = tabs.map((v: string) => ({ value: v, name: v, label: languageModule.getTranslatedToken(`cards.${v}`, 'f') }));
    result.splice(1, 0, ...this.getMetaTabs(cardType));
    return result;
  }

  getMetaData(cardType: string): any {
    return cardType === CardTypesMap.Humans ? configModule.config.human_card : configModule.config.car_card;
  }

  getMetaFilterItems(cardType: string, small: boolean = false): IFormLayoutItem[] {
    const filterItems = this.getMetaData(cardType)?.filters || [];
    const metaItems = filterItems.map((v: IMetaFieldItem) => this.getMetaFormItem(v, small)).filter((item: IFormLayoutItem | undefined) => !!item);
    return metaItems;
  }

  getMetaListItems(cardType: string): any[] {
    const metaFields = this.getMetaData(cardType)?.items || [];
    return metaFields.filter((v: IMetaFieldItem) => v.display.indexOf('list') > -1);
  }

  getMetaFormItemsByTab(tabName: string, cardType: string): IFormLayoutItem[] {
    const items = this.getTabMetaFields(tabName, cardType).map((v) => this.getMetaFormItem(v, false));
    return items.filter((v) => !!v) as IFormLayoutItem[];
  }

  getMetaTabs(cardType: string): TabItem[] {
    const metaData = this.getMetaData(cardType) || [];
    const metaDataTabs = metaData?.tabs || [];
    return metaDataTabs
      .map((v: any) => ({ value: v.name, name: v.name, label: v.label }))
      .filter((v: TabItem) => v.value !== TabItemsMap.Info && v.value !== TabItemsMap.DepricatedGeneral);
  }

  protected getTabMetaFields(tabName: string, cardType: string): IMetaFieldItem[] {
    const metaFields = this.getMetaData(cardType)?.items || [];
    return metaFields.filter((v: any) => {
      const metaFieldTab = v.tab || TabItemsMap.Info;
      if (v.tab === TabItemsMap.DepricatedGeneral) v.tab = TabItemsMap.Info;
      return metaFieldTab === tabName;
    });
  }

  protected getMetaFormItemListItems(item: IMetaFieldItem): any[] {
    const storedItems = dataModule.dicts?.[item.items || item.name] || item.itemsData || [];
    return storedItems.map((v: any) => {
      const isStringValue = typeof v === 'string';
      return isStringValue ? { value: v, label: v } : { value: item.custom_id ? v[item.custom_id] : v.id, label: item.label || item.name };
    });
  }

  protected getMetaFormItemValueItems(item: IMetaFieldItem): any[] {
    const storedItems = dataModule.dicts?.[item.items || item.name] || item.itemsData || [];
    return storedItems.map((v: string) => ({ value: v, label: v }));
  }

  protected getMetaFormItem(item: IMetaFieldItem, small: boolean = false): IFormLayoutItem | undefined {
    const options: SimpleFilterOptions = {
      path: item.name,
      classes: small ? '' : 'n-form-w-6 control-m n-form-pad-10',
      label: item.label,
      disabled: item.editable === false,
      multiple: item.multiple
    };

    let result: IFormLayoutItem | undefined;

    switch (item.type) {
      case MetaFieldTypes.Boolean:
        result = small ? configSmallCheckbox(options) : configCheckbox(options);
        break;

      case MetaFieldTypes.Date:
        result = small ? configSmallDateTime(options, false) : configDateTime(options, false);
        break;

      case MetaFieldTypes.Datetime:
        result = small ? configSmallDateTime(options, true) : configDateTime(options, true);
        break;

      case MetaFieldTypes.List:
        options.items = this.getMetaFormItemListItems(item);
        result = small ? configSelectSmall(options) : configSelect(options);
        break;

      case MetaFieldTypes.ValueList:
        options.items = this.getMetaFormItemValueItems(item);
        result = small ? configSelectSmall(options) : configSelect(options);
        break;

      case MetaFieldTypes.ObjectList:
        console.warn('MetaFieldTypes.ObjectList is deprecated. Item details: ', item);
        break;

      default:
        result = small ? configSmallInput(options) : configInput(options);
        break;
    }

    return result;
  }
}

export const cardAssetsModule = new CardAssetsModule();
