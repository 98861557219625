import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, { placement: "top-start" }, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NButton, {
        type: "secondary",
        onClick: toggle,
        icon: "more-vertical"
      }, null, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, ({ label, name }) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          key: name,
          onClick: () => _ctx.$emit('action', name)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}