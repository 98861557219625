import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonSimple = _resolveComponent("NButtonSimple")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-button-group", _ctx.classes])
  }, [
    (_ctx.canShowAnyButton)
      ? (_openBlock(), _createBlock(_component_NButtonSimple, {
          key: 0,
          type: "secondary",
          onClick: _ctx.clearModel,
          label: _ctx.$t('common.any', 'f')
        }, null, 8, ["onClick", "label"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_NButtonSimple, _mergeProps({
        key: 'id-' + item.value
      }, { ...this.$props, ...item.props }, {
        selected: _ctx.getSelected(item),
        type: _ctx.getButtonType(item.value || item.name),
        icon: item.icon,
        counter: item.counter,
        "suffix-icon": item.suffixIcon,
        color: item.color,
        label: item.label,
        onClick: ($event: any) => (_ctx.selectHandler(item))
      }), null, 16, ["selected", "type", "icon", "counter", "suffix-icon", "color", "label", "onClick"]))
    }), 128))
  ], 2))
}