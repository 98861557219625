
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NForm, { FormValidatorNames } from '@/uikit/forms/NForm.vue';
import { HumanCard } from '@/api';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { getStringLengthValidator } from '@/uikit/forms/validators';
import NButton from '@/uikit/buttons/NButton.vue';
import { monitoringModule } from './MonitoringModule';
import CardItem from '@/components/cards/deprecated/CardItem.vue';
import CardItemRow from '@/components/cards/deprecated/CardItemRow.vue';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';

@Options({
  name: 'CardMonitoringAddDialog',
  components: {
    CardItemRow,
    CardItem,
    NButton,
    NForm,
    NModalWindow
  },
  emits: ['close']
})
export default class CardMonitoringAddDialog extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: HumanCard[];

  enrichedItems: EnrichedCard[] = [];

  protected fields = {
    reason: ''
  };

  protected layout = [
    {
      path: 'reason',
      classes: 'label-m n-form-w-4 n-form-pad-10',
      i18n_label: 'remote_monitoring.monitoring_reason',
      component: NTextareaAutosize,
      props: { minRows: 3, i18n_placeholder: 'remote_monitoring.monitoring_reason_placeholder' },
      validators: [{ name: FormValidatorNames.Required }, getStringLengthValidator({ required: true, min: 3, max: 512 })]
    }
  ];

  async createMonitorings() {
    if (!this.$refs.form.validate()) {
      this.$refs.form.displayErrors();
      return;
    }

    for (let item of this.items) {
      await monitoringModule.create({ card: item.id, reason: this.fields.reason });
    }
    await monitoringModule.load();
    this.$emit('close');
  }

  async enrichItems() {
    this.enrichedItems = await enrichCards(this.items);
  }

  mounted() {
    this.enrichItems();
    this.$refs.form?.focus();
  }
}
