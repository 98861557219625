import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NMultitool = _resolveComponent("NMultitool")!

  return (_openBlock(), _createBlock(_component_NMultitool, {
    actions: _ctx.availableActions,
    "all-selected": _ctx.isAllSelected,
    "selected-items": _ctx.selectedItems,
    "has-sidebar": false,
    onClose: _ctx.handleClose
  }, null, 8, ["actions", "all-selected", "selected-items", "onClose"]))
}