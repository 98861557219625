
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import NTable from '@/uikit/table/NTable.vue';
import { viewModelRepository } from '@/api/common';
import { ITableCell, ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import columns from './schemes/counters-columns';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import SortSection from '@/components/common/SortSection.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import CounterRecords from '@/pages/counters/CounterRecords.vue';
import CounterForm from '@/pages/counters/CounterForm.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import Button from '@/stories/Button.vue';
import FilterSchemeBuilder from '@/components/common/filter/FilterSchemeBuilder';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { GlobalEvent } from '@/store/global-event/types';
import { merge } from 'lodash';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import NInput from '@/uikit/input/NInput.vue';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import { PageType } from '@/store/application/page.definitions';
import { configModule } from '@/store/config';
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import { aclModule } from '@/store/acl';

@Options({
  name: 'CountersPage',
  components: {
    NInfiniteScroll,
    NInput,
    Button,
    SidebarTab,
    SidebarHeader,
    CounterForm,
    CounterRecords,
    NTabs,
    NButton,
    SortSection,
    FilterSection,
    NTable,
    PageContentLayout
  }
})
export default class CountersPage extends Vue {
  readonly module = viewModelRepository.getCountersListViewModel();
  readonly columns = columns;
  private filterBuilderInstance: FilterSchemeBuilder | null = null;

  state = {
    pageType: PageType.counters
  };

  autoUpdateTimer = new SimpleTimer();

  get filterBuilder() {
    if (!this.filterBuilderInstance) {
      this.filterBuilderInstance = this.createFilterBuilder();
    }
    return this.filterBuilderInstance;
  }

  createFilterBuilder() {
    const filterBuilder = new FilterSchemeBuilder();
    filterBuilder.addNameContains();
    filterBuilder.addIdIn({
      i18n_label: 'counters.counter_id',
      i18n_placeholder: 'counters.enter_counter_id'
    });
    filterBuilder.addCameras();
    filterBuilder.addCameraGroups();
    filterBuilder.addDistanceType();
    return filterBuilder;
  }
  get modelAcl() {
    this.module.aclModelName = 'counter';
    return aclModule.getModelAcl(this.module);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.Counters).map((v) => v.model.item.id);
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === MultisidebarItemTypes.Counters ? globalEventModule.current : null;
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler() {
    this.module.get();
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  mounted() {
    this.module.get();
    this.sidebarModule.contentOverlap = true;

    const updateInterval = configModule.config.counters?.update_interval || 30;
    this.autoUpdateTimer.setInterval(() => {
      this.module.get();
    }, updateInterval * 1000);
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.autoUpdateTimer.clear();
    this.sidebarModule.contentOverlap = false;
  }

  addNewCounter() {
    this.sidebarModule.addNewItemByType(MultisidebarItemTypes.Counters);
  }

  handleSelectChanges(changes: number[]) {
    changes
      ?.map((id) => (this.module.items as any[]).find((v: any) => v.id === id))
      .forEach((item) =>
        actionHandler.run(ItemsActionNames.ToggleSelectItem, {
          type: MultisidebarItemTypes.Counters,
          rawItem: item
        })
      );
  }

  handleAction(payload: ITableCellChangePayload) {
    const actionPayload = { type: MultisidebarItemTypes.Counters, rawItem: payload.row };

    switch (payload.path) {
      case 'id':
      case 'name':
        actionHandler.run(ItemsActionNames.ShowItem, actionPayload);
        break;
      case 'active':
        payload.type === 'change' && actionHandler.run(ItemsActionNames.UpdateActiveField, actionPayload);
        break;
    }
  }
}
