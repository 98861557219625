import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.classes),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    style: _normalizeStyle(_ctx.style)
  }, _toDisplayString(_ctx.label), 7))
}