
import { Options, Vue } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import NWorkspaceTabs from '@/uikit/workspace-tabs/NWorkspaceTabs.vue';
import NLaunchpad from '@/uikit/launchpad/NLaunchpad.vue';
import ClickOutside from '@/directives/click-outside';
import { workspaceModule } from '@/store/application/workspace';
import { IWorkspaceTab } from '@/uikit/workspace-tabs/definitions';
import { authModule } from '@/store/auth';
import NButton from '@/uikit/buttons/NButton.vue';
import UserMenu from '@/components/common/UserMenu.vue';
import { getItems } from '@/store/menu';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import LicenseAgreement from '@/components/common/LicenseAgreement.vue';
import { IMenuItem } from '@/definitions/config/menu';
import { PageName, PageState } from '@/store/application/page.definitions';
import { RouterModule } from '@/store/router';
import NIcon from '@/uikit/icons/NIcon.vue';
import { applicationModule } from '@/store/application';
import ChangeLanguage from '@/components/change-language/ChangeLanguage.vue';
import { alertModule } from '@/store/application/alert.module';
import NotificationHeader from '@/components/notification-header/NotificationHeader.vue';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { languageModule } from '@/store/languages';
import { multisidebarModule } from '@/store/multisidebar';
import MultisidebarContent from '@/components/multisidebar/MultisidebarContent.vue';
import MultisidebarMenu from '@/components/multisidebar/MultisidebarMenu.vue';
import DataModal from '@/components/common/DataModal.vue';
import { configModule } from '@/store/config';

const DEFAULT_DOCUMENT_TITLE = 'FindFace 1.3';

@Options({
  name: 'WorkspaceLayout',
  components: {
    DataModal,
    MultisidebarMenu,
    MultisidebarContent,
    NotificationHeader,
    ChangeLanguage,
    NIcon,
    UserMenu,
    NButton,
    NLaunchpad,
    NWorkspaceTabs,
    LicenseAgreement
  },
  directives: {
    ClickOutside
  }
})
export default class WorkspaceLayout extends Vue {
  tabKey = 'launcher';

  launchpad = false;
  launchpadLeft = 0;
  intViewportWidth = 0;
  showLicense = false;

  toggleLaunchpad() {
    const nv = !this.launchpad;
    setTimeout(() => {
      this.launchpad = nv;
    }, 100);
  }

  closeLauncher() {
    this.launchpad = false;
  }

  updateLaunchpadLeft(value: number) {
    this.intViewportWidth = window.innerWidth;
    this.launchpadLeft = value;
  }

  get contentDecreased() {
    return this.sidebarModule.active && this.sidebarModule.currentItem && !this.sidebarModule.contentOverlap;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get applicationModule() {
    return applicationModule;
  }

  get isLauncherPage(): boolean {
    return this.tabKey !== 'launcher';
  }

  get alertModule() {
    return alertModule;
  }

  async logout() {
    try {
      await authModule.logout();
    } catch (e) {
      console.warn('Error on logout', e);
    }

    try {
      workspaceModule.reset();
      this.$router.push({ path: '/login' });
    } catch (e) {
      console.warn('Error on logout', e);
    }
  }

  //@todo: refactor
  get launchpadPos() {
    const delta = 217;
    let maxLeft = this.intViewportWidth - 230;
    let arrowLeft = delta - (maxLeft - this.launchpadLeft);
    if (maxLeft > this.launchpadLeft) {
      maxLeft = this.launchpadLeft;
      arrowLeft = delta;
    }
    if (this.launchpadLeft < 200) {
      maxLeft = 250;
      arrowLeft = 100;
    }
    return {
      containerLeft: maxLeft,
      arrowLeft: arrowLeft
    };
  }

  get formattedItems() {
    return this.items.map((item) => {
      return { ...item, title: this.$t(item.i18n), description: this.$t(item.i18n_description) };
    });
  }

  get items(): IMenuItem[] {
    return getItems();
  }

  @Ref('tab')
  tabsComponent!: NWorkspaceTabs;

  openNewTab(path: string) {
    workspaceModule.addItem(path);
    this.launchpad = false;
  }

  removeTab(i: number) {
    workspaceModule.removeItem(i);
  }

  changeTheme() {
    applicationModule.toggleTheme();
  }

  @Watch('workspaceModule.currentItem', { deep: true })
  workspaceCurrentWatcher(value?: PageState) {
    if (value) {
      let to = { path: '/' + value.pageName, query: RouterModule.pageStateToQuery(value) };
      this.$router.push(to);
    }
    if (workspaceModule.current === 'launcher') {
      let to = { path: '/', query: RouterModule.pageStateToQuery({}) };
      this.$router.push(to);
    }
  }

  @Watch('$route.query')
  changeRouteQuery() {
    workspaceModule.changeRouteHandler(this.$route);
  }

  get module() {
    return settingsItemModule;
  }

  @Watch('module.item.license_accepted', { immediate: true })
  checkLicenseAccept(v: boolean) {
    this.showLicense = Boolean(settingsItemModule.loaded && !v);
  }

  async proceedHandler() {
    if (!this.module.item) return;
    try {
      await settingsItemModule.saveFields({ license_accepted: true });
    } finally {
      this.module.item.license_accepted = true;
    }
  }

  handleContextClick(e: Event, tab: IWorkspaceTab) {}

  handleLogoClick() {
    workspaceModule.current = 'launcher';
  }

  async acknowledgeAll() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeAllCreate();
  }

  navigateToEventsPage() {
    const eventPage = applicationModule.isDefaultProduct ? PageName.events : PageName.remote_monitoring_events;
    this.$router.push(RouterModule.getRouteLocation({ name: eventPage }));
  }

  exportPot() {
    languageModule.convertJsToPot();
  }

  @Watch('workspaceModule.current', { immediate: true })
  handleWorkspaceTabChange(current: string): void {
    document.title = this.findWorkspaceTabById(current)?.label ?? configModule.defaultTitle;
  }

  findWorkspaceTabById(tab: string) {
    return this.workspaceModule.getTabItemByTab(tab);
  }
}
