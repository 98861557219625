
import NIcon from '@/uikit/icons/NIcon.vue';
import { Options, Vue } from 'vue-property-decorator';
import type { NAttachmentFileBlob } from './types';
import { fromDrop } from './utils';

@Options({
  components: { NIcon },
  emits: ['attach', 'drop']
})
export default class NAttachmentsDropArea extends Vue {
  enter = false;

  dispatchAttachEvent(): void {
    this.$emit('attach');
  }

  dispatchDropEvent(files: NAttachmentFileBlob[]): void {
    this.$emit('drop', files);
  }

  dragenter(): void {
    this.enter = !this.enter;
  }

  dragleave(): void {
    this.enter = !this.enter;
  }

  drop(event: DragEvent): void {
    const attachments = fromDrop(event);
    attachments.length && this.dispatchDropEvent(attachments);
    this.enter = !this.enter;
  }
}
