
import { datesAreOnSameDay } from '@/common/filters';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NDateInput from '@/uikit/datetime/NDateInput.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';

@Options({
  name: 'VideoPlayerExport',
  components: { NButtonGroup, NDateInput }
})
export default class VideoPlayerBottomBar extends Vue {
  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  @Prop({ type: String, required: true })
  readonly eventType!: string;

  @Prop({ type: VideoPlayerState, required: true })
  readonly state!: VideoPlayerState;

  @Prop({ type: Number, required: true })
  readonly currentTime!: number;

  get dateInputModel() {
    return new Date(this.currentTime * 1000);
  }

  set dateInputModel(value: Date) {
    value.setHours(12);
    value.setMinutes(0);
    value.setSeconds(0);
    this.$emit('positionChange', value.getTime() / 1000);
  }

  get eventTypes() {
    return ['episodes', 'events'].map((v: string) => ({ name: v, label: this.$t(`common.${v}`, 'f') }));
  }

  get eventTypeModel() {
    return this.eventType;
  }

  set eventTypeModel(value) {
    this.$emit('eventTypeChange', value);
  }

  isDateDisabled(timestamp: number) {
    const date = new Date(timestamp);
    if (!this.state.timelineChunks) {
      return false;
    }
    return !this.state.timelineChunks.items.find((item) => {
      const from = new Date(item.timeFrom * 1000);
      const to = new Date(item.timeTo * 1000);
      return datesAreOnSameDay(from, date) || datesAreOnSameDay(to, date) || (date > from && date < to);
    });
  }

  async mounted() {
    await this.state.fillCameraInfoById(this.cameraId);
  }
}
