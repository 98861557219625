
import { defineComponent, PropType } from 'vue';
import { IMenuItem } from '@/definitions/config/menu';

export default defineComponent({
  name: 'NSidebarMenu',
  props: {
    menuItems: {
      type: Array as PropType<IMenuItem[]>,
      required: true
    }
  }
});
