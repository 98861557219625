
import { NInput } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import NForm, { IFormContext, IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { aclModule } from '@/store/acl';
import { PageState } from '@/store/application/page.definitions';
import { isEmpty, set } from 'lodash';
import { configModule } from '@/store/config';
import { carModels } from '@/store/filters/car-models';

function clearHandler(this: IFormContext) {
  const { layout, model }: { layout: any; model: any } = this;
  const item: any = this.item;

  if (item) {
    const indexToDelete = layout.findIndex((layoutItem: any) => layoutItem.path === item.path);
    const emptyValue = Array.isArray(model[item.path]) ? [] : '';
    set(model, item.path, emptyValue);
    layout.splice(indexToDelete, 1);
  }
}

@Options({
  name: 'FiltersSmall',
  components: {
    NButton,
    NDoubleButton,
    NForm,
    NInput
  }
})
export default class FiltersSmall extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly collapsed!: boolean;

  @Prop({ type: Array as PropType<IFormLayoutItem[]>, required: true })
  readonly formLayout!: IFormLayoutItem[];

  @Prop({ type: Object, required: true })
  readonly modelValue: any;

  @Prop({ type: [Object, Array], default: () => ({}) })
  readonly state?: PageState;

  @Prop({ type: String, default: '' })
  readonly type!: string;

  @Prop({ type: Boolean, default: false })
  readonly supportsSearch!: boolean;

  @Prop({ type: String, default: 'name_contains' })
  readonly searchModelProp: string = 'name_contains';

  @Prop({ type: Boolean, default: false })
  readonly hasFilterManager!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasChanges!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasReport!: boolean;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: [Object, Array] })
  readonly reportFilter: any;

  @Prop({ type: [Array] })
  readonly enabledFields?: string[];

  get model() {
    return this.modelValue;
  }

  set model(data: any) {
    this.$emit('update:modelValue', data);
  }

  get searchValue() {
    const result = this.model[this.searchModelProp];
    return Array.isArray(result) ? result.join(',') : result;
  }

  set searchValue(value: string) {
    const shouldConvertValueToArray = this.searchModelProp.indexOf('_in') > -1;
    let result: string[] | string = '';
    if (shouldConvertValueToArray) {
      result = value ? (value || '').split(',').map((i) => i.trim()) : [];
    } else {
      result = (value || '').trim();
    }
    set(this.model, this.searchModelProp, result);
  }

  get canCreateReport() {
    const canAddReport = aclModule.getAccess('ffsecurity.add_report');
    return this.hasReport && canAddReport;
  }

  get layout(): IFormLayoutItem[] {
    let result = this.addClearHandlerToLayout(this.formLayout);
    result = this.getAvailableFields(result);
    return result;
  }

  mounted() {}

  addClearHandlerToLayout(formLayout: IFormLayoutItem[]) {
    return formLayout.map((item: IFormLayoutItem) => {
      item.on = { close: clearHandler };
      return item;
    });
  }

  isEmptyValue(value: any): boolean {
    if (value instanceof Object) {
      return isEmpty(value);
    } else {
      return value === false || value === 0 ? false : !value;
    }
  }

  getAvailableFields(layout: IFormLayoutItem[]) {
    const enabledItems: string[] = this.enabledFields || [];
    return layout.filter((item: IFormLayoutItem) => {
      const { path, name } = item;
      const isEmptyByMethod: boolean = item.checkForEmpty ? item.checkForEmpty(this.model) : true;
      const isEmptyByPath = path ? this.isEmptyValue(this.model[path]) : true;
      const enabled = enabledItems.includes(path || name || '');
      // console.log('Check available', item, enabled, 'checkEmpty', isEmptyByMethod);
      return enabled || !isEmptyByMethod || !isEmptyByPath;
    });
  }

  showReportForm() {
    this.state && this.$reportsForm.show(this.state, this.reportFilter || this.modelValue);
  }

}
