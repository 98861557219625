
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { VideoArchive } from '@/api';

@Options({
  name: 'DeletingVideoConfirm',
  components: { NModalWindow, NButton }
})
export default class DeletingVideoConfirm extends Vue {
  @Prop({ type: Array, default: [] })
  targetItems!: VideoArchive[];

  get title() {
    return this.$t('ds.delete', 'f') + ' ' + this.videoTitles + '?';
  }

  get videoTitles() {
    return this.targetItems
      .map((item) => item?.name)
      .toString()
      .replace(',', ', ');
  }

  get description() {
    if (this.hasVideoFromURL) return this.$t('ds.deleting_videos_confirm_desc_3', 'f');
    return this.allVideosAreDeleted ? this.$t('ds.deleting_videos_confirm_desc_2', 'f') : this.$t('ds.deleting_videos_confirm_desc_1', 'f');
  }

  get hasVideoFromURL() {
    return this.targetItems.some((item) => item.url);
  }

  get allVideosAreDeleted() {
    return this.targetItems.every((item) => !item.file_size);
  }

  deleteAllHandler() {
    this.$emit('delete-all');
  }
  deleteVideoFilesOnlyHandler() {
    this.$emit('delete-video-files-only');
  }
  cancelHandler() {
    this.$emit('cancel');
  }
}
