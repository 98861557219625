import { SearchFrom } from '@/store/application/page.definitions';

export type SortOption = boolean | { desc?: boolean; asc?: boolean };
export type SortOptions = {
  created_date?: SortOption;
  modified_date?: SortOption;
  name?: SortOption;
  id?: SortOption;
  idAsCreatedDate?: SortOption;
  confidence?: SortOption;
  external_timestamp?: SortOption;
  looks_like_confidence?: SortOption;
};
export type ObjectType = 'face' | 'body' | 'car';
export type ObjectsType = typeof ObjectsTypesMap[keyof typeof ObjectsTypesMap];

export const EventTypesMap = {
  Episodes: 'episodes',
  Events: 'events'
} as const;
export const EventTypes = Object.values(EventTypesMap);
export const ObjectTypes = ['faces', 'bodies', 'cars'];
export const EpisodeTypes = ['humans', 'cars'];
export const CardTypes = ['humans', 'cars'];
export const DisplayTypes = [
  { value: 'short', icon: 'card-view' },
  { value: 'full', icon: 'row-view' }
];
export const SearchPageTypes = ['cards', 'cases', 'events', 'clusters'];

export const SearchFromMap: Record<string, SearchFrom> = {
  Events: 'events',
  Cards: 'cards',
  Clusters: 'clusters',
  // Cases: 'cases',
  File: 'file'
};

export const ObjectsTypesMap = {
  Faces: 'faces',
  Bodies: 'bodies',
  Cars: 'cars'
} as const;

export const EpisodeTypesMap = {
  Humans: 'humans',
  Cars: 'cars'
};

export const CardTypesMap = {
  Humans: 'humans',
  Cars: 'cars'
};

export const DisplayTypesMap = {
  Short: 'short',
  Full: 'full'
};

export const CardTypeMap = {
  Human: 'human',
  Car: 'car'
};

export const ObjectsSingleToMulti: Record<string, string> = {
  face: 'faces',
  body: 'bodies',
  car: 'cars'
};

export const ObjectsMultiToSingle: Record<string, string> = {
  faces: 'face',
  bodies: 'body',
  cars: 'car'
};

export const ObjectsSingleToEventType: Record<string, string> = {
  face: 'events_faces',
  body: 'events_bodies',
  car: 'events_cars'
};

export const EpisodesMultiToSingle: Record<string, string> = {
  humans: 'human',
  cars: 'car'
};

export const EpisodesSingleToMulti: Record<string, string> = {
  human: 'humans',
  car: 'cars'
};

export const CardsMultiToSingle: Record<string, string> = {
  humans: 'human',
  cars: 'car'
};

export const CardsSingleToMulti: Record<string, string> = {
  human: 'humans',
  car: 'cars'
};
