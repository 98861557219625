import { User } from '@/api';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NButton } from '@/uikit';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';

export const auditLogsTableSchema: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.user',
        name: 'user'
      }
    },
    content: {
      path: 'user_login',
      component: NButton,
      props: (item: any) => ({ label: item.user_login, type: 'link' }),
      on: {
        click(this: any) {
          navigateToUser(this.item.user_id);
        }
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.request_ip',
        name: 'request_ip'
      }
    },
    content: {
      path: 'request_ip',
      component: SimpleText,
      props: {
        baseClass: 'label-xs'
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.device_uid',
        name: 'device_uid'
      }
    },
    content: {
      path: 'device_uid',
      component: SimpleText,
      props: {
        baseClass: 'label-xs'
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.action_type',
        name: 'action_type'
      }
    },
    content: {
      component: SimpleText,
      props: function (row: any) {
        return {
          baseClass: 'label-xs',
          i18n_modelValue: `audit_logs.actionTypes.${row.action_type}`
        };
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.object_type',
        name: 'object_type'
      }
    },
    content: {
      component: SimpleText,
      props: function (row: any) {
        return {
          baseClass: 'label-xs',
          i18n_modelValue: `audit_logs.objectTypes.${row.object_type}`
        };
      }
    }
  },
  {
    header: {
      classes: ['align-right', 'bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.object_id',
        name: 'object_id'
      }
    },
    content: {
      path: 'object_id',
      classes: ['align-right'],
      component: SimpleText,
      props: {
        baseClass: 'label-xs'
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'audit_logs.created_date',
        name: 'created_date'
      }
    },
    content: {
      path: 'created_date',
      component: NDateTimeLabel,
      props: {
        baseClass: 'label-xs'
      }
    }
  }
];

async function navigateToUser(id: User['id']): Promise<void> {
  await actionHandler.run(ItemsActionNames.ShowItem, { rawItem: id, type: MultisidebarItemTypes.Users });
}
