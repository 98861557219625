import { languageModule } from "@/store/languages";

type SimpleItem = {
  value: string;
  label: string;
};

type SimpleI18nItem = {
  value: string;
  i18n_label: string;
  color?: string;
};

function computeFieldI18nItems(items: string[], i18nLabel: string, colorized = false): SimpleI18nItem[] {
  if (!Array.isArray(items)) {
    console.warn('[filter.items] empty or not array of ', i18nLabel);
    return [];
  }

  return (items || []).map((item) => {
    const i18nObject: SimpleI18nItem = { value: item, i18n_label: `${i18nLabel}__${item}` };

    if (colorized) {
      i18nObject.color = item !== 'unknown' ? item : '';
    }
    return i18nObject;
  });
}

function computeFieldItems(items: string[]): SimpleItem[] {
  if (!Array.isArray(items)) {
    console.warn('[filter.items] empty or not array of ', items);
    return [];
  }

  return items.map((item) => ({ value: item, label: item }));
}

function mapObjectToSelectItem(items: any) {
  return items.map((watchList: any) => {
    return { label: watchList.name, value: watchList.id, color: watchList.color ? `#${watchList.color}` : null };
  });
}

function alphabeticalSort(items: Array<SimpleI18nItem>) {
  return items.sort(compareItemComputedLabelsAsc) ?? items;
}

function compareItemComputedLabelsAsc(itemA: SimpleI18nItem, itemB: SimpleI18nItem) {
  if (itemA.value === 'unknown') {
    return -1;
  }
  return languageModule.getTranslatedToken(itemA.i18n_label).localeCompare(languageModule.getTranslatedToken(itemB.i18n_label));
}

export { computeFieldI18nItems, computeFieldItems, mapObjectToSelectItem, alphabeticalSort };
