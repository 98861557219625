import { ITableColumn } from '@/uikit/table/NTable.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NTableNameCell from '@/uikit/table/NTableNameCell.vue';

const columns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_name',
        name: 'name',
        sortable: true
      }
    },
    content: {
      path: 'name',
      component: NTableNameCell,
      props: { accent: true }
    }
  },
  {
    width: '100px',
    header: {
      classes: ['align-right', 'bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_active',
        name: 'active',
        sortable: true
      }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch
    }
  }
];

export default columns;
