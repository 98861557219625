import { DataService } from '@/definitions/services/data.services';
import { DefaultAclPrefix, getDelay, getFilterString, PartialWithAnyId, PartialWithId, BaseItemState } from '@/definitions/common/base';
import { cloneDeep } from 'lodash';
import { differenceOf } from '@/definitions/common/utils';

export class ItemViewModel<T> implements BaseItemState<T> {
  name = 'base';
  routeName = 'base';
  aclPrefix = DefaultAclPrefix;
  aclModelName: string | undefined;
  version = 2;

  loading = false;
  loaded = false;
  loadError: any | null = null;

  relations: any = {};

  emptyItem?: T;
  originalItem?: T;
  item?: T;

  excludedChangeKeys: string[] = [];

  private _dataService: DataService<T, any> | undefined;

  constructor() {}

  init(emptyItem: T) {
    this.emptyItem = cloneDeep(emptyItem);
    this.setEmptyItemsState();
  }

  set dataService(v: DataService<T, any>) {
    this._dataService = v;
  }

  get dataService(): DataService<T, any> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  get aclViewPermissionName(): string {
    return `${this.aclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${this.aclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${this.aclPrefix}.change_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${this.aclPrefix}.delete_${this.aclModelName}`;
  }

  get changedData(): Record<string, any> {
    const diffObject = differenceOf(this.item, this.originalItem),
      hasExcludedKeys = this.excludedChangeKeys?.length;
    hasExcludedKeys && this.excludedChangeKeys.forEach((v: string) => delete diffObject[v]);
    return diffObject;
  }

  get changes(): string[] {
    return Object.keys(this.changedData);
  }

  get hasChanges(): boolean {
    return Object.keys(this.changes).length > 0;
  }

  get hasAcl(): boolean {
    return !!this.aclModelName;
  }

  get isNew(): boolean {
    const id = (this.item as any)?.id;
    return !id || Number(id) <= -1000;
  }

  setEmptyItemsState(): void {
    this.item = cloneDeep(this.emptyItem);
    this.originalItem = cloneDeep(this.emptyItem);
  }

  async get(id: string | number): Promise<boolean> {
    this.loading = true;
    this.loadError = null;

    try {
      const item = await this.dataService.get(id);
      this.setItemsState(item);
    } catch (e) {
      this.setItemsState(null, e);
    }

    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setItemsState(item: T | null, error?: any) {
    if (item) {
      this.originalItem = item;
      this.item = cloneDeep(item);
      this.loaded = true;
    } else {
      this.loaded = false;
    }
    this.loadError = error;
    this.loading = false;
  }

  async create(): Promise<T | null> {
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      const createdItem: PartialWithAnyId<T> = cloneDeep(this.item) as PartialWithAnyId<T>;
      delete createdItem.id;
      await getDelay(200);
      result = await this.dataService.create(createdItem);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async update(data: Partial<T>): Promise<T | null> {
    const id = (this.item as any)?.id;
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      if (!id) throw new Error('Can not update, because no item ID is defined');
      await getDelay(200);
      result = await this.dataService.update(id, data);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async save(): Promise<T | null> {
    if (this.isNew) {
      return this.create();
    } else {
      return this.update(this.item!);
    }
  }

  async reset() {
    this.item = cloneDeep(this.originalItem);
  }

  async delete(id: string | number): Promise<boolean> {
    return this.dataService.delete(id).catch((e) => {
      this.setItemsState(null, e);
      return false;
    });
  }

  getItemRoute(): any {
    const id = (this.item as any)?.id;

    return {
      name: `${this.routeName}Edit`,
      params: {
        id: id,
        item: this.item
      }
    };
  }

  dispose() {
    this._dataService = undefined;
    this.relations = null;
  }
}
