import CounterDistance from '@/components/counters/CounterDistance.vue';
import CounterInfo from '@/components/counters/CounterInfo.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';

const columns: ITableColumn[] = [
  {
    width: '100px',
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.id' }
    },
    content: {
      path: 'id',
      component: SimpleText,
      props: { accent: true, clickable: true }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.name' }
    },
    content: {
      path: 'name',
      component: SimpleText,
      props: { accent: true, clickable: true }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.info' }
    },
    content: {
      component: CounterInfo,
      props: (item: any) => ({ item })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.faces' }
    },
    content: {
      path: 'now_faces',
      component: SimpleText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.bodies' }
    },
    content: {
      path: 'now_bodies',
      component: SimpleText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.cars' }
    },
    content: {
      path: 'now_cars',
      component: SimpleText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.distance' }
    },
    content: {
      component: CounterDistance,
      props: (item: any) => ({ item })
    }
  },
  {
    width: '100px',
    header: {
      classes: ['align-right', 'bottom-border'],
      component: SimpleText,
      props: { i18n_modelValue: 'counters.active' }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch
    }
  }
];

export default columns;
