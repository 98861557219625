import { IMenuItem } from '@/definitions/config/menu';
import { configModule } from '@/store/config';

export const settingsItems: IMenuItem[] = [
  {
    meta: {
      permissions: ['ffsecurity.view_runtimesetting']
    },
    name: 'settings',
    i18n: 'common.general',
    path: '/settings'
  },
  {
    meta: {},
    name: 'interface',
    i18n: 'common.interface',
    path: '/interface'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_user']
    },
    name: 'roles',
    i18n: 'common.roles',
    path: '/groups',
    pathStartsFrom: '/groups/'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_user']
    },
    name: 'users',
    i18n: 'common.users',
    path: '/users',
    pathStartsFrom: '/users/filter/'
  },
  {
    meta: {},
    name: 'sessions',
    i18n: 'common.sessions',
    path: '/sessions',
    pathStartsFrom: '/sessions'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_deviceblacklistrecord']
    },
    name: 'blocklistRecords',
    i18n: 'common.blocklist_records',
    path: '/blocklist-records',
    pathStartsFrom: '/blocklist-records/filter/'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_cameragroup']
    },
    name: 'camera-groups',
    i18n: 'common.camera_groups',
    path: '/camera-groups',
    pathStartsFrom: '/camera-groups'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_watchlist']
    },
    name: 'watch-lists',
    i18n: 'common.watch_lists',
    path: '/watch-lists',
    pathStartsFrom: '/watch-lists/'
  },

  {
    meta: {
      permissions: ['ffsecurity.configure_ntls']
    },
    name: 'license',
    i18n: 'common.license',
    path: '/license'
  },
  {
    meta: {
      permissions: ['ffsecurity.view_externalvms']
    },
    name: 'external-vms',
    i18n: 'common.external_vms',
    path: '/external-vms',
    enabled: () => {
      return configModule.config.external_vms?.enabled;
    }
  },
  /*
      {
        name: 'extensions',
        i18n: 'common.extensions',
        meta: {
          permissions: ['ffsecurity.configure_genetec']
        }
      },
      {
          meta: {
            permissions: ['ffsecurity.configure_genetec'],
            plugins: {
              genetec: true
            }
          },
          name: 'genetec',
          i18n: 'common.genetec',
          path: '/genetec'
        },
        {
          name: 'tools',
          i18n: 'common.tools',
          meta: {
            permissions: ['ffsecurity.view_faceevent']
          }
        },
        {
          meta: {
            permissions: ['ffsecurity.view_faceevent']
          },
          name: 'verify',
          i18n: 'common.verify',
          path: '/verify'
        },
      */
  {
    name: 'developer',
    i18n: 'common.developer',
    meta: {
      permissions: ['ffsecurity.view_webhook', 'ffsecurity.view_user'],
      plugins: {
        partner: false
      }
    }
  },
  {
    meta: {
      services: {
        webhooks: true
      },
      permissions: ['ffsecurity.view_webhook']
    },
    name: 'hooks',
    i18n: 'webhooks.webhooks',
    path: '/hooks',
    pathStartsFrom: '/hooks'
  },
  {
    meta: {},
    name: 'documentation',
    i18n: 'common.documentation',
    external: true,
    serverPath: 'doc/'
  },
  {
    meta: {},
    name: 'api_doc',
    i18n: 'common.documentation_api',
    external: true,
    serverPath: 'api-docs/'
  }
];
