
import { CameraStatusColorCode } from '@/api/models/Status';
import { ISelectItem } from '@/uikit';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { defineComponent } from 'vue';
import { bool, object } from 'vue-types';
import { fetchCameraGroups } from './utils';
import { PageType } from '@/store/application/page.definitions';
import { smallFilterManagerModule } from '@/store/filter-manager/SmallFiltersModule';
import { commonFilterSchemaModule } from '@/store/filters/CommonFilterSchemaModule';

export default defineComponent({
  name: 'DataSourcesPageFiltersSmall',
  components: { FiltersSmall },
  props: {
    modelValue: object().isRequired,
    state: object().isRequired,
    forceFilter: object().isRequired,
    hasChanges: bool().def(false)
  },
  emits: ['expand', 'reset', 'update:modelValue'],
  methods: {
    setSmallFilterFields(v: string[]): void {
      smallFilterManagerModule.setFiltersBySource(this.state.pageType, v, true);
    },
    dispatchExpandEvent(): void {
      this.$emit('expand');
    },
    dispatchResetEvent(): void {
      this.$emit('reset');
    },
    createCameraStatusColorCodeFilterField(): IFormLayoutItem {
      const codes: CameraStatusColorCode[] = ['gray', 'green', 'red', 'yellow'];
      const items: ISelectItem[] = codes.map((code) => ({ color: code, i18n_label: `ds.${code}`, label: code, value: code }));
      return {
        component: NSelect,
        path: 'state_color',
        props: { clearIcon: true, items, label: this.$t('common.status', 'f') }
      };
    },
    createCameraGroupsFilterField(): IFormLayoutItem {
      return {
        component: NSelect,
        path: 'camera_groups',
        props: {
          clearIcon: true,
          label: this.$t('common.camera_groups', 'f'),
          loadItems: async () => (await fetchCameraGroups()).map<ISelectItem>(({ name, id }) => ({ label: name, value: id })),
          multiple: true
        }
      };
    }
  },
  computed: {
    smallFilterFields(): string[] {
      return smallFilterManagerModule.items[this.state.pageType];
    },
    model: {
      get(): unknown {
        return this.modelValue;
      },
      set(modelValue: unknown) {
        this.$emit('update:modelValue', modelValue);
      }
    },
    layout(): IFormLayoutItem[] {
      const options = { small: true };
      return this.isExternalDetectorsPage
        ? [commonFilterSchemaModule.getNameContains(options), commonFilterSchemaModule.getActiveStatus(options), this.createCameraGroupsFilterField()]
        : [commonFilterSchemaModule.getNameContains(options), commonFilterSchemaModule.getActiveStatus(options), this.createCameraStatusColorCodeFilterField(), this.createCameraGroupsFilterField()];
    },
    isExternalDetectorsPage() {
      return this.state.pageType === PageType.external_detectors;
    }
  }
});
