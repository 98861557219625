
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IMultitoolActionI18N } from '@/uikit';
import { DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import NDropdownItem from '@/uikit/NDropdown/NDropdownItem.vue';
import NDropdown from '@/uikit/NDropdown/NDropdown.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';

@Options({
  name: 'SidebarHeaderActions',
  components: { ActionsDropdown, SidebarHeaderActions, NDropdownItem, NDropdown, NButton },
  emits: ['action']
})
export default class SidebarHeaderActions extends Vue {
  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Array, default: () => [] })
  readonly actions!: IMultitoolActionI18N[];

  @Prop({ type: Boolean, default: true })
  readonly hasToggle!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasClose!: boolean;

  get hiddenActions() {
    return this.actions.filter((action) => action.hidden);
  }

  get visibleActions() {
    return this.actions.filter((action) => !action.hidden);
  }

  dispatchActionClose() {
    this.dispatchAction(ItemsActionNames.RemoveCurrentItem);
  }

  dispatchActionToggle() {
    this.dispatchAction(ItemsActionNames.ToggleCurrent);
  }

  dispatchAction(action: string) {
    this.$emit('action', action);
  }
}
