
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { CarClusterEvent, FaceClusterEvent } from '@/api';

@Options({
  name: 'ParticipantClusterEvents',
  components: { NThumbnail },
  emits: []
})
export default class ParticipantClusterEvents extends Vue {
  @Prop({ type: Array, default: [] })
  readonly clusterEvents!: Array<FaceClusterEvent | CarClusterEvent>;

  showImageGallery() {
    this.$photoViewer.show(this.clusterEvents);
  }
}
