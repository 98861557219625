import { EmptyWebhook } from '@/api/models/Webhook';
import { getDelay } from '@/definitions/common/base';
import { IAuthConfig } from '@/definitions/config/auth';
import { ILanguagesConfig } from '@/definitions/config/languages';
import { IServerConfig } from '@/definitions/config/server';
import { IThemesConfig } from '@/definitions/config/theme';
import { languageModule } from '@/store/languages';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { reactive } from 'vue';
import { configLanguageModule } from './languages';
import { OverlayObjects } from '@/components/video-player/VideoPlayerOverlay.vue';

export type IConfig = {
  auth?: IAuthConfig | null;
  car_card?: any;
  detect_config?: any;
  dicts?: any;
  enable_acl?: boolean;
  external_vms?: any;
  extra_options?: string[];
  hooks_default_filters?: any;
  human_card?: any;
  language?: string;
  languages?: ILanguagesConfig | null;
  menu?: any;
  objects?: any;
  plugins?: Record<string, boolean> | null;
  server?: IServerConfig | null;
  services?: any;
  themes?: IThemesConfig | null;
  title?: string;
  video_player?: any;
  vms?: any;
  counters?: any;
};

export type IConfigState = {
  config: IConfig;
  loading: boolean;
};

export type IConfigFeatures = {
  vms_enabled: boolean;
  event_video_player: boolean;
};

export type IConfigPlugins = {
  puppeteer?: boolean;
};

export type IConfigServices = {
  active_directory?: boolean;
  area?: boolean;
  cleanup?: boolean;
  clusters?: boolean;
  counters?: boolean;
  episodes?: boolean;
  proximity_calibration?: boolean;
  reports?: boolean;
  video_archive_events_manager?: boolean;
  vms_cleanup?: boolean;
  webhooks?: boolean;
};

export type ITimelineObjectItem = {
  enabled: boolean;
  limit: number;
  matchedColor: string;
  unmatchedColor: string;
};

export type ITimelineObjects = {
  events: {
    faces: ITimelineObjectItem;
    bodies: ITimelineObjectItem;
    cars: ITimelineObjectItem;
  };
  episodes: {
    humans: ITimelineObjectItem;
    cars: ITimelineObjectItem;
  };
};

export class ConfigModule implements IConfigState {
  config: IConfig = {};
  loading = false;

  get serverUrl() {
    return this.config?.server?.url ?? `${location.origin}/`;
  }

  get features(): IConfigFeatures {
    return {
      vms_enabled: this.config?.vms?.enabled,
      event_video_player: this.config?.vms?.enabled && this.config?.vms?.video_player
    };
  }

  get overlay_objects(): OverlayObjects {
    return this.config?.video_player?.overlay.objects as OverlayObjects;
  }

  get overlay_gdpr(): boolean {
    return this.config?.video_player?.overlay.gdpr as boolean;
  }

  get timeline_min_zoom(): number | undefined {
    return this.config?.video_player?.timeline?.min_zoom;
  }

  get timeline_max_zoom(): number | undefined {
    return this.config?.video_player?.timeline?.max_zoom;
  }

  get timeline_objects(): ITimelineObjects | undefined {
    return this.config?.video_player?.timeline?.objects;
  }

  get plugins(): IConfigPlugins {
    return {
      ...this.config.plugins
    };
  }

  get services(): IConfigServices {
    return this.config.services.ffsecurity;
  }

  private async loadConfig(): Promise<boolean> {
    const url = `config.json?r=${Math.random()}`;
    this.loading = true;
    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.config = response.data as IConfig;
        return true;
      })
      .then(() => {
        return this.loadCarModels();
      })
      .then(() => {
        return this.loadHooksFilters();
      })
      .catch(async (e: AxiosError) => {
        await getDelay(1000);
        return this.loadConfig();
      })
      .finally(async () => {
        this.loading = false;
      });
  }

  private async loadCarModels(): Promise<boolean> {
    const sourceUrl = this.config.objects?.cars?.features?.model?.url;
    if (!sourceUrl) return true;
    const url = `${sourceUrl}?r=${Math.random()}`;
    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.config.objects.cars.features.model = response.data.items;
        return true;
      })
      .catch(() => {
        console.warn("[Config module]: hasn't car models");
        return false;
      });
  }

  private async loadHooksFilters(): Promise<boolean> {
    const sourceUrl = this.config.hooks_default_filters?.url;
    if (!sourceUrl) return true;
    const url = `${sourceUrl}?r=${Math.random()}`;
    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.config.hooks_default_filters = response.data;
        EmptyWebhook.filters = response.data;
        return true;
      })
      .catch(() => {
        console.warn("[Config module]: hasn't hooks default filters");
        return false;
      });
  }

  async load() {
    await this.loadConfig();
    const languages = await configLanguageModule.loadLanguages();
    languages.forEach((v) => {
      const currentLanguageData = languageModule.items.find((i) => i.name === v.name);
      if (currentLanguageData) {
        merge(currentLanguageData.data, v.data);
      } else {
        languageModule.items.push(v);
      }
    });
    return true;
  }

  get defaultTitle(): string {
    return this.config.themes?.title ?? 'FF.Multi 2.0';
  }
}

async function loadTheme(): Promise<boolean> {
  return true;
}

export const configModule = reactive(new ConfigModule()) as ConfigModule;
