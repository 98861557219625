
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NMultitool from '@/uikit/multitool/NMultitool.vue';
import type { IMultitoolAction } from '@/uikit/multitool/types';
import { defineComponent } from 'vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { aclModule } from '@/store/acl';

export default defineComponent({
  name: 'ReportsPageMultitool',
  components: {
    NButtonGroup,
    NMultitool
  },
  props: {
    selectedCount: {
      required: true,
      type: Number
    }
  },
  emits: ['delete', 'download', 'select-all', 'unselect-all', 'update'],
  computed: {
    hasDelete() {
      return aclModule.getAccess('ffsecurity.delete_report');
    },
    hasUpdate() {
      return aclModule.getAccess('ffsecurity.change_report');
    },
    actions(): IMultitoolAction[] {
      const actions = [
        {
          handler: this.dispatchDownloadEvent,
          label: this.$t('reports.download', 'f'),
          name: 'download'
        }
      ];

      const updateAction = {
        handler: this.dispatchUpdateEvent,
        label: this.$t('reports.update', 'f'),
        name: 'update'
      };

      const deleteAction = {
        handler: this.dispatchDeleteEvent,
        label: this.$t('reports.delete', 'f'),
        name: 'delete'
      };

      this.hasUpdate && actions.push(updateAction);
      this.hasDelete && actions.push(deleteAction);

      return actions;
    },
    selectedCountArrayWorkaround() {
      return new Array<number>(this.selectedCount).fill(0);
    }
  },
  methods: {
    handleMultitoolActionEvent(action: string): void {
      action === ItemsActionNames.SelectAll && this.dispatchSelectAllEvent();
    },
    dispatchDeleteEvent(): void {
      this.$emit('delete');
    },
    dispatchDownloadEvent(): void {
      this.$emit('download');
    },
    dispatchSelectAllEvent(): void {
      this.$emit('select-all');
    },
    dispatchUnselectAllEvent(): void {
      this.$emit('unselect-all');
    },
    dispatchUpdateEvent(): void {
      this.$emit('update');
    }
  }
});
