
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButtonType } from '@/uikit';
import NButton, { NButtonSize } from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'NButtonFileSelect',
  components: { NButton },
  emits: ['files']
})
export default class NButtonFileSelect extends Vue {
  @Prop({ type: String, default: NButtonType.Primary })
  readonly type!: NButtonType;

  @Prop({ type: String, default: NButtonSize.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: String, default: '' })
  readonly label?: string;

  @Prop({ type: String })
  readonly icon?: string;

  @Prop({ type: String })
  readonly suffixIcon?: string;

  @Prop({ type: Boolean, default: false })
  readonly round!: false;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: String })
  readonly accept?: string;

  handlerClick() {
    this.$refs.inputFile.click();
  }

  changeFiles(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files as FileList;
    this.$emit('files', files);
  }
}
