import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "heading-m" }
const _hoisted_2 = { class: "confirm paragraph-m" }
const _hoisted_3 = { class: "confirm__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    className: "confirm",
    layout: true,
    "insert-into-body": true,
    onClose: _ctx.cancelHandler
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_NButton, {
        icon: "close",
        type: "secondary",
        round: "",
        class: "n-modal-window__close-button",
        onClick: _ctx.cancelHandler
      }, null, 8, ["onClick"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.description), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NButton, {
          type: "secondary",
          onClick: _ctx.deleteAllHandler
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.delete_all', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        (!_ctx.allVideosAreDeleted)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              onClick: _ctx.deleteVideoFilesOnlyHandler
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ds.delete_video_files_only', 'f')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}