import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vp-bottom-bar" }
const _hoisted_2 = { class: "vp-bottom-bar__left" }
const _hoisted_3 = { class: "vp-bottom-bar__center" }
const _hoisted_4 = { class: "vp-bottom-bar__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateInput = _resolveComponent("NDateInput")!
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.state.cameraGroupName) + " / " + _toDisplayString(_ctx.state.cameraName), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NDateInput, {
        modelValue: _ctx.dateInputModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateInputModel) = $event)),
        isDateDisabled: _ctx.isDateDisabled
      }, null, 8, ["modelValue", "isDateDisabled"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NButtonGroup, {
        items: _ctx.eventTypes,
        modelValue: _ctx.eventTypeModel,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.eventTypeModel) = $event))
      }, null, 8, ["items", "modelValue"])
    ])
  ]))
}