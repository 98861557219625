import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "n-multitool" }
const _hoisted_2 = { class: "n-multitool__button-group" }
const _hoisted_3 = { class: "n-multitool__button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NButtonGroup, {
        items: _ctx.basicActions,
        multiple: true,
        modelValue: _ctx.selectedBasicActions
      }, null, 8, ["items", "modelValue"]),
      (_ctx.hiddenActions.length > 0)
        ? (_openBlock(), _createBlock(_component_NDropdown, {
            key: 0,
            placement: "top-start"
          }, {
            toggle: _withCtx(({ toggle }) => [
              _createVNode(_component_NButton, {
                type: "secondary",
                onClick: toggle,
                icon: "more"
              }, null, 8, ["onClick"])
            ]),
            body: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hiddenActions, ({ label, name, icon }) => {
                return (_openBlock(), _createBlock(_component_NDropdownItem, {
                  key: name,
                  icon: icon,
                  onClick: () => _ctx.$emit('action', name)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(label), 1)
                  ]),
                  _: 2
                }, 1032, ["icon", "onClick"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.isButtonGroupDisplayed)
          ? (_openBlock(), _createBlock(_component_NButtonGroup, {
              key: 0,
              items: _ctx.displayedActions,
              type: "separated",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = (name) => _ctx.$emit('action', name))
            }, null, 8, ["items"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}