import { IFormLayoutItem, IFormModel } from '@/uikit/forms/NForm.vue';
import {
  configButtonGroup,
  configCardSelect,
  configDateTimeRange,
  configDateTimeRangeSmall,
  configInput,
  configInputIds, configMultipleInputSmall,
  configSelectAsTags,
  configSelectAsTagsSmall,
  configSelectSmall,
  configTextSmall,
  configureDivider,
  SimpleFilterOptions
} from '@/store/filters/types';
import CameraMultiple from '@/components/counters/CameraMultiple.vue';
import CameraGroupMultiple from '@/components/counters/CameraGroupMultiple.vue';
import NFilterDateRange from '@/components/common/filter/FilterDateRange.vue';
import CounterSelect from '@/components/counters/CounterSelect.vue';
import { computeFieldItems } from '@/common/utilsFilterSchema';
import DistanceRange from '@/components/counters/DistanceRange.vue';
import { commonFilterSchemaModule } from '@/store/filters/CommonFilterSchemaModule';
import FilterSmallRange from '@/components/common/filter/FilterSmallRange.vue';

export default class FilterSchemeBuilder {
  filterSchemeSmall: IFormLayoutItem[] = [];
  filterSchemeLarge: IFormLayoutItem[] = [];

  clear() {
    this.filterSchemeSmall = [];
    this.filterSchemeLarge = [];
  }

  addItem(small: IFormLayoutItem, large: IFormLayoutItem) {
    this.filterSchemeSmall.push(small);
    this.filterSchemeLarge.push(large);
  }

  getLarge() {
    return this.filterSchemeLarge;
  }

  getSmall() {
    return this.filterSchemeSmall;
  }

  addDivider(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.attributes'
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeLarge.push(configureDivider(resultOptions));
  }

  addCounter(options: SimpleFilterOptions = {}) {
    const itemSmall = {
      classes: '',
      component: CounterSelect,
      path: options.path || 'counter',
      props: {
        i18n_label: 'counters.counter',
        i18n_placeholder: 'counters.select_counter',
        hideChevron: true,
        closeIcon: true,
        multiple: true
      }
    };
    const itemLarge = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: CounterSelect,
      path: options.path || 'counter',
      i18n_label: 'counters.counter',
      props: {
        i18n_placeholder: 'counters.select_counter',
        clearIcon: true
      }
    };
    this.filterSchemeSmall.push(itemSmall);
    this.filterSchemeLarge.push(itemLarge);
  }

  addIdIn(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      path: 'id_in',
      i18n_label: 'common.id',
      i18n_placeholder: 'common.enter_id',
      readonly: false
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeSmall.push(configMultipleInputSmall(resultOptions));
    this.filterSchemeLarge.push(configInputIds(resultOptions));
  }

  addNameContains(options: SimpleFilterOptions = {}) {
    this.filterSchemeSmall.push(commonFilterSchemaModule.getNameContains({ ...options, small: true }));
    this.filterSchemeLarge.push(commonFilterSchemaModule.getNameContains({ ...options, small: false }));
  }


  addCameras(options: SimpleFilterOptions = {}) {
    this.filterSchemeSmall.push(commonFilterSchemaModule.getCameras({ ...options, small: true }));
    this.filterSchemeLarge.push(commonFilterSchemaModule.getCameras({ ...options, small: false }));
  }

  addCameraGroups(options: SimpleFilterOptions = {}) {
    const item = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: CameraGroupMultiple,
      path: 'camera_groups',
      i18n_label: 'common.camera_groups',
      props: {
        i18n_placeholder: 'common.select_camera_group'
      }
    };
    this.filterSchemeSmall.push(commonFilterSchemaModule.getCameraGroups({ ...options, small: true }));
    this.filterSchemeLarge.push(commonFilterSchemaModule.getCameraGroups({ ...options, small: false }));
  }

  addStartEnd(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'common.date_and_time'
    };
    const itemSmall = { ...configDateTimeRange({ ...baseOptions, ...options }) };
    itemSmall.i18n_label = undefined;
    itemSmall.props = function (model: IFormModel) {
      return {
        model,
        gteProp: 'created_date_gte',
        lteProp: 'created_date_lte',
        clearButton: false
      };
    };
    itemSmall.checkForEmpty = (model: IFormModel) => !model['created_date_gte'] && !model['created_date_lte'];

    const itemLarge = { ...configDateTimeRange({ ...baseOptions, ...options }) };
    this.filterSchemeSmall.push(itemSmall);
    this.filterSchemeLarge.push(itemLarge);
  }

  addCountFaces(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      path: 'faces_count_gte',
      i18n_label: 'counters.count_faces',
      i18n_placeholder: 'counters.count_faces',
      anyItem: true,
      labeled: true,
      items: computeFieldItems(['1', '2', '3', '5', '10'])
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeSmall.push(configSelectSmall(resultOptions));
    this.filterSchemeLarge.push(configButtonGroup(resultOptions));
  }

  addCountSilhouettes(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      path: 'silhouettes_count_gte',
      i18n_label: 'counters.count_silhouettes',
      i18n_placeholder: 'counters.count_silhouettes',
      anyItem: true,
      labeled: true,
      items: computeFieldItems(['1', '2', '3', '5', '10'])
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeSmall.push(configSelectSmall(resultOptions));
    this.filterSchemeLarge.push(configButtonGroup(resultOptions));
  }

  addCountCars(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      path: 'cars_count_gte',
      i18n_label: 'counters.count_cars',
      i18n_placeholder: 'counters.count_cars',
      anyItem: true,
      labeled: true,
      items: computeFieldItems(['1', '2', '3', '5', '10'])
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeSmall.push(configSelectSmall(resultOptions));
    this.filterSchemeLarge.push(configButtonGroup(resultOptions));
  }

  addDistanceType(options: SimpleFilterOptions = {}) {
    const baseOptions: SimpleFilterOptions = {
      path: 'detect_proximity',
      i18n_label: 'counters.distance',
      i18n_placeholder: 'counters.distance',
      anyItem: true,
      items: [
        { value: 'True', i18n_label: 'counters.with_detect_proximity' },
        { value: 'False', i18n_label: 'counters.without_detect_proximity' }
      ]
    };
    const resultOptions = Object.assign(baseOptions, options);
    this.filterSchemeSmall.push(configSelectSmall(resultOptions));
    this.filterSchemeLarge.push(configButtonGroup(resultOptions));
  }

  addMinimumDistance() {
    const item = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: DistanceRange,
      i18n_label: 'counters.min_distance',
      props: (model: IFormModel) => ({ model, key_gte: 'proximity_min_gte', key_lte: 'proximity_min_lte' })
    };
    const itemSmall = {
      component: FilterSmallRange,
      props: (model: IFormModel) => ({
        i18n_label: 'counters.min_distance',
        model,
        gteFieldName: 'proximity_min_gte',
        lteFieldName: 'proximity_min_lte',
        format: 'distance'
      }),
      checkForEmpty: (model: IFormModel) => !model['proximity_min_gte'] && !model['proximity_min_lte']
    };
    this.filterSchemeSmall.push(itemSmall);
    this.filterSchemeLarge.push(item);
  }

  addMaximumDistance() {
    const item = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: DistanceRange,
      i18n_label: 'counters.max_distance',
      props: (model: IFormModel) => ({ model, key_gte: 'proximity_max_gte', key_lte: 'proximity_max_lte' })
    };
    const itemSmall = {
      component: FilterSmallRange,
      props: (model: IFormModel) => ({
        i18n_label: 'counters.max_distance',
        model,
        gteFieldName: 'proximity_max_gte',
        lteFieldName: 'proximity_max_lte',
        format: 'distance'
      }),
      checkForEmpty: (model: IFormModel) => !model['proximity_max_gte'] && !model['proximity_max_lte']
    };
    this.filterSchemeSmall.push(itemSmall);
    this.filterSchemeLarge.push(item);
  }

  addAverageDistance() {
    const item = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      component: DistanceRange,
      i18n_label: 'counters.avg_distance',
      props: (model: IFormModel) => ({ model, key_gte: 'proximity_avg_gte', key_lte: 'proximity_avg_lte' })
    };
    const itemSmall = {
      component: FilterSmallRange,
      props: (model: IFormModel) => ({
        i18n_label: 'counters.avg_distance',
        model,
        gteFieldName: 'proximity_avg_gte',
        lteFieldName: 'proximity_avg_lte',
        format: 'distance'
      }),
      checkForEmpty: (model: IFormModel) => !model['proximity_avg_gte'] && !model['proximity_avg_lte']
    };
    this.filterSchemeSmall.push(itemSmall);
    this.filterSchemeLarge.push(item);
  }
}
