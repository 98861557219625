import { FaceEventsFilters, HumanEpisodesFilters } from '@/api';
import { isoStringToDate } from '@/common/filters';
import { isDefined } from '@/common/utils';
import { FaceFeatures, FeatureObject } from '@/components/common/types';
import { bodyFeatures, carsFeatures, facesFeatures, getSatisfyEventFeatures } from '@/store/events/features.filter';
import intersection from 'lodash/intersection';

export function satisfyEventFilter(event: any, filter: any, objects: FeatureObject): boolean {
  let satisfy = true;

  if (satisfy && filter.id) {
    satisfy = filter.id === event.id;
  }

  if (satisfy && filter.episode) {
    satisfy = String(filter.episode) === String(event.episode);
  }

  if (satisfy && filter.matched_card) {
    satisfy = filter.matched_card === event.matched_card;
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.no_match) {
    satisfy = filter.no_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups.length) {
    satisfy = filter.camera_groups.find((v: number) => v === event.camera_group);
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v: number) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);
    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  if (satisfy && filter.video_archive) {
    satisfy = Array.isArray(filter.video_archive)
      ? filter.video_archive.length === 0 || filter.video_archive.includes(event.video_archive)
      : Number(filter.video_archive) === event.video_archive;
  }

  if (satisfy && filter.bs_type) {
    satisfy = filter.bs_type === event.bs_type;
  }

  satisfy = satisfy && getSatisfyEventFeatures(event, facesFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, carsFeatures, filter);
  satisfy = satisfy && getSatisfyEventFeatures(event, bodyFeatures, filter);
  satisfy = satisfy && (objects === 'faces' ? doesEventSatisfyHeadposeFilter(event.features ?? {}, filter) : true);

  return satisfy;
}

export function doesEventSatisfyHeadposeFilter(
  { headpose_pitch, headpose_yaw }: Partial<FaceFeatures>,
  filter: FaceEventsFilters | HumanEpisodesFilters
): boolean {
  return [
    isDefined(filter.headpose_pitch_angle_gte) && isDefined(headpose_pitch) ? headpose_pitch.name >= filter.headpose_pitch_angle_gte : true,
    isDefined(filter.headpose_pitch_angle_lte) && isDefined(headpose_pitch) ? headpose_pitch.name <= filter.headpose_pitch_angle_lte : true,
    isDefined(filter.headpose_yaw_angle_gte) && isDefined(headpose_yaw) ? headpose_yaw.name >= filter.headpose_yaw_angle_gte : true,
    isDefined(filter.headpose_yaw_angle_lte) && isDefined(headpose_yaw) ? headpose_yaw.name <= filter.headpose_yaw_angle_lte : true
  ].every(Boolean);
}
