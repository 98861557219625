
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NCheckbox from '../checkbox/NCheckbox.vue';

export interface CheckboxItem {
  value: string | number | Record<string, any>;
  label?: string;
  disabled?: boolean;
}

export type CheckboxItemValue = Pick<CheckboxItem, 'value'>;

@Options({
  name: 'NCheckboxGroup',
  components: { NCheckbox }
})
export default class NCheckboxGroup extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: CheckboxItem[];

  @Prop({ type: String, default: '' })
  readonly customClass?: string;

  @Prop({ type: String, default: 'horizontal' })
  readonly direction?: string;

  @Prop({ type: Array, default: () => [] })
  readonly modelValue!: CheckboxItemValue[];

  getKey(item: CheckboxItem, index: number) {
    const id = typeof item === 'object' ? (typeof item.value === 'object' ? item.value.id || item.label : item.value) : index;
    return `item-${id}`;
  }

  isItemSelected(item: CheckboxItem) {
    return this.modelValue.includes(item.value as CheckboxItemValue);
  }

  handleChange(v: boolean, item: CheckboxItem) {
    let result = [...this.modelValue];

    if (result.includes(item.value as CheckboxItemValue)) {
      result = result.filter((v) => v !== item.value);
    } else {
      result.push(item.value as CheckboxItemValue);
    }

    this.$emit('update:modelValue', result);
  }
}
