
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CarEpisode, HumanEpisode } from '@/api';
import EventItemV2 from '@/components/events/EventItemV2.vue';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import { ListViewModel } from '@/definitions/view-models';
import { NLoadingCircle } from '@/uikit';
import { loadDataModule } from '@/store/data/LoadDataModule';

@Options({
  components: { EventItemV2, NLoadingCircle }
})
export default class EpisodeEventsPage extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanEpisode | CarEpisode;

  @Prop({ type: String, required: true })
  type!: string;

  $module: ListViewModel<any, any> | null = null;
  $secondModule: ListViewModel<any, any> | null = null;

  get firstObjectType() {
    return this.type === 'humans' ? 'faces' : 'cars';
  }

  get firstModule(): ListViewModel<any, any> {
    if (!this.$module) this.$module = loadDataModule.getEventsLVMByEpisodeID({ id: this.item.id, type: this.firstObjectType });
    return this.$module;
  }

  get secondObjectType() {
    return this.type === 'humans' ? 'bodies' : null;
  }

  get secondModule(): ListViewModel<any, any> | null {
    if (this.secondObjectType && !this.$secondModule)
      this.$secondModule = loadDataModule.getEventsLVMByEpisodeID({ id: this.item.id, type: this.secondObjectType });
    return this.$secondModule;
  }

  get iconActions() {
    return [{ icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight }];
  }

  get defaultEventAction() {
    return EventDetails.ShowInfo;
  }

  actionHandler(id: string | number, action: string, item: any): void {
    this.$emit('action', action, id, item);
  }

  scrollHandler(): void {
    const el = this.$refs.events;
    const scrollBottom = el ? el.scrollHeight - el.clientHeight - el.scrollTop : '';
    if (scrollBottom && scrollBottom < 200) {
      this.firstModule.append();
    }
  }

  beforeUnmount() {
    this.firstModule.reset();
  }
}
