
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CarEpisode, HumanEpisode, VideoArchive } from '@/api';
import { viewModelRepository } from '@/api/common';
import { websocketModule } from '@/store/ws/websocket.module';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import { CardTypeMap, EpisodesSingleToMulti } from '@/store/application/data.assets';

function unshiftOrUpdateItemInList<T extends { id: number }>(item: T, items: T[]) {
  let itemIndex = items.findIndex((v: T) => v.id === item.id);
  itemIndex === -1 ? items.unshift(item) : Object.assign(items[itemIndex], item);
}

@Options({
  name: 'VideoProcessPreviewItems',
  components: { NInfiniteScroll },
  emits: []
})
export default class VideoProcessPreviewItems extends Vue {
  @Prop({ type: Object, required: true })
  item!: VideoArchive;

  @Prop({ type: String, default: CardTypeMap.Human })
  type!: 'human' | 'car';

  get types() {
    return EpisodesSingleToMulti[this.type];
  }

  get objectTypes() {
    switch (this.type) {
      case 'car':
        return 'cars';
      case 'human':
      default:
        return 'faces';
    }
  }

  get websocketModule() {
    return websocketModule;
  }

  get module() {
    switch (this.type) {
      case 'car':
        return viewModelRepository.getEpisodesCarsListViewModel();
      case 'human':
      default:
        return viewModelRepository.getEpisodesHumansListViewModel();
    }
  }

  get items() {
    return this.module.items;
  }

  @Watch('item.active')
  reload() {
    this.load();
  }

  @Watch('websocketModule.episode', { deep: true })
  handleNewEpisode(episodeByType: { [key in 'human' | 'car']: HumanEpisode | CarEpisode }) {
    let episode = episodeByType[this.type];
    if (episode?.video_archive === this.item.id) {
      unshiftOrUpdateItemInList(episode, this.items);
    }
  }

  load() {
    this.module.filter.current.video_archive = [this.item.id];
    this.module.filter.current.limit = '50';
    this.module.get();
  }

  mounted() {
    this.load();
  }
}
