
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NDateTimeRange } from '@/uikit';
import { formatDate, formatTimeHHMM } from '@/common/filters';
import dayjs from 'dayjs';
import { I18NDateIntervalItem, IDateIntervalItem } from '@/uikit/datetime/types';

@Options({
  name: 'NFilterDateRange',
  components: {
    NDateTimeRange
  },
  emits: ['update:modelValue']
})
export default class NFilterDateRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Boolean, default: true })
  hasIntervals!: boolean;

  @Prop({ type: Boolean, default: true })
  clearable!: boolean;

  @Prop({ type: Boolean, default: true })
  clearButton!: boolean;

  @Prop({ type: Boolean })
  plain?: boolean;

  @Prop({ type: String, default: 'created_date_gte' })
  readonly gteProp!: string;

  @Prop({ type: String, default: 'created_date_lte' })
  readonly lteProp!: string;

  @Prop({ type: String })
  readonly size?: string;


  get dateAsArray(): string[] {
    return [this.model[this.gteProp], this.model[this.lteProp]];
  }

  get placeholders() {
    return [
      {
        datePlaceholder: formatDate(new Date(0), 'DD.MM.YYYY'),
        timePlaceholder: formatTimeHHMM(new Date(0))
      },
      {
        datePlaceholder: formatDate(new Date(), 'DD.MM.YYYY'),
        timePlaceholder: formatTimeHHMM(new Date())
      }
    ];
  }

  changeHandler([from, to]: (Date | null)[]) {
    const updatedFields = { [this.gteProp]: from?.toISOString() || null, [this.lteProp]: to?.toISOString() || null };
    Object.assign(this.model, updatedFields);
    this.$emit('update:modelValue', this.model);
  }

  get intervals() {
    if (!this.hasIntervals) {
      return [];
    }

    const intervals: I18NDateIntervalItem[] = [
      { label_i18n: 'today', interval: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()] },
      { label_i18n: 'hour_mf', offset: { value: 24, unit: 'hours' } },
      { label_i18n: 'day_mf', offset: { value: 7, unit: 'days' } },
      { label_i18n: 'day_mf', offset: { value: 15, unit: 'days' } },
      { label_i18n: 'month_mf', offset: { value: 1, unit: 'month' } },
      { label_i18n: 'month_mf', offset: { value: 2, unit: 'months' } },
      { label_i18n: 'month_mf', offset: { value: 3, unit: 'months' } },
      { label_i18n: 'year_mf', offset: { value: 1, unit: 'year' } }
    ];

    return intervals.map((interval): IDateIntervalItem => {
      let label = this.$tm(`common.${interval.label_i18n}`, { count: interval.offset?.value });
      return { label, ...interval };
    });
  }
}
