import qs from 'qs';
import _ from 'lodash';
import { Filter } from '@/definitions/view-models/filter';

export const AutoUpdateIntervalInMs = 20000;

export const getDelay = (time = 1000) => new Promise((r, j) => setTimeout(r, time));

export const DefaultAclPrefix = 'ffsecurity';

export interface IIntervalData {
  enabled: boolean;
  intervalInMs: number;
  intervalIndex: number;
}

export type PartialWithId<T> = Partial<T> & { id: string | number };

export type PartialWithAnyId<T> = Partial<T> & { id?: string | number };

export function getPageFromNextPage(value: string): string {
  let filterFromPageQuery = qs.parse((value || '').split('?')[1] || '', { arrayLimit: 100 });
  return (filterFromPageQuery.page as string) ?? '';
}

export function getFilterString(filter: any, emptyFilter: any): string {
  return qs.stringify(
    _.pickBy({ ...emptyFilter, ...filter }, (v) => !!v),
    { arrayFormat: 'repeat' }
  );
}

export interface ItemStorage<T> {
  current?: T;
  empty?: T;
}

export class BaseItemState<T> {
  name = 'base';
  loading = false;
  loaded = false;
  loadError?: any;

  emptyItem?: T | null = null;
  originalItem?: T | null = null;
  item?: T | null = null;
}

export class BaseListState<T, F> {
  name = 'base';
  routeName = 'base';
  aclModelName? = 'base';

  loading = false;
  appending = false;
  loaded = false;
  loadError?: any;

  selectedItemIds: (string | number)[] = [];

  items: T[] = [];
  itemHandler?: (...args: any[]) => any;

  page = '';
  next_page: string | null = null;
  prev_page: string[] = [];
  limits: number[] = [10, 20, 50, 100, 200, 500];

  filter: Filter<F> = new Filter<F>();
}
