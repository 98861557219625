
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, reactive, unref, watch } from 'vue';
import NImageViewer from '@/uikit/image-viewer/NImageViewer.vue';
import { NButton } from '@/uikit';
import { ArrowType, ArrowTypes } from '@/uikit/image-viewer/types';

export default defineComponent({
  name: 'NImageGallery',
  components: { NImageViewer, NButton },
  emits: ['close', 'show-items'],
  props: {
    items: { type: Array as PropType<string[]>, required: true },
    bboxes: { type: Array as PropType<any[]>, required: false },
    srcIndex: { type: Number, default: 0 },
    zoomable: { type: Boolean, default: true },
    draggable: { type: Boolean, default: true },
    bboxSelectable: { type: Boolean, default: true },
    arrowsType: { type: String as PropType<ArrowType>, default: ArrowTypes.Middle },
    itemsButton: { type: Boolean, default: false },
    errorMessage: { type: String }
  },
  setup(props, { emit }) {
    const state = reactive({
      srcIndex: props.srcIndex ?? 0
    });
    const currentSrc = computed(() => props.items[state.srcIndex]);
    const currentBBoxes = computed(() => (props.bboxes ? props.bboxes[state.srcIndex] : null));
    const hasArrows = computed(() => props.items.length > 1);
    const hasLeftTopArrows = computed(() => unref(hasArrows) && props.arrowsType === ArrowTypes.TopLeft);
    const hasMiddleArrows = computed(() => unref(hasArrows) && props.arrowsType === ArrowTypes.Middle);

    watch(
      () => props.srcIndex,
      () => (state.srcIndex = props.srcIndex)
    );

    function next() {
      state.srcIndex = state.srcIndex === props.items.length - 1 ? 0 : state.srcIndex + 1;
    }

    function prev() {
      state.srcIndex = state.srcIndex === 0 ? props.items.length - 1 : state.srcIndex - 1;
    }

    onMounted(bindArrowsHandler);
    function bindArrowsHandler() {
      document.addEventListener('keyup', handleArrow);
    }

    onBeforeUnmount(unbindArrowsHandler);
    function unbindArrowsHandler() {
      document.removeEventListener('keyup', handleArrow);
    }

    function handleArrow(e: KeyboardEvent) {
      if (e.key === 'ArrowLeft' && unref(hasArrows)) prev();
      if (e.key === 'ArrowRight' && unref(hasArrows)) next();
    }

    return {
      ...props,
      emit,
      currentSrc,
      hasMiddleArrows,
      hasLeftTopArrows,
      currentBBoxes,
      prev,
      next
    };
  }
});
