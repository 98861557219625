import { IFormContext, IFormLayout, IFormLayoutRow, IFormModel, IFormOptionalLayout } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import { NButton, NDateInput, NTimeInput } from '@/uikit';
import TestSplitter from '@/uikit/simple/TestSplitter.vue';
import FileSize from '@/components/data-source/FileSize.vue';
import { detectors } from './data-source-detectors';
import TestText from '@/uikit/simple/TestText.vue';
import { findLayoutItemByPath } from '@/common/form';
import { getRequiredValidator } from '@/uikit/forms/validators';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import CameraName from '@/components/data-source/CameraName.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import { VideoArchive } from '@/api';

export function getDataSourceFileGeneralLayout(item: any = {}, availableObjects: string[]): IFormLayout {
  const result: IFormOptionalLayout = [
    [
      {
        path: 'name',
        classes: 'n-form-w-5 n-form-pad-10',
        i18n_label: 'ds.name',
        component: NInput,
        validators: [getRequiredValidator()]
      },
      {
        path: 'file_size',
        classes: 'n-form-w-2 n-form-pad-10',
        i18n_label: 'ds.file_size',
        component: FileSize
      }
    ],
    {
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      path: 'stream_settings.enable_liveness',
      props: { i18n_label: 'ds.enable_liveness' }
    },
    item.url
      ? {
          path: 'url',
          classes: 'n-form-w-3  n-form-pad-10',
          i18n_label: 'ds.url',
          component: TestText
        }
      : null,
    {
      classes: 'n-form-w-6  n-form-pad-10',
      component: TestSplitter,
      props: { i18n_label: 'ds.info' }
    },
    [
      {
        path: 'camera_group',
        classes: 'n-form-w-3  n-form-pad-10',
        i18n_label: 'ds.camera_group',
        component: CameraGroupName,
        props: { excludeVideoArchive: false }
      },
      {
        path: 'camera',
        classes: 'n-form-w-3  n-form-pad-10',
        i18n_label: 'ds.camera_optional',
        component: CameraName,
        props: (item: VideoArchive) => {
          return { cameraGroup: item.camera_group };
        }
      }
    ],
    ...detectors,
    {
      classes: 'n-form-w-6  n-form-pad-10',
      component: TestSplitter,
      props: { i18n_label: 'ds.start_time' }
    },
    [
      {
        path: 'start_stream_timestamp',
        classes: 'n-form-w-4  n-form-pad-10',
        component: NDateInput,
        props: { datePlaceholder: '01.01.2020', timePlaceholder: '12:00', timeEnabled: true, secondsEnabled: true },
        encode: function (this: IFormContext, model: IFormModel, value: string) {
          if (value) {
            this.model.stream_settings.use_stream_timestamp = true;
            this.model.stream_settings.start_stream_timestamp = Math.round(new Date(value).getTime() / 1000);
          } else {
            this.model.stream_settings.use_stream_timestamp = false;
            this.model.stream_settings.start_stream_timestamp = 0;
          }
        },
        decode: function (this: IFormContext) {
          const timestamp = this.model.stream_settings.start_stream_timestamp;
          return timestamp ? new Date(timestamp * 1000).toISOString() : null;
        }
      },
      {
        classes: 'n-form-w-1 n-form-pad-10',
        component: NButton,
        props: {
          type: 'secondary',
          i18n_label: 'ds.now'
        },
        on: {
          click(this: any) {
            this.model.stream_settings.use_stream_timestamp = true;
            this.model.stream_settings.start_stream_timestamp = Math.round(new Date().getTime() / 1000);
          }
        }
      },
      {
        classes: 'n-form-w-1 n-form-pad-10',
        component: NButton,
        props: { type: 'secondary', i18n_label: 'ds.clear' },
        on: {
          click(this: any) {
            this.model.stream_settings.use_stream_timestamp = false;
            this.model.stream_settings.start_stream_timestamp = 0;
          }
        }
      }
    ]
  ];

  availableObjects.forEach((v) => {
    const layoutItem = findLayoutItemByPath(result, v);
    if (layoutItem && layoutItem.props) layoutItem.props.disabled = false;
  });

  return result.filter((v: IFormLayoutRow | null) => !!v) as any as IFormLayout;
}
