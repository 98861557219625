/* eslint-disable @typescript-eslint/ban-types */
export type License = {
  readonly generated?: number;
  readonly last_updated: Nullable<number>;
  readonly license_id?: string;
  readonly limits?: LicenseLimit[];
  readonly name: string;
  readonly services: LicenseService[];
  readonly source?: string;
  readonly time?: number;
  readonly type?: string;
  readonly valid: LicenseValid;
};

export type LicenseLimit = LicenseBooleanLimit | LicenseNumberLimit | LicenseTimeLimit;

export type LicenseBooleanLimitName =
  | (
      | 'age'
      | 'all-attrs'
      | 'beard'
      | 'body-emben'
      | 'body-other-attrs'
      | 'car-emben'
      | 'car-licplate'
      | 'car-other-attrs'
      | 'car-special-types'
      | 'countries'
      | 'emotions'
      | 'fast-index'
      | 'gender'
      | 'glasses'
      | 'head-other-attrs'
      | 'liveness'
      | 'medmask'
      | 'look'
      | 'sec-genetec'
      | 'video-recording'
    )
  | ({} & string);

export type LicenseBooleanLimit = {
  readonly name: LicenseBooleanLimitName;
  readonly type: 'boolean';
  readonly value: boolean;
};

export type LicenseNumberLimitName = 'cameras' | 'extraction_api' | 'faces' | ({} & string);

export type LicenseNumberLimit = {
  readonly current: number;
  readonly name: LicenseNumberLimitName;
  readonly type: 'number';
  readonly value: number;
};

export type LicenseTimeLimitName = 'end' | ({} & string);

export type LicenseTimeLimit = {
  readonly name: LicenseTimeLimitName;
  readonly type: 'time';
  readonly value: number;
};

export type LicenseService = {
  readonly ip: string;
  readonly name: string;
};

export type LicenseValid = {
  readonly description?: string;
  readonly source: string;
  readonly value: boolean;
};

export type LicenseUsage = {
  readonly active_limits: Nullable<LicenseUsageInterval>;
  readonly by_interval: LicenseUsageInterval[];
};

export type LicenseUsageInterval = {
  readonly counters: LicenseUsageIntervalCounterSet;
  readonly since: string;
  readonly till: Nullable<string>;
};

export type LicenseUsageIntervalCounterSet = Record<LicenseUsageIntervalCounterName, LicenseUsageIntervalCounter>;

export type LicenseUsageIntervalCounterName = string;

export type LicenseUsageIntervalCounter = {
  readonly available?: number;
  readonly used: number;
};

export type LicenseUsageIntervalCounterRelativeLimitSpending = 'approaching' | 'exceed' | 'not-reached' | 'reached';

export function isLicenseBooleanLimit(licenseLimit: LicenseLimit): licenseLimit is LicenseBooleanLimit {
  return licenseLimit.type == 'boolean';
}

export function isLicenseNumberLimit(licenseLimit: LicenseLimit): licenseLimit is LicenseNumberLimit {
  return licenseLimit.type == 'number';
}

export function isLicenseTimeLimit(licenseLimit: LicenseLimit): licenseLimit is LicenseTimeLimit {
  return licenseLimit.type == 'time';
}

export type Nullable<T> = T | null;
