
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { IFormError } from '@/uikit/forms/NForm.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';

@Options({
  name: 'NFormError',
  components: { NTooltip }
})
export default class NFormError extends Vue {
  @Prop({ type: Object, required: true })
  readonly reference!: any;

  @Prop({ type: Object, required: true })
  readonly error!: IFormError;

  get title() {
    let title = this.error.message || this.$t(this.error.i18n_message, 'f');
    if (this.error.replaceArray instanceof Array) {
      this.error.replaceArray.forEach((item) => {
        title = title.replaceAll(item[0], item[1]);
      });
    }
    return title;
  }

  get allowedPlacements() {
    return ['left', 'right'];
  }
}
