
import { CamerasService, ExternalVmsService, VmsService } from '@/api';
import { isDefined } from '@/common/utils';
import { IObjectItem } from '@/components/video-player/objects/timeline-object';
import TimelineChunks from '@/components/video-player/objects/vms/chunks';
import VideoPlayerInfoPanel from '@/components/video-player/panels/VideoPlayerInfoPanel.vue';
import VideoPlayerPtzPanel from '@/components/video-player/panels/VideoPlayerPtzPanel.vue';
import VideoPlayerSettings from '@/components/video-player/panels/VideoPlayerSettings.vue';
import debounce from '@/components/video-player/utils/debounce';
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import VideoPlayerBottomBar from '@/components/video-player/VideoPlayerBottomBar.vue';
import VideoPlayerControls from '@/components/video-player/VideoPlayerControls.vue';
import VideoPlayerExport from '@/components/video-player/VideoPlayerExport.vue';
import VideoPlayerOverlay from '@/components/video-player/VideoPlayerOverlay.vue';
import VideoPlayerRender from '@/components/video-player/VideoPlayerRender.vue';
import VideoPlayerThumbnail from '@/components/video-player/VideoPlayerThumbnail.vue';
import VideoPlayerTimeline from '@/components/video-player/VideoPlayerTimeline.vue';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import NIcon from '@/uikit/icons/NIcon.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';
import { dataModule } from '@/store/data';
import { Debounce } from '@/common/debounce-decorator';
import { request as __request } from '@/api/core/request';

enum TimeType {
  Live = 'live',
  Archive = 'archive',
  ExternalArchive = 'external-archive',
  NotFound = 'not_found'
}

interface IStreamProps {
  width: number;
  height: number;
  fps: number;
}

@Options({
  name: 'VideoPlayer',
  components: {
    NLoadingCircle,
    NIcon,
    VideoPlayerBottomBar,
    VideoPlayerControls,
    VideoPlayerExport,
    VideoPlayerInfoPanel,
    VideoPlayerOverlay,
    VideoPlayerPtzPanel,
    VideoPlayerRender,
    VideoPlayerSettings,
    VideoPlayerThumbnail,
    VideoPlayerTimeline
  }
})
export default class VideoPlayer extends Vue {
  @Prop({ type: String, default: 'cameras' })
  readonly model!: string;

  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  @Prop({ type: Number, required: true })
  readonly timeFrom!: number;

  @Prop({ type: Boolean, default: false })
  readonly reconnectOnClose!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showTimeline!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showControls!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showRewinds!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showBottomBar!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeable!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showOverlay!: boolean;

  @Prop({
    type: Array,
    default() {
      return ['faces', 'bodies', 'cars'];
    }
  })
  readonly showBboxObjects!: string[];

  @Ref('playerRender')
  playerRender!: VideoPlayerRender;

  @Ref('playerTimeline')
  playerTimeline!: VideoPlayerTimeline;

  private lastTimelineTime = 0;

  isLive = false;
  ptzButton = false;
  hasPtzPanel = false;
  hasInfoPanel = false;
  hasSettingsPanel = false;
  isCameraRemoved = false;

  settings = new VideoPlayerState();

  eventType = 'events';
  frameObjects = {};
  streamProps: IStreamProps = { width: 640, height: 480, fps: 30 };
  container = { top: 0, left: 0, width: 640, height: 480, scale: 1 };
  playerContent!: HTMLDivElement;

  exportTimeFrom = 0;
  exportTimeTo = 0;

  wsUrl = '';
  playerState = '';
  exportMode = false;
  thumbSrc = '';
  thumbCss = {};
  currentTime = 0;
  oldState = TimeType.NotFound;
  newState = TimeType.NotFound;
  positionStartTime = 0;
  restartRenderDebounced: any = false;

  // для скрытия контролов
  hoverFlag = true;
  hoverTimer = new SimpleTimer();
  mousemove() {
    this.hoverFlag = true;
    this.hoverTimer.setTimeout(() => {
      this.hoverFlag = false;
    }, 10000);
  }

  readonly observer = new ResizeObserver(([entry]) => isDefined(entry) && this.resizeContainer(entry.contentRect));

  /* обработка settings.brightness, settings.contrast, settings.saturation */
  get cssFilters() {
    return { filter: `brightness(${this.settings.brightness / 50}) contrast(${this.settings.contrast / 50}) saturate(${this.settings.saturation / 50})` };
  }

  get restartIntervalSec() {
    return this.isLive ? 600 : 0;
  }

  get syncLiveIntervalSec() {
    return this.isLive ? 6 : 0;
  }

  /* обработка settings.resolution и settings.videoQuality */
  get resolution() {
    const [width, height] = this.settings.resolution.split('x');
    return { width: Number(width), height: Number(height) };
  }
  restartRender() {
    if (this.oldState === TimeType.Archive) {
      this.oldState = TimeType.NotFound;
      this.timelinePositionChange(this.playerRender.realTimestamp);
    }
  }
  restartRenderWithDebounce() {
    if (!this.restartRenderDebounced) {
      this.restartRenderDebounced = debounce(this.restartRender, 1000);
    }
    this.restartRenderDebounced();
  }

  @Watch('settings.resolution')
  resolutionWatcher() {
    this.restartRenderWithDebounce();
  }

  @Watch('settings.videoQuality')
  videoQualityWatcher() {
    this.restartRenderWithDebounce();
  }

  playerContentRef(el: HTMLDivElement) {
    this.playerContent = el;
  }

  get frameObjectsEx() {
    if (this.oldState === TimeType.Live) {
      return this.frameObjects;
    }
    return [];
  }

  get isPause() {
    return this.playerState === 'paused';
  }

  getLiveUrl(cameraId: number, model: string) {
    let secure = window.location.protocol === 'https:';
    let configServerUrl = configModule.config?.server?.url;
    let serverUrl = configServerUrl && configServerUrl !== '/' ? configServerUrl : window.location.host + '/';
    let protocol = secure ? 'wss://' : 'ws://';
    let wsUrl = protocol + serverUrl.replace(/^(https?:)?\/\//i, '') + model + '/' + cameraId + '/stream/?token=' + authModule.token;
    return wsUrl;
  }

  async getArchiveUrl(cameraId: number, timeFrom: number) {
    try {
      const archive = await VmsService.vmsArchiveRetrieve(
        new Date(timeFrom * 1000).toISOString(),
        cameraId,
        this.resolution.height,
        this.resolution.width,
        this.settings.videoQuality
      );
      return archive.url;
    } catch (e) {
      console.error('video-player: playArchive error', e);
    }
    return '';
  }

  setTimelinePosition(time: number) {
    if (this.playerTimeline) {
      this.playerTimeline.setPosition(time);
      this.playerTimeline.autoOffset(time);
    }
    this.currentTime = time;
  }

  videoPositionChange(time: number) {
    this.setTimelinePosition(time);
  }

  play() {
    this.playerRender.play();
  }

  pause() {
    this.isLive = false;
    this.playerRender.pause();
  }

  // Based on https://ntechlab.atlassian.net/browse/FFSEC-2305
  // https://gitlab.int.ntl/ntech/universe/-/blob/ecf501a5334c5882cfb7f299db4008edf7de39f7/ffsecurity-ui/src/components/video-player/video-player.vue
  async playFromExternalVms() {
    try {
      // TODO: refactor this in future
      // use this.playerRender.realTimestamp
      // or just delete it
      // console.log('this.lastTimelineTime', this.lastTimelineTime);
      if (!this.lastTimelineTime) {
        this.lastTimelineTime = this.timeFrom;
        // console.log('evms inited!');
      }

      // Получаем ID интеграции
      const cameraObject = this.settings.camera;
      if (!cameraObject) {
        console.error('video-player: top5vms cameraObject not found!');
        return;
      }
      if (!cameraObject.external_vms) {
        console.warn('video-player: top5vms cameraObject.external_vms not found!');
        return;
      }
      const external_vms_id = cameraObject.external_vms;
      const camera_id = cameraObject.id;
      const time_from = new Date(this.lastTimelineTime * 1000).toISOString();
      const time_to = new Date((this.lastTimelineTime + 2 * 60 * 60) * 1000).toISOString();

      const filter: any = {
        camera: camera_id,
        time_from,
        time_to,
        start_time: this.lastTimelineTime
      };

      const config = configModule.config.external_vms;
      if (config.max_width) {
        filter.max_width = config.max_width;
      }
      if (config.max_height) {
        filter.max_height = config.max_height;
      }
      if (config.quality) {
        filter.quality = config.quality;
      }

      const archive: any = await __request({
        method: 'GET',
        path: `/external-vms/${external_vms_id}/archive/`,
        query: filter
      });

      this.wsUrl = archive.url;
      let external_link = archive.external_link;

      // send play command
      setTimeout(async () => {
        try {
          await __request({
            method: 'POST',
            path: `/external-vms/${external_vms_id}/play/`,
            query: {
              camera: camera_id,
              time_from,
              time_to,
              url: external_link
            }
          });
        } catch (e) {
          console.error('video-player: top5vms play error', e);
        }
      }, 10);
    } catch (e) {
      console.error('video-player: top5vms archive error', e);
    }
  }

  @Watch('playerState')
  @Debounce(500)
  playerStateWatcher(playerState: string) {
    // если не получилось проиграть с локальной ВМС, пытаемся с внешней
    if (this.oldState === TimeType.Archive && (playerState === 'closed' || playerState === 'error')) {
      this.oldState = TimeType.ExternalArchive;
      // console.log('playFromExternalVms!');
      this.playFromExternalVms();
    }
  }

  // Update player state when time changes
  async timelinePositionChange(time: number) {
    this.lastTimelineTime = time;
    if (this.isCameraRemoved) {
      console.warn('CameraRemoved');
      return;
    }

    // Get mode by time
    if (time < new Date().getTime() / 1000) {
      this.newState = TimeType.Archive;
    } else {
      this.newState = TimeType.Live;
    }

    // Connection was closed
    if (this.playerState === 'closed' || this.playerState === 'error') {
      this.oldState = TimeType.NotFound;
    }

    // Progress offline videos - live mode
    if (this.model === 'videos') {
      this.newState = TimeType.Live;
    }

    // need for debug
    // console.log(this.oldState, this.newState);

    // Live mode
    if (this.newState === TimeType.Live) {
      this.wsUrl = this.getLiveUrl(this.cameraId, this.model);
      this.positionStartTime = new Date().getTime() / 1000;
      this.isLive = true;
    } else {
      this.positionStartTime = 0;
      this.isLive = false;
    }

    // Archive mode
    if (this.newState === TimeType.Archive) {
      if (this.oldState === TimeType.Archive) {
        this.playerRender.seek(time);
      } else {
        this.wsUrl = '';
        this.wsUrl = await this.getArchiveUrl(this.cameraId, time);
      }
    }

    this.settings.canChangeQuality = this.newState === TimeType.Archive;
    this.settings.canChangeObjects = this.newState === TimeType.Live;

    this.oldState = this.newState;
  }

  async timelinePositionChangeForce(time: number) {
    await this.timelinePositionChange(time);
    this.playerTimeline && this.playerTimeline.autoOffsetForceNext();
  }

  async mounted() {
    this.attachPlayerContentResizeObserver();
    await this.settings.fillCameraInfoById(this.cameraId);

    if (this.settings.camera) {
      this.ptzButton = this.settings.camera.onvif_camera !== null;
    } else {
      if (this.model === 'cameras') {
        this.isCameraRemoved = true;
        this.settings.isCameraRemoved = false;
      }
    }

    // Let's try to play the video
    this.timelinePositionChangeForce(this.timeFrom);

    if (this.showTimeline && this.playerTimeline) {
      this.setTimelinePosition(this.timeFrom); // Set timeline cursor
      this.playerTimeline.updateObjectsWithDebounce(); // Load timeline Objects
    }
  }

  beforeUnmount() {
    this.detachPlayerContentResizeObserver();
  }

  thumbChanged({ src, css }: { src: string; css: any }) {
    this.thumbSrc = src;
    this.thumbCss = css;
  }

  exportTimeChange({ timeFrom, timeTo }: { timeFrom: number; timeTo: number }) {
    this.exportTimeFrom = timeFrom;
    this.exportTimeTo = timeTo;
  }

  @Watch('camera')
  cameraWatcher() {
    this.timelinePositionChange(new Date().getTime() / 1000 + 10);
  }

  // TODO: replace to @Watcher(videoState) in future
  videoSeeked() {
    this.playerTimeline && this.playerTimeline.autoOffsetForceNext();
  }

  get containerStyles() {
    return {
      top: this.container.top + 'px',
      left: this.container.left + 'px',
      width: this.container.width + 'px',
      height: this.container.height + 'px'
    };
  }

  resizeContainer(rect: DOMRect) {
    const scaleFactorH = rect.width / this.streamProps.width;
    const scaleFactorV = rect.height / this.streamProps.height;
    if (scaleFactorH * this.streamProps.height > rect.height) {
      this.container.top = 0;
      this.container.left = (this.streamProps.width * (scaleFactorH - scaleFactorV)) / 2;
      this.container.width = this.streamProps.width * scaleFactorV;
      this.container.height = this.streamProps.height * scaleFactorV;
      this.container.scale = scaleFactorV;
    } else {
      this.container.top = (this.streamProps.height * (scaleFactorV - scaleFactorH)) / 2;
      this.container.left = 0;
      this.container.width = this.streamProps.width * scaleFactorH;
      this.container.height = this.streamProps.height * scaleFactorH;
      this.container.scale = scaleFactorH;
    }
  }

  streamPropsChange(streamProps: IStreamProps) {
    this.streamProps = streamProps;
    isDefined(this.playerContent) && this.resizeContainer(this.playerContent.getBoundingClientRect());
  }

  toggleExportMode() {
    this.exportMode = !this.exportMode;
    if (this.exportMode && !this.isLive && !this.isPause) {
      this.pause();
    }
  }

  private attachPlayerContentResizeObserver(): void {
    this.$nextTick(() => this.observer.observe(this.playerContent));
  }

  private detachPlayerContentResizeObserver(): void {
    this.observer.disconnect();
  }
}
