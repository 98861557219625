export default {
  create_hooks: 'Новый вебхук',
  hooks_no_items: 'У вас нет ни одного вебхука',
  webhooks: 'вебхуки',
  webhook_title: 'Заголовок вебхука',
  batch_size: 'Количество сообщений в пакете',
  send_attempts: 'Количество попыток отправки',
  send_attempts_tip: '0 - не ограничено',
  filters: 'Фильтры',
  webhook_id: 'ID вебхука:',
  clipboard_success: 'Скопировано в буфер обмена',
  copy_id: 'Скопировать ID'
};
