import { cloneDeep } from 'lodash';
import { viewModelRepository } from '@/api/common';
import { CardTypesMap, ObjectsTypesMap } from '@/store/application/data.assets';
import { PageType, SearchPageState } from '@/store/application/page.definitions';
import { reactive } from 'vue';
import { CarCard, HumanCard } from '@/api';

export type CardsType = 'humans' | 'cars';
export type ObjectType = 'face' | 'body' | 'car';
type TogglePayload = { type: ObjectType; id: string };

type CardLooksLikeItem = {
  cluster: string | null;
  objects: string[];
};

type CardLooksLikeState = Record<ObjectType, CardLooksLikeItem>;

function looksLikeEntryToArray([key, value]: [string, CardLooksLikeItem]) {
  let result = [];
  if (value.objects.length) result.push(...value.objects.map((v: string) => `${key}object:${v}`));
  if (value.cluster) result.push(`${key}cluster:${value.cluster}`);
  return result;
}

const DefaultState: CardLooksLikeState = {
  face: { objects: [], cluster: null },
  body: { objects: [], cluster: null },
  car: { objects: [], cluster: null }
};

export class CardModule {
  public state: CardLooksLikeState = cloneDeep(DefaultState);
  public availableObjects: Record<string, boolean> = { face: false, body: false, car: false };

  private facesModule = viewModelRepository.getObjectsFacesListViewModel();
  private bodiesModule = viewModelRepository.getObjectsBodiesListViewModel();
  private carsModule = viewModelRepository.getObjectsCarsListViewModel();

  constructor(public pageState: SearchPageState, public card: HumanCard | CarCard) {}

  useAvailableObjects(v: Record<string, boolean>) {
    this.availableObjects = v;
  }

  apply() {
    if (this.hasChanges) {
      this.pageState.cardLooksLike = this.result;
    }
  }

  get hasChanges() {
    return (
      this.pageState.cardLooksLike?.length !== this.result.length ||
      this.pageState.cardLooksLike?.filter((v) => this.result.includes(v)).length !== this.result.length
    );
  }

  get result(): string[] {
    return Object.entries(this.state).map(looksLikeEntryToArray).flat();
  }

  get objectModules() {
    let modules = [];
    switch (this.pageState.cardType) {
      case CardTypesMap.Humans:
        this.availableObjects.face && modules.push(this.facesModule);
        this.availableObjects.body && modules.push(this.bodiesModule);
        break;
      case CardTypesMap.Cars:
        this.availableObjects.car && modules.push(this.carsModule);
        break;
      default:
        throw new Error(`Unknown cardType: "${this.pageState.cardType}"`);
    }

    return modules;
  }

  getSelectedObjects(objectType: ObjectType) {
    return this.state[objectType] ? this.state[objectType].objects : [];
  }

  getObjects(objectType: ObjectType) {
    switch (objectType) {
      case 'face':
        return this.facesModule.items;
      case 'body':
        return this.bodiesModule.items;
      case 'car':
        return this.carsModule.items;
      default:
        return [];
    }
  }

  async loadObjects() {
    let promises = [];
    for (let module of this.objectModules) {
      module.filter.current.card = [+this.card.id];
      module.filter.current.limit = 50;
      promises.push(module.get());
    }

    return Promise.allSettled(promises);
  }

  get objectsAllowedForPageState() {
    let objects: string[] = [];
    switch (this.pageState.cardType) {
      case 'humans':
        this.availableObjects.face && objects.push(ObjectsTypesMap.Faces);
        this.availableObjects.body && objects.push(ObjectsTypesMap.Bodies);
        break;
      case 'cars':
        this.availableObjects.car && objects.push(ObjectsTypesMap.Cars);
        break;
      default:
        throw new Error(`Unknown cardType: "${this.pageState.cardType}"`);
    }

    if (this.pageState.pageType == PageType.cases) {
      objects = objects.filter((object) => object === ObjectsTypesMap.Faces);
    }

    return objects;
  }

  deselectObjectByType(type: ObjectType) {
    this.state[type].objects = [];
  }

  selectObjectsByType(type: ObjectType) {
    this.deselectObjectByType(type);
    this.getObjects(type).forEach((item) => {
      this.state[type].objects.push(item.id);
    });
  }

  getClusterByType(type: ObjectType) {
    return this.card[`${type}_cluster` as keyof (HumanCard | CarCard)];
  }

  selectClusterByType(type: ObjectType) {
    const cluster = this.getClusterByType(type) as string;
    this.state[type].cluster = cluster || null;
  }

  toggleClusterByType(type: ObjectType) {
    const cluster = this.getClusterByType(type) as string;
    this.state[type].cluster = this.state[type].cluster ? null : cluster;
  }

  toggleObject({ type, id }: TogglePayload) {
    let objectsList = this.state[type].objects;
    let objectIndex = objectsList.indexOf(id);
    objectIndex === -1 ? objectsList.push(id) : objectsList.splice(objectIndex, 1);
  }

  reset() {
    this.state = cloneDeep(DefaultState);
  }

  public static create(pageState: SearchPageState, card: HumanCard | CarCard) {
    return reactive(new this(pageState, card));
  }
}
