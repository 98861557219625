import { getQueryString } from '@/definitions/services/helpers';
import { AuthGuardModule } from '@/store/application/auth-guard';
import { ExceptionsModule } from '@/store/application/exceptions';
import { NotificationsModule } from '@/store/application/notifications';
import { AuthModule } from '@/store/auth';
import { ConfigModule } from '@/store/config';
import { languageModule, LanguageModule } from '@/store/languages';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash';
import { axiosInstanceConfig } from './axiosInstanceConfig';
import { axiosBundleAdapter } from '@/definitions/services/AxiosBundleRequestAdapter';

abstract class ListResponseData<T> {
  results: T[] | null = null;
  next_page: string | null = null;
}

type StatisticResponseType = {
  count: number;
};

type ApiIdType = string | number;

type ListPromiseResponse<T> = Promise<ListResponseData<T>>;
type ItemPromiseResponse<T> = Promise<T>;
type DeletePromiseResponse = Promise<boolean>;
type StatisticResponse = Promise<StatisticResponseType>;

export abstract class DataService<T, F> {
  abstract getList(filters: F): ListPromiseResponse<T>;
  abstract get(id: ApiIdType): ItemPromiseResponse<T>;
  abstract getListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any>;
  abstract getItemListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any>;
  abstract getItemSomethingByAction(id: ApiIdType, action: string): ListPromiseResponse<any>;
  abstract getStatistics(filters: F): StatisticResponse;
  abstract createItemSomethingByAction(id: ApiIdType, action: string, data?: any, query?: any): ListPromiseResponse<any>;
  abstract create(data: Partial<T>): ItemPromiseResponse<T>;
  abstract update(id: ApiIdType, data: Partial<T>): ItemPromiseResponse<T>;
  abstract delete(id: ApiIdType): DeletePromiseResponse;
}

class DefaultHTTPService<T, F> extends DataService<T, F> {
  private axios: AxiosInstance;
  private _url: string;

  constructor(axios: AxiosInstance, url: string) {
    super();
    this.axios = axios;
    this._url = url;
  }

  get url() {
    return this._url;
  }

  getList(filters: Partial<F> | undefined): ListPromiseResponse<T> {
    return this.axios({ url: this.url, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  get(id: ApiIdType): ItemPromiseResponse<T> {
    return this.axios({ url: `${this.url}/${id}/`, method: 'get' }).then(this.adaptResult);
  }

  getListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${action}/`, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  getItemListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${id}/${action}/`, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  getStatistics(filters: Partial<F> | undefined): StatisticResponse {
    return this.axios({ url: `${this.url}/count/`, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  create(data: Partial<T>): ItemPromiseResponse<T> {
    return this.axios({ url: this.url, method: 'post', data }).then(this.adaptResult);
  }

  getItemSomethingByAction(id: ApiIdType, action: string): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${id}/${action}/`, method: 'get' }).then(this.adaptResult);
  }

  createItemSomethingByAction(id: ApiIdType, action: string, data?: any, query?: any): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${id}/${action}/`, params: query, method: 'post', data }).then(this.adaptResult);
  }

  update(id: ApiIdType, data: Partial<T>): ItemPromiseResponse<T> {
    return this.axios({ url: `${this.url}/${id}/`, method: 'patch', data }).then(this.adaptResult);
  }

  delete(id: ApiIdType): DeletePromiseResponse {
    return this.axios({ url: `${this.url}/${id}/`, method: 'delete' }).then((r) => r.status >= 200);
  }

  adaptResult(v: AxiosResponse<any>): any {
    return v.data;
  }

  cleanObject(i: any) {
    return _.pickBy(_.cloneDeep(i), (v) => !(v === '' || v === null));
  }
}

export class DataServiceFactory {
  private _services: { [P: string]: DataService<any, any> } = {};
  authModule?: AuthModule;
  configModule?: ConfigModule;
  languageModule?: LanguageModule;

  constructor(readonly guard: AuthGuardModule) {}

  getService<T = any, F = any>(url: string): DataService<T, F> {
    let result = this._services[url] || new DefaultHTTPService<T, F>(this.getAxiosInstance(), url);
    this._services[url] = result;
    return result as DataService<T, F>;
  }

  getAxiosInstance(): AxiosInstance {
    const instance = axios.create({
      paramsSerializer: getQueryString,
      adapter: axiosBundleAdapter.handleRequest
    });

    this.guard.inject(instance);

    instance.interceptors.request.use((config) => {
      const defaultHeaders: Record<string, any> = {
        'accept-language': this.languageModule?.locale || 'en-US',
        'x-uuid': this.authModule?.uuid
      };
      const authorization = this.authModule?.getAuthorizationValue();
      if (authorization) defaultHeaders.authorization = authorization;

      return {
        ...config,
        url: config.url?.replace(/\/\/\/?/, '/'), // todo: check
        baseURL: this.configModule?.serverUrl || '',
        headers: {
          ...defaultHeaders,
          ...config.headers
        }
      };
    });
    return instance;
  }
}

export const dataServiceFactory = new DataServiceFactory(createAuthGuardModule());
export const getService = dataServiceFactory.getService.bind(dataServiceFactory);
axiosInstanceConfig.setInstance(dataServiceFactory.getAxiosInstance());

function createAuthGuardModule() {
  const notifications = NotificationsModule.create();
  const exceptions = ExceptionsModule.create({ language: languageModule, notifications });
  return AuthGuardModule.create({ auth: null, exceptions: exceptions, router: null });
}
