
import { Options, Vue } from 'vue-class-component';
import { NMultitool, IMultitoolActionI18N, IMultitoolAction } from '@/uikit';
import { CameraGroup, WatchList } from '@/api';
import { NDropdown, NDropdownItem, NButton } from '@/uikit';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import { Prop } from 'vue-property-decorator';
import translateProps from '@/common/translate-props';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { DefaultModelAclResult, DefaultItemAclResult } from '@/store/acl';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

const ActionDeleteAllConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.DeleteAll,
  i18n_label: 'common.delete_all_selected',
  hidden: true
};

type IMultitoolItem = WatchList | CameraGroup;

@Options({
  name: 'BlocklistMultitool',
  components: { NButton, NMultitool, NDropdown, NDropdownItem, NDoubleButton }
})
export default class BlocklistMultitool extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly extraActions!: IMultitoolActionI18N[];

  @Prop({ type: Array, default: () => [] })
  readonly items!: IMultitoolItem[];

  @Prop({ type: Number, default: 0 })
  readonly changedCount!: number;

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: IMultitoolItem[];

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Boolean, default: true })
  readonly activable = true;

  @Prop({ type: Boolean, default: true })
  readonly updateEnabled = true;

  get isAllSelected() {
    return this.selectedItems?.length === this.items?.length;
  }

  get availableActions() {
    let actions = [] as IMultitoolActionI18N[];
    if (this.modelAcl.delete) actions.push(ActionDeleteAllConfig);
    actions.push(...this.extraActions);

    return actions.map((action) => translateProps(action, this.$t, 'f') as IMultitoolAction);
  }

  getAction(name: string) {
    return this.availableActions.find((action) => action.name === name);
  }

  handleClose() {
    this.$emit('action', ItemsActionNames.DeselectAll);
  }
}
