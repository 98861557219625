import { workspaceModule } from '@/store/application/workspace';
import { reactive } from 'vue';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { PageName, PageState, PageType } from '@/store/application/page.definitions';
import { startCase } from '@/common/filters';
import { viewModelRepository } from '@/api/common';
import { RouteLocation } from 'vue-router';
import { PageViewModel, parseQueryObjectToPageOptions } from '@/store/application/page.view.model';
import { getPageStateByName } from '@/store/application/page.state';

export class PageModule {
  pagesByTabMap: Record<string, any> = {};
  modulesByKeyMap: Record<string, any> = {};

  constructor() {}

  getPageStateByTab(name: PageName, tab: string): PageState {
    const newState = getPageStateByName(name);
    let state = workspaceModule.getItemByTab(tab) || newState;
    state.tab = tab;

    //@todo: Try to refactor settings
    if (state.pageName !== name) {
      state.pageName = newState.pageName;
      state.pageType = newState.pageType;
    }
    return state;
  }

  getModelsKey(state: PageState): string {
    let result = '';

    if (state.pageType === PageType.events) {
      result = `${state.pageType}_${state.objectType}_list`;
    } else if (state.pageType === PageType.episodes) {
      result = `${state.pageType}_${state.episodeType}_list`;
    } else if (state.pageType === PageType.cards) {
      result = `${state.pageType}_${state.cardType}_list`;
    } else if (state.pageType === PageType.clusters) {
      result = `${state.pageType}_${state.objectType}_list`;
    } else if (state.pageType === PageType.block_list) {
      result = 'device_blacklist_records_list';
    } else if (state.pageType === PageType.external_vms_cameras || state.pageType === PageType.external_detectors) {
      result = 'cameras_list';
    } else if (state.pageName === PageName.remote_monitoring_events) {
      result = 'puppeteer_remote_monitoring_events_list';
    } else {
      result = `${state.pageType}_list`;
      console.warn(`[pageModule] Use default getter module ${result} for ${state}`);
    }
    return startCase(result).replace(/ /g, '');
  }

  getCacheID(state: PageState) {
    return state.tab + '__' + this.getModelsKey(state);
  }

  getPageModule(state: PageState): ListViewModel<any, any> | ItemViewModel<any> {
    const modelName = this.getModelsKey(state);
    const cacheId = this.getCacheID(state);
    let module = this.modulesByKeyMap[cacheId] as unknown as ListViewModel<any, any>;
    // console.log(`[getPageModule] cache-id ${cacheId}, model ${modelName}, from cache ${!!module}`);

    if (!module) {
      const modelGetterName = `get${modelName}ViewModel`;
      try {
        module = (viewModelRepository as any)[modelGetterName]();
        this.setModuleOrdering(module, state.pageType);
        this.modulesByKeyMap[cacheId] = module;
      } catch (e) {
        console.warn(`[error] createLVM Cant create new model by viewModelRepository[${modelGetterName}]...`, e);
      }
    }
    return module;
  }

  setModuleOrdering(module: ListViewModel<any, any>, pageType: PageType) {
    if (pageType === 'events' || pageType === 'episodes' || pageType === 'clusters' || pageType === 'cards') {
      module.filter.current.ordering = '-id';
    }
  }

  getPageViewModel(route: RouteLocation) {
    const tab = String(route.query.tab);
    const page = this.pagesByTabMap[tab] || this.createPageViewModel(route);
    this.pagesByTabMap[tab] = page;
    return page;
  }

  createPageViewModel(route: RouteLocation) {
    const options = parseQueryObjectToPageOptions(route.query);
    options.name = route.path?.substring(1) as any;
    options.pageState = workspaceModule.getItemByTab(options.tab!);
    return new PageViewModel<any, any>(options as any);
  }

  getPageViewModelDirect(name: string, pageState = {}, tab: string) {
    let options: any = {};
    options.name = name;
    options.pageState = pageState;
    options.tab = tab;
    const page = new PageViewModel<any, any>(options as any);
    this.pagesByTabMap[tab] = page;
    return page;
  }
}

export const pageModule = reactive<PageModule>(new PageModule());
