import { IMenuItem } from '@/definitions/config/menu';

export const launcherItems: IMenuItem[] = [
  {
    icon: 'search',
    name: 'search',
    i18n: 'menu.search',
    i18n_description: 'menu.search_desc',
    path: '/search',
    meta: {
      permissions: [
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent',
        'ffsecurity.view_humancard',
        'ffsecurity.view_carcard'
      ]
    }
  },
  {
    icon: 'relations',
    name: 'relations',
    i18n: 'menu.relations',
    i18n_description: 'menu.relations_desc',
    path: '/interactions',
    meta: {
      permissions: ['ffsecurity.view_facecluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'video-wall',
    name: 'video_wall',
    i18n: 'menu.video_wall',
    i18n_description: 'menu.video_wall_desc',
    path: '/video-wall',
    meta: {
      permissions: ['ffsecurity.view_camera']
    }
  },
  {
    icon: 'cibr',
    name: 'cases',
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_desc',
    path: '/cases',
    meta: {
      permissions: ['ffsecurity.view_case']
    }
  },
  {
    icon: 'cibr',
    name: 'cibr',
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_desc',
    path: '/cibr',
    meta: {
      permissions: ['ffsecurity_incidents.view_case'],
      plugins: {
        incidents: true
      }
    }
  },
  {
    icon: 'external-search',
    name: 'external-search',
    i18n: 'menu.external_search',
    i18n_description: 'menu.external_search_desc',
    path: '/external-search',
    meta: {
      permissions: ['ffsecurity_puppeteer.view_searchrequest'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'remote-monitoring',
    name: 'remote_monitoring_events',
    i18n: 'menu.remote_monitoring_events',
    i18n_description: 'menu.remote_monitoring_events_desc',
    path: '/remote-monitoring-events',
    meta: {
      permissions: ['ffsecurity_puppeteer.view_remotemonitoringrecord'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'counters',
    name: 'counters',
    i18n: 'menu.counters',
    i18n_description: 'menu.counters_desc',
    path: '/counters',
    meta: {
      permissions: ['ffsecurity.view_counter']
    }
  },
  {
    icon: 'data-sources',
    name: 'data_sources',
    i18n: 'menu.data_sources',
    i18n_description: 'menu.data_sources_desc',
    path: '/data-sources',
    meta: {
      permissions: ['ffsecurity.view_camera', 'ffsecurity.view_videoarchive']
    }
  },
  {
    icon: 'episodes',
    name: 'events_episodes',
    i18n: 'menu.episodes_and_events',
    i18n_description: 'menu.episodes_and_events_desc',
    path: '/events',
    meta: {
      permissions: [
        'ffsecurity.view_humanepisode',
        'ffsecurity.view_carepisode',
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent'
      ]
    }
  },
  {
    icon: 'episodes',
    name: 'clusters',
    i18n: 'menu.clusters',
    i18n_description: 'menu.clusters_desc',
    path: '/clusters',
    meta: {
      permissions: ['ffsecurity.view_facecluster', 'ffsecurity.view_bodycluster', 'ffsecurity.view_carcluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'analytics',
    name: 'analytics',
    i18n: 'menu.analytics',
    i18n_description: 'menu.analytics_desc',
    path: '/analytics',
    meta: {
      permissions: ['ffsecurity.view_faceevent']
    }
  },
  {
    icon: 'settings',
    name: 'settings',
    i18n: 'menu.settings',
    i18n_description: 'menu.settings_desc',
    path: '/settings',
    meta: {}
  },
  {
    icon: 'cards',
    name: 'cards',
    i18n: 'menu.cards',
    i18n_description: 'menu.cards_desc',
    path: '/cards',
    meta: {
      permissions: ['ffsecurity.view_humancard', 'ffsecurity.view_carcard']
    }
  },
  {
    icon: 'areas',
    name: 'areas',
    i18n: 'menu.areas',
    i18n_description: 'menu.areas_desc',
    path: '/areas',
    meta: {
      permissions: ['ffsecurity.view_area']
    }
  },
  {
    icon: 'video-player',
    name: 'player',
    i18n: 'menu.player',
    i18n_description: 'menu.player_desc',
    path: '/video-player',
    meta: {}
  },
  {
    icon: 'verify',
    name: 'verify',
    i18n: 'menu.verify',
    i18n_description: 'menu.verify_desc',
    path: '/verify',
    meta: {}
  },
  {
    icon: 'audit-logs',
    name: 'audit-logs',
    i18n: 'menu.audit_logs',
    i18n_description: 'menu.audit_logs',
    path: '/audit_logs',
    meta: {
      permissions: ['ffsecurity.view_auditlog']
    }
  },
  {
    icon: 'reports',
    name: 'reports',
    i18n: 'menu.reports',
    i18n_description: 'menu.reports_desc',
    path: '/reports',
    meta: {
      permissions: ['ffsecurity.view_report']
    }
  }
];

export const additionalItems: IMenuItem[] = [
  {
    icon: 'cibr',
    name: 'case',
    path: '/case',
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_description',
    meta: {}
  }
];
