import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { commonFilterSchemaModule } from '@/store/filters/CommonFilterSchemaModule';
import { attributeFilterSchemaModule } from '@/store/filters/AttributeFilterSchemaModule';
import { OptionalIFormLayout, SimpleFilterOptions } from '@/store/filters/types';

export function getClusterFiltersBuilder(options: SimpleFilterOptions) {
  const availableObjects = dataAssetsModule.availableObjectsTypedMap;
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getHasCard(options),
    commonFilterSchemaModule.getMatchedLists(options),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCameras(options),
    commonFilterSchemaModule.getCardSelect(options),
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getFirstClusterEventDateTimeRange(options),
    commonFilterSchemaModule.getClusterEventDateTimeRange(options),
    commonFilterSchemaModule.getId(options)
    /* commonFilterSchemaModule.getHasCaseParticipants(options) @todor: make allowed modules by user & config provider */
  ];

  const faceFeatures: OptionalIFormLayout[] = availableObjects.face ? attributeFilterSchemaModule.getFaceAttributes(options) : [];
  const bodyFeatures: OptionalIFormLayout[] = availableObjects.body ? attributeFilterSchemaModule.getBodyAttributes(options) : [];
  const carFeatures: OptionalIFormLayout[] = availableObjects.car ? attributeFilterSchemaModule.getCarAttributes(options) : [];

  function getFilterByType(pageType: string, objectType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [];
    switch (objectType) {
      case 'faces':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...faceFeatures];
        break;
      case 'bodies':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...bodyFeatures];
        break;
      case 'cars':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...carFeatures];
        break;
    }
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}

export function getClusterContactsFiltersBuilder(options: SimpleFilterOptions) {
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getUseLastEvent(options),
    commonFilterSchemaModule.getContactThreshold(options),
  ];

  function getFilterByType(pageType: string, objectType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [
      ...commonAll,
      ...getClusterFiltersBuilder(options).getFilterByType(pageType, objectType)
    ];
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}