import { viewModelRepository } from '@/api/common';
import { PageName, PageState } from '@/store/application/page.definitions';
import { reactive } from 'vue';

class TabLabelModule {
  private store: Record<string, string> = {};

  private getStoreId(state: PageState) {
    let { pageName, id } = state;
    return `${pageName}_${id}`;
  }

  private getModule(pageName: string) {
    switch (pageName) {
      case PageName.case:
        return viewModelRepository.getCasesItemViewModel();
      default:
        return false;
    }
  }

  private async loadItemLabel(state: PageState) {
    let module = this.getModule(state.pageName);
    if (module && state.id) {
      await module.get(state.id);
      return module.item?.name || '';
    }
    return '';
  }

  get(pageState: PageState): string {
    const storeId = this.getStoreId(pageState);
    if (typeof this.store[storeId] !== 'undefined') return this.store[storeId];
    this.store[storeId] = '';
    this.loadItemLabel(pageState).then((label) => {
      this.store[storeId] = label;
    });
    return String(pageState.id || '');
  }
}

export const tabLabelModule = reactive(new TabLabelModule());
