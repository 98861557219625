
import { Options, Vue } from 'vue-class-component';
import { NMultitool, IMultitoolActionI18N, IMultitoolAction } from '@/uikit';
import { CameraGroup, WatchList } from '@/api';
import { NDropdown, NDropdownItem, NButton } from '@/uikit';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import { Prop } from 'vue-property-decorator';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { DefaultModelAclResult, DefaultItemAclResult } from '@/store/acl';
import { actionsModule } from '@/store/data/ActionsModule';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

type IMultitoolItem = WatchList | CameraGroup;

@Options({
  name: 'SettingsMultitool',
  components: { NButton, NMultitool, NDropdown, NDropdownItem, NDoubleButton }
})
export default class SettingsMultitool extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly extraActions!: IMultitoolActionI18N[];

  @Prop({ type: Array, default: () => [] })
  readonly items!: IMultitoolItem[];

  @Prop({ type: Number, default: 0 })
  readonly changedCount!: number;

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: IMultitoolItem[];

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Boolean, default: true })
  readonly activable = true;

  @Prop({ type: Boolean, default: true })
  readonly updateEnabled = true;

  @Prop({ type: Boolean, default: false })
  readonly processable = false;

  @Prop({ type: Boolean, default: true })
  readonly showSidebar = true;

  @Prop({ type: Boolean, default: false })
  readonly hasCurrentChanges = false;

  @Prop({ type: Boolean, default: false })
  readonly caseStatusEnabled = false;

  @Prop({ type: Boolean, default: false })
  readonly isWatchlistPage = false;

  @Prop({ type: Boolean, default: true })
  readonly editMode = true;

  get isAllSelected() {
    return this.selectedItems?.length >= this.items?.length;
  }

  get actionSaveAll(): IMultitoolAction | null {
    let action = this.getAction(ItemsActionNames.SaveAll);
    return action ? { ...action, hidden: this.changedCount === 0, counter: this.changedCount } : null;
  }

  get actionSaveCurrent(): IMultitoolAction | null {
    return this.hasCurrentChanges && this.currentItemAcl.update ? { name: ItemsActionNames.Save, label: this.$t('common.save_current'), hidden: true } : null;
  }

  get availableActions() {
    return actionsModule
      .getMultipleItemsActions(this.modelAcl, {
        updateSupported: this.updateEnabled,
        hasChanges: this.changedCount > 0,
        hasActive: this.activable,
        isArchivable: this.caseStatusEnabled,
        hasProcess: this.processable,
        hasStopProcess: this.processable,
        hasDeleteCard: this.isWatchlistPage
      })
      .map(actionsModule.computeActionByName);
  }

  getAction(name: string) {
    return this.availableActions.find((action) => action.name === name);
  }

  handleClose() {
    this.$emit('action', ItemsActionNames.DeselectAll);
  }
}
