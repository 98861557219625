/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AreasFilters } from './AreasFilters';
import { BodyEventsFilters } from './BodyEventsFilters';
import { CarEpisodesFilters } from './CarEpisodesFilters';
import { CarEventsFilters } from './CarEventsFilters';
import { CounterFilters } from './CounterFilters';
import { FaceEventsFilters } from './FaceEventsFilters';
import { HumanEpisodesFilters } from './HumanEpisodesFilters';
import { TriggerEventFilters } from './TriggerEventFilters';
export type WebhookFilters = {
    face_events?: FaceEventsFilters;
    body_events?: BodyEventsFilters;
    car_events?: CarEventsFilters;
    human_episodes?: HumanEpisodesFilters;
    car_episodes?: CarEpisodesFilters;
    counters?: CounterFilters;
    areas?: AreasFilters;
    trigger_events?: TriggerEventFilters;
};
export const EmptyWebhookFilters: WebhookFilters = {
    face_events: undefined,
    body_events: undefined,
    car_events: undefined,
    human_episodes: undefined,
    car_episodes: undefined,
    counters: undefined,
    areas: undefined,
    trigger_events: undefined,
};