
import NPageIcon from '@/uikit/page-icon/NPageIcon.vue';
import NInput from '@/uikit/input/NInput.vue';
import { MenuItem } from '@/uikit/launchpad/NLaunchpad.vue';

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'NLauncher',
  components: { NInput, NPageIcon }
})
export default class NLauncher extends Vue {
  private inputText = '';

  @Prop({ type: Array, required: true })
  items!: MenuItem[];

  getItemClasses(item: MenuItem): Record<string, boolean> {
    const enabled = this.inputText ? (item.title + item.description).toLocaleLowerCase()?.indexOf(this.inputText.toLocaleLowerCase()) > -1 : true;
    return {
      'n-launcher__item': true,
      'n-launcher__item_disabled': !enabled
    };
  }

  generateId(path: string) {
    return `launcher.${path.replace('/', '')}`;
  }
}
