
import { Options, Vue } from 'vue-class-component';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import NButtonGroup, { NButtonGroupItem } from '@/uikit/buttons/NButtonGroup.vue';
import DataSourcesDevices, { IDataSourceDevice } from '@/pages/data-sources/AddDevices/DataSourcesDevices.vue';
import DataSourcesOnvifDiscovery from '@/pages/data-sources/AddDevices/DataSourcesOnvif.vue';
import DataSourcesUploadNext from '@/pages/data-sources/AddDevices/DataSourcesUploadNext.vue';
import DataSourcesExternalDetectors from '@/pages/data-sources/AddDevices/DataSourcesExternalDetectors.vue';
import DataSourcesDevicesNext from '@/pages/data-sources/AddDevices/DataSourcesDevicesNext.vue';
import { Prop, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import SelectVideoForm from '@/pages/videos/SelectVideoForm.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import ParticipantItem from '@/components/participants/ParticipantItem.vue';
import DataSourcesOnvifNext from '@/pages/data-sources/AddDevices/DataSourcesOnvifNext.vue';
import { aclModule } from '@/store/acl';

@Options({
  name: 'AddNewWizard',
  components: {
    SelectVideoForm,
    ParticipantItem,
    NButton,
    NModalWindow,
    DataSourcesOnvifNext,
    NIcon,
    DataSourcesDevicesNext,
    DataSourcesExternalDetectors,
    DataSourcesUploadNext,
    DataSourcesOnvifDiscovery,
    DataSourcesDevices,
    NButtonGroup,
    PageContentLayout
  },
  emits: ['upload']
})
export default class AddNewWizard extends Vue {
  @Prop({ type: String, default: 'cameras' })
  modelValue!: string;

  localTab = '';
  currentStep = 1; // TODO use route push history
  currentData: IDataSourceDevice[] = [];

  get currentTab() {
    return this.localTab || this.modelValue;
  }

  set currentTab(val: string) {
    if (val !== 'onvif_discovery') {
      this.$emit('update:modelValue', val);
    }
    this.localTab = val;
  }

  get tabItems() {
    const items = [
      {
        name: 'cameras',
        label: this.$t('ds.tabs.cameras', 'f'),
        permissions: 'ffsecurity.add_camera'
      },
      {
        name: 'onvif_discovery',
        label: this.$t('ds.tabs.onvif_discovery', 'f'),
        permissions: 'ffsecurity.add_camera'
      },
      {
        name: 'videos',
        label: this.$t('ds.tabs.videos', 'f'),
        permissions: 'ffsecurity.add_videoarchive'
      },
      {
        name: 'external_detectors',
        label: this.$t('ds.tabs.external_detectors', 'f'),
        permissions: 'ffsecurity.add_camera'
      }
    ];

    return items.filter((v) => aclModule.getAccess(v.permissions));
  }

  prevStep() {
    this.currentStep--;
  }

  nextStep(data: IDataSourceDevice[]) {
    this.currentStep++;
    this.currentData = data;
  }

  uploadHandler(v: any) {
    this.$emit('upload', v);
  }

  @Watch('pageType')
  pageTypeWatcher(page: string) {
    this.currentStep = 1;
  }

  @Watch('modelValue')
  currentTabWatcher(page: string) {
    this.currentStep = 1;
  }
}
