
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NLoadingDots from '@/uikit/loading/NLoadingDots.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NCounter from '@/uikit/counters/NCounter.vue';

export enum NButtonSize {
  Big = 'big',
  Normal = 'normal'
}

export enum NButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Text = 'text',
  Link = 'link'
}

@Options({
  name: 'NButtonSimple',
  components: { NCounter, NIcon, NLoadingDots }
})
export default class NButtonSimple extends Vue {
  @Prop({ type: String, default: NButtonType.Primary })
  readonly type!: NButtonType;

  @Prop({ type: String, default: 'button' })
  readonly nativeType!: string;

  @Prop({ type: String, default: NButtonSize.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: String })
  readonly icon?: string;

  @Prop({ type: String })
  readonly color?: string;

  @Prop({ type: String })
  readonly suffixIcon?: string;

  @Prop({ type: [Number, String] })
  readonly counter?: number | string;

  @Prop({ type: Boolean, default: false })
  readonly round!: false;

  @Prop({ type: Boolean, default: false })
  readonly loading!: false;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly selected!: false;

  @Prop({ type: String, default: '' })
  readonly label?: string;

  get labelClassMap() {
    return {
      [NButtonSize.Big]: 'control-l',
      [NButtonSize.Normal]: 'control-m'
    };
  }

  get counterEnabled() {
    return this.counter !== undefined;
  }

  hasLabel = false;

  get nodes() {
    return (this.$slots?.default || []).length;
  }

  // todo: replace this method to CSS combination :has & :not & :empty when :has will be implemented in browsers.
  //  or change paddings to use empty() on prefix & suffix
  getHasLabel() {
    if (this.label) return true;
    if (this.$slots.default) {
      let nodes = this.$slots.default();
      for (let i in nodes) {
        const node = nodes[i];
        if (node && node.children) {
          return true;
        }
      }
    }
    return false;
  }

  get cssClass() {
    const root = 'n-button-simple';
    return {
      [root]: true,
      [`${root}_round`]: this.round,
      [`${root}_disabled`]: this.disabled,
      [`${root}_selected`]: this.selected,
      [`${root}_loading`]: this.loading,
      [`${root}__pl_low`]: this.icon || !this.hasLabel,
      [`${root}__pr_low`]: this.counterEnabled || this.suffixIcon || !this.hasLabel,
      [`${root}__size_${this.size}`]: this.size,
      [`${root}__type_${this.type}`]: this.type,
      [this.labelClassMap[this.size || NButtonSize.Normal]]: this.size
    };
  }

  mounted() {
    // console.dir(this.$props);
    this.hasLabel = this.getHasLabel();
  }
}
