
import { ClusterEventView } from '@/components/clusters/adapter';
import CameraLabelsV2 from '@/components/common/CameraLabelsV2.vue';
import Features from '@/components/common/Features.vue';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import NButton from '@/uikit/buttons/NButton.vue';
import { EventDetails, IThumbnailAction, ThumbnailPositions } from '@/uikit/thumbnail';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import isNull from 'lodash/isNull';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'ClusterEventItem',
  components: { CameraLabelsV2, NButton, NThumbnail, Features }
})
export default class ClusterEventItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: ClusterEventView;

  @Prop({ type: String, required: true })
  readonly objectType!: string;

  defaultAction = EventDetails.ShowFullScreen;

  get actions() {
    const actions: IThumbnailAction[] = [
      { icon: 'trash', name: ItemsActionNames.Delete, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: ItemsActionNames.ShowFullScreen, position: ThumbnailPositions.BottomLeft }
    ];
    return actions;
  }

  get hasPlay() {
    return configModule.config.vms?.enabled;
  }

  get camerasIds() {
    return isNull(this.item.camera) ? [] : [this.item.camera];
  }

  get cameraGroupsIds() {
    return isNull(this.item.camera_groups) ? [] : this.item.camera_groups;
  }

  showEpisode(id: number) {
    const type = dataAssetsModule.getEpisodeTypeByObjectType(this.objectType);
    actionHandler.run(ItemsActionNames.ShowItem, { type: `episodes_${type}`, rawItem: id });
  }

  showPlayer(cameraId: number) {
    const timeFrom = new Date(this.item.created_date).getTime() / 1000;
    this.$videoPlayer.playArchive(cameraId, timeFrom - 3);
  }

  handleCameraAction(action: ItemsActionName, payload: any) {
    switch (action) {
      case ItemsActionNames.ShowPlayer:
        this.showPlayer(payload);
        break;
      default:
        actionHandler.run(action, payload);
    }
  }
}
