
import { defineComponent, PropType, toRefs } from 'vue';
import { NButton } from '@/uikit';
import { ScaleOptions } from '@/uikit/image-viewer/types';

// todo: implement Zoom component
export default defineComponent({
  name: 'NImageViewerZoom',
  components: { NButton },
  props: {
    modelValue: { type: Number, required: true },
    options: { type: Object as PropType<ScaleOptions>, required: true }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function zoomIn() {
      emitUpdate(props.options.fill);
    }

    function zoomOut() {
      emitUpdate(props.options.default);
    }

    function emitUpdate(value: number) {
      emit('update:modelValue', value);
    }

    return {
      ...toRefs(props),
      zoomIn,
      zoomOut
    };
  }
});
