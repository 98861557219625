
import { CarCardAttachment, HumanCardAttachment } from '@/api';
import { CarCard } from '@/api/models/CarCard';
import { HumanCard } from '@/api/models/HumanCard';
import MoreActions from '@/components/common/MoreActions.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import { ListViewModel } from '@/definitions/view-models';
import MetafieldListItem from '@/pages/cards/content/MetafieldListItem.vue';
import { monitoringModule } from '@/pages/cards/monitoring/MonitoringModule';
import { CardsMultiToSingle, CardTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { actionsModule } from '@/store/data/ActionsModule';
import { AttachmentModule } from '@/store/data/AttachmentModule';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { IMultitoolAction, NButton, NIcon } from '@/uikit';
import { NAttachment, NAttachments } from '@/uikit/NAttachments';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  components: {
    MoreActions,
    MetafieldListItem,
    NAttachments,
    NButton,
    NIcon,
    WatchListsGroup
  },
  emits: ['click-on-image']
})
export default class CardItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: String, required: true })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: Boolean, default: false })
  showShortComment!: boolean;

  @Prop({ type: Boolean, default: true })
  hoverable!: boolean;

  @Prop({ type: Boolean, default: true })
  selectable!: boolean;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: Boolean, default: true })
  showMonitoring!: boolean;

  @Prop({ type: Boolean, default: false })
  showFullframe!: boolean;

  @Prop({ type: Boolean, default: true })
  showMore!: boolean;

  @Prop({ type: Number, default: 0 })
  confidence!: number;

  @Prop({ type: String, default: '' })
  textComment!: string;

  @Prop({ type: Object })
  modelAcl!: any;

  objectsVM: ListViewModel<any, any> | null = null;

  @Watch('displayType')
  initDisplayTypeAssets() {
    if (this.displayType === 'full') {
      this.attachmentModule.loadAttachments();
    }
  }

  get showName() {
    if (this.isFull) {
      return configModule.config.human_card?.options?.list?.name !== false;
    } else {
      return true;
    }
  }

  get testMetaItem() {
    return Object.assign(this.item, { meta: { meta_surname: 'Timofeev', meta_name: 'Vasily', meta_birthday: '06.10.1981' } });
  }

  get attachmentModule() {
    return new AttachmentModule({
      url: `/${CardsMultiToSingle[this.type]}-card-attachments/`,
      source_id: this.item.id,
      source_form_field_name: 'card',
      source_filter_name: 'card'
    });
  }

  get isFull(): boolean {
    return this.displayType === 'full';
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get isPuppeteerEnabled() {
    return this.type === CardTypesMap.Humans && this.monitoringModule.isPuppeteerEnabled;
  }

  get isOnMonitoring() {
    return this.monitoringModule.loaded && !!this.monitoringModule.getItemsByCardId(this.item.id).length;
  }

  get selectButtonType(): string {
    return this.selected ? 'primary' : 'secondary';
  }

  get unknownIcon(): string {
    return this.type === 'cars' ? 'car' : 'person';
  }

  get autoCard(): boolean {
    const commonCard = this.item as any;
    return !this.item.filled && (commonCard.face_cluster || commonCard.body_cluster || commonCard.car_cluster);
  }

  get cardName(): string {
    return this.autoCard ? this.$t('cards.unknown', 'f') : this.item.name;
  }

  get thumbnail(): string | null {
    const hasItems = this.objectsVM?.items.length;
    return hasItems ? this.objectsVM?.items[0]?.thumbnail : null;
  }

  get itemActions(): IMultitoolAction[] {
    return this.modelAcl
      ? actionsModule
          .getItemActions(this.modelAcl, null, {
            hasSearch: true,
            hasCardFilterEvent: true,
            hasActive: true,
            currentActive: this.item.active,
            hasPuppeteer: true,
            hasPuppeteerMonitoringRecord: this.isOnMonitoring
          })
          .map(actionsModule.computeActionByName)
      : [];
  }

  get attachments(): ReadonlyArray<NAttachment> {
    return (this.attachmentModule.module.items as ReadonlyArray<CarCardAttachment | HumanCardAttachment>).map<NAttachment>((attachment) => ({
      file: attachment.file,
      id: attachment.id,
      name: attachment.name ?? '',
      progress: 100,
      size: attachment.size,
      status: 'done'
    }));
  }

  @Watch('item.id', { immediate: true })
  load(id: number) {
    if (!id) return;
    const objectType = dataAssetsModule.getObjectTypeByCardType(this.type);
    this.objectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.item.id, limit: 1, type: objectType, ordering: 'id' });
    this.initDisplayTypeAssets();
  }

  toggleSelect() {
    this.$emit('select', { id: this.item.id, selected: !this.selected });
  }

  thumbnailClickHandler(event: MouseEvent) {
    this.$emit('click-on-image');
    if (this.showFullframe) {
      event.stopPropagation();
      this.$photoViewer.show((this.objectsVM?.items as any).map(this.adaptObjectToEvent));
    }
  }

  adaptObjectToEvent(v: any): any {
    return {
      id: v.id,
      thumbnail: v.thumbnail,
      fullframe: v.source_photo,
      bbox: v
    };
  }
}
