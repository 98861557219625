
import type { ExternalVms } from '@/api';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SettingsMultitool from '@/components/common/SettingsMultitool.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import enrichExternalVms from '@/pages/external-vms/enrich-external-vms';
import ExternalVMSStatus from '@/pages/external-vms/ExternalVmsStatus.vue';
import { EnrichedExternalVms } from '@/pages/external-vms/types';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { GlobalEvent } from '@/store/global-event/types';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { NTableColumn } from '@/uikit/NTable';
import NTable from '@/uikit/NTable/NTable.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import NTableOutdated from '@/uikit/table/NTable.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actionsModule } from '@/store/data/ActionsModule';
import { aclModule } from '@/store/acl';
import { ExternalVmsFilter } from '@/api/models/ExternalVmsFilter';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import { IModelAclResult } from '@/store/acl/types';

@Options({
  components: {
    ActionsDropdown,
    NForm,
    NInfiniteScroll,
    NLoadingCircle,
    NTable,
    NTableOutdated,
    NTabs,
    SettingsBar,
    SettingsMultitool,
    SettingsPageLayout,
    SidebarHeader,
    SidebarTab
  }
})
export default class ExternalVmsPage extends Vue {
  loading = false;
  enrichedItems: EnrichedExternalVms[] = [];

  get computedVMSItems() {
    return this.items.map((v: ExternalVms) => {
      const enrichedItem = this.enrichedItems.find((i) => i.id === v.id);
      return enrichedItem || v;
    });
  }

  get selectedEnrichedItems() {
    return this.enrichedItems.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.ExternalVms).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get sidebarType() {
    return MultisidebarItemTypes.ExternalVms;
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get state(): PageState {
    const tab = 'external-vms';
    return pageModule.getPageStateByTab(PageName.external_vms, tab);
  }

  get items(): ExternalVms[] {
    return this.module.items;
  }

  get columns(): NTableColumn<EnrichedExternalVms>[] {
    return [
      {
        width: 150,
        head: this.$t('external_vms.name'),
        body: ({ model }) => {
          const props = { accent: true, clickable: true, modelValue: model.verbose_name, onClick: this.handleNameClick.bind(this, model) };
          return <SimpleText {...props} />;
        }
      },
      {
        width: 150,
        head: this.$t('external_vms.vms'),
        body: ({ model }) => {
          const props = { modelValue: model.name };
          return <SimpleText {...props} />;
        }
      },
      {
        width: 70,
        head: this.$t('external_vms.version'),
        body: ({ model }) => {
          const props = { modelValue: model.version };
          return <SimpleText {...props} />;
        }
      },
      {
        width: 'minmax(100px, 1fr)',
        head: this.$t('external_vms.ip_address'),
        body: ({ model }) => {
          const props = { modelValue: model.options?.server_api_url };
          return <SimpleText {...props} />;
        }
      },
      {
        width: 'minmax(100px, 1fr)',
        head: this.$t('external_vms.status'),
        body: ({ model }) => {
          const props = { modelValue: model.health_status };
          return <ExternalVMSStatus {...props} />;
        }
      },
      {
        width: 150,
        head: this.$t('external_vms.created'),
        body: ({ model }) => <NDateTimeLabel modelValue={model.created_date} multiline={false} size={'xs'} />
      },
      {
        width: 50,
        head: '',
        body: ({ model }) => {
          const dropdownProps = {
            actions: this.actions,
            placement: 'left-start',
            onAction: (action: ItemsActionName) => this.actionHandler(action, model)
          };
          return (
            <div class="datasource-camera-table__actions-cell">
              <ActionsDropdown class="datasource-camera-table__actions" {...dropdownProps} />
            </div>
          );
        }
      }
    ];
  }

  get modelAcl() {
    this.module.aclModelName = 'externalvms';
    return aclModule.getModelAcl<ExternalVms, ExternalVmsFilter>(this.module);
  }

  get actions() {
    return actionsModule
      .getItemActions(this.modelAcl, null, {
        hasDelete: true
      })
      .map(actionsModule.computeActionByName);
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === MultisidebarItemTypes.ExternalVms ? globalEventModule.current : null;
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.load();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  actionHandler(v: ItemsActionName, rawItem: ExternalVms) {
    actionHandler.run(v, { type: MultisidebarItemTypes.ExternalVms, rawItem });
  }

  handleSearch(query: string) {
    if (this.module.filter.current.name !== query) {
      this.module.filter.current.name = query;
      this.module.get();
    }
  }

  handleCreate() {
    this.sidebarModule.addNewItemByType(this.sidebarType);
  }

  handleSelect(selectedChanges: EnrichedExternalVms[]) {
    selectedChanges.forEach((v) => {
      actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
    });
  }

  handleNameClick(model: EnrichedExternalVms) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.ExternalVms, rawItem: model });
  }

  async load() {
    this.loading = true;
    try {
      await this.module.get();
      this.enrichedItems = [];
      await this.enrichVmsItems();
    } finally {
      this.loading = false;
    }
  }

  async append(): Promise<void> {
    this.loading = true;
    try {
      await this.module.append();
      await this.enrichVmsItems();
    } finally {
      this.loading = false;
    }
  }

  async enrichVmsItems(): Promise<void> {
    const items = this.items.slice(this.enrichedItems.length);
    this.enrichedItems = [...this.enrichedItems, ...(await enrichExternalVms(items))];
  }

  mounted() {
    this.load();
  }
}
