
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

// TODO: Выпилить список размеров и сделать просто поле задающее css width:90px 100% 50vh etc;
const ModalWindowStartZIndex = 1000;

export enum NModalWindowSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Custom = 'custom'
}

export enum NModalWindowFooterType {
  Basic = 'basic',
  Outlined = 'outlined'
}

@Options({
  name: 'NModalWindow',
  components: { NButton },
  emits: ['close']
})
export default class NModalWindow extends Vue {
  @Prop({ type: String, default: NModalWindowSize.Medium })
  readonly size!: NModalWindowSize;

  @Prop({ type: String, default: '' })
  readonly customSize!: '';

  @Prop({ type: String, default: '' })
  readonly label!: '';

  @Prop({ type: Boolean, default: true })
  readonly insertIntoBody!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly layout!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly display!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly closeIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeDisabled = false;

  @Prop({ type: Boolean, default: true })
  readonly showHeader!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasMargins = true;

  @Prop({ type: String, default: NModalWindowFooterType.Basic })
  readonly footerType!: NModalWindowFooterType;

  @Prop({ type: Array })
  readonly stackPlugs?: [];

  @Prop({ type: String, default: '' })
  readonly className!: '';

  zIndex = ModalWindowStartZIndex;

  getElement() {
    return this.$refs.modalWindow;
  }

  get cssClass() {
    const base = 'n-modal-window';
    let size = this.size;
    if (this.customSize) {
      size = NModalWindowSize.Custom;
    }
    return {
      [base]: true,
      [`${base}__size_${size}`]: size,
      [`${base}_layout`]: this.layout,
      [`${base}_margins`]: this.hasMargins,
      [`${base}__footer-type_${this.footerType}`]: this.footerType,
      [this.className]: true
    };
  }

  get modalWindow(): HTMLDivElement | null {
    return (this.$refs.modal_window as HTMLDivElement) || null;
  }

  get customSizeCss() {
    if (this.customSize) {
      return {
        width: this.customSize
      };
    }
    return {};
  }

  get headerDisplay() {
    return this.$slots.header || this.label;
  }

  get contentDisplay() {
    return this.$slots.content || !this.headerDisplay;
  }

  closeHandler() {
    if (!this.closeDisabled) {
      this.$emit('close');
    }
  }

  updateZIndex() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.zIndex = (window.__modal_window_z_index || ModalWindowStartZIndex) + 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.__modal_window_z_index = this.zIndex;
  }

  mounted() {
    this.updateZIndex();
    this.modalWindow?.focus();
  }
}
