
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { NButton } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PolygonDrawTool from '@/components/polygon-draw/PolygonDrawTool.vue';

@Options({
  name: 'CameraZonesHeader',
  components: { PolygonDrawTool, NButtonGroup, NButton }
})
export default class CameraZonesHeader extends Vue {
  @Prop({ type: String, required: true })
  readonly activeTab!: string;

  @Prop({ type: Array, required: true })
  readonly items!: [];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  fullscreenEnabled = false;

  fullscreenToggle() {
    this.fullscreenEnabled = !this.fullscreenEnabled;
  }

  actionHandler(action: string) {
    this.$emit('action', action);
  }

  changeTab(value: string) {
    this.$emit('change', value);
  }
}
