import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0af30ae3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "viewer" }
const _hoisted_2 = {
  key: 0,
  class: "viewer__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageViewer = _resolveComponent("ImageViewer")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_ImageViewer, {
      key: _ctx.detectionModule.imageUrl,
      src: _ctx.detectionModule.imageUrl,
      bboxes: _ctx.selectableBboxes,
      bboxSelectable: true,
      actions: _ctx.actions,
      onSelectBbox: _ctx.selectBbox,
      onClose: _ctx.closeHandler
    }, null, 8, ["src", "bboxes", "actions", "onSelectBbox", "onClose"])),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NLoadingCircle)
        ]))
      : _createCommentVNode("", true)
  ]))
}