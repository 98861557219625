
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { NBaseBar, NButton, NDateTimeLabel } from '@/uikit';
import { VideoArchive } from '@/api';
import translateProps from '@/common/translate-props';
import NSelect from '@/uikit/select/NSelect.vue';
import ProcessingVideoConfirm from '@/components/data-source/ProcessingVideoConfirm.vue';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import { PageName, PageType } from '@/store/application/page.definitions';
import router from '@/router';
import { RouterModule } from '@/store/router';
import { CardTypeMap, EpisodesSingleToMulti } from '@/store/application/data.assets';
import { aclModule } from '@/store/acl';

const PlaySpeedItems = [
  { value: -1, i18n_label: 'videos.maximum' },
  { value: 0.5, label: '0.5x' },
  { value: 1, label: '1x' },
  { value: 2, label: '2x' },
  { value: 5, label: '5x' },
  { value: 10, label: '10x' }
];

@Options({
  name: 'VideoProcessPreviewBar',
  components: { ProcessingVideoConfirm, NDateTimeLabel, NSelect, NBaseBar, NButton },
  emits: []
})
export default class VideoProcessPreviewBar extends Vue {
  @Prop({ type: Object, required: true })
  item!: VideoArchive;

  @Prop({ type: String, default: CardTypeMap.Human })
  type!: 'human' | 'car';

  private actionInProgress = false;
  showProcessingVideoConfirm = false;

  get itemModel() {
    let model = viewModelRepository.getVideosItemViewModel();
    model.setItemsState(this.item);
    return model;
  }

  get sourceTime() {
    let value = this.item.health_status.statistic.processed_duration || this.item.source_len;
    let date = new Date(0);
    date.setHours(0, 0, value);
    return date;
  }

  get playSpeed() {
    return this.item.stream_settings?.play_speed || PlaySpeedItems[0].value;
  }

  get playSpeedItems() {
    return PlaySpeedItems.map((item) => translateProps(item, this.$t));
  }

  get isActive() {
    return this.item.active;
  }

  get modelAcl() {
    let model = viewModelRepository.getVideosListViewModel();
    model.aclModelName = 'videoarchive';
    return aclModule.getModelAcl(model);
  }

  get hasVideoBeenProcessed() {
    return this.item?.finished;
  }

  @Watch('isActive')
  resetActionInProgress() {
    this.actionInProgress = false;
  }

  openEpisodes() {
    const location = RouterModule.getRouteLocation({
      name: PageName.events,
      pageState: { pageType: PageType.episodes, episodeType: EpisodesSingleToMulti[this.type], filter: { video_archive: [this.item.id] } }
    });
    router.push(location);
  }

  processStop() {
    if (this.actionInProgress) return;
    this.actionInProgress = true;
    dataServiceRepository.VideosService.createItemSomethingByAction(this.item.id, 'stop');
  }

  processStart() {
    if (this.actionInProgress) return;
    this.actionInProgress = true;
    dataServiceRepository.VideosService.createItemSomethingByAction(this.item.id, 'process');
  }

  confirmProcessing() {
    this.showProcessingVideoConfirm = false;
    this.processStart();
  }

  updateSpeed(play_speed: number) {
    this.itemModel.update({ stream_settings: { play_speed } });
  }
}
