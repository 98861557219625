
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RoleEnum } from '@/api';
import translateProps from '@/common/translate-props';
import { NButtonSelect } from '@/uikit';

export const CaseRoleItems = [
  { value: RoleEnum.VICTIM, i18n_label: 'cases.victim', color: 'var(--color-yellow)' },
  { value: RoleEnum.WITNESS, i18n_label: 'cases.witness', color: 'var(--color-green)' },
  { value: RoleEnum.SUSPECT, i18n_label: 'cases.suspect', color: 'var(--color-red)' }
];

@Options({
  name: 'ParticipantForm',
  components: { NButtonSelect },
  emits: ['update:modelValue']
})
export default class ParticipantRole extends Vue {
  @Prop({ type: String, default: null })
  public readonly modelValue!: RoleEnum | null;

  get items() {
    return CaseRoleItems.map((item) => translateProps(item, this.$t, 'f'));
  }

  handleUpdate(v: RoleEnum) {
    this.$emit('update:modelValue', v);
  }
}
