
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'DistanceRange',
  components: { NSelect }
})
export default class DistanceRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: String, required: true })
  key_gte!: string;

  @Prop({ type: String, required: true })
  key_lte!: string;

  get modelValueGte() {
    return this.model[this.key_gte];
  }

  set modelValueGte(value: any) {
    Object.assign(this.model, { [this.key_gte]: value });
  }

  get modelValueLte() {
    return this.model[this.key_lte];
  }

  set modelValueLte(value: string) {
    Object.assign(this.model, { [this.key_lte]: value });
  }

  get allItems() {
    return Array(21)
      .fill(0)
      .map((v, index) => {
        const n = index * 50;
        return { label: String(n), value: n };
      });
  }

  get minItems() {
    return this.allItems.filter((item) => item.value <= Number(this.modelValueLte || 1000));
  }

  get maxItems() {
    return this.allItems.filter((item) => item.value >= Number(this.modelValueGte || 0));
  }
}
