export default {
  add_new: 'Add new record',
  duplicate_card: 'It looks like the record already exists',
  ignore_this_warning: 'Ignore this warning',
  add_photo_to_existing_card: 'add photo to this record',
  duplicate: 'duplicate',
  unknown: 'Unknown',
  autocards: 'Autorecords',
  face_objects: 'Faces',
  face_clusters: 'Face clusters',
  face_cluster_events: 'Face cluster events',
  body_objects: 'Bodies',
  body_clusters: 'Body clusters',
  body_cluster_events: 'Body cluster events',
  car_objects: 'Vehicles',
  car_clusters: 'Vehicle clusters',
  car_cluster_events: 'Vehicle cluster events',
  connections: 'Connections',
  location: 'Location',
  info: 'Info',
  human_participants: 'Cases',
  license_plate: 'License plate',
  case_human_cards: 'Related records',
  upload_photo: 'Upload photo',
  select_files: 'select files',
  select_folder: 'select a folder',
  or: 'or',
  no_objects: 'no object in photo',
  success: 'success',
  detection_error: 'detection error',
  in_queue: 'in the queue',
  prefix_name: 'Name prefix',
  suffix_name: 'Name postfix',
  filename_as_name: 'Use file name',
  prefix_comment: 'Comment prefix',
  suffix_comment: 'Comment postfix',
  photo_group: 'Group photo',
  parallel_upload: 'Parallel upload',
  selected: 'Selected',
  uploaded: 'Uploaded',
  errors: 'Errors',
  reject: 'Reject',
  biggest: 'Save the biggest object',
  all: 'All',
  batch_cards_upload__after_try: 'batch record upload',
  do_you_have_many_cards: 'Too many records to create? Try',
  upload: 'upload',
  start: 'start',
  pause: 'pause',
  name: 'record name',
  active: 'record active'
};
