
import NButtonSimple from './NButtonSimple.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { NButtonType } from '@/uikit/buttons/NButtonSimple.vue';

export type NButtonGroupItem =
  | {
      value: string | number;
      name?: string;
      label: string;
      icon?: string;
      color?: string;
      suffixIcon?: string;
      handler?: () => void;
      counter?: number;
      checked?: boolean;
    }
  | {
      value: string | number;
      name?: string;
      label?: string;
      icon: string;
      color?: string;
      suffixIcon?: string;
      handler?: () => void;
      counter?: number;
      checked?: boolean;
    };

export enum NButtonGroupType {
  Default = 'default',
  Rounded = 'rounded',
  Separated = 'separated',
  DataSourcePage = 'data-source-page'
}

@Options({
  name: 'NButtonGroup',
  components: { NButtonSimple }
})
export default class NButtonGroup extends Vue {
  @Prop({ type: String, default: NButtonGroupType.Default })
  readonly type!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiline!: false;

  @Prop({ type: Boolean, default: false })
  readonly showAnyButton!: false;

  @Prop({ type: [String, Array], required: false })
  readonly modelValue?: string | number | string[] | number[];

  @Prop({ type: Array, required: true })
  readonly items!: NButtonGroupItem[];

  @Prop({ type: Boolean, default: false })
  readonly initValueOnEmpty!: boolean;

  get model() {
    return !this.modelValue && this.multiple ? [] : this.modelValue;
  }

  get classes(): Record<string, boolean> {
    const base = 'n-button-group';
    const result = {
      [`${base}_${this.type}`]: true,
      [`${base}_multiline`]: this.multiline
    };
    return result;
  }

  get canShowAnyButton() {
    return this.showAnyButton;
  }

  @Watch('items', { immediate: true, deep: true })
  handleItemsChange() {
    if (this.initValueOnEmpty && this.items.length && !this.items.find((v) => v.value === this.modelValue)) {
      this.selectHandler(this.items[0]);
    }
  }

  getButtonType(value: string | undefined | null): NButtonType {
    let buttonType;
    if (this.type === NButtonGroupType.Default || this.type === NButtonGroupType.Separated || !value) buttonType = NButtonType.Secondary;
    else if (this.type === NButtonGroupType.DataSourcePage) buttonType = NButtonType.Secondary;
    else buttonType = NButtonType.Outline;
    return buttonType;
  }

  getSelected(item: NButtonGroupItem): boolean {
    let result;
    if (this.multiple) {
      let index = (this.model as unknown as string[])?.findIndex((v: string) => (item.value ?? item.name) === v);
      result = index > -1;
    } else {
      result = this.model === (item.value ?? item.name);
    }
    return result;
  }

  selectHandler(item: NButtonGroupItem): void {
    let result;

    if (this.multiple) {
      let items = [...(this.model as any[])];
      let index = items.findIndex((v: string) => item.value === v);
      index > -1 ? items.splice(index, 1) : items.push(item.value);
      result = items;
    } else {
      result = item.value ?? item.name;
    }
    item.handler && item?.handler();
    this.$emit('update:modelValue', result);
  }

  clearModel() {
    if (Array.isArray(this.modelValue)) this.$emit('update:modelValue', []);
    if (typeof this.modelValue === 'string') this.$emit('update:modelValue', undefined);
  }
}
