
import { NButton, NInputNumber } from '@/uikit';
import { isNumber } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const AngleGteMin = -90;
const AngleGteMax = 90;

const AngleLteMin = -90;
const AngleLteMax = 90;

@Options({
  name: 'NFilterAngleRange',
  components: {
    NInputNumber,
    NButton
  }
})
export default class NFilterAngleRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Object, required: true })
  gteFieldName!: string;

  @Prop({ type: Object, required: true })
  lteFieldName!: string;

  private gteValue: number | null = AngleGteMax;
  private lteValue: number | null = AngleLteMin;

  created() {
    [this.gteValue, this.lteValue] = [this.model[this.gteFieldName], this.model[this.lteFieldName]];
  }

  get angleGteMin() {
    return AngleGteMin;
  }

  get angleGteMax() {
    return AngleGteMax;
  }

  get angleLteMin() {
    return AngleLteMin;
  }

  get angleLteMax() {
    return AngleLteMax;
  }

  get clearable() {
    return isNumber(this.gteValue) || isNumber(this.lteValue);
  }

  reset() {
    this.gteValue = null;
    this.lteValue = null;
    if (this.model[this.gteFieldName]) this.model[this.gteFieldName] = null;
    if (this.model[this.lteFieldName]) this.model[this.lteFieldName] = null;
  }

  changeHandlerGte(scoreValue: number) {
    this.gteValue = scoreValue;
    Object.assign(this.model, { [this.gteFieldName]: scoreValue });
  }
  changeHandlerLte(scoreValue: number) {
    this.lteValue = scoreValue;
    Object.assign(this.model, { [this.lteFieldName]: scoreValue });
  }
}
