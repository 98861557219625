
import { ActionTypes, Colors, ModeNames, Sizes } from '@/components/polygon-draw/helpers/constants';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

type PointAsArray = [number, number];
type DoublePointArray = [PointAsArray, PointAsArray];
type PointAction = {
  type: string;
  index: number | null;
};

function drawPath(points: PointAsArray[], path2d: Path2D | null = null) {
  const polygonPath = path2d || new Path2D();
  const startPoint = points[0];
  polygonPath.moveTo(startPoint[0], startPoint[1]);
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    polygonPath.lineTo(point[0], point[1]);
  }
  polygonPath.lineTo(startPoint[0], startPoint[1]);
  polygonPath.closePath();
  return polygonPath;
}

@Options({
  name: 'PolygonDrawTool'
})
export default class PolygonDrawTool extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly onlyRect!: boolean;

  @Prop({ type: [String, Object], default: '' })
  readonly image!: string | Blob;

  @Prop({ type: Array, required: false })
  readonly points?: PointAsArray[];

  @Prop({ type: Array, required: false })
  readonly rotPoints?: PointAsArray[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly crossedLines!: boolean;

  @Prop({ type: String, required: false })
  readonly color?: string;

  @Prop({ type: Boolean, default: true })
  readonly fill!: boolean;

  @Ref('canvasContainer')
  canvasContainer: HTMLDivElement | null = null;

  internalPoints: PointAsArray[] = [];

  imageBlob: Blob | null = null;

  imageRect = {
    width: 0,
    height: 0
  };

  loading = false;

  internalChange = false;

  canvasRect = {
    width: 0,
    height: 0,
    x: 0,
    y: 0
  };

  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  pointsBeforeDrag: any[][] | null = [];
  polygonPath: Path2D | null = null;
  isClosed = false;
  draggedStartMousePoint: Record<any, any> | null = null;
  draggedPointIndex: number | null = null;
  overPointIndex: number | boolean = 0;
  overAction: PointAction = {
    type: '',
    index: 0
  };
  overPoint: PointAsArray | null = null;

  mode = ModeNames.Rect;

  @Watch('image')
  imageHandler(v: string) {
    this.resizeCanvas();
    this.loadImage();
  }

  @Watch('mode')
  modeHandler(v: string) {
    if (v === ModeNames.Rect) this.clear();
  }

  @Watch('points')
  pointsChangeHandler() {
    if (this.internalChange) {
      this.internalChange = false;
      return;
    }
    this.internalPoints = this.getPointsToInternal(this.points);
  }

  @Watch('internalPoints', { deep: true })
  internalPointsChangeHandler() {
    const result = this.getInternalToPoints();
    this.internalChange = true;
    this.$emit('change', result);
    this.nextTickDraw();
  }

  setMode() {
    const pointsLength = this.points?.length ?? 0;
    const isRectMode = this.onlyRect;
    this.mode = isRectMode ? ModeNames.Rect : ModeNames.Poly;
    this.isClosed = isRectMode || pointsLength > 2;
  }

  getPointsToInternal(points: PointAsArray[] | null | undefined): PointAsArray[] {
    return (points || []).map((v: any) => [v[0] / this.scaleFactor + this.offsets.x, v[1] / this.scaleFactor + this.offsets.y]);
  }

  getInternalToPoints() {
    const computeXPoint = (v: any) => Math.round((v - this.offsets.x) * this.scaleFactor);
    const computeYPoint = (v: any) => Math.round((v - this.offsets.y) * this.scaleFactor);

    const result = this.internalPoints?.map((v) => [computeXPoint(v[0]), computeYPoint(v[1])]);
    return result.length ? result : null;
  }

  computeMaxMinPoint(point: PointAsArray): PointAsArray {
    return this.imageRect
      ? [
          Math.round(this.applyMaxMin(point[0], this.offsets.x, this.offsets.x + this.imageRect?.width / this.scaleFactor)),
          Math.round(this.applyMaxMin(point[1], this.offsets.y, this.offsets.y + this.imageRect.height / this.scaleFactor))
        ]
      : point;
  }

  applyMaxMin(value: number, min: number, max: number) {
    return value > max ? max : value < min ? min : value;
  }

  getNearCurrentPointIndex(point: PointAsArray) {
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i];
      const isNear = this.isNearPoint(point, currentPoint);
      if (isNear) {
        return i;
      }
    }
    return null;
  }

  isNearPoint(point: PointAsArray, current: PointAsArray) {
    const distanceX = Math.abs(point[0] - current[0]);
    const distanceY = Math.abs(point[1] - current[1]);
    return distanceX <= Sizes.PointSize && distanceY <= Sizes.PointSize;
  }

  nextTickDraw() {
    this.$nextTick(this.draw);
  }

  draw() {
    if (!this.canvasContext) return;
    this.canvasContext.clearRect(0, 0, this.canvasRect.width, this.canvasRect.height);
    this.fillPolygon();
    this.fillOutOfRot();
    for (let i = 0; i < this.internalPoints.length; i++) {
      const startPoint = this.internalPoints[i];
      const nextPoint = this.internalPoints[i + 1];
      if (startPoint && nextPoint) this.drawLine(startPoint, nextPoint);
      else {
        if (this.isClosed) this.drawLine(startPoint, this.internalPoints[0]);
        else if (this.overPoint) this.drawActiveLine(startPoint, this.overPoint);
      }
    }

    for (let i = 0; i < this.internalPoints.length; i++) {
      const point = this.internalPoints[i];
      this.drawPointSquare(point, i);
    }

    this.internalChange = false;
  }

  getPointFillColor(index: number) {
    let result = Colors.PointFill;
    if (this.draggedPointIndex === index || (this.draggedPointIndex as number) < 0) {
      result = this.color || Colors.PointDraggedFill;
    } else if (this.overPointIndex === index) {
      result = Colors.PointOverFill;
    }
    return result;
  }

  drawPointSquare(point: any, index: number) {
    const ctx = this.canvasContext;
    const x = point[0] - Sizes.PointSize / 2;
    const y = point[1] - Sizes.PointSize / 2;

    if (!ctx) return;
    ctx.beginPath();
    ctx.fillStyle = this.getPointFillColor(index);
    ctx.setLineDash([]);
    ctx.strokeStyle = this.color || Colors.PointBorder;
    ctx.lineWidth = 1;
    ctx.rect(x, y, Sizes.PointSize, Sizes.PointSize);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  drawLine(startPoint: number[], endPoint: number[]) {
    const ctx = this.canvasContext;
    if (!ctx) return;
    ctx.setLineDash([]);
    ctx.lineWidth = Sizes.LineWidth;
    ctx.strokeStyle = this.color || Colors.LineDefault;
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.closePath();
  }

  drawActiveLine(startPoint: PointAsArray, endPoint: PointAsArray) {
    const ctx = this.canvasContext;
    if (!ctx) return;
    ctx.setLineDash([8, 12]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = Colors.LineActive;
    ctx.beginPath();
    ctx.moveTo(startPoint[0], startPoint[1]);
    ctx.lineTo(endPoint[0], endPoint[1]);
    ctx.stroke();
    ctx.closePath();
  }

  fillOutOfRot() {
    if (!this.rotPoints || this.rotPoints.length < 3) return;
    const ctx = this.canvasContext;
    if (!ctx) return;
    const internalRotPoints = this.getPointsToInternal(this.rotPoints);
    const canvasFullSquare: PointAsArray[] = [
      [0, 0],
      [this.canvasRect.width, 0],
      [this.canvasRect.width, this.canvasRect.height],
      [0, this.canvasRect.height]
    ];
    const polygonPath = drawPath(canvasFullSquare);
    drawPath(internalRotPoints, polygonPath);
    ctx.fillStyle = Colors.RotFill;
    ctx.fill(polygonPath, 'evenodd');
  }

  fillPolygon() {
    const ctx = this.canvasContext;
    if (!ctx) return;
    this.polygonPath = null;
    if (this.internalPoints.length < 3) return;
    const polygonPath = drawPath(this.internalPoints);
    ctx.fillStyle = this.fill ? Colors.PolygonFill : Colors.Transparent;
    ctx.fill(polygonPath);
    this.polygonPath = polygonPath;
  }

  distance(a: PointAsArray, b: PointAsArray) {
    return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
  }

  getCrossPointOnAddPoint(point: PointAsArray) {
    return this.getCrossedLineByPoints([...this.internalPoints, point]);
  }

  getCrossPointOnMovePoint(point: PointAsArray) {
    const points = [
      ...this.internalPoints.slice((this.draggedPointIndex as number) + 1, this.internalPoints.length),
      ...this.internalPoints.slice(0, (this.draggedPointIndex as number) + 1)
    ];
    points[points.length - 1] = point;
    return this.getCrossedLineByPoints(points, true);
  }

  getCrossPoint([[x1, y1], [x2, y2]]: DoublePointArray, [[x3, y3], [x4, y4]]: DoublePointArray) {
    const x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
    const y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
    const points = [
      [x1, y1],
      [x2, y2],
      [x3, y3],
      [x4, y4]
    ];
    const isConnectPoint = points.find((v) => v[0] === x && v[1] === y);

    if (isNaN(x) || isNaN(y) || isConnectPoint) {
      return false;
    } else {
      if (x1 > x2) {
        if (!(x2 <= x && x <= x1)) {
          return false;
        }
      } else {
        if (!(x1 <= x && x <= x2)) {
          return false;
        }
      }
      if (y1 > y2) {
        if (!(y2 <= y && y <= y1)) {
          return false;
        }
      } else {
        if (!(y1 <= y && y <= y2)) {
          return false;
        }
      }
      if (x3 > x4) {
        if (!(x4 <= x && x <= x3)) {
          return false;
        }
      } else {
        if (!(x3 <= x && x <= x4)) {
          return false;
        }
      }
      if (y3 > y4) {
        if (!(y4 <= y && y <= y3)) {
          return false;
        }
      } else {
        if (!(y3 <= y && y <= y4)) {
          return false;
        }
      }
    }

    return [x, y];
  }

  clear() {
    this.internalPoints = [];
    this.isClosed = this.mode === ModeNames.Rect;
    this.nextTickDraw();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContainer?.appendChild(this.canvas);
  }

  resizeCanvas() {
    const sourceRect = this.canvasContainer?.getBoundingClientRect();
    if (!sourceRect || !this.canvas) return;
    this.canvasRect = { width: sourceRect.width, height: sourceRect.height, x: 0, y: 0 };
    this.canvas.width = this.canvasRect?.width;
    this.canvas.height = this.canvasRect.height;
  }

  resize() {
    this.resizeCanvas();
    this.updateInternalPoints();
    this.nextTickDraw();
  }

  isString(v: unknown): v is string {
    return typeof v === 'string';
  }

  isBlob(v: unknown): v is Blob {
    return v instanceof Blob;
  }

  loadImage() {
    const url: string | null = this.isString(this.image) ? this.image : null;
    const blob: Blob | null = this.isBlob(this.image) ? this.image : null;
    if (!url && !blob) return;

    let image = new Image();
    image.onload = () => {
      let imageRect = { width: 0, height: 0 };
      this.loading = false;
      imageRect.width = image.naturalWidth;
      imageRect.height = image.naturalHeight;
      // image.src = this.image;
      image.onload = null;
      this.imageRect = imageRect;
      this.resize();
      url ? this.convertImageToBlob(image) : (this.imageBlob = blob);
    };
    this.loading = true;
    image.src = url || URL.createObjectURL(blob);
  }

  convertImageToBlob(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    context?.drawImage(image, 0, 0);
    canvas.toBlob(
      (v) => {
        this.imageBlob = v;
      },
      'image/jpeg',
      0.9
    );
  }

  updateInternalPoints() {
    this.internalPoints = this.getPointsToInternal(this.points);
  }

  // eslint-disable-next-line no-undef
  mouseDoubleClick(e: MouseEvent) {
    const mousePoint: PointAsArray = [e.offsetX, e.offsetY],
      currentPointIndex = this.getNearCurrentPointIndex(mousePoint);

    if (currentPointIndex !== null && this.mode !== ModeNames.Rect) {
      this.internalPoints.splice(currentPointIndex, 1);
    }
  }

  getAction(point: PointAsArray) {
    const currentPointIndex: number | null = this.getNearCurrentPointIndex(point);
    const currentLinePointIndex: number | null = this.getOnLinePointIndex(point);
    const hasNearPoint = currentPointIndex !== null;
    const hasNearLine = currentLinePointIndex !== null;
    const isPointInPolygon =
      !hasNearPoint && !hasNearLine && this.isClosed && this.polygonPath && this.canvasContext?.isPointInPath(this.polygonPath, ...point);
    let result: PointAction = { type: ActionTypes.None, index: null };

    switch (this.mode) {
      case ModeNames.Poly:
        if (hasNearPoint) {
          if (currentPointIndex === 0 && this.internalPoints.length > 2 && !this.isClosed) {
            result.type = ActionTypes.Close;
            result.index = currentPointIndex;
          } else {
            result.type = ActionTypes.Move;
            result.index = currentPointIndex;
          }
        } else if (hasNearLine) {
          result.type = ActionTypes.AddPoint;
          result.index = (currentLinePointIndex as number) + 1;
        } else if (isPointInPolygon) {
          result.type = ActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = ActionTypes.AddPoint;
        }
        break;
      default:
        if (hasNearPoint) {
          result.type = ActionTypes.Move;
          result.index = currentPointIndex;
        } else if (isPointInPolygon) {
          result.type = ActionTypes.MoveAll;
          result.index = -1;
        } else {
          result.type = ActionTypes.DrawRect;
        }
    }
    return result;
  }

  getRectPoints([x, y]: PointAsArray): PointAsArray[] {
    return [
      [x, y],
      [x + 1, y],
      [x + 1, y + 1],
      [x, y + 1]
    ];
  }

  mouseOut() {
    this.overPoint = null;
    this.nextTickDraw();
  }

  mouseDown(e: MouseEvent) {
    e.preventDefault();
    const mousePoint = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    let action = this.getAction(mousePoint);

    switch (action?.type) {
      case ActionTypes.DrawRect:
        this.internalPoints = this.getRectPoints(mousePoint);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = 2;
        this.nextTickDraw();
        break;
      case ActionTypes.Close:
        if (this.getCrossPointOnAddPoint(mousePoint)) return;
        this.isClosed = true;
        this.nextTickDraw();
        break;
      case ActionTypes.MoveAll:
      case ActionTypes.Move:
        this.pointsBeforeDrag = this.internalPoints.map((v) => [...v]);
        this.draggedStartMousePoint = mousePoint;
        this.draggedPointIndex = action.index as number;
        this.nextTickDraw();
        break;
      case ActionTypes.AddPoint:
        this.addPoint(mousePoint, action.index);
        this.draggedPointIndex = action.index as number;
        break;
    }
  }

  addPoint(point: any, afterIndex: number | null) {
    const isLineAfter = afterIndex === this.internalPoints.length;

    if (afterIndex === null || (isLineAfter && !this.isClosed)) {
      if (!this.crossedLines && this.getCrossPointOnAddPoint(point)) return;
      this.internalPoints.push(point);
    } else {
      this.internalPoints.splice(afterIndex, 0, point);
    }
  }

  getCrossedLineByPoints(points: any, twoWays = false) {
    let lines: any[] = [];
    let crossedLine = null;

    points.forEach((v: any, k: any) => {
      const nextPoint = points[k + 1];
      if (nextPoint) lines.push([v, nextPoint]);
    });

    if (lines.length >= 3) {
      const lastLine = lines.pop();
      const closeLine: DoublePointArray = [points[points.length - 1], points[0]];
      crossedLine = lines.find((line, index) => {
        return this.getCrossPoint(line, lastLine) || (twoWays && this.getCrossPoint(line, closeLine));
      });
    }

    return crossedLine;
  }

  getOnLinePointIndex(point: any) {
    for (let i = 0; i < this.internalPoints.length; i++) {
      const currentPoint = this.internalPoints[i],
        nextPoint = this.internalPoints[i + 1] || this.internalPoints[0];
      if (this.isPointOnLine(point, currentPoint, nextPoint)) return i;
    }
    return null;
  }

  isPointOnLine(currentPoint: any, startPoint: any, endPoint: any) {
    const distanceCurrent = this.distance(startPoint, currentPoint) + this.distance(currentPoint, endPoint),
      distanceLine = this.distance(startPoint, endPoint),
      diffDistance = Math.abs(distanceCurrent - distanceLine),
      DistanceFactor = 0.7;
    return diffDistance < DistanceFactor;
  }

  mouseUp() {
    this.draggedPointIndex = null;
    this.nextTickDraw();
  }

  mouseMove(e: MouseEvent) {
    e.preventDefault();
    const point = this.computeMaxMinPoint([e.offsetX, e.offsetY]);
    this.overPoint = point;

    if (this.draggedPointIndex !== null) {
      switch (this.mode) {
        case ModeNames.Poly:
          this.movePolyPoint(point);
          break;
        default:
          this.moveRectPoint(point);
          break;
      }
      this.internalPointsChangeHandler();
    } else {
      const action = this.getAction(point);
      this.overAction = action;
      const previousIndex = this.overPointIndex;
      this.overPointIndex = action.type === ActionTypes.Move && (action.index as number);
      if (previousIndex !== this.overPointIndex) this.nextTickDraw();
    }

    if (this.mode === ModeNames.Poly && !this.isClosed) this.nextTickDraw();
  }

  moveAllPoints(point: PointAsArray) {
    if (!this.draggedStartMousePoint) return;
    const diffPoint = [point[0] - this.draggedStartMousePoint[0], point[1] - this.draggedStartMousePoint?.[1]];
    // @ts-ignore
    this.internalPoints = this.pointsBeforeDrag?.map((v: any) => this.computeMaxMinPoint([v[0] + diffPoint[0], v[1] + diffPoint[1]]));
  }

  movePolyPoint(point: any) {
    if ((this.draggedPointIndex as number) < 0) {
      this.moveAllPoints(point);
    } else {
      if (!this.crossedLines && this.getCrossPointOnMovePoint(point)) return;
      this.internalPoints[this.draggedPointIndex as number] = [...point] as PointAsArray;
    }
  }

  moveRectPoint(point: any) {
    const draggedPointIndex = this.draggedPointIndex as number;
    if (draggedPointIndex < 0) {
      this.moveAllPoints(point);
    } else {
      const currentIndex = draggedPointIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : 3;
      const nextIndex = currentIndex < 3 ? currentIndex + 1 : 0;
      const currentPoint = this.internalPoints[currentIndex];
      const prevPoint = this.internalPoints[prevIndex];
      const nextPoint = this.internalPoints[nextIndex];
      const isPrevXEqual = Math.abs(prevPoint[0] - currentPoint[0]) < 1;
      this.internalPoints[currentIndex] = [...point] as PointAsArray;

      if (isPrevXEqual) {
        this.internalPoints[prevIndex] = [point[0], prevPoint[1]];
        this.internalPoints[nextIndex] = [nextPoint[0], point[1]];
      } else {
        this.internalPoints[prevIndex] = [prevPoint[0], point[1]];
        this.internalPoints[nextIndex] = [point[0], nextPoint[1]];
      }
    }
  }

  get mouseStyle() {
    const action = this.overAction;
    let result = 'default';
    switch (action?.type) {
      case ActionTypes.MoveAll:
      case ActionTypes.Move:
        result = 'move';
        break;
      case ActionTypes.DrawRect:
      case ActionTypes.AddPoint:
      case ActionTypes.Close:
        result = 'crosshair';
        break;
      default:
        break;
    }
    return { cursor: result };
  }

  get imageContainerStyle() {
    if (!this.imageBlob) return null;
    const url = URL.createObjectURL(this.imageBlob);
    return { backgroundImage: `url("${url}")` };
  }

  get classes() {
    return {
      'polygon-draw__content-disabled': this.disabled
    };
  }

  get offsets() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;

    if (!canCompute) return { x: 0, y: 0 };

    const scaleFactor = this.scaleFactor,
      x = (canvasRect.width - imageRect.width / scaleFactor) / 2,
      y = (canvasRect.height - imageRect.height / scaleFactor) / 2;
    return { x, y };
  }

  get scaleFactor() {
    const { imageRect, canvasRect } = this,
      canCompute = imageRect && canvasRect;
    if (!canCompute) return 1;

    const scaleFactor = Math.max(imageRect.width / canvasRect.width, imageRect.height / canvasRect.height);
    return scaleFactor;
  }

  created() {
    this.setMode();
  }

  @Watch('onlyRect')
  onlyRectWatcher() {
    this.setMode();
  }

  mounted() {
    this.createCanvas();
    this.resizeCanvas();
    this.loadImage();
    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('resize', this.resize);
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('resize', this.resize);
  }
}
