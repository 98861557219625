export default {
  params: {
    track_overlap_threshold_desc:
      'The percentage of overlap of bboxes between two serial frames so that these bboxes are considered as one track.\n' +
      'The value of this parameter should be reduced at a low frame rate, otherwise the tracks will be torn too often, which will lead to a large number of overall frames and an excessive load on the findface-extraction-api.\n' +
      'The value of this parameter should be increased if an error occurs when different objects appear inside one track (tracks of different objects move from one to another).\n' +
      'The range of values is from 0 to 1',
    track_send_history_desc:
      'Send array of bbox coordinates along with the event. May be applicable for external integrations to map the path of an object.\n' +
      'Doesn’t work with track_max_duration_frames = 0 - you need to set this parameter to a non-zero value, otherwise you will get misconfigured status',
    filter_min_quality_faces_desc:
      'The minimum quality of the face image for detection.\n' +
      'The allowed range is from 0 to 1.\n' +
      'The recommended reference value is 0.5, which corresponds to face images of the satisfying quality.\n' +
      'Values close to 1 correspond to the high-quality aligned images of faces being completely in a frame.\n' +
      'Values close to 0 correspond to blurred images, as well as to images containing upside-down faces or faces rotated at large angles - thus, recognition may be ineffective',
    filter_min_quality_bodies_desc:
      'The minimum quality of the body image for detection.\n' +
      'The allowed range is from 0 to 1.\n' +
      'The recommended reference value is 0.6, which corresponds to body images of the satisfying quality.\n' +
      'Values close to 1 correspond to the high-quality aligned images of bodies being completely in a frame.\n' +
      'Values close to 0 correspond to blurred images, as well as to images containing upside-down bodies or bodies rotated at large angles - thus, recognition may be ineffective',
    filter_min_quality_cars_desc:
      'The minimum image quality of the vehicle for detection. \n' +
      'The allowed range is from 0 to 1. \n' +
      'The recommended reference value is 0.65, which corresponds to vehicle images of the satisfying quality. \n' +
      'Values close to 1 correspond to the high-quality aligned images of vehicles being completely in a frame. \n' +
      'Values close to 0 correspond to blurred images, as well as to images containing upside-down vehicles or vehicles rotated at large angles - thus, recognition may be ineffective\n' +
      '',
    track_max_duration_frames_desc:
      'This parameter limits the maximum number of frames in a track\'s history.\n' +
      'If the value of this parameter is greater than zero and the number of frames in the track exceeds it, the track will be forcibly stopped. However, due to specifics of videoworker (interpolation of bboxes), this check is not strict - for example, with track_max_duration_frames = 5 a track can last both 6 and 7 frames.\n' +
      'This parameter is used to stop "perpetual" tracks of objects - for instance, a face on a billboard that came into the field of view of a camera.\n' +
      'For the track_send_history option to work, parameter track_max_duration_frames has to be set to a non-zero value',
    filter_min_size: 'Size',
    filter_max_size: '-',
    filter_min_quality: 'Quality',
    jpeg_quality: 'JPEG quality',
    fullframe_use_png: 'Full frame in PNG',
    track_overlap_threshold: 'Overlap threshold',
    track_max_duration_frames: 'Track maximum duration',
    track_miss_interval: 'Forced termination interval',
    track_interpolate_bboxes: 'Interpolate bounding boxes',
    track_send_history: 'Send track history',
    fullframe_crop_rot: 'Crop full frame',
    overall_only: 'Offline mode (overall_only)',
    realtime_post_interval: 'Interval',
    realtime_post_every_interval: 'Post in every interval',
    realtime_post_first_immediately: 'Post first object immediately',
    post_first_track_frame: 'Post track first frame',
    post_last_track_frame: 'Post track last frame',
    post_best_track_normalize: 'Post track best normalized image',
    post_best_track_frame: 'Post track best full frame',
    deduplicate_desc: 'Record only unique events among cameras of the group, excluding overlaps',
    deduplicate_delay_desc: 'Time period in seconds between two consecutive checks for event uniqueness',
    roi_desc: 'Post objects detected only inside the given region of interest',
    rot_desc: 'Detect and track objects only inside the given clipping rectangle. Use this option to reduce CPU load',
    min_score: 'Minimum object quality (min_score)',
    min_score_desc:
      'Minimum quality of an object snapshot when searching for the best one. To be fitted empirically: negatives values around 0 = high quality objects, -1 = good quality, -2 = satisfactory quality, -5 = inverted objects and large object angles, object recognition may be inefficient',
    min_d_score: 'Maximum object deviation (min_d_score)',
    min_d_score_desc:
      'Maximum object deviation from its frontal position. To be fitted empirically: -3.5 = large object angles, object recognition may be inefficient, -2.5 = satisfactory deviation, -0.05 = close to the frontal position, 0 = frontal object',
    filter_min_size_desc: 'Minimum object size in pixels to post',
    filter_max_size_desc: 'Maximum object size in pixels to post',
    realtime: 'Real-time mode (realtime)',
    realtime_desc:
      'Real-time mode. Pick up the best snapshot within each realtime_dly time interval. If realtime_post_perm: true, the best snapshot is posted at the end of each interval; if false, the best snapshot is posted only if its quality has improved comparing to the previously posted snapshot',
    realtime_post_first_immediately_desc:
      'If true, post the first object from a track immediately after it passes through the quality, size, and ROI filters, without waiting for the first realtime_post_interval to complete in real-time mode. If false, post the first object after the first realtime_post_interval completes',
    realtime_post_perm: 'Post best snapshot',
    realtime_post_perm_desc:
      'If true, post the best snapshot obtained within each realtime_dly time interval in real-time mode. If false, post the best snapshot only if its quality has improved comparing to the previously posted snapshot',
    realtime_dly: 'Time interval (realtime_dly)',
    realtime_dly_desc: 'Time interval in milliseconds within which the object tracker picks up the best snapshot in real-time mode',
    overall: 'Offline mode (overall)',
    overall_desc: 'Offline mode. Enable posting one snapshot of the best quality for each object',
    ffmpeg_params: 'FFmpeg parameters (ffmpeg_params)',
    ffmpeg_params_desc: 'FFmpeg options for a video stream in the key=value format "rtsp_transport=tcp allowed_media_types=video"',
    fd_frame_height_desc:
      'Video frame height in pixels for the object tracker. Negative values correspond to the initial size. Optimum value to reduce load: 640-720',
    npersons: 'Maximum number of objects (npersons)',
    npersons_desc: 'Maximum number of objects simultaneously tracked by the object tracker. This parameter severely affects performance',
    tracker_threads: 'Tracking threads number (tracker_threads)',
    tracker_threads_desc:
      'Number of tracking threads for the object tracker. This value should be less or equal to npersons. Recommended to set them equal. If the number of tracking threads is less than the maximum number of tracked objects, resource consumption is reduced but so is the tracking speed',
    jpeg_quality_desc: 'Full frame compression quality',
    draw_track: 'Display object motion track (draw_track)',
    draw_track_desc: 'Display object motion track inside bbox',
    api_timeout: 'Response timeout (api_timeout)',
    api_timeout_desc: 'Response timeout for an API request',
    md_threshold: 'Minimum motion intensity (md_threshold)',
    md_threshold_desc:
      'Minimum motion intensity to be detected by the motion detector. To be fitted empirically: 0 = detector disabled, 0.002 = default value, 0.05 = minimum intensity is too high to detect motion',
    md_scale: 'Video frame scaling coefficient (md_scale)',
    md_scale_desc:
      'Video frame scaling coefficient for the motion detector from 0 to 1. Scale down in the case of high resolution cameras, or close up objects, or if the CPU load is too high, to reduce the system resources consumption',
    fullframe_crop_rot_desc:
      'If true, then crop the full frame to the size of the ROT area before sending it for recognition. The size of the full frame will be equal to the size of the ROT area',
    track_miss_interval_desc: 'Terminate track if no new object has been detected within the specified time (in seconds)',
    post_first_track_frame_desc:
      'At the end of the track, the first frame of the track will be additionally sent complimentary to the overall frame of the track. May be applicable for external integrations',
    post_last_track_frame_desc:
      'At the end of the track, the last frame of the track will be additionally sent complimentary to the overall frame of the track. May be applicable for external integrations',
    router_timeout_ms: 'Timeout in ms',
    router_timeout_ms_desc: 'Timeout in milliseconds for posting objects',
    start_stream_timestamp: 'Add to timestamps (start_stream_timestamp)',
    start_stream_timestamp_desc: 'Add the specified number of seconds to timestamps from the video stream',
    use_stream_timestamp: 'Retrieve  timestamps from stream',
    use_stream_timestamp_desc: 'Post timestamps from the video stream instead of the server date and time',
    use_stream_timestamp__simple: 'Send video stream timestamps to the server',
    use_stream_timestamp_desc__simple: 'If unchecked, current date and time will be sent by default',
    select_start_stream_timestamp: 'Specify timestamp for the uploaded video',
    select_start_stream_timestamp_desc: 'To specify the timestamp manually, enable sending video stream timestamps above',
    watch_list_camera_groups_desc: 'If empty, the watch list works across all camera groups',
    play_speed: 'Play speed limit (play_speed)',
    play_speed_desc:
      'If less than zero, the speed is not limited. In other cases, the stream is read with the given play_speed. Not applicable for live-streaming',
    ffmpeg_format: 'Force input format (ffmpeg_format)',
    ffmpeg_format_desc: 'Pass FFMPEG format (mxg, flv, etc.) if it cannot be detected automatically',
    router_verify_ssl: 'Verify SSL (router_verify_ssl)',
    router_verify_ssl_desc:
      'If true, enable verification of the server SSL certificate\n' +
      'when the object tracker posts objects to the server over https.\n' +
      'If false, a self-signed certificate can be accepted',
    imotion_threshold: 'Minimum motion intensity (imotion_threshold)',
    imotion_threshold_desc: 'Minimum motion intensity to be detected by the motion detector',
    realtime_post_interval_desc: 'Time interval in seconds (integer or decimal) within which the object tracker picks up the best snapshot in real-time mode',
    realtime_post_every_interval_desc:
      'If true, post the best snapshot obtained within each time interval (realtime_post_interval) in real-time mode. If false, post the best snapshot only if its quality has improved comparing to the previously posted snapshot',
    detect_bodies_desc: 'For the body counter to work, body detection must be enabled. Refer to documentation or contact administrator to enable it',
    detect_cars_desc: 'For the vehicle counter to work, vehicle detection must be enabled. Refer to documentation or contact administrator to enable it',
    time_should_be_in_utc: 'Time must be specified in the UTC format',
    collect_track_data_seconds_desc: 'Minimum time - 15 seconds, maximum - 300',
    detect_proximity_recommendations_desc: 'Recommendations for a successful camera calibration:',
    detect_proximity_recommendations_desc_1: 'The person in the frame must be visible at full height',
    detect_proximity_recommendations_desc_2: 'The person must walk through the area visible by the camera, along which the distances will be calculated',
    detect_proximity_recommendations_desc_3: 'To achieve the best quality, the person must walk on a flat surface',
    distance_measurement_desc:
      'After activating the distance measurement, save changes. Then configure the counter by entering the calibration time and initiating the calibration.',
    disable_drops: 'Process all appropriate frames without dropping some of them (disable_drops)',
    disable_drops_desc:
      'By default (false), if findface-video-worker does not have enough resources to process all frames with objects, it drops some of them. If this option is active (true), findface-video-worker puts excessive frames on a waiting list to process them later. Set a variable to true when processing files as cameras',
    single_pass: 'Process the stream once until the end or until the first error (single_pass)',
    single_pass_desc: 'If true, disable restarting video processing upon error',
    confidence_face_desc:
      'A confidence threshold is used for matching the detected face object with the internal entity face. Recommended threshold values for a face object: 0.63 - low, 0.714 - normal, 0.77 - high.\n' +
      'Normal is considered to be the optimal threshold.',
    confidence_body_desc:
      'A confidence threshold is used for matching the detected silhouette object with the internal entity silhouette. Recommended threshold values for a silhouette object: 0.5 - low, 0.65 - normal, 0.85 - high.\n' +
      'Normal is considered to be the optimal threshold.',
    confidence_car_desc:
      'A confidence threshold is used for matching the detected vehicle object with the internal entity vehicle. Recommended threshold values for a vehicle object: 0.3 - low, 0.65 - normal, 0.85 - high.\n' +
      'Normal is considered to be the optimal threshold.'
  },
  devices_pf: '{ count, plural, one {# device} other {# devices}}',
  files_pf: '{ count, plural, one {# file} other {# files}}',
  open_video_events: 'Open video events',
  camera_optional: 'Camera (optional)',
  tabs: {
    external_detectors: 'External detectors',
    cameras: 'Devices',
    onvif_discovery: 'ONVIF discovery',
    videos: 'Upload'
  },
  transformation: 'Transformation',
  mirror: 'Mirror',
  flip: 'Flip',
  rotate_clockwise: 'Rotate clockwise',
  rotate_counterclockwise: 'Rotate counterclockwise',
  posting_faces: 'Posting faces',
  timeout: 'Timeout',
  timestamp: 'Timestamp',
  add_to_timestamps: 'Add to timestamps',
  other: 'Other',
  ffmpeg_parameters: 'FFmpeg parameters',
  play_speed_limit: 'Play speed limit',
  stream_data_filter: 'Stream data filter',
  retrieve_timestamps_from_stream: 'Retrieve timestamps from stream',
  verify_ssl_certificate: 'Verify SSL certificate',
  force_input_format: 'Force input format',
  imotion_threshold: 'Minimum motion intensity',
  read_frames_from_source_without_drops: 'Read frames from source without drops',
  one_detector_must_be_on: 'At least one detector must be on',
  start_time: 'Start time',
  file_size: 'File size',
  now: 'now',
  clear: 'clear',
  url: 'URL',
  reset_params: 'Reset parameters',
  deleting_video_confirm_desc:
    'Warning: all data like recognized images and case participants records associated with the video file will be purged. Are you sure you want to continue?',
  yes_start_process: 'Yes, reprocess',
  cancel: 'Cancel',
  process: 'process',
  again: 'one more time',
  sections: {
    filter: 'Filter',
    compression: 'Compression',
    rot: 'ROT',
    tracking: 'Tracking',
    posting: 'Posting'
  },
  deleting_videos_confirm_desc_1:
    'Do you want to delete video files but keep the associated data (recognized images, case participants records), or delete files and purge the associated data?',
  deleting_videos_confirm_desc_2: 'This video file was already deleted. Do you want to purge its associated data like recognized images and case participants?',
  deleting_videos_confirm_desc_3: 'Do you want to delete the video and all participants, clusters related to it?',
  delete_all: 'Delete video files and purge associated data',
  delete_video_files_only: 'Delete video files only',
  delete: 'Delete',
  processed: 'processed',
  not_processed: 'not processed',
  brand: 'brand',
  devices: 'devices',
  discover_again: 'Discover again',
  discovering: 'Discovering...',
  ip_address: 'IP address',
  login_to_camera: 'Login to camera',
  mac_address: 'MAC address',
  model: 'model',
  port: 'port',
  active: 'active',
  gray: 'gray',
  green: 'green',
  only_active: 'only active',
  only_not_active: 'only not active',
  red: 'red',
  yellow: 'yellow',
  frames_dropped: 'Frames dropped',
  face_objects_not_posted: 'Faces not posted',
  face_objects_posted: 'Faces posted',
  body_objects_not_posted: 'Bodies not posted',
  body_objects_posted: 'Bodies posted',
  car_objects_not_posted: 'Vehicles not posted',
  car_objects_posted: 'Vehicles posted',
  process_duration: 'Process duration',
  status: 'Status',
  upload: 'Upload',
  uploads: 'Uploads',
  cameras: 'Cameras',
  external_detectors: 'External detectors',
  create: 'Add',
  image: 'Image',
  name: 'Name',
  cameras_no_items: 'You don\'t have any cameras',
  videos_no_items: 'You don\'t have any uploaded files',
  external_detectors_no_items: 'You don\'t have any external detectors',
  external_vms_no_items: 'You don\'t have any external VMS cameras',
  not_found_items: 'Sorry, we didn\'t find anything',
  create_cameras: 'Add сameras',
  create_videos: 'Upload files',
  create_external_detectors: 'Add external detectors',
  create_external_vms: 'Add external VMS camera',
  zones: 'zones',
  general: 'general',
  advanced: 'advanced',
  info: 'info',
  faces: 'faces',
  bodies: 'bodies',
  cars: 'vehicles',
  detectors: 'Detectors',
  reset_face_detector: 'Reset face parameters',
  reset_body_detector: 'Reset body parameters',
  reset_car_detector: 'Reset vehicle parameters',
  enabled: 'Enabled',
  map: 'geo',
  latitude: 'latitude',
  longitude: 'longitude',
  azimuth: 'azimuth',
  camera_reset: 'Reset to default',
  camera_reset_advanced: 'Reset advanced',
  camera_reset_all: 'Reset all',
  camera_reset_zone: 'Reset zones',
  camera_reset_detectors: 'Reset recognition',
  camera_reset_map: 'Reset geo',
  inactivated: 'inactivated',
  sec: 'sec',
  ms: 'ms',
  px: 'px',
  enable_liveness: 'enable liveness',
  enable_recorder: 'record video',
  camera_id: 'Camera ID',
  camera_status: 'Camera status',
  disabled: 'DISABLED',
  connection_type: 'Connection type',
  resolution: 'Resolution',
  camera_name: 'Camera name',
  ds_camera_group: 'Camera group',
  description_optional: 'Description',
  enter_description_optional: 'Enter description',
  streaming_protocol_url: 'Stream URL',
  connection_onvif: 'ONVIF',
  connection_stream: 'stream',
  connection_external: 'external detector',
  connection_external_vms: 'external VMS',
  camera_group: 'Camera group',
  description: 'description',
  external_detector_name: 'External detector name',
  enter_external_detector_name: 'Enter external detector name',
  external_detector_token: 'Token',
  external_detector_camera_group: 'Initial camera group',
  select_external_detector_camera_group: 'Select initial camera group',
  faces_posted: 'faces posted',
  faces_failed: 'faces failed',
  faces_not_posted: 'faces not posted',
  bodies_posted: 'bodies posted',
  bodies_failed: 'bodies failed',
  bodies_not_posted: 'bodies not posted',
  cars_posted: 'vehicles posted',
  cars_failed: 'vehicles failed',
  cars_not_posted: 'vehicles not posted',
  job_starts: 'Job starts',
  decoding_errors: 'Decoding errors',
  objects_posted: 'Objects posted',
  objects_failed: 'Objects failed',
  objects_not_posted: 'Objects not posted',
  objects_statistics: 'objects statistics',
  back: 'back',
  add_and_config: 'Add and configure',
  add: 'Add',
  add_cameras_phrase_1: 'Add multiple URLs using Enter or',
  add_cameras_phrase_2: '. You can also write a CSV formatted list right in the field above.',
  add_cameras_phrase_3: 'Supported format: "Name | Camera group | Stream URL or device IP address".',
  upload_csv: 'upload CSV file',
  download_sample_csv: 'Download CSV file sample',
  stream_url: 'Stream URL',
  enter_stream_url: 'Enter stream URL',
  stream: 'Stream',
  login: 'Login',
  login_enter: 'Enter login',
  password: 'Password',
  password_enter: 'Enter password',
  save_and_check: 'Save and check',
  discard: 'Discard',
  ds_name: 'Name',
  ds_info: 'Info',
  latest_device_events: 'Latest device events',
  you_are_adding: 'You are adding',
  camera_ip_address: 'Camera IP Address',
  stream_profile: 'Stream Profile',
  id: 'ID'
};
