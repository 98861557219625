import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = { class: "n-button-simple__label" }
const _hoisted_3 = {
  key: 0,
  class: "n-button__dots-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NCounter = _resolveComponent("NCounter")!
  const _component_NLoadingDots = _resolveComponent("NLoadingDots")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.cssClass),
    type: _ctx.nativeType,
    disabled: _ctx.disabled
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(['n-button__content', { 'n-button__content_invisible': _ctx.loading }]),
      ref: "content"
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_NIcon, {
            key: 0,
            class: "n-button__icon",
            name: _ctx.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (_ctx.color)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "n-button-simple__color",
            style: _normalizeStyle({ backgroundColor: _ctx.color })
          }, null, 4))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ])
      ]),
      (_ctx.counterEnabled)
        ? (_openBlock(), _createBlock(_component_NCounter, {
            key: 2,
            class: "n-button-simple__counter",
            value: _ctx.counter
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.suffixIcon)
        ? (_openBlock(), _createBlock(_component_NIcon, {
            key: 3,
            class: "n-button__icon-suffix",
            name: _ctx.suffixIcon,
            width: "20",
            height: "20"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(_component_NLoadingDots)
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}