import { ISelectItem } from '@/uikit';

function translateItems(items: ISelectItem[], $t: any, tOptions = 'f') {
  return items.map((item) => {
    if (item.i18n_label) {
      item.label = $t(item.i18n_label, tOptions);
    }
    return item;
  });
}

function translateProps(props: Record<string, any> | undefined, $t: any, tOptions = 'f') {
  if (!props) {
    return props;
  }
  let translatedProps: Record<string, any> = {};
  Object.keys(props)
    .filter((key) => props[key] !== undefined)
    .forEach((key) => {
      if (key.startsWith('i18n_')) {
        translatedProps[key.substr(5)] = $t(props[key], tOptions);
      } else if (key === 'items') {
        translatedProps[key] = translateItems(props[key], $t, tOptions);
      } else {
        translatedProps[key] = props[key];
      }
    });
  return translatedProps;
}

export default translateProps;
