
import { NIcon } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FilterAgeRange from '@/components/common/filter/FilterAgeRange.vue';

@Options({
  components: {
    FilterAgeRange,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallAgeRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: String, default: 'age_gte' })
  readonly gteProp!: string;

  @Prop({ type: String, default: 'age_lte' })
  readonly lteProp!: string;

  @Prop({ type: String })
  readonly label?: string;
}
