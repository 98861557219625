
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { PageName } from '@/store/application/page.definitions';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import { getParticipantFilters } from '@/components/participants/forms/ParticipantFilters';
import { smallFilterManagerModule } from '@/store/filter-manager/SmallFiltersModule';

@Options({
  components: { FiltersBig, FiltersSmall },
  emits: ['reset', 'update:modelValue']
})
export default class FilterSection extends Vue {
  @Prop({ type: [Object, Array], required: true })
  readonly modelValue!: any;

  @Prop({ type: [Object], required: true })
  readonly state!: any;

  @Prop({ type: Boolean, default: false })
  private hasFilterManager!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasFilterChanges!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly supportSearch!: boolean;

  @Prop({ type: [Object], required: true })
  readonly customLarge!: any;

  @Prop({ type: [Object], required: true })
  readonly customSmall!: any;

  @Prop({ type: Boolean, default: false })
  readonly hasReport!: boolean;

  @Prop({ type: String, default: '' })
  readonly type!: string;

  isFilterVisible = false;

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get smallFilterFields() {
    return smallFilterManagerModule.items[this.type];
  }

  setSmallFilterFields(v: string[]) {
    smallFilterManagerModule.setFiltersBySource(this.type, v, true);
  }

  get bigFilterLayout() {
    if (this.customLarge) {
      return this.customLarge;
    }

    const { pageType, objectType, episodeType } = this.state;
    const options = { small: false };
    if (pageType === PageName.clusters) {
      return getClusterFiltersBuilder(options).getFilterByType(pageType, objectType);
    } else if (pageType === PageName.participants) {
      return getParticipantFilters(options);
    } else {
      return getEventFiltersBuilder(options).getFilterByType(pageType, objectType, episodeType);
    }
  }

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  get smallFilterLayout() {
    if (this.customSmall) {
      return this.customSmall;
    }

    const { pageType, objectType, episodeType } = this.state;
    const options = { small: true };
    if (pageType === PageName.clusters) {
      return getClusterFiltersBuilder(options).getFilterByType(pageType, objectType);
    } else if (pageType === PageName.participants) {
      return getParticipantFilters(options);
    } else {
      return getEventFiltersBuilder(options).getFilterByType(pageType, objectType, episodeType);
    }
  }

  resetFilters() {
    this.$emit('reset');
  }
}
