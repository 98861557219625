import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "counter-records" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_NInfiniteScroll = _resolveComponent("NInfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilterSection, {
      "custom-large": _ctx.filters.getLarge(),
      "custom-small": _ctx.filters.getSmall(),
      "has-filter-changes": _ctx.module.hasFilterChanges,
      state: { pageType: 'counters' },
      onReset: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.resetFilters())),
      "has-report": "",
      modelValue: _ctx.module.filter.current,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.filter.current) = $event))
    }, null, 8, ["custom-large", "custom-small", "has-filter-changes", "modelValue"]),
    _createVNode(_component_NTable, {
      columns: _ctx.columns,
      content: _ctx.module.items,
      onCellClick: _ctx.handleTableAction,
      hover: "",
      activeRow: ""
    }, null, 8, ["columns", "content", "onCellClick"]),
    _createVNode(_component_NInfiniteScroll, {
      filters: _ctx.module.filter.current,
      onLoadMore: _cache[2] || (_cache[2] = ($event: any) => (_ctx.module.append()))
    }, null, 8, ["filters"])
  ]))
}