import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "sidebar-events-page__loading"
}
const _hoisted_2 = {
  key: 2,
  class: "sidebar-events-page__no-data-item label-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventItemV2 = _resolveComponent("EventItemV2")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_openBlock(), _createElementBlock("div", {
    class: "episode-events-page",
    ref: "events",
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollHandler && _ctx.scrollHandler(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstModule.items, (item) => {
      return (_openBlock(), _createBlock(_component_EventItemV2, {
        key: item.id,
        objects: _ctx.firstObjectType,
        item: item,
        type: "events",
        display: "",
        hideAcknowledge: "",
        defaultAction: _ctx.defaultEventAction,
        selected: _ctx.firstModule.getIsSelectedItem(item.id),
        iconActions: _ctx.iconActions,
        onSelect: () => _ctx.firstModule.toggleSelectedItem(item.id),
        onAction: (v) => _ctx.actionHandler(item.id, v, item)
      }, null, 8, ["objects", "item", "defaultAction", "selected", "iconActions", "onSelect", "onAction"]))
    }), 128)),
    (_ctx.secondModule)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.secondModule.items, (item) => {
          return (_openBlock(), _createBlock(_component_EventItemV2, {
            key: item.id,
            objects: _ctx.secondObjectType,
            item: item,
            type: "events",
            display: "",
            hideAcknowledge: "",
            defaultAction: _ctx.defaultEventAction,
            selected: _ctx.secondModule.getIsSelectedItem(item.id),
            iconActions: _ctx.iconActions,
            onSelect: () => _ctx.secondModule.toggleSelectedItem(item.id),
            onAction: (v) => _ctx.actionHandler(item.id, v, item)
          }, null, 8, ["objects", "item", "defaultAction", "selected", "iconActions", "onSelect", "onAction"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.firstModule.loading || _ctx.firstModule.appending)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_NLoadingCircle)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.firstModule.items.length && !_ctx.firstModule.loading && !_ctx.secondModule?.items?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('common.no_data', 'f')), 1))
      : _createCommentVNode("", true)
  ], 544))
}