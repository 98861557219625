
import { AuthSession } from '@/api';
import translateProps from '@/common/translate-props';
import { aclModule, DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl';
import { IItemAclResult, IModelAclResult } from '@/store/acl/types';
import { IMultitoolAction, IMultitoolActionI18N, NButton, NDropdown, NDropdownItem, NMultitool } from '@/uikit';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

const ActionBlockConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.Block,
  i18n_label: 'common.block'
};

const ActionCloseConfig: IMultitoolActionI18N = {
  name: ItemsActionNames.Close,
  i18n_label: 'common.terminate'
};

type IMultitoolItem = AuthSession;

@Options({
  name: 'SessionsMultitool',
  components: { NButton, NMultitool, NDropdown, NDropdownItem, NDoubleButton }
})
export default class SessionsMultitool extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly extraActions!: IMultitoolActionI18N[];

  @Prop({ type: Array, default: () => [] })
  readonly items!: IMultitoolItem[];

  @Prop({ type: Number, default: 0 })
  readonly changedCount!: number;

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: IMultitoolItem[];

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: IModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: IItemAclResult;

  @Prop({ type: Boolean, default: true })
  readonly available = false;

  get isAllSelected() {
    return this.selectedItems?.length === this.items?.length;
  }

  get visibleBlockAction() {
    let action = this.getAction(ItemsActionNames.Block);
    return this.available && action ? { ...action, hidden: false } : null;
  }

  get visibleCloseAction() {
    let action = this.getAction(ItemsActionNames.Close);
    return this.available && action ? { ...action, hidden: false } : null;
  }

  get availableActions() {
    let actions = [] as IMultitoolActionI18N[];
    this.arePermissionsGrantedForSessionBlock() && actions.push(ActionBlockConfig);
    this.arePermissionsGrantedForCloseSession() && actions.push(ActionCloseConfig);
    actions.push(...this.extraActions);

    return actions.map((action) => translateProps(action, this.$t, 'f') as IMultitoolAction);
  }

  getAction(name: string) {
    return this.availableActions.find((action) => action.name === name);
  }

  handleClose() {
    this.$emit('action', ItemsActionNames.DeselectAll);
  }

  private arePermissionsGrantedForSessionBlock(): boolean {
    return aclModule.getAccess('ffsecurity.add_deviceblacklistrecord');
  }

  private arePermissionsGrantedForCloseSession(): boolean {
    return aclModule.getAccess('ffsecurity.delete_all_own_sessions');
  }
}
