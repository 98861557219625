
import { Options, Vue } from 'vue-class-component';
import { h } from '@vue/runtime-core';
import { TypeEnum } from '@/api';
import { dataServiceRepository } from '@/api/common';
import ReportNotification from './ReportNotification.vue';
import {
  areaIdInputSchemaItem,
  camerasInSelectSchemaItem,
  camerasOutSelectSchemaItem,
  nameInputSchemaItem,
  picturesSelectSchemaItem,
  reportFormatCheckboxGroupSchemaItem,
  reportTypeSelectSchemaItem,
  dataTypeSelectSchemaItem
} from './reports-form-schema';
import NButton from '@/uikit/buttons/NButton.vue';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
import notify from '@/uikit/notification/helpers/notification';
import { ReportFormModelType } from './ReportsFormController';

@Options({
  name: 'ReportsForm',
  components: {
    NModalWindow,
    NButton,
    NForm,
    ReportNotification
  }
})
export default class ReportsForm extends Vue {
  private type: TypeEnum | undefined;

  created() {
    this.type = this.model.type;
  }

  get model(): ReportFormModelType {
    return this.$reportsForm.model;
  }

  get layout(): IFormLayout {
    if (this.type === TypeEnum.FACE_EVENTS) {
      if (this.model.type === TypeEnum.FACE_EVENTS_IN_OUT)
        return [
          nameInputSchemaItem(),
          reportFormatCheckboxGroupSchemaItem(),
          reportTypeSelectSchemaItem(TypeEnum.FACE_EVENTS),
          picturesSelectSchemaItem(),
          camerasInSelectSchemaItem(this.model.meta?.cameras_out && Number(this.model.meta.cameras_out)),
          camerasOutSelectSchemaItem(this.model.meta?.cameras_in && Number(this.model.meta.cameras_in))
        ];
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), reportTypeSelectSchemaItem(TypeEnum.FACE_EVENTS), picturesSelectSchemaItem()];
    }
    if (this.type === TypeEnum.AREAS) {
      if (this.model.type === TypeEnum.AREA_ACTIVATIONS_IN_OUT)
        return [
          nameInputSchemaItem(),
          reportFormatCheckboxGroupSchemaItem(),
          reportTypeSelectSchemaItem(TypeEnum.AREAS, TypeEnum.AREA_ACTIVATIONS_IN_OUT),
          areaIdInputSchemaItem(),
          picturesSelectSchemaItem()
        ];
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), reportTypeSelectSchemaItem(TypeEnum.AREAS, TypeEnum.AREA_ACTIVATIONS_IN_OUT)];
    }
    if (this.type === TypeEnum.AREA_ACTIVATIONS) {
      if (this.model.type === TypeEnum.AREA_ACTIVATIONS_IN_OUT)
        return [
          nameInputSchemaItem(),
          reportFormatCheckboxGroupSchemaItem(),
          reportTypeSelectSchemaItem(TypeEnum.AREA_ACTIVATIONS),
          areaIdInputSchemaItem(),
          picturesSelectSchemaItem()
        ];
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), reportTypeSelectSchemaItem(TypeEnum.AREA_ACTIVATIONS)];
    }
    if (this.type === TypeEnum.AREA_RECORDS) {
      if (this.model.type === TypeEnum.AREA_ACTIVATIONS_IN_OUT)
        return [
          nameInputSchemaItem(),
          reportFormatCheckboxGroupSchemaItem(),
          reportTypeSelectSchemaItem(TypeEnum.AREA_RECORDS, TypeEnum.AREA_ACTIVATIONS_IN_OUT),
          areaIdInputSchemaItem()
        ];
      return [
        nameInputSchemaItem(),
        reportFormatCheckboxGroupSchemaItem(),
        reportTypeSelectSchemaItem(TypeEnum.AREA_RECORDS, TypeEnum.AREA_ACTIVATIONS_IN_OUT),
        picturesSelectSchemaItem()
      ];
    }
    if (this.type === TypeEnum.AUDIT_LOGS) {
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem()];
    }
    if (this.type === TypeEnum.KYC) {
      if (this.model.type === TypeEnum.FACE_CLUSTER_EVENTS) {
        return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), picturesSelectSchemaItem(), dataTypeSelectSchemaItem()];
      }
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), dataTypeSelectSchemaItem()];
    }
    return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), picturesSelectSchemaItem()];
  }

  get size() {
    return NModalWindowSize.Medium;
  }

  getIsFormValid(): boolean {
    const result = this.$refs.infoForm ? this.$refs.infoForm.validate() : true;
    if (!result) {
      this.$refs.infoForm.displayErrors();
    }
    return result;
  }

  async createHandler() {
    if (!this.getIsFormValid()) return;
    try {
      await dataServiceRepository.ReportsService.create(this.model);
      await notify({ content: () => h(ReportNotification), showClose: true });
      await this.closeHandler();
    } catch (err) {
      await notify({ content: 'Error', showClose: true, type: 'error' });
    }
  }

  closeHandler() {
    this.$reportsForm.hide();
  }
}
