import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_VideoProcessPreviewBar = _resolveComponent("VideoProcessPreviewBar")!
  const _component_VideoProcessPreviewItems = _resolveComponent("VideoProcessPreviewItems")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (`${_ctx.model}${_ctx.cameraId}`)
    ? (_openBlock(), _createBlock(_component_NModalWindow, {
        key: 0,
        customSize: "90vw",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
        "close-disabled": true,
        "close-icon": false,
        showHeader: false,
        "has-margins": false
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_VideoPlayer, {
            key: `${_ctx.model}${_ctx.cameraId}`,
            model: _ctx.model,
            cameraId: _ctx.cameraId,
            "show-timeline": false,
            "show-bottom-bar": false,
            "show-controls": false,
            closeable: "",
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["model", "cameraId"])),
          _createVNode(_component_VideoProcessPreviewBar, { item: _ctx.videoArchive }, null, 8, ["item"]),
          _createVNode(_component_VideoProcessPreviewItems, { item: _ctx.videoArchive }, null, 8, ["item"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}