import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "participant_related_items" }
const _hoisted_2 = {
  key: 0,
  class: "participant_related_items__group"
}
const _hoisted_3 = { class: "heading-m" }
const _hoisted_4 = { class: "participant_related_items__group-list" }
const _hoisted_5 = {
  key: 1,
  class: "participant_related_items__group"
}
const _hoisted_6 = { class: "heading-m" }
const _hoisted_7 = { class: "participant_related_items__group-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantItem = _resolveComponent("ParticipantItem")!
  const _component_CardItem = _resolveComponent("CardItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.enrichRelatedParticipants.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('cases.related_participants')) + ":", 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enrichRelatedParticipants, (item) => {
              return (_openBlock(), _createBlock(_component_ParticipantItem, {
                "show-fullframe": true,
                item: item,
                key: item.id,
                onClick: ($event: any) => (_ctx.participantClick(item))
              }, null, 8, ["item", "onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.relatedCards.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('cases.related_cards')) + ":", 1),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enrichRelatedCards, (item) => {
              return (_openBlock(), _createBlock(_component_CardItem, {
                "show-fullframe": true,
                key: item.id,
                item: item,
                onClick: ($event: any) => (_ctx.cardClick(item))
              }, null, 8, ["item", "onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}