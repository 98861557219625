export default {
  'area-activations-in-out': 'области (время входа/выхода)',
  'area-activations': 'активации области',
  'area-records': 'записи области',
  'audit-logs': 'журнал действий',
  'body-cluster-events': 'события кластера силуэтов',
  'body-clusters': 'кластеры силуэтов',
  'body-events': 'события с силуэтами',
  'car-cards': 'карточки ТС',
  'car-cluster-events': 'события кластера ТС',
  'car-clusters': 'кластеры ТС',
  'car-episodes': 'эпизоды с ТС',
  'car-events': 'события с ТС',
  'face-cluster-events-in-out': 'события кластера лиц (время входа/выхода)',
  'face-cluster-events': 'события кластера лиц',
  'face-clusters': 'кластеры лиц',
  'face-events-in-out': 'события с лицами (время входа/выхода)',
  'face-events': 'события с лицами',
  'human-cards': 'карточки людей',
  'human-episodes': 'эпизоды с людьми',
  areas: 'области',
  cameras: 'камеры',
  completed: 'завершено',
  counters: 'счетчики',
  csv: 'CSV',
  delete: 'удалить',
  download: 'скачать',
  downloading_report: 'Скачивание отчета',
  downloading_reports: 'Скачивание отчетов',
  error: 'ошибка',
  fullframe: 'полный кадр',
  gb: 'Гбайт',
  id: 'ID',
  json: 'JSON',
  kb: 'кбайт',
  kyc: 'аналитика',
  link: 'ссылка',
  mb: 'Мбайт',
  modified: 'изменено',
  name: 'имя',
  processing: 'обработка',
  records: 'записи',
  report_by_area_activations_in_out: 'отчет по областям (время входа/выхода)',
  report_by_area_activations: 'отчет по активациям области',
  report_by_area_records: 'отчет по записям области',
  report_by_areas: 'отчет по областям',
  report_by_audit_logs: 'отчет по журналу действий',
  report_by_body_clusters: 'отчет по кластерам силуэтов',
  report_by_body_events: 'отчет по событиям с силуэтами',
  report_by_cameras: 'отчет по камерам',
  report_by_car_cards: 'отчет по карточкам ТС',
  report_by_car_clusters: 'отчет по кластерам ТС',
  report_by_car_episodes: 'отчет по эпизодам с ТС',
  report_by_car_events: 'отчет по событиям с ТС',
  report_by_counters: 'отчет по счетчикам',
  report_by_face_clusters: 'отчет по кластерам лиц',
  report_by_face_events_in_out: 'отчет по событиям с лицами (время входа/выхода)',
  report_by_face_events: 'отчет по событиям с лицами',
  report_by_human_cards: 'отчет по карточкам людей',
  report_by_human_episodes: 'отчет по эпизодам с людьми',
  report_by_kyc: 'отчет по аналитике',
  report_format: 'формат отчета',
  report_type: 'тип отчета',
  report: 'отчет',
  reports_page: 'страницу отчетов',
  save_report_images_as: 'сохранить изображения в отчете как',
  scheduled: 'запланировано',
  size: 'размер',
  standard_report: 'стандартный отчет',
  work_time_report: 'учёт рабочего времени',
  camera_entry: 'камера входа',
  camera_exit: 'камера выхода',
  status: 'статус',
  successful_report_creation: 'Отчет сформирован. Перейти на',
  thumbnail: 'миниатюра | миниатюры',
  type: 'тип',
  update: 'обновить',
  xls: 'XLS',
  id_areas: 'ID области',
  analytic_select_data: 'выберите данные для включения в отчёт',
  analytic_output_data: 'итоговые данные',
  analytic_input_data: 'исходные данные для графиков'
};
