
import { PuppeteerRemoteMonitoringEventsFilter } from '@/api/models/PuppeteerRemoteMonitoringEventsFilter';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import FilterDateRange from '@/components/common/filter/FilterDateRange.vue';
import ImageViewer from '@/components/image-viewer/ImageViewer.vue';
import CardSelect from '@/pages/external-search/requests/CardSelect.vue';
import ListPage from '@/pages/ListPage.vue';
import { ListViewModel } from '@/definitions/view-models';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { PageViewModel } from '@/store/application/page.view.model';
import { filterManagerModule } from '@/store/filter-manager';
import { websocketPuppeteerModule } from '@/store/ws/websocket.module';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { satisfyPuppeteerRemoteMonitoringEvent } from './helpers';
import RemoteMonitoringEventItem from './RemoteMonitoringEventItem.vue';
import { ViewerItem } from './types';
import { monitoringModule } from '@/pages/cards/monitoring/MonitoringModule';

@Options({
  name: 'RemoteMonitoringEventsPage',
  components: {
    FilterDateRange,
    CardSelect,
    NBaseBar,
    NButton,
    ImageViewer,
    RemoteMonitoringEventItem,
    ListPage
  }
})
export default class RemoteMonitoringEventsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  private viewerItem: ViewerItem | null = null;
  private newEvents: RemoteMonitoringEvent[] = [];
  private pageVM: PageViewModel<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter> = new PageViewModel<
    RemoteMonitoringEvent,
    PuppeteerRemoteMonitoringEventsFilter
  >({
    tab: String(Math.random()),
    name: PageName.remote_monitoring_events
  });

  get state(): PageState {
    return this.pageVM.state as any;
  }

  get module() {
    return this.pageVM.module as ListViewModel<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter>;
  }

  get websocketModule() {
    return websocketPuppeteerModule;
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get playing() {
    return this.pageVM.state.playing;
  }

  get items() {
    return this.playing ? [...this.newEvents, ...this.module.items] : this.module.items;
  }

  get currentFilter() {
    return this.module.filter.current;
  }

  @Watch('websocketModule.acknowledgedAll')
  unacknowledgedHandler(v: boolean) {
    if (v) this.refresh();
  }

  @Watch('websocketModule.updatedMonitoringEvent')
  monitoringEventChanged(v: RemoteMonitoringEvent) {
    const item = this.items.find((item: RemoteMonitoringEvent) => item.id === v.id);
    if (item) {
      Object.assign(item, v);
    }
  }

  async created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    this.pageVM = reactive(pageModule.getPageViewModel(route));
    await this.monitoringModule.load();
    await filterManagerModule.getFilters();
  }

  togglePlaying() {
    this.pageVM.togglePlaying();
    this.currentFilter.created_date_lte = this.playing ? undefined : new Date().toISOString();
    this.refresh();
  }

  toggleViewer(item: ViewerItem | undefined = undefined) {
    this.viewerItem = item ?? null;
    this.state.showViewer = !this.state.showViewer;
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  async acknowledgeAll() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeAllCreate();
    await this.module.get();
  }

  @Watch('websocketModule.monitoringEvent', { deep: false })
  handleNewEvent(remoteMonitoringEvent: RemoteMonitoringEvent) {
    satisfyPuppeteerRemoteMonitoringEvent(this.module.filter.current, remoteMonitoringEvent) && this.newEvents.unshift(remoteMonitoringEvent);
  }

  @Watch('websocketModule.updateMonitoringEvent', { deep: true })
  handleUpdateEvent(remoteMonitoringEvent: RemoteMonitoringEvent) {
    if (satisfyPuppeteerRemoteMonitoringEvent(this.currentFilter, remoteMonitoringEvent)) {
      let event = this.items.find((v) => v.id === remoteMonitoringEvent.id);
      if (event) {
        Object.assign(event, remoteMonitoringEvent);
      }
    }
  }

  @Watch('currentFilter', { deep: true })
  async refresh() {
    await this.module.get();
    this.newEvents = [];
  }

  @Watch('currentFilter.created_date_lte')
  handlerFilterDateLte(v: any, p: any) {
    if (v) {
      this.pageVM.state.playing = false;
    } else {
      this.pageVM.state.playing = true;
    }
  }
}
