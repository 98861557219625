import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "n-launchpad" }
const _hoisted_2 = { class: "n-launchpad__items" }
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = { class: "n-launchpad__icon-wrapper" }
const _hoisted_5 = { class: "n-launchpad__title control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_page_icon = _resolveComponent("n-page-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "n-launchpad__item",
          key: index,
          title: item.title,
          onClick: ($event: any) => (_ctx.$emit('openNewTab', item.path))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_n_page_icon, {
              class: "n-launchpad__icon",
              icon: item.icon
            }, null, 8, ["icon"])
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "n-launchpad__arrow",
      style: _normalizeStyle({ left: _ctx.arrowLeft + 'px' })
    }, null, 4)
  ]))
}