import { resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "n-input-date",
  ref: "root"
}
const _hoisted_2 = {
  key: 0,
  class: "n-input-date__date-picker-wrapper",
  ref: "body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NDatePicker = _resolveComponent("NDatePicker")!
  const _component_NTimeInput = _resolveComponent("NTimeInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NInput, {
      ref: "nInput",
      class: _normalizeClass(['n-input-date__date label-mono-m', { 'n-input-date__date_clearable': _ctx.clearable }]),
      "onUpdate:modelValue": _ctx.dateInputHandler,
      onChange: _ctx.dateChangeHandler,
      onFocus: _ctx.showDatePicker,
      onKeydown: [
        _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.autoInsertDelimiter = false), ["backspace"])),
        _withKeys(_ctx.hideDatePicker, ["tab"])
      ],
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.autoInsertDelimiter = true), ["backspace"])),
      plain: _ctx.plain,
      modelValue: _ctx.dateUserInput,
      autofocus: _ctx.autofocus,
      placeholder: _ctx.datePlaceholder,
      clearable: _ctx.clearable,
      disabled: _ctx.disabled
    }, null, 8, ["class", "onUpdate:modelValue", "onChange", "onFocus", "onKeydown", "plain", "modelValue", "autofocus", "placeholder", "clearable", "disabled"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.datePickerShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_NDatePicker, {
              modelValue: _ctx.date ? _ctx.date.toDate() : null,
              "selected-dates": _ctx.selectedDates,
              min: _ctx.min,
              max: _ctx.max,
              isDateDisabled: _ctx.isDateDisabled,
              "onUpdate:modelValue": _ctx.dateSelectHandler
            }, null, 8, ["modelValue", "selected-dates", "min", "max", "isDateDisabled", "onUpdate:modelValue"])
          ], 512))
        : _createCommentVNode("", true)
    ])),
    (_ctx.timeEnabled)
      ? (_openBlock(), _createBlock(_component_NTimeInput, {
          key: 0,
          ref: "nTimeInput",
          class: "n-input-date__time",
          plain: _ctx.plain,
          "seconds-enabled": _ctx.secondsEnabled,
          modelValue: _ctx.time ? _ctx.time.toDate() : null,
          min: _ctx.min,
          max: _ctx.max,
          clearable: _ctx.clearable,
          placeholder: _ctx.timePlaceholder,
          disabled: _ctx.disabled,
          "onUpdate:modelValue": _ctx.timeChangeHandler
        }, null, 8, ["plain", "seconds-enabled", "modelValue", "min", "max", "clearable", "placeholder", "disabled", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 512))
}