import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterSection = _resolveComponent("FilterSection")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SortSection = _resolveComponent("SortSection")!
  const _component_NTable = _resolveComponent("NTable")!
  const _component_NInfiniteScroll = _resolveComponent("NInfiniteScroll")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_FilterSection, {
        modelValue: _ctx.module.filter.current,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.filter.current) = $event)),
        "has-filter-changes": _ctx.module.hasFilterChanges,
        state: _ctx.state,
        type: 'counters',
        "custom-large": _ctx.filterBuilder.getLarge(),
        "custom-small": _ctx.filterBuilder.getSmall(),
        "has-report": "",
        onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.module.resetFilters()))
      }, null, 8, ["modelValue", "has-filter-changes", "state", "custom-large", "custom-small"]),
      _createVNode(_component_SortSection, { module: _ctx.module }, {
        after: _withCtx(() => [
          _createVNode(_component_NButton, {
            type: "secondary",
            icon: "add",
            onClick: _ctx.addNewCounter
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('counters.add_counter')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["module"]),
      _createVNode(_component_NTable, {
        class: "counters-page__table",
        columns: _ctx.columns,
        content: _ctx.module.items,
        "selected-items-ids": _ctx.selectedItemIds,
        disabled: !_ctx.modelAcl.update,
        onAction: _ctx.handleAction,
        "onSelect:changes": _ctx.handleSelectChanges
      }, null, 8, ["columns", "content", "selected-items-ids", "disabled", "onAction", "onSelect:changes"]),
      _createVNode(_component_NInfiniteScroll, {
        filters: _ctx.module.filter.current,
        onLoadMore: _cache[2] || (_cache[2] = ($event: any) => (_ctx.module.append()))
      }, null, 8, ["filters"])
    ]),
    _: 1
  }))
}