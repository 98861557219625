
import NTable from '@/uikit/table/NTable.vue';
import { columns } from './sessions-table-schema';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SettingsMenu from '@/components/common/settings/SettingsMenu.vue';
import SessionsMultitool from '@/components/common/SessionsMultitool.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import NForm from '@/uikit/forms/NForm.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ListPage from '@/pages/ListPage.vue';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { ListViewModel } from '@/definitions/view-models';
import NSidebarMenu from '@/uikit/sidebar-menu/NSidebarMenu.vue';
import { sessionsFilters } from '@/pages/sessions/forms/small-filter';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NInput from '@/uikit/input/NInput.vue';
import BlocklistForm from '@/pages/blocklist/forms/BlocklistForm.vue';
import { aclModule } from '@/store/acl';
import SessionsAndBlocklistTabs from '@/pages/sessions/tabs/SessionsAndBlocklistTabs.vue';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import { AclModelNames } from '@/pages/sessions/tabs/tabs-helper';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { RouterModule } from '@/store/router';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  components: {
    SettingsMenu,
    SettingsPageLayout,
    NLoadingCircle,
    SettingsBar,
    SessionsMultitool,
    NTable,
    NForm,
    SidebarTab,
    SidebarHeader,
    ListPage,
    NSidebarMenu,
    FiltersSmall,
    NButtonGroup,
    NInput,
    BlocklistForm,
    SessionsAndBlocklistTabs,
    NInfiniteScroll
  }
})
export default class SessionsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  hasSidebar = false;
  sortField = '';
  showModalWindow = false;

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get blocklistModule(): ListViewModel<any, any> {
    pageModule.getPageModule(this.blocklistState).aclModelName = AclModelNames.blocklist_records;
    return pageModule.getPageModule(this.blocklistState) as unknown as ListViewModel<any, any>;
  }

  get blocklistState(): PageState {
    const tab = 'blacklist';
    return pageModule.getPageStateByTab(PageName.block_list, tab);
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.blocklistModule);
  }

  get state(): PageState {
    const tab = 'sessions';
    return pageModule.getPageStateByTab(PageName.sessions, tab);
  }

  get items() {
    return this.module.items;
  }

  get columns() {
    return columns;
  }

  get selectedItems() {
    return this.module.selectedItems.map((v) => v.item);
  }
  get sidebarModule() {
    return multisidebarModule;
  }
  activated() {
    this.sidebarModule.contentOverlap = true;
  }
  mounted() {
    this.sidebarModule.contentOverlap = true;
  }
  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }
  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  resetFilters() {
    this.module.resetFilters();
  }

  get getSmallFilterLayout() {
    return sessionsFilters;
  }

  handleModalWindowClose(actionComplete: boolean) {
    this.showModalWindow = false;
    actionComplete && this.navigateToBlocklistRecords();
  }

  handleTableSort(columnName: string) {
    this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = this.sortField;
    this.module.get();
  }

  async handleAction(actionName: ItemsActionName) {
    switch (actionName) {
      case ItemsActionNames.SelectAll:
        this.module.selectAllItems();
        break;

      case ItemsActionNames.DeselectAll:
        this.module.deselectAllItems();
        break;

      case ItemsActionNames.Block:
        this.showModalWindow = true;
        break;

      case ItemsActionNames.Close:
        await this.module.deleteAllSelectedItems();
        await this.module.get();
        break;

      default:
        console.warn(`Unknown action "${actionName}"`);
    }
  }

  private navigateToBlocklistRecords(): void {
    this.$router.push(
      RouterModule.getRouteLocation({
        name: PageName.block_list,
        tab: this.$route.query.tab ?? ''
      })
    );
  }

  @Watch('module.filter.current', { deep: true })
  currentFilterHandler(v: any, p: any) {
    this.module.get();
  }

  @Watch('selectedItems')
  selectedItemsHandler(v: any[]) {
    if (v.length) {
      this.module.setCurrentItem(v[v.length - 1].id);
    } else {
      this.hasSidebar = false;
    }
  }

  @Watch('$route.path')
  changeRoutePath(v: string) {
    if (v === '/sessions') {
      this.module.get();
    }
  }
}
