import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "interactions-section interactions-events"
}
const _hoisted_2 = { class: "interactions-header" }
const _hoisted_3 = { class: "interactions-header__content heading-l" }
const _hoisted_4 = { class: "interactions-content" }
const _hoisted_5 = { class: "interactions-section" }
const _hoisted_6 = { class: "interactions-header" }
const _hoisted_7 = { class: "interactions-header__content heading-l" }
const _hoisted_8 = { class: "interactions-header__filter" }
const _hoisted_9 = {
  key: 0,
  class: "interactions-content",
  ref: "container"
}
const _hoisted_10 = {
  key: 0,
  class: "interactions-banner"
}
const _hoisted_11 = {
  key: 1,
  class: "interactions-banner"
}
const _hoisted_12 = {
  key: 2,
  class: "interactions-banner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_interaction_item = _resolveComponent("interaction-item")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_interaction_cluster_item = _resolveComponent("interaction-cluster-item")!
  const _component_NInfiniteScroll = _resolveComponent("NInfiniteScroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.module.currentItem?.item?.matched_cluster_events)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('common.events', 'f')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.currentItem.item.matched_cluster_events, (clusterEvents) => {
              return (_openBlock(), _createBlock(_component_interaction_item, {
                key: clusterEvents.join('-'),
                "video-enabled": _ctx.videoEnabled,
                items: clusterEvents
              }, null, 8, ["video-enabled", "items"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_NButton, {
            type: "secondary",
            counter: _ctx.filtersCount,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('display-filters')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.filters', 'f')), 1)
            ]),
            _: 1
          }, 8, ["counter"])
        ])
      ]),
      (_ctx.module)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.items, (item) => {
              return (_openBlock(), _createBlock(_component_interaction_cluster_item, {
                key: 'ici_' + item.id,
                item: item,
                objects: 'faces',
                selected: _ctx.module.getIsCurrentItem(item.id),
                onClick: ($event: any) => (_ctx.module.setCurrentItem(item.id))
              }, null, 8, ["item", "selected", "onClick"]))
            }), 128)),
            (_ctx.module.loaded && !_ctx.module.items.length && !_ctx.module.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('common.no_items', 'f')), 1))
              : _createCommentVNode("", true),
            (_ctx.module.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('common.loading', 'f')), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_NInfiniteScroll, {
              filters: _ctx.module.filter.current,
              onLoadMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.module.append()))
            }, null, 8, ["filters"]),
            (_ctx.module.appending)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('common.loading', 'f')), 1))
              : _createCommentVNode("", true)
          ], 512))
        : _createCommentVNode("", true)
    ])
  ], 64))
}