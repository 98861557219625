
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import { copyTextToClipboard } from '@/uikit/helpers';

@Options({
  name: 'SimpleText',
  components: { NButton }
})
export default class SimpleText extends Vue {
  @Prop({ type: [String, Number], default: '' })
  readonly modelValue!: string | number;

  @Prop({ type: Boolean, default: false })
  readonly accent!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly clickable!: boolean;

  @Prop({ type: String, default: 'break-word' })
  readonly overflowType!: string;

  @Prop({ type: Boolean, default: false })
  readonly copy!: boolean;

  @Prop({ type: String, default: '' })
  readonly defaultText!: string;

  private copyComplete = false;

  @Prop({
    type: String,
    default: 'label-m'
  })
  baseClass!: string;

  get text() {
    return this.modelValue === '' ? this.defaultText : this.modelValue;
  }

  get classes() {
    return {
      'simple-text_accent': this.accent,
      'simple-text_clickable': this.clickable,
      'simple-text_ellipsis': this.overflowType === 'ellipsis',
      'simple-text_break-word': this.overflowType === 'break-word',
      [this.baseClass]: this.baseClass
    };
  }

  handleClick() {
    this.clickable && this.$emit('update:modelValue', this.modelValue);
  }

  async copyToClipboard() {
    this.copyComplete = await copyTextToClipboard(String(this.modelValue));
  }
}
