import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!

  return (_openBlock(), _createBlock(_component_SimpleText, {
    "model-value": _ctx.label,
    accent: "",
    clickable: "",
    onClick: _ctx.handleClick
  }, null, 8, ["model-value", "onClick"]))
}