import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FiltersSmall, {
      key: "smallFilters",
      state: _ctx.state,
      "form-layout": _ctx.smallFilterLayout,
      "has-changes": _ctx.hasFilterChanges,
      "has-filter-manager": _ctx.hasFilterManager,
      "has-report": _ctx.hasReport,
      "supports-search": _ctx.supportSearch,
      type: _ctx.type,
      enabledFields: _ctx.smallFilterFields,
      "onUpdate:enabledFields": _cache[0] || (_cache[0] = (v) => _ctx.setSmallFilterFields(v)),
      onOpenFilters: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFilterVisible = true)),
      onResetFilters: _ctx.resetFilters,
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event))
    }, {
      pre: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _: 3
    }, 8, ["state", "form-layout", "has-changes", "has-filter-manager", "has-report", "supports-search", "type", "enabledFields", "onResetFilters", "modelValue"]),
    (_ctx.isFilterVisible)
      ? (_openBlock(), _createBlock(_component_FiltersBig, {
          key: "bigFilters",
          "form-layout": _ctx.bigFilterLayout,
          "has-report": _ctx.hasReport,
          state: _ctx.state,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFilterVisible = false)),
          enabledFields: _ctx.smallFilterFields,
          "onUpdate:enabledFields": _cache[4] || (_cache[4] = (v) => _ctx.setSmallFilterFields(v)),
          onResetFilters: _ctx.resetFilters,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
        }, null, 8, ["form-layout", "has-report", "state", "enabledFields", "onResetFilters", "modelValue"]))
      : _createCommentVNode("", true)
  ]))
}