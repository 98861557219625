
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import DragHelper from './DragHelper';
import NIcon from '@/uikit/icons/NIcon.vue';
import Button from '@/stories/Button.vue';
import { request as __request } from '@/api/core/request';

@Options({
  name: 'VideoPlayerPtzPanel',
  components: { Button, NIcon },
  directives: { DragHelper }
})
export default class VideoPlayerPtzPanel extends Vue {
  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  updatePosition(dx: number, dy: number) {
    const pan = dx / 20;
    const tilt = dy / 20;
    __request({
      method: 'POST',
      path: `/cameras/${this.cameraId}/ptz/`,
      body: {
        movement_type: 'relative',
        pan,
        tilt
      }
    });
  }

  resetPosition() {
    __request({
      method: 'POST',
      path: `/cameras/${this.cameraId}/ptz/`,
      body: {
        movement_type: 'absolute',
        pan: 1e-6,
        tilt: 1e-6
      }
    });
  }

  updateZoom(delta: number) {
    __request({
      method: 'POST',
      path: `/cameras/${this.cameraId}/ptz/`,
      body: {
        movement_type: 'relative',
        zoom: delta / 20
      }
    });
  }
}
