
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton, { NButtonSize, NButtonType } from '@/uikit/buttons/NButton.vue';
import NButtonsDoubleGroup from '@/uikit/buttons/NButtonsDoubleGroup.vue';
import ClickOutside from '@/directives/click-outside';

@Options({
  name: 'NDoubleButton',
  components: { NButtonsDoubleGroup, NButton },
  directives: {
    ClickOutside
  }
})
export default class NDoubleButton extends Vue {
  @Prop({ type: String, default: NButtonType.Primary })
  type?: NButtonType;

  @Prop({ type: String, default: NButtonSize.Normal })
  size?: NButtonSize;

  @Prop({ type: [Number, String] })
  readonly counter?: number | string;

  dropdownStatus = false;

  triggerDropdown() {
    this.dropdownStatus = !this.dropdownStatus;
    this.$emit('toggle', this.dropdownStatus);
  }

  closeDropdown() {
    this.dropdownStatus = false;
  }
}
