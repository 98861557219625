import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content-not-found" }
const _hoisted_2 = { class: "multisidebar-content-not-found__content" }
const _hoisted_3 = { class: "heading-l" }
const _hoisted_4 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarHeader = _resolveComponent("SidebarHeader")!
  const _component_NThemeImage = _resolveComponent("NThemeImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SidebarHeader, { onAction: _ctx.actionHandler }, null, 8, ["onAction"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('errors.sorry')) + "!", 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('errors.server.notfound_desc')), 1),
      _createVNode(_component_NThemeImage, {
        class: "multisidebar-content-not-found__image",
        "css-var": "--image-not-found-alien"
      })
    ])
  ]))
}