
import SettingsBar from '@/components/common/SettingsBar.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: { NTable, SettingsBar },
  emits: ['action']
})
export default class ModuleTable extends Vue {
  @Prop({ type: Object, required: true })
  readonly columns!: any;

  @Prop({ type: Object, required: true })
  readonly module!: any;

  @Prop({ type: String, default: '' })
  readonly searchQuery!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  sortField = '';

  get items() {
    let items = this.searchQuery.length > 0 ? this.filterItemsByName(this.module.items, this.searchQuery) : this.module.items;
    this.sortField && items.sort(this.sortByField(this.sortField));
    return items;
  }

  filterItemsByName(items: any[], query: string) {
    const re = new RegExp(query, 'i');
    return items.filter((item) => re.test(item.name));
  }

  sortByField(fieldName: any) {
    let [field, direction] = fieldName.charAt(0) === '-' ? [fieldName.slice(1), -1] : [fieldName, 1];
    return function (a: any, b: any) {
      return a[field].localeCompare(b[field]) * direction;
    };
  }

  handleSort(columnName: string) {
    if (columnName === 'name') {
      this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    }
  }

  handleAction(payload: ITableCellChangePayload) {
    this.$emit('action', payload);
  }
}
