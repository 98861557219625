
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import VideoProcessPreviewBar from '@/pages/videos/process-preview/VideoProcessPreviewBar.vue';
import VideoProcessPreviewItems from '@/pages/videos/process-preview/VideoProcessPreviewItems.vue';
import { VideoArchive } from '@/api';

@Options({
  name: 'VideoProcessDialog',
  components: { VideoProcessPreviewItems, VideoProcessPreviewBar, VideoPlayer, NModalWindow }
})
export default class VideoProcessDialog extends Vue {
  @Prop({ type: String, required: true })
  readonly model!: string;

  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  @Prop({ type: Object, required: true })
  videoArchive!: VideoArchive;
}
