import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "participant_cluster_events" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clusterEvents, (item) => {
      return (_openBlock(), _createBlock(_component_NThumbnail, {
        class: "participant_cluster_event",
        key: item.id,
        "model-value": item.thumbnail,
        onClick: ($event: any) => (_ctx.showImageGallery(item))
      }, null, 8, ["model-value", "onClick"]))
    }), 128))
  ]))
}