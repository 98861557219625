
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import NThemeImage from '@/uikit/image/NThemeImage.vue';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';

@Options({
  name: 'MultisidebarContentNotFound',
  components: { SidebarHeader, NThemeImage }
})
export default class MultisidebarContentNotFound extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<any>>;

  actionHandler(action: ItemsActionName) {
    actionHandler.run(action, this.sidebarItem);
  }
}
