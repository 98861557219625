/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TypeEnum {
    FACE_EVENTS = 'face-events',
    FACE_EVENTS_IN_OUT = 'face-events-in-out',
    BODY_EVENTS = 'body-events',
    CAR_EVENTS = 'car-events',
    HUMAN_EPISODES = 'human-episodes',
    CAR_EPISODES = 'car-episodes',
    FACE_CLUSTERS = 'face-clusters',
    BODY_CLUSTERS = 'body-clusters',
    CAR_CLUSTERS = 'car-clusters',
    FACE_CLUSTER_EVENTS = 'face-cluster-events',
    FACE_CLUSTER_EVENTS_IN_OUT = 'face-cluster-events-in-out',
    BODY_CLUSTER_EVENTS = 'body-cluster-events',
    CAR_CLUSTER_EVENTS = 'car-cluster-events',
    HUMAN_CARDS = 'human-cards',
    CAR_CARDS = 'car-cards',
    KYC = 'kyc',
    CAMERAS = 'cameras',
    COUNTERS = 'counters',
    AREAS = 'areas',
    AREA_ACTIVATIONS = 'area-activations',
    AREA_ACTIVATIONS_IN_OUT = 'area-activations-in-out',
    AREA_RECORDS = 'area-records',
    AUDIT_LOGS = 'audit-logs',
}