
import { Component, toRaw } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import translateProps from '@/common/translate-props';
import get from 'lodash/get';
import set from 'lodash/set';
import { AnyFunction } from '@/uikit/forms/NForm.vue';

export interface ITableCell {
  classes?: any;
  component?: Component;
  props?: Record<string, any> | ((item: any) => any);
  on?: Record<string, any>;

  row?: any;
  path?: string;
  encode?: (model: any, value: any) => void;
  decode?: (model: any) => any;
}

export type ITableActionType = 'change' | 'sort';

export interface ITableCellChangePayload {
  type: ITableActionType;
  path?: string;
  row?: any;
  value?: any;
}

@Options({
  name: 'NTableCell'
})
export default class NTableCell extends Vue {
  @Prop({ type: Object, required: true })
  readonly cell!: ITableCell;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, default: undefined })
  readonly sort?: string;

  @Prop({ type: String })
  readonly dataQa?: string;

  get classes() {
    return this.cell.classes;
  }

  get component() {
    return toRaw(this.cell.component);
  }

  get props() {
    let props = this.cell.props instanceof Function ? this.cell.props(this.cell.row) : this.cell.props;
    if (!props) {
      props = { disabled: this.disabled, item: this.cell.row, cell: this.cell };
    } else if (!props.disabled && !Object.isFrozen(props)) {
      props.disabled = this.disabled;
    }
    return translateProps(props, this.$t, 'f');
  }

  get isUseModel(): boolean {
    return !!(this.cell.path || this.cell.encode || this.cell.decode);
  }

  get handlers() {
    const handlers: Record<string, AnyFunction> = {};
    if (this.cell.on instanceof Object) {
      Object.keys(this.cell.on).forEach((name: string) => {
        handlers[name] = (...args: any[]) => {
          this.cell.on?.[name].apply(this.cellContext, args);
        };
      });
    }
    return handlers;
  }

  get cellContext() {
    return {
      item: this.cell.row,
      cell: this.cell,
      $router: this.$router,
      $t: this.$t,
      tableCell: this
    };
  }

  get modelValue() {
    if (this.cell.decode instanceof Function) {
      return this.cell.decode.call(this.cell, this.cell.row);
    }
    return get(this.cell.row, this.cell.path || 'undefined');
  }

  set modelValue(value) {
    if (this.cell.encode instanceof Function) {
      this.cell.encode.call(this.cell, this.cell.row, value);
      return;
    }
    if (this.cell.path) {
      set(this.cell.row, this.cell.path || 'undefined', value);
    }
    this.$emit('action', { type: 'change', row: this.cell.row, path: this.cell.path, value: value });
  }
}
