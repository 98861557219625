import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sidebar-tab__content" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "sidebar-tab__label label-xs"
}
const _hoisted_4 = {
  key: 3,
  class: "sidebar-tab__label label-xs"
}
const _hoisted_5 = {
  key: 0,
  class: "sidebar-tab__dot-change"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.src)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "sidebar-tab__image",
            src: _ctx.src,
            alt: ""
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "sidebar-tab__first-chars",
            style: _normalizeStyle({ 'background-color': _ctx.color })
          }, _toDisplayString(_ctx.firstChars), 5)),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, " "))
    ]),
    (_ctx.changed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ], 2))
}