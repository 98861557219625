
import { CameraStatusColorCode } from '@/api/models/Status';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { ISelectItem } from '@/uikit';
import NButtonGroup, { NButtonGroupItem } from '@/uikit/buttons/NButtonGroup.vue';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { fetchCameraGroups } from './utils';
import { PageType } from '@/store/application/page.definitions';
import { smallFilterManagerModule } from '@/store/filter-manager/SmallFiltersModule';
import { commonFilterSchemaModule } from '@/store/filters/CommonFilterSchemaModule';

export default defineComponent({
  name: 'DataSourcesPageFiltersBig',
  components: { FiltersBig },
  props: {
    modelValue: object().isRequired,
    state: object().isRequired,
    forceFilter: object().isRequired
  },
  emits: ['close', 'reset', 'update:modelValue'],
  methods: {
    setSmallFilterFields(v: string[]): void {
      smallFilterManagerModule.setFiltersBySource(this.state.pageType, v, true);
    },
    dispatchCloseEvent(): void {
      this.$emit('close');
    },
    dispatchResetEvent(): void {
      this.$emit('reset');
    },
    createCameraGroupsFilterField(): IFormLayoutItem {
      return {
        classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
        component: NSelect,
        i18n_label: 'common.camera_groups',
        path: 'camera_groups',
        props: {
          loadItems: async () => (await fetchCameraGroups()).map<ISelectItem>(({ name, id }) => ({ label: name, value: id })),
          multiple: true
        }
      };
    },
    createActiveFilterField(): IFormLayoutItem {
      return {
        classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
        component: NButtonGroup,
        i18n_label: 'ds.active',
        path: 'active',
        props: {
          multiline: true,
          showAnyButton: true,
          type: 'rounded',
          items: [
            { value: 'False', label: this.$t('ds.only_not_active', 'f') },
            { value: 'True', label: this.$t('ds.only_active', 'f') }
          ]
        }
      };
    },
    createCameraStatusColorCodeFilterField(): IFormLayoutItem {
      const codes: CameraStatusColorCode[] = ['gray', 'green', 'red', 'yellow'];
      const items: NButtonGroupItem[] = codes.map<NButtonGroupItem>((code) => ({ color: code, label: this.$t(`ds.${code}`, 'f'), value: code }));
      return {
        classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
        component: NButtonGroup,
        i18n_label: 'common.status',
        path: 'state_color',
        props: {
          items,
          multiline: true,
          showAnyButton: true,
          type: 'rounded'
        }
      };
    }
  },
  computed: {
    smallFilterFields(): string[] {
      return smallFilterManagerModule.items[this.state.pageType];
    },
    model: {
      get(): unknown {
        return this.modelValue;
      },
      set(modelValue: unknown) {
        this.$emit('update:modelValue', modelValue);
      }
    },
    layout(): IFormLayoutItem[] {
      const layout = [commonFilterSchemaModule.getNameContains({ small: false }), this.createCameraGroupsFilterField(), this.createActiveFilterField()];
      if (!this.isExternalDetectorsPage) {
        layout.push(this.createCameraStatusColorCodeFilterField());
      }
      return layout;
    },
    isExternalDetectorsPage() {
      return this.state.pageType === PageType.external_detectors;
    }
  }
});
