import AuditLogsPage from '@/pages/audit-logs/AuditLogsPage.vue';
import BlocklistPage from '@/pages/blocklist/BlocklistPage.vue';
import CameraGroupsPage from '@/pages/camera-groups/CameraGroupsPage.vue';
import CardsPage from '@/pages/cards/CardsPage.vue';
import CasesPage from '@/pages/cases/CasesPage.vue';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import ClustersPage from '@/pages/clusters/ClustersPage.vue';
import CountersPage from '@/pages/counters/CountersPage.vue';
import DataSourcesPage from '@/pages/data-sources/DataSourcesPage.vue';
import EmptyPage from '@/pages/empty/EmptyPage.vue';
import EventsPage from '@/pages/events/EventsPage.vue';
import ExternalSearchPage from '@/pages/external-search/ExternalSearchPage.vue';
import ExternalVmsPage from '@/pages/external-vms/ExternalVmsPage.vue';
import InteractionsPage from '@/pages/interactions/InteractionsPage.vue';
import KycPage from '@/pages/kyc/KycPage.vue';
import { LicensePage } from '@/pages/license';
import RemoteMonitoringEventsPage from '@/pages/remote-monitoring/RemoteMonitoringEventsPage.vue';
import { ReportsPage } from '@/pages/reports';
import SearchPage from '@/pages/search/SearchPage.vue';
import SessionsPage from '@/pages/sessions/SessionsPage.vue';
import GroupsPage from '@/pages/groups/GroupsPage.vue';
import UsersPage from '@/pages/users/UsersPage.vue';
import { VerifyPage } from '@/pages/verify';
import VideoPlayerPage from '@/pages/video-player/VideoPlayerPage.vue';
import { VideoWallPage } from '@/pages/video-wall';
import WatchListsPage from '@/pages/watch-lists/WatchListsPage.vue';
import WebhooksPage from '@/pages/webhooks/WebhooksPage.vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { Routes } from './enums';
import PageNotFound from '@/pages/PageNotFound.vue';
import InterfaceSettingsPage from '@/pages/interface-settings/InterfaceSettingsPage.vue';

const routes: Array<RouteRecordRaw> = [
  Routes.Main,
  Routes.Login,
  Routes.Settings,
  {
    name: 'CardsPage',
    path: '/cards',
    component: CardsPage
  },
  {
    name: 'EventsAndEpisodesPage',
    path: '/events',
    component: EventsPage
  },
  {
    name: 'DataSourcesPage',
    path: '/data-sources',
    component: DataSourcesPage
  },
  {
    name: 'VideoPlayerPage',
    path: '/video-player',
    component: VideoPlayerPage
  },
  {
    name: 'GroupsPage',
    path: '/groups',
    component: GroupsPage
  },
  {
    name: 'UsersPage',
    path: '/users',
    component: UsersPage
  },
  {
    name: 'SessionsPage',
    path: '/sessions',
    component: SessionsPage
  },
  {
    name: 'BlocklistPage',
    path: '/blocklist-records',
    component: BlocklistPage
  },
  {
    name: 'WatchListsPage',
    path: '/watch-lists',
    component: WatchListsPage
  },
  {
    name: 'CameraGroupsPage',
    path: '/camera-groups',
    component: CameraGroupsPage
  },
  {
    name: 'CasesPage',
    path: '/cases',
    component: CasesPage
  },
  {
    name: 'EditCasePage',
    path: '/case',
    component: EditCasePage
  },
  {
    name: 'ClustersPage',
    path: '/clusters',
    component: ClustersPage
  },
  {
    name: 'CountersPage',
    path: '/counters',
    component: CountersPage
  },
  {
    name: 'SearchPage',
    path: '/search',
    component: SearchPage
  },
  {
    name: 'ExternalSearchPage',
    path: '/external-search',
    component: ExternalSearchPage
  },
  {
    name: 'RemoteMonitoringEventsPage',
    path: '/remote-monitoring-events',
    component: RemoteMonitoringEventsPage
  },
  {
    name: 'AreasPage',
    path: '/areas',
    component: EmptyPage
  },
  {
    name: 'VideoWallPage',
    component: VideoWallPage,
    path: '/video-wall'
  },
  {
    name: 'KycPage',
    path: '/analytics',
    component: KycPage
  },
  {
    name: 'VerifyPage',
    path: '/verify',
    component: VerifyPage
  },
  {
    name: 'LicensePage',
    path: '/license',
    component: LicensePage
  },
  {
    name: 'AuditLogsPage',
    path: '/audit_logs',
    component: AuditLogsPage
  },
  {
    name: 'ReportsPage',
    path: '/reports',
    component: ReportsPage
  },
  {
    name: 'ExternalVmsPage',
    path: '/external-vms',
    component: ExternalVmsPage
  },
  {
    name: 'WebhooksPage',
    path: '/hooks',
    component: WebhooksPage
  },
  {
    name: 'InteractionsPage',
    path: '/interactions',
    component: InteractionsPage
  },
  {
    name: 'InterfaceSettingsPage',
    path: '/interface',
    component: InterfaceSettingsPage
  },
  {
    name: 'NotFoundPage',
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
