
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { Point, Size } from '@/uikit/bbox/types';
import { BboxDistance } from '@/uikit/bbox/types';
import NBboxDistances from '@/uikit/bbox/NBboxDistances.vue';

export default defineComponent({
  name: 'NImageViewerBboxDistancesLayer',
  components: { NBboxDistances },
  props: {
    distances: { type: Array as PropType<BboxDistance[]>, required: true },
    size: { type: Object as PropType<Size>, required: true },
    scale: { type: Number, default: 1 },
    offset: { type: Object as PropType<Point>, default: () => ({ x: 0, y: 0 }) }
  },
  emits: ['select'],
  setup(props) {
    const style = computed(() => {
      return {
        transform: `translate(calc(-50% + ${props.offset.x}px), calc(-50% + ${props.offset.y}px))`,
        width: `${props.size.width * props.scale}px`,
        height: `${props.size.height * props.scale}px`
      };
    });

    return {
      ...toRefs(props),
      style
    };
  }
});
