
import { NIcon } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FilterRange from '@/components/common/filter/FilterRange.vue';
import { LteOrGteObject } from '@/store/filters/types';

@Options({
  components: {
    FilterRange,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallDataRange extends Vue {
  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: Object, required: true })
  readonly model!: any;

  @Prop({ type: Object, required: true })
  readonly gteObject!: LteOrGteObject;

  @Prop({ type: Object, required: true })
  readonly lteObject!: LteOrGteObject;

  @Prop({ type: Function, required: false })
  readonly decode?: (v: any) => any;

  @Prop({ type: Function, required: false })
  readonly entcode?: (v: any) => any;

  get canClear() {
    return this.model[this.gteObject.fieldName] || this.model[this.lteObject.fieldName];
  }

  reset() {
    this.$refs.range?.reset();
  }
}
