
import { NInput, NIcon } from '../../../uikit';
import { NSelect, ISelectItem } from '@/uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IFormModel } from '@/uikit/forms/NForm.vue';
import { carModels } from '@/store/filters/car-models';

@Options({
  components: {
    NSelect,
    NInput,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterCarModel extends Vue {
  @Prop({ type: [String, Number], required: true })
  readonly modelValue!: string | number;

  @Prop({ type: Object })
  readonly currentModel: IFormModel;

  @Prop({ type: Boolean, default: false })
  readonly closeIcon!: boolean;

  @Prop({ type: String })
  readonly label: string = '';

  @Prop({ type: String })
  readonly placeholder: string = '';

  @Prop({ type: Boolean, default: false })
  readonly hideChevron!: boolean;

  created() {
    carModels.get();
  }

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  get items() {
    return this.computeFieldItems(carModels.items);
  }

  get filteredItems() {
    const items = this.computeFieldItems(carModels.items);
    const { make } = this.currentModel;
    return make?.length ? items.filter((modelItem) => !!make.find((makeItem: any) => modelItem.label.startsWith(makeItem))) : items;
  }

  clear() {
    this.$emit('update:modelValue', '');
    this.$emit('close', '');
  }

  computeFieldItems(items: string[]): SimpleItem[] {
    if (!Array.isArray(items)) {
      console.warn('[filter.items] empty or not array of ', items);
      return [];
    }

    return items.map((item) => ({ value: item, label: item }));
  }
}

type SimpleItem = {
  value: string;
  label: string;
};
