import NLauncherPage from '@/pages/launcher/LauncherPage.vue';
import { RouteRecordRaw } from 'vue-router';
import LoginPage from '@/pages/login/LoginPage.vue';
import SettingsPage from '@/pages/settings/SettingsPage.vue';

const Main: RouteRecordRaw = {
  path: '/',
  name: 'LauncherPage',
  component: NLauncherPage
};

const Login: RouteRecordRaw = {
  path: '/login',
  name: 'LoginPage',
  component: LoginPage
};

const Settings: RouteRecordRaw = {
  name: 'settings',
  path: '/settings',
  component: SettingsPage
};

export const Routes = {
  Main,
  Login,
  Settings
};


