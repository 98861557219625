import { dataAssetsModule } from '@/store/application/data.assets.module';
import { attributeFilterSchemaModule } from '@/store/filters/AttributeFilterSchemaModule';
import { commonFilterSchemaModule } from '@/store/filters/CommonFilterSchemaModule';
import { OptionalIFormLayout } from '@/store/filters/types';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';

export function getEventFiltersBuilder(options: { small: boolean; cardType?: string }) {
  const availableObjects = dataAssetsModule.availableObjectsTypedMap;
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getMatchedLists(options),
    commonFilterSchemaModule.getAcknowledged({ ...options }),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCameras(options),
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getVideoArchive(options),
    commonFilterSchemaModule.getMatchedCardSelect(options)
  ];

  const faceFeatures: OptionalIFormLayout[] = availableObjects.face ? attributeFilterSchemaModule.getFaceAttributes(options) : [];
  const bodyFeatures: OptionalIFormLayout[] = availableObjects.body ? attributeFilterSchemaModule.getBodyAttributes(options) : [];
  const carFeatures: OptionalIFormLayout[] = availableObjects.car ? attributeFilterSchemaModule.getCarAttributes(options) : [];

  let faceHeadposeFeatures: OptionalIFormLayout[] = availableObjects.face
    ? [attributeFilterSchemaModule.getFaceHeadposeYaw(options), attributeFilterSchemaModule.getFaceHeadposePitch(options)]
    : [];

  faceHeadposeFeatures = faceHeadposeFeatures.filter((v) => attributeFilterSchemaModule.isFeaturePresentInConfigByFormItem(v, attributeFilterSchemaModule.faceFeatures));

  const commonEvents: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getMatches({ ...options }),
    ...commonAll,
    commonFilterSchemaModule.getEpisodeId(options),
    commonFilterSchemaModule.getEventId(options)
  ];

  function getFilterByType(pageType: string, objectType: string, episodeType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [];
    if (pageType === 'events') {
      switch (objectType) {
        case 'faces':
          filterSchema = [
            ...commonEvents,
            commonFilterSchemaModule.getBsType(options),
            commonFilterSchemaModule.getAttributesDivider(options),
            ...faceFeatures,
            ...faceHeadposeFeatures
          ];
          break;
        case 'bodies':
          filterSchema = [...commonEvents, commonFilterSchemaModule.getAttributesDivider(options), ...bodyFeatures];
          break;
        case 'cars':
          filterSchema = [
            ...commonEvents,
            commonFilterSchemaModule.getAttributesDivider(options),
            ...carFeatures,
            attributeFilterSchemaModule.getCarOrientation(options)
          ];
          break;
      }
    }
    if (pageType === 'episodes') {
      switch (episodeType) {
        case 'humans':
          filterSchema = [
            commonFilterSchemaModule.getFaceMatches({ ...options }),
            commonFilterSchemaModule.getBodyMatches({ ...options }),
            ...commonAll,
            commonFilterSchemaModule.getEpisodeIdInEpisodes(options),
            commonFilterSchemaModule.getEventsCount(options),
            commonFilterSchemaModule.getFaceAttributesDivider(options),
            ...faceFeatures,
            commonFilterSchemaModule.getBodyAttributesDivider(options),
            ...bodyFeatures
          ];
          break;
        case 'cars':
          filterSchema = [
            commonFilterSchemaModule.getCarMatches({ ...options }),
            ...commonAll,
            commonFilterSchemaModule.getEpisodeIdInEpisodes(options),
            commonFilterSchemaModule.getEventsCount(options),
            commonFilterSchemaModule.getCarAttributesDivider(options),
            ...carFeatures
          ];
          break;
      }
    }
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}
