import { Ref, unref, VNode, withModifiers } from 'vue';
import type { NTableCell, NTableRow, NTableSectionSchema } from '../types';
import {
  computeTableCellClass,
  computeTableCellContent,
  computeTableCellStyle,
  getTableCellContentSchema,
  getTableCellCustomClass,
  getTableCellCustomStyle,
  isDefined
} from '../utils';

export function useTableSectionViewModel<C extends NTableCell>(rows: Ref<NTableRow<C>[]>, schemas: Ref<NTableSectionSchema<C>[]>, onClick: (cell: C) => void) {
  function renderTableCell(cell: C, schema: NTableSectionSchema<C>, index: number): VNode {
    const content = computeTableCellContent(cell, getTableCellContentSchema(schema));
    const customClass = computeTableCellClass(cell, getTableCellCustomClass(schema));
    const customStyle = computeTableCellStyle(cell, getTableCellCustomStyle(schema));

    return (
      // withModifiers(() => onClick(cell), ['self'])
      <div class={['n-table-section__cell', customClass]} key={index} onClick={() => onClick(cell)} style={customStyle}>
        {content}
      </div>
    );
  }

  function renderTableRow(row: NTableRow<C>, index: number): VNode {
    const content = row.map((cell, index) => {
      const schema = unref(schemas)[index];
      return isDefined(schema) && renderTableCell(cell, schema, index);
    });

    return (
      <div class="n-table-section__row" key={index}>
        {content}
      </div>
    );
  }

  return () => {
    const content = unref(rows).map(renderTableRow);
    return <div class="n-table-section">{content}</div>;
  };
}
