import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-form__float-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFormItem = _resolveComponent("NFormItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["n-form", { 'n-form_inline': _ctx.inlineForm, 'n-form_float': _ctx.floatForm }]),
    onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focusInHandler && _ctx.focusInHandler(...args)))
  }, [
    (_ctx.floatForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "float-right")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, rowIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: rowIndex ,
        class: "n-form__row"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (col, colIndex) => {
          return (_openBlock(), _createBlock(_component_NFormItem, {
            ref_for: true,
            ref: "formItems",
            key: colIndex + '_' + col.path + '_' + col.name,
            item: col,
            model: _ctx.model,
            context: _ctx.context,
            hasEnabledFields: _ctx.hasEnabledFields,
            isFieldEnabled: _ctx.getIsCheckboxEnabled(col),
            disabled: _ctx.computeDisabled(col),
            error: _ctx.showErrors ? _ctx.rowErrors[rowIndex][colIndex] : null,
            "onUpdate:fieldEnabled": _ctx.updateEnabledFields
          }, null, 8, ["item", "model", "context", "hasEnabledFields", "isFieldEnabled", "disabled", "error", "onUpdate:fieldEnabled"]))
        }), 128))
      ]))
    }), 128))
  ], 34))
}