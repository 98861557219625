import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_VideoProcessPreviewBar = _resolveComponent("VideoProcessPreviewBar")!
  const _component_VideoProcessPreviewItems = _resolveComponent("VideoProcessPreviewItems")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: "video-process-preview",
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('close')), ["esc"]))
    }, [
      (_openBlock(), _createBlock(_component_VideoPlayer, {
        class: _normalizeClass(['video-process-preview__player', { 'video-process-preview__player_inactive': !_ctx.isActive }]),
        model: "videos",
        cameraId: _ctx.item.id,
        "show-timeline": false,
        "show-bottom-bar": false,
        "show-controls": false,
        key: _ctx.isActive,
        style: _normalizeStyle(_ctx.playerBackground)
      }, null, 8, ["class", "cameraId", "style"])),
      _createVNode(_component_NButton, {
        class: "video-process-preview__close",
        icon: "close",
        type: "secondary-alt",
        round: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }),
      _createVNode(_component_VideoProcessPreviewBar, { item: _ctx.item }, null, 8, ["item"]),
      _createVNode(_component_VideoProcessPreviewItems, { item: _ctx.item }, null, 8, ["item"])
    ], 32)
  ]))
}