
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

export enum ChevronType {
  None = '',
  Up = 'up',
  Down = 'down'
}

@Options({
  name: 'NChevron'
})
export default class NChevron extends Vue {
  @Prop({ type: String, default: '' })
  readonly value!: ChevronType;

  @Prop({ type: String, default: '' })
  readonly color!: string;

  get visible() {
    return this.value !== ChevronType.None;
  }

  get classes() {
    return { 'n-chevron__up': this.value === ChevronType.Up };
  }

  get styles() {
    return this.color ? { background: this.color } : { background: 'var(--color-primary-100)' };
  }
}
