
import { computed, ref, defineComponent, watch, PropType } from 'vue';
import { string, bool } from 'vue-types';
import { NColorPickerColorFormats } from '@/uikit/input-color/enums';
import NColorPicker from '@/uikit/input-color/NColorPicker.vue';
import { defaultColor } from '@/uikit/input-color/helpers/utils';

export default defineComponent({
  name: 'NInputColor',
  components: { NColorPicker },
  props: {
    modelValue: string().def(defaultColor),
    label: string(),
    placeholder: string(),
    itemClass: string(),
    disabled: bool().def(false),
    colorFormat: { type: String as PropType<keyof typeof NColorPickerColorFormats>, default: 'hex' }
  },
  setup(props, context) {
    const color = ref(props.modelValue);
    const currentColor = ref(props.modelValue);
    const format = computed(() => {
      return NColorPickerColorFormats[props.colorFormat];
    });

    const handleChange = (value: string) => {
      currentColor.value = value.replace('#', '');
    };

    const handleClear = () => {
      color.value = '';
      currentColor.value = '';
    };

    const handleConfirm = () => {
      color.value = currentColor.value;
      context.emit('update:modelValue', color.value);
    };

    watch(() => props.modelValue, (v: string) => {
      color.value = v;
      handleChange(v);
    });

    return { color, currentColor, format, handleChange, handleClear, handleConfirm };
  }
});
