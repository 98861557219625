import { multisidebarModule } from '@/store/multisidebar';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarRawItemPayload, MultisidebarCommonItem } from '@/store/multisidebar/types';
import { cameraResetModule, CameraResetType, CameraResetTypes } from '@/store/camera/camera.reset.module';
import { Camera } from '@/api';
import { getPageStateByMsbType, isMultisidebarItem, isMultisidebarRawItemPayload } from '@/store/multisidebar/helpers';
import { globalEventModule } from '@/store/global-event';
import { dataServiceRepository } from '@/api/common';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { RouterModule } from '@/store/router';
import { PageName, PageType, SearchFrom, SearchPageState } from '@/store/application/page.definitions';
import router from '@/router';
import { ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { photoViewerController } from '@/components/photo-viewer/PhotoViewerController';
import { merge, omit } from 'lodash';
import { dialogModule } from '@/store/dialogs/dialogModule';

export async function convertToMsbItem(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
  if (isMultisidebarItem(payload)) {
    return payload;
  }
  if (isMultisidebarRawItemPayload(payload)) {
    const msbItem =
      multisidebarModule.getItem(payload.type, payload.rawItem.id ?? payload.rawItem) ||
      (await multisidebarModule.convertRawItemToMsbByType(payload.type, payload.rawItem));

    if (typeof payload.rawItem === 'object') {
      const toMerge = omit(payload.rawItem, msbItem.model.changes);
      merge(msbItem.model.item, toMerge);
    }

    return msbItem;
  }
  throw new Error("Can't convert passed payload. Expected types: MultisidebarRawItemPayload | MultisidebarCommonItem");
}

class ActionHandler {
  public getIsItemFormValid?: () => Promise<boolean> | boolean;

  private async validate() {
    return this.getIsItemFormValid?.() || false;
  }

  async run(actionName: ItemsActionName, payload: unknown, showConfirmDialog: boolean = true) {
    const optionalConfirmResult = showConfirmDialog ? await dialogModule.createConfirm(actionName, payload as any) : true;

    if (!optionalConfirmResult) return;

    switch (actionName) {
      case ItemsActionNames.CameraResetAll:
        return (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.resetCameraFullAndSave(payload);
      case ItemsActionNames.CameraReset:
      case ItemsActionNames.CameraResetZone:
      case ItemsActionNames.CameraResetAdvanced:
      case ItemsActionNames.CameraResetDetectors:
      case ItemsActionNames.CameraResetMap:
      case ItemsActionNames.CameraResetFull:
        return (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.resetCamera(actionName, payload);
      case ItemsActionNames.AddItem:
        return isMultisidebarRawItemPayload(payload) && multisidebarModule.addItem(payload.type, payload.rawItem);
      case ItemsActionNames.CloseCurrentItem:
        multisidebarModule.closeCurrent();
        return true;
      case ItemsActionNames.CloseGroup:
        multisidebarModule.removeItemsByGroupName(payload as string);
        return true;
      case ItemsActionNames.Delete:
      case ItemsActionNames.DeleteAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.deleteMsbItemOrRaw(payload);
        return true;
      case ItemsActionNames.FilterCardEvent:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToCardEvents(payload);
        return true;
      case ItemsActionNames.RemoveCurrentItem:
        multisidebarModule.currentItem && multisidebarModule.removeBarItem(multisidebarModule.currentItem);
        return true;
      case ItemsActionNames.Remove:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.removeBarItem(payload));
        return true;
      case ItemsActionNames.Reset:
        (payload as MultisidebarCommonItem).model.reset();
        return true;
      case ItemsActionNames.ToggleSelectItem:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.toggleItemSelect(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.ToggleCurrent:
        isMultisidebarItem(payload) && multisidebarModule.toggleCurrentBarItem(payload);
        return true;
      case ItemsActionNames.Save:
      case ItemsActionNames.SaveAll:
        if ((await this.validate()) && isMultisidebarItem(payload)) {
          return multisidebarModule.save(payload);
        }
        return false;
      case ItemsActionNames.SelectAll:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.addItems(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.Search:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToSearch(payload);
        return true;
      case ItemsActionNames.ShowFullScreen:
        photoViewerController.show(payload);
        return true;
      case ItemsActionNames.ShowItem:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.addItemAndOpen(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.Activate:
      case ItemsActionNames.ActivateAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.setItemActivation(payload, true));
        return true;
      case ItemsActionNames.Deactivate:
      case ItemsActionNames.DeactivateAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.setItemActivation(payload, false));
        return true;
      case ItemsActionNames.UpdateActiveField:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.updateItemActivation(payload));
        return true;
      case ItemsActionNames.Process:
      case ItemsActionNames.ProcessAll:
        await this.processVideoArchive(payload);
        await this.syncVideoArchive(payload);
        return true;
      case ItemsActionNames.StopProcess:
      case ItemsActionNames.StopProcessAll:
        await this.stopProcessVideoArchive(payload);
        await this.syncVideoArchive(payload);
        return true;
      case ItemsActionNames.DeleteVideoFile:
      case ItemsActionNames.DeleteVideoFileAll:
        await this.deleteFileVideoArhive(payload);
        return true;
      case ItemsActionNames.DeleteCards:
      case ItemsActionNames.DeleteCardsInAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.purgeItem(payload));
        return true;
      default:
        console.warn('[actionHandler]: unknown action: ' + actionName);
        return false;
    }
  }

  private async removeBarItem(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    return multisidebarModule.removeBarItem(payload);
  }

  private async deleteMsbItemOrRaw(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    await multisidebarModule.deleteItem(payload, true);
  }

  private async updateItemActivation(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const model = payload.model;
    if (typeof model.item.active === 'undefined') return;
    return this.setItemActivation(payload, model.item.active);
  }

  private async setItemActivation(payload: MultisidebarRawItemPayload | MultisidebarCommonItem, active: boolean) {
    payload = await convertToMsbItem(payload);
    const model = payload.model;
    if (typeof model.item.active === 'undefined') return;
    const updatePayload = { id: model.item.id, active };
    const changes = model.changedData;
    delete changes.active;
    try {
      await model.update(updatePayload);
    } catch (e) {
      if (e.isAxiosError) {
        console.warn(`[actionHandler]: can't toggle active of item: ${payload.id}. Error: ` + e);
      } else {
        throw e;
      }
    }
    Object.assign(model.item, changes);
    await globalEventModule.sendUpdate(payload.type, model.item);
  }

  private async resetCameraFullAndSave(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    await cameraResetModule.reset(payload.model.item as Camera, CameraResetTypes.Full);
    await payload.model.save();
  }

  private async resetCamera(actionName: ItemsActionName, payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    const CameraResetActionPrefix = 'camera-reset-';
    payload = await convertToMsbItem(payload);
    const resetType = actionName.replace(CameraResetActionPrefix, '');
    return cameraResetModule.reset(payload.model.item as Camera, resetType as CameraResetType);
  }

  private async processVideoArchive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    return dataServiceRepository.VideosService.createItemSomethingByAction(msbItem.model.item.id, 'process');
  }

  private async stopProcessVideoArchive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    return dataServiceRepository.VideosService.createItemSomethingByAction(msbItem.model.item.id, 'stop');
  }

  private async syncVideoArchive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    await msbItem.model.get(msbItem.model.item.id);
    globalEventModule.sendUpdate(msbItem.type, msbItem.model.item);
  }

  private async deleteFileVideoArhive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    const axios = dataServiceFactory.getAxiosInstance();
    return axios.delete(`videos/${msbItem.model.item.id || ''}/file/`);
  }

  private async navigateToSearch(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const pageState: Partial<SearchPageState> = { ...getPageStateByMsbType(payload.type), id: payload.model.item.id };
    const searchFrom = pageState.pageType as SearchFrom;
    pageState.searchFrom = Object.values(SearchFromMap).includes(searchFrom) ? searchFrom : '';
    const to = RouterModule.getRouteLocation({ name: PageName.search, pageState });
    await router.push(to);
    multisidebarModule.toggleCurrentBarItem(payload);
  }

  private async navigateToCardEvents(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const pageState = { ...getPageStateByMsbType(payload.type), pageType: PageType.events, filter: { matched_card: payload.model.item.id } };
    pageState.objectType = pageState.cardType === ObjectsTypesMap.Cars ? ObjectsTypesMap.Cars : ObjectsTypesMap.Faces;
    const to = RouterModule.getRouteLocation({ name: PageName.events, pageState });
    router.push(to);
  }

  private async purgeItem(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    const id = msbItem.model.item.id;
    return id && msbItem.model.dataService.createItemSomethingByAction(id, 'purge');
  }
}

export const actionHandler = new ActionHandler();
