
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NConfidence from '@/uikit/confidence/NConfidence.vue';
import { ObjectType } from '@/store/application/data.assets';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { Settings } from '@/api';

const DefaultFallbackThreshold = 0.75;

@Options({
  name: 'ObjectThreshold',
  components: { NConfidence, NCheckbox },
  emits: ['update:modelValue']
})
export default class ObjectThreshold extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number | null;

  @Prop({ type: String, required: true })
  readonly objectType!: ObjectType;

  get showConfidenceThreshold() {
    return !!this.modelValue;
  }

  set showConfidenceThreshold(value: boolean) {
    this.$emit('update:modelValue', value ? this.defaultThreshold : null);
  }

  get objectThresholdProp() {
    return `${this.objectType}_confidence_threshold` as keyof Settings;
  }

  get defaultThreshold() {
    return settingsItemModule.item?.[this.objectThresholdProp] ?? DefaultFallbackThreshold;
  }
}
