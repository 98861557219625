import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'n-checkbox-icon',
      {
        'n-checkbox-icon_checked': _ctx.modelValue,
        'n-checkbox-icon_indeterminate': _ctx.isIndeterminated,
        'n-checkbox-icon_disabled': _ctx.disabled,
        'n-checkbox-icon_focused': _ctx.focused
      }
    ]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)), ["prevent"]))
  }, [
    _createVNode(_component_NIcon, {
      name: _ctx.name,
      width: _ctx.size,
      height: _ctx.size
    }, null, 8, ["name", "width", "height"]),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("label", {
        for: `checkbox-${_ctx.label}`,
        class: "n-checkbox-icon__label label-m"
      }, _toDisplayString(_ctx.label), 9, _hoisted_1)
    ])
  ], 2))
}