import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "counter-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "n-nowrap" }
const _hoisted_4 = { class: "n-nowrap" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "n-nowrap"
}
const _hoisted_7 = {
  key: 3,
  class: "n-nowrap"
}
const _hoisted_8 = { class: "status__item" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonStatus = _resolveComponent("CommonStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonStatus, {
      color: _ctx.item.health_status.color,
      description: _ctx.item.health_status.code_desc
    }, null, 8, ["color", "description"]),
    (_ctx.item.health_status.statistic)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.health_status.statistic.failed_records) + " / ", 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.health_status.statistic.total_records), 1)
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_5, "- / -")),
    (_ctx.item.multi_camera)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('counters.multi_camera')), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('counters.single_camera')), 1)),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.distanceEnabled)
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.translatedCalibrationStatus), 1))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('counters.proximity_not_enabled')), 1)
          ], 64))
    ])
  ]))
}