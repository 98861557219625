import { ITableColumn } from '@/uikit/table/NTable.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import { User } from '@/api';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import SimpleText from '@/uikit/simple/SimpleText.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { dataModule } from '@/store/data';
import UserADCell from './UserADCell.vue';
import UserGroupCell from './UserGroupCell.vue';

export const mainColumns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.name',
        name: 'first_name',
        sortable: true
      }
    },
    content: {
      path: 'real_name',
      component: SimpleText,
      props: { accent: true, clickable: true }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.login',
        name: 'username',
        sortable: true
      }
    },
    content: {
      path: 'name',
      component: SimpleText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.primary_role',
        name: 'primary_group',
        sortable: true
      }
    },
    content: {
      path: 'primary_group',
      component: UserGroupCell
    }
  }
];

export const ADColumn = {
  width: '130px',
  header: {
    classes: ['bottom-border'],
    component: NTableColumnCell,
    props: {
      label: 'Active Directory'
    }
  },
  content: {
    classes: ['align-center'],
    component: UserADCell,
    path: 'ad_user'
  }
};

export const metaColumns = [
  {
    width: '85px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_created',
        name: 'date_joined',
        sortable: true
      }
    },
    content: {
      path: 'created_date',
      component: NDateTimeLabel,
      props: {
        multiline: true,
        size: '2xs'
      }
    }
  },
  {
    width: '85px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_modified',
        name: 'modified_date',
        sortable: true
      }
    },
    content: {
      path: 'modified_date',
      component: NDateTimeLabel,
      props: {
        multiline: true,
        size: '2xs'
      }
    }
  },
  {
    width: '100px',
    header: {
      classes: ['align-right', 'bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.column_active',
        name: 'is_active',
        sortable: true
      }
    },
    content: {
      path: 'active',
      classes: ['align-right'],
      component: NSwitch,
      props: (item: User) => ({ disabled: item.id === 1 || dataModule.currentUserModule.item?.id === item.id })
    }
  }
];
