
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Options({
  name: 'NInputBase',
  inheritAttrs: false
})
export default class NInputBase extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String })
  readonly placeholder?: string;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly expanded!: boolean;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  focusHandler(event: FocusEvent) {
    this.$emit('focus', event);
  }

  keydownHandler(e: KeyboardEvent) {
    this.$emit('keydown', e);
  }

  focus() {
    this.$refs.input?.focus();
  }
}
