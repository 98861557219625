
import { viewModelRepository } from '@/api/common';
import FilterSchemeBuilder from '@/components/common/filter/FilterSchemeBuilder';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import NTable from '@/uikit/table/NTable.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import columns from './schemes/counter-records-columns';
import { CounterRecord } from '@/api';
import NInfiniteScroll from "@/components/cases/NInfiniteScroll.vue";

function gatherRecordBboxes(record: CounterRecord) {
  return [...(record.faces_bbox ?? []), ...(record.cars_bbox ?? []), ...(record.silhouettes_bbox ?? [])];
}

@Options({
  name: 'CounterRecords',
  components: { NInfiniteScroll, FilterSection, NTable }
})
export default class CounterRecords extends Vue {
  @Prop({ type: Number, required: true })
  readonly counterId!: number;

  readonly columns = columns;
  readonly module = viewModelRepository.getCounterRecordsListViewModel();
  readonly filters = new FilterSchemeBuilder();

  created() {
    // this.filters.addCounter();
    this.filters.addCameras();
    this.filters.addStartEnd();
    this.filters.addCountFaces();
    this.filters.addCountSilhouettes();
    this.filters.addCountCars();
    this.filters.addIdIn({
      i18n_label: 'counters.counter_record_id',
      i18n_placeholder: 'counters.enter_counter_record_id'
    });
    this.filters.addMinimumDistance();
    this.filters.addMaximumDistance();
    this.filters.addAverageDistance();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    this.module.get();
  }

  @Watch('counterId')
  reload() {
    this.module.filter.force.counter = [this.counterId];
    this.module.get();
  }

  mounted() {
    this.reload();
  }

  handleTableAction(record: CounterRecord) {
    const item = { fullframe: record.fullframe, bboxes: gatherRecordBboxes(record) };
    this.$photoViewer.show(item);
  }
}
