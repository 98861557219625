export default {
  sorry: 'Sorry',
  page_notfound: 'This page doesn’t exist',
  json_invalid: 'Invalid JSON',
  date: {
    greater_than_current: 'Value must be greater than the current date',
    date_gte_lte: 'Incorrect date range'
  },
  image: {
    not_found: 'Image is not found',
    load: 'Failed to load image'
  },
  camera: {
    not_found: 'Camera is not found'
  },
  camera_group: {
    not_found: 'Camera group is not found'
  },
  card: {
    not_found: 'Image from the record index is not found'
  },
  webcam: {
    not_available: 'No webcams available',
    not_available_desc: 'Please check the webcam connection',
    not_allowed: 'Webcam access denied',
    not_allowed_desc: 'Grant access to the webcam in your browser to use facial authentication',
    not_readable: 'Unable to read webcam stream',
    not_readable_desc: 'Unable to read the webcam video stream. Another program might be using it'
  },
  server: {
    no_response: 'Server not responding',
    no_response_desc: 'Problem with the server or network. Please contact administrator',
    notfound: 'Not found',
    notfound_desc: 'The requested resource is not found',
    forbidden: 'Forbidden',
    forbidden_desc: 'You do not have sufficient permissions to perform this action',
    bad_request: 'Bad request',
    bad_request_desc: 'The server was unable to process the received request',
    internal: 'Server error',
    internal_desc: 'Internal server error. Please contact administrator',
    unavailable: 'Service unavailable',
    unavailable_desc: 'Service temporary unavailable. Please contact administrator',
    timeout: 'Server response timeout',
    timeout_desc: 'Looks like the server is taking to long to respond. Please contact administrator',
    check_onvif: 'Check Onvif camera settings'
  },
  form: {
    validation: 'Form validation error'
  },
  confirm: {
    password: 'Password and its confirmation do not match'
  },
  field: {
    url: 'Incorrect URL',
    uint: 'Value must be greater than or equal to 0',
    uint_max_min: 'Value must be in the range from {{min}} to {{max}}',
    ufloat: 'Value must be in the range from {{min}} to {{max}}, without trailing zeros in decimal part',
    realtime_or_overall: 'Real-time or overall mode is required',
    cameras_are_not_selected: 'Cameras are not selected',
    enter_a_valid_ip: 'Enter a valid IPv4 or IPv6 address'
  },
  required: {
    field: 'Field is required',
    field_name: 'Field is required. Minimum length 2 chars',
    login: 'Login is required',
    name: 'Name is required',
    email: 'Email is required',
    password: 'Password is required',
    primary_group: 'Primary role is required',
    all_params: 'All parameters must be specified'
  },
  number: {
    from_min: 'Value must be an integer greater than or equal to {{min}}',
    to_max: 'Value must be an integer less than or equal to {{max}}',
    between: 'Value must be an integer between {{min}} and {{max}}',
    float_from_min: 'Value must be greater than or equal to {{min}}',
    float_to_max: 'Value must be less than or equal to {{max}}',
    float_between: 'Value must be between {{min}} and {{max}}',
    validator_error: 'Validator error'
  },
  string: {
    from_min: 'Length must be greater than or equal to {{min}} symbols',
    to_max: 'Length must be less than or equal to {{max}} symbols',
    between: 'Length must be between {{min}} and {{max}}',
    validator_error: 'Validator error',
    should_be_ascii: 'Only ASCII symbols are allowed',
    shouldnt_contain_only_digits: 'Shouldn\'t contain only digits'
  },
  age: {
    age_gte_lte: 'Incorrect age range'
  },
  license: {
    file_too_large: 'License file is too large. Maximum file size is 1MB',
    not_valid: 'License is not valid',
    not_found: 'License is not found',
    expired: 'License is expired'
  },
  play_sound: 'Unable to play sound',
  crypto_pro: {
    plugin_install: 'CryptoPro plugin install error',
    plugin_internal: 'CryptoPro plugin internal error'
  },
  array: {
    empty: 'At least one item must be selected'
  },
  unique: {
    default: 'Must be unique',
    login: 'Login must be unique'
  }
};
