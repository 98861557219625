
import { PropType } from 'vue';
import { defineComponent } from 'vue';
import { NLoaderType } from './types';

export default defineComponent({
  name: 'NLoadingCircle',
  props: {
    type: {
      type: String as PropType<NLoaderType>,
      default: NLoaderType.Primary
    }
  },
  computed: {
    cssClass(): Record<string, boolean> {
      return {
        'n-loading-circle': true,
        'n-loading-circle_primary': this.type === NLoaderType.Primary,
        'n-loading-circle_secondary': this.type === NLoaderType.Secondary
      };
    }
  }
});
