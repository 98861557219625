<template>
  <div class="n-buttons-double-group"><slot name="default"></slot></div>
</template>

<script>
export default {
  name: 'NButtonsDoubleGroup'
};
</script>

<style lang="stylus">
.n-buttons-double-group {
  .n-button + .n-button {
    border-radius: 0;
    margin-left: 0;
  }
  .n-button {
    margin: 0;

    &:first-of-type {
      border-image-width: 15px 0 15px 15px
      &:before {
        right: 0;
      }
    }
    &:last-of-type {
      border-image-width: 15px 15px 15px 0;
    }
  }
}
</style>
