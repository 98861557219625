
import { NInput, NIcon } from '../../../uikit';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {
    NInput,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FiltersSimpleText extends Vue {
  @Prop({ type: [String, Number], required: true })
  readonly modelValue!: string | number;

  @Prop({ type: String })
  readonly label?: string;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }

  clear() {
    this.$emit('update:modelValue', '');
    this.$emit('close', '');
  }
}
