
import NTable from '@/uikit/table/NTable.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SettingsMultitool from '@/components/common/SettingsMultitool.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { Options, Vue } from 'vue-class-component';
import { mainColumns, ADColumn, metaColumns } from './users-table-schema';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { multisidebarModule } from '@/store/multisidebar';
import { actionHandler } from '@/store/data/ActionHandler';
import { viewModelRepository } from '@/api/common';
import { aclModule } from '@/store/acl';
import { Watch } from 'vue-property-decorator';
import { GlobalEvent } from '@/store/global-event/types';
import { globalEventModule, GlobalEventName } from '@/store/global-event';
import { merge } from 'lodash';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';

const ActionPathNames = {
  RealName: 'real_name',
  Group: 'primary_group',
  Active: 'active'
};

@Options({
  components: {
    NInfiniteScroll,
    SettingsPageLayout,
    NLoadingCircle,
    SettingsBar,
    SettingsMultitool,
    NTable
  }
})
export default class UsersPage extends Vue {
  get module() {
    const module = viewModelRepository.getUsersListViewModel();
    module.aclModelName = 'user';
    module.getStatistics();
    return module;
  }

  get items() {
    return this.module.items;
  }

  get isADEnabled() {
    return configModule.config.services.ffsecurity.active_directory;
  }

  get columns() {
    let columns = [...mainColumns];
    if (this.isADEnabled) columns.push(ADColumn);
    columns.push(...metaColumns);
    return columns;
  }

  get selectedItems() {
    return this.module.selectedItems.map((v) => v.item);
  }

  get currentItem() {
    return this.module.currentItem?.item;
  }

  get modelAcl() {
    return { ...aclModule.getModelAcl(this.module), delete: this.isLoggedInUserAdmin, add: this.isLoggedInUserAdmin };
  }

  get isLoggedInUserAdmin() {
    return dataModule.isCurrentUserAdmin;
  }

  get groups() {
    return dataModule.groupsModule.items;
  }

  get sidebarType() {
    return MultisidebarItemTypes.Users;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === this.sidebarType).map((v) => v.model.item?.id);
  }

  get lastPageEvent() {
    return globalEventModule.current?.type === MultisidebarItemTypes.Users ? globalEventModule.current : null;
  }

  @Watch('lastPageEvent')
  handleGlobalEvent(event: GlobalEvent) {
    if (!event) return;
    switch (event.name) {
      case GlobalEventName.Create:
      case GlobalEventName.Delete:
        this.module.get();
        break;
      case GlobalEventName.Update:
        merge(
          this.module.items.find((v) => v.id === event.payload.id),
          event.payload
        );
        break;
    }
  }

  handleSelectChanges(changes: number[]) {
    changes
      ?.map((id) => (this.module.items as any[]).find((v: any) => v.id === id))
      .forEach((item) => actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: item }));
  }

  async handleTableAction(payload: ITableCellChangePayload) {
    if (payload.type === 'change') {
      const item = { type: MultisidebarItemTypes.Users, rawItem: payload.row };
      const isActive = payload.row.active;

      switch (payload.path) {
        case ActionPathNames.Active:
          await actionHandler.run(isActive ? ItemsActionNames.Activate : ItemsActionNames.Deactivate, item);
          break;
        case ActionPathNames.RealName:
          await actionHandler.run(ItemsActionNames.ShowItem, item);
          break;
        case ActionPathNames.Group:
          await actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Groups, rawItem: payload.row.primary_group });
          break;
      }
    }
  }

  handleSearch(query: string) {
    if (this.module.filter.current.real_name_contains !== query) {
      this.module.filter.current.real_name_contains = query;
      this.module.get();
    }
  }

  handleTableSort(columnName: string) {
    const ordering = this.module.filter.current.ordering === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = ordering;
    this.module.get();
  }

  async handleCreate() {
    const newMsbItem = await this.sidebarModule.addNewItemByType(this.sidebarType);
    const user = newMsbItem.model.item;
    if (!user.primary_group && this.groups.length) {
      user.primary_group = this.groups[this.groups.length - 1]?.id;
    }
  }

  mounted() {
    this.module.get();
  }
}
