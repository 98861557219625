import { throttle } from 'lodash';
import axios from 'axios';
import { authModule } from '@/store/auth';

type CacheRecord = { value: CacheRecordValue; timestamp: number };
type CacheRecordValue = Promise<string>;
const CacheTTLMilliseconds = 60_000;

class ImageLoader {
  private cacheStore: Map<string, CacheRecord> = new Map();

  public async get(url: string, resetCache = false) {
    return this.cacheStore.has(url) && !resetCache ? this.getCacheValue(url) : this.load(url, resetCache);
  }

  private cacheCleanerThrottled = throttle(this.cacheCleaner);

  private async cacheCleaner() {
    const now = Date.now();
    const entriesToDelete = [...this.cacheStore].filter(([_, record]) => record.timestamp + CacheTTLMilliseconds < now);

    for (const [key, record] of entriesToDelete) {
      try {
        let value = await record.value;
        URL.revokeObjectURL(value);
      } finally {
        this.cacheStore.delete(key);
      }
    }
  }

  private getCacheValue(url: string) {
    return this.cacheStore.get(url)!.value;
  }

  private setCacheValue(url: string, value: CacheRecordValue) {
    this.cacheStore.set(url, { value, timestamp: Date.now() });
    setTimeout(this.cacheCleanerThrottled.bind(this), CacheTTLMilliseconds);
  }

  private load(url: string, resetCache = false) {
    const promise = new Promise<string>((r, j) => {
      axios({
        url,
        method: resetCache ? 'POST' : 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + encodeURIComponent(authModule.token!.toString())
        }
      })
        .then((response) => {
          r(URL.createObjectURL(response.data));
        })
        .catch((e) => {
          j(e);
        });
    });

    this.setCacheValue(url, promise);
    return promise;
  }
}

export const imageLoader = new ImageLoader();
