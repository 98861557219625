import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "photo-viewer__items"
}
const _hoisted_2 = { class: "photo-viewer__items-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_ImageGallery = _resolveComponent("ImageGallery")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: "photo-viewer",
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)), ["esc"])),
      tabindex: "-1",
      ref: "root"
    }, [
      (_ctx.showItems)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NBaseBar, { class: "photo-viewer__items-bar" }, {
              start: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$tm(`common.images_mf`, { count: _ctx.items.length })), 1)
              ]),
              end: _withCtx(() => [
                _createVNode(_component_NButton, {
                  type: "text",
                  icon: "close",
                  onAction: _ctx.handleClose
                }, null, 8, ["onAction"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thumbnails, (thumbnail, i) => {
                return (_openBlock(), _createBlock(_component_NThumbnail, {
                  class: "photo-viewer__item",
                  key: i,
                  "model-value": thumbnail,
                  onClick: ($event: any) => (_ctx.showItemFullframe(i))
                }, null, 8, ["model-value", "onClick"]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createBlock(_component_ImageGallery, {
            key: _ctx.$photoViewer.options.key,
            items: _ctx.fullframes,
            bboxes: _ctx.$photoViewer.options.bboxes,
            "bbox-selectable": false,
            srcIndex: _ctx.activeItemIndex,
            "arrows-type": _ctx.$photoViewer.options.arrowTypes,
            "items-button": _ctx.$photoViewer.options.itemsButton,
            onClose: _ctx.handleClose,
            onShowItems: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showItems = true))
          }, null, 8, ["items", "bboxes", "srcIndex", "arrows-type", "items-button", "onClose"]))
    ], 544)
  ]))
}