import { CarEpisode, HumanEpisode, VideoArchive } from '@/api';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { getLogger } from 'loglevel';

const logger = getLogger('[ws]');
logger.setLevel('warn');

export const WsMessageTypes = {
  Ping: 'ping',
  LicenseAlert: 'license_alert',
  TokenInvalid: 'token_invalid',

  VideoArchiveProgress: 'video_archive_progress',
  VideoArchiveVMStatus: 'video_archive_vm_status',
  CameraVmStatus: 'camera_vm_status',

  EventCreated: 'event_created',
  EventUpdated: 'event_updated',

  MonitoringEventCreated: 'monitoring_event_created',
  MonitoringEventUpdated: 'monitoring_event_updated',
  RemoteMonitoringNotificationAcknowledged: 'remote_monitoring_notification_acknowledged',

  EpisodeOpen: 'episode_open',
  EpisodeUpdated: 'episode_updated',
  EpisodeClose: 'episode_close',
  EpisodeEvent: 'episode_event',

  ClusterCreated: 'cluster_created',
  ClusterUpdated: 'cluster_updated',

  HasUnacknowledged: 'unacknowledged',
  AllEventsAcknowledged: 'events_acknowledged',

  ModelEvent: 'model_event'
};

const WsEndpoints = {
  events: 'events',
  puppeteerEvents: 'puppet-events'
};

const EmptyObjectData = {
  face: null,
  car: null,
  body: null
};

const EmptyEpisodeData = {
  human: null,
  car: null
};

const WsRefreshTimeout = 3600 * 1e3;

export class WebsocketModule {
  connected = false;
  time?: Date;
  attempts = 0;

  videoArchivesById: Record<string, VideoArchive> = {};
  episodes: (HumanEpisode | CarEpisode)[] = [];

  monitoringEvent = {};
  updatedMonitoringEvent = {};

  event = cloneDeep(EmptyObjectData);
  eventUpdated = cloneDeep(EmptyObjectData);

  episode: any = cloneDeep(EmptyEpisodeData);
  episodeUpdated: any = cloneDeep(EmptyEpisodeData);

  cluster = cloneDeep(EmptyObjectData);

  licenseMessage = '';

  unacknowledged: any;
  notify: any;
  acknowledgedAll: boolean = true;

  camera = {};
  cameraUpdated = {};
  videoUpdated = {};

  tokenInvalid = false;

  private connectTimeoutIndex = 0;
  private refreshTimeoutIndex = 0;

  subscriptions = {
    ws_events: true,
    ws_notifications: false,
    ws_unacknowledged_events: true,
    ws_episodes: true,
    ws_clusters: true
  };

  constructor(public endpoint: string) {}

  get wsUrl(): string {
    return (configModule.serverUrl + this.endpoint + '/').replace('http', 'ws');
  }

  get token() {
    return authModule.token;
  }

  get wsRefreshTimeout() {
    return +localStorage.debugWsRefreshTimeout > 1e3 ? +localStorage.debugWsRefreshTimeout : WsRefreshTimeout;
  }

  connect(): void {
    logger.info('[ws] connect', this.wsUrl, this.token, ', refresh time(ms): ', this.wsRefreshTimeout);
    try {
      if (!this.token) {
        logger.error('[ws] Try to connect without authorization');
      }

      let ws = new WebSocket(this.wsUrl);
      ws.onopen = (...rest) => this.wsOpenHandler(ws, ...rest);
      ws.onclose = (...rest) => this.wsCloseHandler(ws, ...rest);
      ws.onerror = (...rest) => this.wsCloseHandler(ws, ...rest);
    } catch (e) {
      logger.error('[ws:error] in connect ', e);
    }
  }

  wsOpenHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws] connected', this.wsUrl);
    this.connected = true;
    this.time = new Date();
    this.attempts = 0;
    this.wsSend(ws, { type: 'auth', data: { token: authModule.token, msg_groups: this.subscriptions } });
    ws.onmessage = (e) => this.wsMessageHandler(ws, e);
    clearTimeout(this.refreshTimeoutIndex);
    this.refreshTimeoutIndex = setTimeout(() => ws.close(), this.wsRefreshTimeout) as any as number;
  }

  wsCloseHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws] closed', this.wsUrl);
    ws.onopen = ws.onclose = ws.onmessage = null;
    this.time = undefined;
    this.connected = false;
    this.attempts++;
    clearTimeout(this.connectTimeoutIndex);
    clearTimeout(this.refreshTimeoutIndex);
    if (this.token) {
      this.connectTimeoutIndex = setTimeout(this.connect.bind(this), 1000) as any as number;
    }
  }

  wsSend(ws: WebSocket, message: any) {
    ws.send(JSON.stringify(message));
  }

  wsMessageHandler(ws: WebSocket, e: MessageEvent) {
    const message = JSON.parse(e.data);
    const messageData: any = message.data;
    // console.log('>> Message handler: ', message.type, message.data);

    switch (message.type) {
      case WsMessageTypes.Ping:
        this.wsSend(ws, { type: 'pong', data: { ...messageData, pong_date: new Date().toISOString() } });
        break;

      case WsMessageTypes.CameraVmStatus:
        this.handleCameraUpdate(message.data);
        break;

      case WsMessageTypes.VideoArchiveProgress:
      case WsMessageTypes.VideoArchiveVMStatus:
        this.videoArchivesById[messageData.id || messageData['video_archive_id']] = messageData;
        this.videoUpdated = messageData;
        break;

      case WsMessageTypes.EpisodeOpen:
      case WsMessageTypes.EpisodeClose:
        this.handleEpisode(message.data);
        break;

      case WsMessageTypes.EpisodeUpdated:
        this.handleEpisodeUpdate(message.data);
        break;

      case WsMessageTypes.EventCreated:
        this.handleEvent(message.data);
        break;

      case WsMessageTypes.EventUpdated:
        this.handleEventUpdate(message.data);
        break;

      case WsMessageTypes.MonitoringEventCreated:
        this.handleMonitoringEvent(message.data);
        break;

      case WsMessageTypes.MonitoringEventUpdated:
        this.handleUpdateMonitoringEvent(message.data);
        break;

      case WsMessageTypes.ClusterCreated:
        this.handleCluster(message.data);
        break;

      case WsMessageTypes.LicenseAlert:
        this.handleLicenseAlert(message.data);
        break;

      case WsMessageTypes.HasUnacknowledged:
        this.handlerUnacknowledged(message.data);
        break;

      case WsMessageTypes.RemoteMonitoringNotificationAcknowledged:
        this.handleRemoteMonitoringAcknowledgedNotification(message.data);
        break;

      case WsMessageTypes.TokenInvalid:
        this.handleTokenInvalid();
        break;

      default:
        logger.log('[ws:message] handler not found for ', message.type, message);
        break;
    }
  }

  handleTokenInvalid() {
    this.tokenInvalid = true;
  }

  handleCameraUpdate(data: any) {
    this.cameraUpdated = data;
  }

  handleVideoUpdate(data: any) {
    this.videoUpdated = data;
  }

  handleRemoteMonitoringAcknowledgedNotification(data: any) {
    this.updatedMonitoringEvent = data?.notification;
  }

  handleEvent(data: any) {
    this.event = data;
  }

  handleEventUpdate(data: any) {
    this.eventUpdated = data;
  }

  handleMonitoringEvent(data: any) {
    this.monitoringEvent = data;
  }

  handleUpdateMonitoringEvent(data: any) {
    this.updatedMonitoringEvent = data;
  }

  handleEpisode(data: any) {
    const { episode_type, episode } = data;
    this.episode = { [episode_type]: episode };
  }

  handleEpisodeUpdate(data: any) {
    const { episode_type, episode } = data;
    this.episodeUpdated = { [episode_type]: episode };
  }

  handleCluster(data: any) {
    this.cluster = data;
  }

  handleLicenseAlert({ message }: { message: string }) {
    this.licenseMessage = message;
  }

  handlerUnacknowledged(data: any) {
    this.unacknowledged = data.all;
    this.notify = data.notify;
    this.acknowledgedAll = !this.unacknowledged;
  }
}

export const websocketModule = reactive(new WebsocketModule(WsEndpoints.events));
export const websocketPuppeteerModule = reactive(new WebsocketModule(WsEndpoints.puppeteerEvents));
