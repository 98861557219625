
import { NButton, NIcon, NLoadingCircle } from '@/uikit';
import { getOrCreateContainer, removeElement } from '@/uikit/notification/helpers/element';
import { computed, defineComponent, onMounted, ref, render, toRefs } from 'vue';
import { bool, func, number, oneOfType, string } from 'vue-types';
import { NotificationRenderer } from './helpers/types';

export default defineComponent({
  name: 'NNotification',
  components: { NLoadingCircle, NIcon, NButton },
  props: {
    type: string().def('info'),
    content: oneOfType([string(), func<NotificationRenderer>()]).isRequired,
    action: string().def(''),
    position: string().def('top-right'),
    showClose: bool().def(true),
    offset: number(),
    duration: number().def(4500),
    customClass: string(),
    size: string().def('small'),
    threadId: string().def('')
  },
  setup(props, context) {
    const { duration, position, offset, action, type, content } = toRefs(props);
    const root = ref<HTMLDivElement | null>(null);
    let visible = ref(false);
    let timer = ref<number | undefined>(undefined);

    const uploading = computed(() => {
      return type.value === 'uploading';
    });

    const hasRenderer = computed(() => {
      return typeof content.value === 'function';
    });

    let container: HTMLDivElement = getOrCreateContainer(position.value);

    const transitionEnterClass = computed(() => {
      return `n-notification--fade-in-${position.value === 'top' ? 'down' : 'up'}`;
    });

    const callToAction = () => {
      context.emit(action.value);
      dismiss();
    };

    const dismiss = () => {
      if (timer.value) clearTimeout(timer.value);
      root.value?.addEventListener('animationend', removeNotification, { once: true });
      visible.value = false;
    };

    const removeNotification = () => {
      const wrapper = root.value;

      if (!wrapper) return;

      render(null, wrapper);
      removeElement(wrapper);
    };

    const showNotification = () => {
      if (!root.value) return;
      const wrapper = root.value?.parentElement;
      container?.insertAdjacentElement('afterbegin', root.value);
      if (wrapper) removeElement(wrapper);
      if (offset.value) root.value.style[position.value as any] = `${offset.value}px`;
      visible.value = true;
      if (duration.value > 0) timer.value = window.setTimeout(dismiss, duration.value);
    };

    const getThreadNotifications = () => {
      if (container && props.threadId) {
        return container.querySelectorAll(`[data-thread-id="${props.threadId}"]`);
      }
      return [];
    };

    const hasThreadNotifications = () => {
      return getThreadNotifications().length > 0;
    };

    onMounted(() => {
      !hasThreadNotifications() && showNotification();
    });

    const onKeydown = ({ keyCode }: KeyboardEvent) => {
      if (keyCode === 27) {
        if (visible.value) {
          removeNotification();
        }
      }
    };

    document.addEventListener('keydown', onKeydown);

    return {
      root,
      visible,
      uploading,
      callToAction,
      hasRenderer,
      removeNotification,
      transitionEnterClass,
      ...props
    };
  }
});
