
import { CaseAttachment } from '@/api';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { getListViewModel } from '@/definitions/view-models';
import { informationLayout, permissionsLayout } from '@/pages/cases/case-form-schema';
import { applicationModule } from '@/store/application';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { RouterModule } from '@/store/router';
import { NButton } from '@/uikit';
import NForm from '@/uikit/forms/NForm.vue';
import { NAttachment, NAttachmentAttachDoneHandler, NAttachmentRemoveDoneHandler, NAttachments, NAttachmentsI18n } from '@/uikit/NAttachments';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

function createAttachmentModule() {
  const options = {
    url: '/case-attachments/',
    routeName: 'Cases',
    emptyItem: { id: 0, case: 0, name: '', url: '' },
    emptyFilter: { limit: 100, case: 0 }
  };
  return getListViewModel(options);
}

@Options({
  name: 'CaseForm',
  components: {
    NAttachments,
    NButton,
    NForm
  },
  emits: ['save', 'close'],
  informationLayout,
  permissionsLayout
})
export default class CaseForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly item: any;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean = false;

  showPermissions = false;

  attachments: NAttachment[] = [];

  get appModule() {
    return applicationModule;
  }

  get dataModule() {
    return dataModule;
  }

  get isCurrentUserAdmin() {
    return dataModule.isCurrentUserAdmin;
  }

  get isNew() {
    return this.item.id < -1000;
  }

  get uploadUrl(): string {
    return (configModule.config.server?.url ?? '/') + 'case-attachments/';
  }

  get headers(): Record<string, string> {
    return {
      authorization: `Token ${authModule.getAuthorizationValue()}`
    };
  }

  get attachmentLabels(): NAttachmentsI18n {
    return {
      attach_first_one: this.$t('common.attach_first_one'),
      attach_more: this.$t('common.attach_more'),
      cancel: this.$t('common.cancel', 'f'),
      files: this.$tm('common.files_mf', { count: this.attachments.length }),
      no_files: this.$t('common.no_files'),
      removing_confirmation: this.$t('common.removing_confirmation'),
      yes: this.$t('common.yes', 'f')
    };
  }

  validate(): boolean {
    return this.$refs.infoForm?.validate();
  }

  saveHandler() {
    if (!this.validate()) {
      this.$refs.infoForm?.displayErrors();
      return;
    }
    this.$emit('save');
  }

  get attachmentModule() {
    return createAttachmentModule();
  }

  @Watch('item.id', { immediate: true })
  loadAttachments() {
    if (!this.isNew && this.item.id !== 0) {
      this.attachmentModule.filter.current.case = this.item.id;
      this.attachmentModule.get();
    }
  }

  async uploadAttachmentHandler(attachment: NAttachment, done: NAttachmentAttachDoneHandler) {
    const axios = dataServiceFactory.getAxiosInstance();
    const data = new FormData();
    data.append('case', this.item.id);
    data.append('name', attachment.name);
    data.append('file', attachment.file);

    const uploadProgressHandler = ({ loaded, total }: ProgressEvent) => {
      attachment.progress = (loaded / total) * 100;
    };

    try {
      attachment.status = 'uploading';
      await axios.post(this.attachmentModule.name, data, { onUploadProgress: uploadProgressHandler });
      attachment.status = 'done';
    } catch (e) {
      attachment.status = 'error';
      console.warn('[attachment] error: ', e);
    } finally {
      done();
    }

    await this.attachmentModule.get({ resetState: false });
  }

  async deleteAttachmentHandler(attachment: NAttachment, done: NAttachmentRemoveDoneHandler) {
    await this.attachmentModule.delete(attachment.id);
    await this.attachmentModule.get({ resetState: false });
    done();
  }

  @Watch('attachmentModule.items', { immediate: true })
  handleAttachmentsChange(attachments: CaseAttachment[]): void {
    this.attachments = attachments.map<NAttachment>((attachment) => ({
      file: attachment.file,
      id: attachment.id,
      name: attachment.name ?? '',
      progress: 100,
      size: attachment.size,
      status: 'done'
    }));
  }

  navigateToUser(id: number) {
    RouterModule.navigateToUser(id);
  }
}
