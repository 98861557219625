import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, {
    "has-left-bar": "",
    "decrease-content": false
  }, {
    "left-bar": _withCtx(() => [
      _createVNode(_component_SettingsMenu)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    sidebar: _withCtx(() => [
      _renderSlot(_ctx.$slots, "sidebar")
    ]),
    multitool: _withCtx(() => [
      _renderSlot(_ctx.$slots, "multitool")
    ]),
    _: 3
  }))
}