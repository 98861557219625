
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { NCounter, NIcon } from '@/uikit';

@Options({
  name: 'NSelectTagsCount',
  components: { NCounter, NIcon }
})
export default class NSelectTagsCount extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly counterValue!: number;
}
