import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!

  return (_openBlock(), _createBlock(_component_FiltersSmall, {
    onOpenFilters: _ctx.dispatchExpandEvent,
    onResetFilters: _ctx.dispatchResetEvent,
    class: "data-sources-page-filters-small",
    "has-report": "",
    state: _ctx.state,
    "form-layout": _ctx.layout,
    "has-changes": _ctx.hasChanges,
    "report-filter": _ctx.forceFilter,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    enabledFields: _ctx.smallFilterFields,
    "onUpdate:enabledFields": _cache[1] || (_cache[1] = (v) => _ctx.setSmallFilterFields(v))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onOpenFilters", "onResetFilters", "state", "form-layout", "has-changes", "report-filter", "modelValue", "enabledFields"]))
}