import { MultisidebarGroup, MultisidebarItem } from '@/store/multisidebar/types';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { confirmDialogFactory, ConfirmOptions } from '@/store/dialogs/confirmDialogFactory';
import { languageModule } from '@/store/languages';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { convertToMsbItem } from '@/store/data/ActionHandler';

const t = languageModule.getTranslatedToken;

export class DialogModule {
  async createConfirm(action: ItemsActionName, item: MultisidebarItem<any>): Promise<boolean> {
    let result = Promise.resolve(true);
    let msbItem: MultisidebarItem<any> | null = null;

    switch (action) {
      case ItemsActionNames.Process:
      case ItemsActionNames.ProcessAll:
        msbItem = await convertToMsbItem(item);
        if (msbItem.model?.item?.finished) result = confirmDialogFactory.create(this.getPocessingConfirmOptions());
        break;
      case ItemsActionNames.DeleteAll:
      case ItemsActionNames.Delete:
        result = confirmDialogFactory.create(this.getDeleteConfirmOptions(item.type));
        break;

      case ItemsActionNames.DeleteVideoFile:
      case ItemsActionNames.DeleteVideoFileAll:
        result = confirmDialogFactory.create(this.getDeleteVideoFileConfirmOptions(item.type));
        break;

      case ItemsActionNames.DeleteCards:
      case ItemsActionNames.DeleteCardsInAll:
        result = confirmDialogFactory.create(this.getDeleteCardsConfirmOptions(item.type));
        break;
    }
    return result;
  }

  createGroupConfirm(action: ItemsActionName, group: MultisidebarGroup): Promise<boolean> {
    return this.createConfirm(action, group.items[0].item);
  }

  private getDefaultOptions(): Partial<ConfirmOptions> {
    return {
      confirmLabel: t('common.yes', 'f'),
      cancelLabel: t('common.cancel', 'f')
    };
  }

  private getPocessingConfirmOptions(): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.video_processing_confirm', 'f')
    };
    return result;
  }

  private getDeleteCardsConfirmOptions(type: string): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.delete_all_cards_confirm', 'f')
    };
    return result;
  }

  private getDeleteConfirmOptions(type: string): ConfirmOptions {
    let confirmToken = 'common.delete_confirm';
    switch (type) {
      case MultisidebarItemTypes.ExternalVms:
        confirmToken = 'common.delete_external_vms_confirm';
        break;
      case MultisidebarItemTypes.Webhooks:
        confirmToken = 'common.delete_webhooks_confirm';
        break;
      case MultisidebarItemTypes.ClustersFaces:
      case MultisidebarItemTypes.ClustersBodies:
      case MultisidebarItemTypes.ClustersCars:
        confirmToken = 'common.delete_clusters_confirm';
        break;
      case MultisidebarItemTypes.Groups:
        confirmToken = 'common.delete_groups_confirm';
        break;
      case MultisidebarItemTypes.Users:
        confirmToken = 'common.delete_users_confirm';
        break;
      case MultisidebarItemTypes.Cameras:
        confirmToken = 'common.delete_cameras_confirm';
        break;
      case MultisidebarItemTypes.CardsHumans:
      case MultisidebarItemTypes.CardsCars:
        confirmToken = 'common.delete_cards_confirm';
        break;
      case MultisidebarItemTypes.CameraGroups:
        confirmToken = 'common.delete_camera_groups_confirm';
        break;
      case MultisidebarItemTypes.WatchLists:
        confirmToken = 'common.delete_all_watch_lists_confirm';
        break;
      case MultisidebarItemTypes.Videos:
        confirmToken = 'common.delete_video_confirm';
        break;
    }

    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t(confirmToken, 'f')
    };
    return result;
  }

  private getDeleteVideoFileConfirmOptions(type: string): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.delete_confirm', 'f')
    };
    return result;
  }
}

export const dialogModule = new DialogModule();
