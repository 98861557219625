
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { trim } from 'lodash';
import { THEME_VARS_CONTAINER_ID } from '@/common/theme-load';

function clearSrc(src = '') {
  if (src.startsWith('url')) {
    src = src.match(/^url\((.*?)\)/i)?.[1] || '';
  }
  src = trim(src, '"\'').replaceAll('\\', '');
  return src;
}

@Options({
  name: 'NThemeImage'
})
export default class NThemeImage extends Vue {
  @Prop({ type: String, required: true })
  readonly cssVar!: string;

  public src = '';
  private observer?: MutationObserver;

  mounted() {
    this.setSrc();
    this.startThemeObserve();
  }

  beforeUnmount() {
    this.observer?.disconnect();
  }

  setSrc() {
    const src = getComputedStyle(document.documentElement).getPropertyValue(this.cssVar).trim();
    this.src = clearSrc(src);
  }

  startThemeObserve() {
    const themeContainer = document.getElementById(THEME_VARS_CONTAINER_ID);
    if (themeContainer) {
      this.observer = new MutationObserver(this.setSrc.bind(this));
      this.observer.observe(themeContainer, {
        attributes: true
      });
    }
  }
}
