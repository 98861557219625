
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NButton } from '@/uikit';
import isNumber from 'lodash/isNumber';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CameraLabelItem from './CameraLabelItem.vue';
import { shortString } from '@/common/filters';

export enum CameraLabelsType {
  Button = 'button',
  Text = 'text'
}

let CameraLabelsComponent = {
  [CameraLabelsType.Button]: 'NButton',
  [CameraLabelsType.Text]: 'div'
};

const VIDEO_ARCHIVE_CAMERA_GROUP = -1;

@Options({
  name: 'CameraLabelsV2',
  components: { CameraLabelItem, NButton }
})
export default class CameraLabelsV2 extends Vue {
  @Prop({ type: Array, required: false })
  readonly cameraGroupsIds?: Array<number>;

  @Prop({ type: Array, required: false })
  readonly camerasIds?: Array<number>;

  @Prop({ type: Number, required: false })
  readonly videoArchiveId?: number;

  @Prop({ type: String, default: CameraLabelsType.Button })
  readonly type!: CameraLabelsType;

  @Prop({ type: String, default: '/' })
  readonly separator!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasPlay!: boolean;

  get cameraLabelsComponent() {
    return CameraLabelsComponent[this.type];
  }

  get formattedCameraGroupNotFound() {
    return this.formatNotFound(this.$t('errors.camera_group.not_found'));
  }

  get formattedCameraNotFound() {
    return this.formatNotFound(this.$t('errors.camera.not_found'));
  }

  get formattedSeparator() {
    return ' ' + this.separator + ' ';
  }

  isVideoArchiveCameraGroup(cameraGroupId: number): boolean {
    return cameraGroupId === VIDEO_ARCHIVE_CAMERA_GROUP;
  }

  formatNotFound(str: string) {
    return '( ' + str + ' )';
  }

  getCameraGroupName(id: number): string {
    return shortString(dataAssetsModule.getCameraGroupById(id)?.name, 24) ?? this.formattedCameraGroupNotFound;
  }

  getCameraName(cameraId: number): string {
    return shortString(dataAssetsModule.getCameraById(cameraId)?.name, 24) ?? this.formattedCameraNotFound;
  }

  emitActionShowCamera(id: number) {
    this.$emit('action', ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: id });
  }

  emitActionShowVideo() {
    if (this.videoArchiveId) {
      this.$emit('action', ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Videos, rawItem: this.videoArchiveId });
    }
  }

  emitPlayAction(id: number) {
    this.$emit('action', ItemsActionNames.ShowPlayer, id);
  }

  getCameraGroupByCameraId(cameraId: number): string {
    const group = dataAssetsModule.getCameraById(cameraId)?.group;
    return isNumber(group) ? this.getCameraGroupName(group) : this.formattedCameraGroupNotFound;
  }

}
