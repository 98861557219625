
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { VideoArchive } from '@/api';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import { NButton } from '@/uikit';
import VideoProcessPreviewBar from './VideoProcessPreviewBar.vue';
import VideoProcessPreviewItems from '@/pages/videos/process-preview/VideoProcessPreviewItems.vue';
import axios from 'axios';
import { authModule } from '@/store/auth';

@Options({
  name: 'VideoProcessPreview',
  components: { VideoProcessPreviewItems, VideoProcessPreviewBar, NButton, VideoPlayer },
  emits: ['close']
})
export default class VideoProcessPreview extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: VideoArchive;

  private screenshot = '';

  @Watch('item.screenshot')
  async loadScreenshot() {
    if (!this.item.screenshot) return;

    const token = authModule.token?.toString();

    let response = await axios({
      url: this.item.screenshot,
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(token!)
      }
    });

    this.screenshot = URL.createObjectURL(response.data);
  }

  get isActive() {
    return this.item.active;
  }

  get playerBackground() {
    if (!this.isActive && this.screenshot) {
      return `background-image: url("${this.screenshot}");`;
    }
    return '';
  }

  mounted() {
    this.loadScreenshot();
  }

  beforeUnmount() {
    this.screenshot && URL.revokeObjectURL(this.screenshot);
  }
}
