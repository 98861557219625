
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';
import { Camera } from '@/api';

@Options({
  name: 'CameraName',
  components: { NSelect }
})
export default class CameraName extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly?: boolean;

  @Prop({ type: Array, default: [] })
  readonly idIn?: number[];

  @Prop({ type: Number })
  readonly cameraGroup?: number;

  get items() {
    const result: any[] = dataModule.camerasModule.items
      .filter((item) => (this.idIn?.length ? this.idIn.includes(item.id) : true))
      .filter((item) => (this.cameraGroup === undefined ? true : item.group === this.cameraGroup))
      .map((item) => ({
        label: item.name,
        value: item.id
      }));
    return result;
  }
  get cameraName() {
    const dataItems = dataModule.camerasModule.items;
    const camera = dataItems.find((item) => item.id === this.modelValue);
    if (camera) return camera.name;
    return '[camera:not found]';
  }

  @Watch('cameraGroup')
  handleCameraGroupChange() {
    if (this.cameraGroup !== undefined && this.items.length === 0) {
      this.updateValue(null);
    }
  }

  updateValue(value: number | null) {
    this.$emit('update:modelValue', value);
  }
}
