
import { EnrichedCard } from '@/components/cards/enrich-cards';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'CardItemRow',
  components: { NButton, WatchListsGroup, NThumbnail }
})
export default class CardItemRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedCard;

  @Prop({ type: Boolean, default: true })
  readonly closeable!: boolean;

  @Prop({ type: String, default: 'small' })
  readonly thumbnailSize!: string;

  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;

  @Prop({ type: Boolean, default: false })
  readonly showFullframeActive!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly cardNavigateActive!: boolean;

  get thumbnail() {
    return this.item.faceObject?.thumbnail;
  }

  dispatchActionEvent(): void {
    this.$emit('action', 'show-fullframe');
  }
}
