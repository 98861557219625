
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NPageIcon from '../page-icon/NPageIcon.vue';

export interface MenuItem {
  icon: string;
  title: string;
  description: string;
  path: string;
}

@Options({
  name: 'NLaunchpad',
  components: { NPageIcon }
})
export default class Launchpad extends Vue {
  @Prop({ type: Array, required: true })
  items!: MenuItem[];

  @Prop({ type: Number, default: 228 })
  arrowLeft!: number;
}
