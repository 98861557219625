
import { CaseParticipantAttachment } from '@/api';
import { viewModelRepository } from '@/api/common';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { NAttachment, NAttachmentAttachDoneHandler, NAttachmentRemoveDoneHandler, NAttachments, NAttachmentsI18n } from '@/uikit/NAttachments';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'ParticipantAttachments',
  components: { NAttachments }
})
export default class ParticipantAttachments extends Vue {
  protected uploadService = dataServiceFactory.getAxiosInstance();

  attachments: NAttachment[] = [];

  @Prop({ type: Number, required: true })
  public readonly id!: number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  get module() {
    return viewModelRepository.getCaseParticipantAttachmentsListViewModel();
  }

  get attachmentsI18n(): NAttachmentsI18n {
    return {
      attach_first_one: this.$t('common.attach_first_one'),
      attach_more: this.$t('common.attach_more'),
      cancel: this.$t('common.cancel', 'f'),
      files: this.$tm('common.files_mf', { count: this.attachments.length }),
      no_files: this.$t('common.no_files'),
      removing_confirmation: this.$t('common.removing_confirmation'),
      yes: this.$t('common.yes', 'f')
    };
  }

  @Watch('id', { immediate: true })
  loadAttachments(v: number) {
    if (v) {
      this.module.filter.current.case_participant = v;
      this.module.filter.current.limit = 1000;
      this.module.get();
    }
  }

  @Watch('module.items', { immediate: true })
  handleAttachmentsChange(attachments: CaseParticipantAttachment[]): void {
    this.attachments = attachments.map<NAttachment>((attachment) => ({
      file: attachment.file,
      id: attachment.id,
      name: attachment.name ?? '',
      progress: 100,
      size: attachment.size,
      status: 'done'
    }));
  }

  async handleDelete(attachment: NAttachment, done: NAttachmentRemoveDoneHandler) {
    await this.module.delete(attachment.id);
    await this.module.get();
    done();
  }

  async handleUpload(attachment: NAttachment, done: NAttachmentAttachDoneHandler) {
    let payload = new FormData();
    payload.append('case_participant', String(this.id));
    payload.append('name', attachment.name);
    payload.append('file', attachment.file);

    try {
      attachment.status = 'uploading';
      await this.uploadService.post(this.module.name, payload);
      attachment.status = 'done';
    } catch (e) {
      attachment.status = 'error';
      console.warn('[participant attachment]: ' + e);
    } finally {
      done();
    }

    await this.module.get();
  }
}
