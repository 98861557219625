import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { ListViewModel } from '@/definitions/view-models';
import { aclModule } from '@/store/acl';
import { IModelAclResult } from '@/store/acl/types';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { nextTick } from 'vue';

type PageViewModelOptions = {
  tab: string;
  name: PageName;
  aclName?: string;
  pageState?: Partial<PageState>;
  filter?: any;
  id?: string;
  getIsItemFormValid?: () => boolean;
};

export function parseQueryObjectToPageOptions(query: any): Partial<PageViewModelOptions> {
  const result: Partial<PageViewModelOptions> = {};
  const { tab, pageState } = query;
  if (tab) result.tab = tab;
  if (pageState) result.pageState = JSON.parse(pageState);
  return result;
}

export class PageViewModel<T, F> {
  protected readonly aclName?: string;
  public readonly state: PageState;
  public getIsItemFormValid?: () => boolean;

  constructor(options: PageViewModelOptions) {
    this.togglePlaying = this.togglePlaying.bind(this);
    this.actionHandler = this.actionHandler.bind(this);
    const state = Object.assign(pageModule.getPageStateByTab(options.name, options.tab), options.pageState, { tab: options.tab });
    this.state = state;
    this.applyState();
  }

  applyState() {
    const { filter } = this.state;
    nextTick(() => {
      if (filter) Object.assign(this.module.filter.current, filter);
    });
  }

  get moduleKey(): string {
    return pageModule.getModelsKey(this.state);
  }

  get moduleAcl(): IModelAclResult {
    this.module.aclModelName = this.module.aclModelName || this.aclName;
    return this.module.hasAcl ? aclModule.getModelAcl(this.module) : aclModule.maxAcl;
  }

  get module(): ListViewModel<T, F> /*| ItemViewModel<T>*/ {
    return pageModule.getPageModule(this.state) as any as ListViewModel<T, F>;
  }

  get selectedItems(): (T | undefined)[] {
    return this.module.selectedItems.map((v) => v.item);
  }

  get currentItem(): T | undefined {
    return this.module.currentItem?.item;
  }

  togglePlaying() {
    this.state.playing = !this.state.playing;
    if (this.state.playing) this.module.get();
  }

  setFilterFromString(v: string) {
    this.module.filter.setCurrentByString(v);
  }

  toggleSidebar() {
    const showSidebar = !this.state.showSidebar;
    const shouldSetCurrentToShow = !this.currentItem && this.selectedItems.length;

    if (showSidebar && shouldSetCurrentToShow) {
      this.module.setCurrentItem((this.selectedItems[0] as any).id);
    }

    this.state.showSidebar = showSidebar;
  }

  async actionHandler(id: string | number, action: string, payload?: any): Promise<boolean> {
    const index = (this.module.items as any[]).findIndex((v) => v.id === id);
    const payloadItemID = payload?.item?.id;
    const payloadItemIndex = payloadItemID ? (this.module.items as any[]).findIndex((v) => v.id === payloadItemID) : -1;

    const actionItem: any = (id && this.module.items.find((v: any) => v?.id === id)) ?? this.module.currentItem;
    const actionItemVM = this.module.selectedItems.find((v: any) => v.item.id === actionItem.id) || this.module.getItemViewModelByItem(actionItem);

    const getIsFormValid = () => (this.getIsItemFormValid ? this.getIsItemFormValid() : false);
    let result = false;
    this.state.defaultAction = action;

    switch (action) {
      case EventDetails.CloseInfo:
        this.state.showSidebar = false;
        result = true;
        break;

      case EventDetails.ShowInfo:
        if (payloadItemID) {
          if (payloadItemIndex < 0) this.module.virtualItems.push(payload!.item);
          this.module.setCurrentItem(payloadItemID);
          this.state.id = payloadItemID;
        } else {
          this.module.setCurrentItem(id);
          this.state.id = id;
        }

        this.state.showSidebar = true;
        result = true;
        break;

      case EventDetails.Remove:
        this.module.toggleSelectedItem(id);
        this.state.id = this.module.currentItemId;
        result = true;
        break;

      case EventDetails.ShowFullScreen:
        this.state.showViewer = true;
        this.state.viewerIndex = index;
        result = true;
        break;

      case EventDetails.ShowPlayer:
        this.state.showPlayer = true;
        this.state.playerIndex = index;
        result = true;
        break;

      case ItemsActionNames.SelectAll:
        this.module.selectAllItems();
        result = true;
        break;

      case ItemsActionNames.DeselectAll:
        this.module.deselectAllItems();
        result = true;
        break;

      case ItemsActionNames.ToggleCurrent:
        this.toggleSidebar();
        result = true;
        break;

      case ItemsActionNames.DeleteAll:
        await this.module.deleteAllSelectedItems();
        await this.module.get({ resetState: false });
        result = true;
        break;

      case ItemsActionNames.Delete:
        if (id !== (this.currentItem as any)?.id) {
          await actionItemVM.delete(id);
        } else {
          await this.module.deleteCurrentItem();
        }

        await this.module.get({ resetState: false });
        result = true;
        break;

      case ItemsActionNames.Reset:
        await this.module.resetCurrentItem();
        result = true;
        break;

      case ItemsActionNames.Deselect:
        this.module.deselectCurrentItem();
        result = true;
        break;

      case ItemsActionNames.Close:
        await this.module.deleteCurrentItem();
        result = true;
        break;

      case ItemsActionNames.SaveAll:
        if (!getIsFormValid()) break;
        await this.module.saveAllSelectedItems();
        await this.module.get({ resetState: false });
        result = true;
        break;

      case ItemsActionNames.Save:
        if (!getIsFormValid()) break;
        await this.module.saveCurrentItem();
        await this.module.get({ resetState: false });
        result = true;
        break;
    }

    return result;
  }
}
