import { VideoWallPageState } from '@/pages/video-wall/types';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';

export enum DisplayType {
  Short = 'short',
  Full = 'full',
  Table = 'table'
}

export type SearchFrom = 'events' | 'cards' | 'clusters' | 'cases' | 'file' | '';

export enum PageName {
  audit_logs = 'audit_logs',
  block_list = 'blocklist-records',
  camera_groups = 'camera_groups', // todo: rename to kebab
  cards = 'cards',
  case = 'case',
  cases = 'cases',
  clusters = 'clusters',
  datasources = 'data-sources',
  events = 'events',
  external_search = 'external-search',
  external_vms = 'external_vms', // todo: rename to kebab
  hooks = 'hooks',
  none = 'none',
  interactions = 'interactions',
  participants = 'participants',
  remote_monitoring_events = 'remote-monitoring-events',
  reports = 'reports',
  roles = 'roles',
  search = 'search',
  sessions = 'sessions',
  settings = 'settings',
  users = 'users',
  VideoWall = 'video-wall',
  watch_lists = 'watch-lists'
}

export enum PageType {
  area_activations = 'area_activations',
  area_records = 'area_records',
  areas = 'areas',
  audit_logs = 'audit_logs',
  block_list = 'blacklist',
  camera_groups = 'camera_groups',
  cameras = 'cameras',
  cards = 'cards',
  cases = 'cases',
  cluster_events = 'cluster_events',
  clusters = 'clusters',
  counter_records = 'counter_records',
  counters = 'counters',
  episodes = 'episodes',
  events = 'events',
  external_detectors = 'external_detectors',
  external_vms = 'external_vms',
  external_vms_cameras = 'external_vms_cameras',
  hooks = 'hooks',
  kyc = 'kyc',
  none = 'none',
  participants = 'participants',
  reports = 'reports',
  roles = 'roles',
  search = 'search',
  sessions = 'sessions',
  users = 'users',
  videos = 'videos',
  VideoWall = 'video-wall',
  watch_lists = 'watch-lists'
}

export type PageState = {
  tab: string;
  pageName: PageName;
  pageType: PageType;
  cardType?: string;
  objectType?: string;
  episodeType?: string;
  displayType?: DisplayType;
  id?: string | number;
  filter?: Record<string, any>;
  defaultAction?: string;
  showSidebar: boolean;
  showViewer: boolean;
  showPlayer: boolean;
  showMultitool?: boolean;
  showLeftBar?: boolean;
  showOverlay?: boolean;
  showMainHeader?: boolean;
  viewerIndex?: number;
  playerIndex?: number;
  playing?: boolean;
  searchFrom?: SearchFrom;
  subPage?: string;
  additionalId?: number;
};

export type SearchPageState = PageState & {
  cardType: string;
  objectType: string;
  episodeType: string;
  detectionId?: string;
  cardLooksLike?: string[];
};

export type EventsPageState = PageState & {
  objectType: string;
  episodeType: string;
  displayType: DisplayType;
};

export const EmptyDefaultPageState: PageState = {
  tab: '',
  pageName: PageName.none,
  pageType: PageType.none,
  showSidebar: false,
  showViewer: false,
  showPlayer: false
};

export const EmptyDatasourcePageState: PageState = {
  tab: '',
  pageName: PageName.datasources,
  pageType: PageType.cameras,
  showSidebar: false,
  showViewer: false,
  showPlayer: false
};

export const EmptyEventPageState: EventsPageState = {
  tab: '',
  pageName: PageName.events,
  pageType: PageType.episodes,
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo,
  playing: true,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showMultitool: false
};

export const EmptyCardPageState: PageState = {
  tab: '',
  pageName: PageName.cards,
  pageType: PageType.cards,
  cardType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo,
  showSidebar: false,
  showViewer: false,
  showPlayer: false
};

export const EmptyClusterPageState: PageState = {
  tab: '',
  playing: true,
  pageName: PageName.clusters,
  pageType: PageType.clusters,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showMultitool: false
};

export const EmptyParticipantsPageState: PageState = {
  tab: '',
  pageName: PageName.participants,
  pageType: PageType.participants,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showMultitool: false
};

export const EmptyCasesPageState: PageState = {
  tab: '',
  pageName: PageName.cases,
  pageType: PageType.cases,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showMultitool: false,
  showOverlay: false
};

export const EmptyAuditLogsPageState: PageState = {
  tab: '',
  pageName: PageName.audit_logs,
  pageType: PageType.audit_logs,
  displayType: DisplayType.Short,
  showSidebar: false,
  showViewer: false,
  showPlayer: false
};

export const EmptySessionPageState: PageState = {
  tab: '',
  pageName: PageName.sessions,
  pageType: PageType.sessions,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showLeftBar: true,
  showMultitool: true,
  showOverlay: false
};

export const EmptyBlockListPageState: PageState = {
  tab: '',
  pageName: PageName.block_list,
  pageType: PageType.block_list,
  defaultAction: EventDetails.ShowInfo,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showLeftBar: true,
  showOverlay: false,
  showMultitool: true
};

export const EmptySearchPageState: SearchPageState = {
  tab: '',
  pageName: PageName.search,
  pageType: PageType.cards,
  cardType: 'humans',
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayType.Short,
  defaultAction: EventDetails.ShowInfo,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showMultitool: false,
  showOverlay: false,
  detectionId: '',
  searchFrom: ''
};

export const EmptyExternalMonitoringEventsPage: PageState = {
  tab: '',
  pageName: PageName.remote_monitoring_events,
  pageType: PageType.none,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  playing: true
};

export const EmptyExternalVMSPageState: PageState = {
  tab: '',
  pageName: PageName.external_vms,
  pageType: PageType.external_vms,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  showLeftBar: true,
  showMultitool: true,
  showOverlay: false
};

export const EmptyWebhooksPageState: PageState = {
  tab: '',
  pageName: PageName.hooks,
  pageType: PageType.hooks,
  showSidebar: false,
  showViewer: false,
  showPlayer: false,
  playing: true
};

export const EmptyVideoWallPageState: VideoWallPageState = {
  layout: 'videowall-and-sidebar',
  pageName: PageName.VideoWall,
  pageType: PageType.VideoWall,
  preset: {
    cameraIds: [null],
    id: null,
    layout: '1_camera',
    name: ''
  },
  showPlayer: false,
  showSidebar: false,
  showViewer: false,
  tab: ''
};
