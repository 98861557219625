
import { toRaw } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AnyFunction, IFormContext, IFormError, IFormLayoutItem, IFormModel } from '@/uikit/forms/NForm.vue';
import translatedProps from '@/common/translate-props';
import NHint from '@/uikit/hint/NHint.vue';
import NFormError from '@/uikit/forms/NFormError.vue';
import { cloneDeep, get, set } from 'lodash';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NCheckboxIcon from '@/uikit/checkbox/NCheckboxIcon.vue';

@Options({
  name: 'NFormItem',
  components: { NCheckboxIcon, NCheckbox, NTooltip, NFormError, NHint }
})
export default class NFormItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: IFormLayoutItem;

  @Prop({ type: Object, required: true })
  readonly model!: IFormModel;

  @Prop({ type: Object, default: null })
  readonly context!: IFormContext;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Object, default: null })
  readonly error!: IFormError;

  @Prop({ type: Boolean, default: false })
  readonly hasEnabledFields!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isFieldEnabled!: boolean;

  reference: any = null;

  referenceChange(reference: any) {
    this.reference = reference;
  }

  get fieldId() {
    return this.item.path || this.item.name;
  }

  get itemContext(): IFormContext {
    return { ...this.context, item: this.item };
  }

  get label() {
    if (this.item.i18n_label) return this.$t(this.item.i18n_label, 'f');
    return this.item.label;
  }

  get tooltip() {
    if (this.item.i18n_tooltip) return this.$t(this.item.i18n_tooltip, 'f');
    return this.item.tooltip;
  }

  get tooltipPlacement() {
    return this.item.tooltipPlacement ?? 'top-right';
  }

  get component() {
    return toRaw(this.item.component);
  }

  get isUseModel(): boolean {
    return !!(this.item.path || this.item.encode || this.item.decode);
  }

  get modelValue() {
    if (this.item.decode instanceof Function) {
      return this.item.decode.call(this.itemContext, this.model);
    }
    return get(this.model, this.item.path || 'undefined');
  }

  set modelValue(value) {
    if (this.item.encode instanceof Function) {
      this.item.encode.call(this.itemContext, this.model, value);
      return;
    }
    set(this.model, this.item.path || 'undefined', value);
  }

  get handlers() {
    const handlers: Record<string, AnyFunction> = {};
    if (this.item.on instanceof Object) {
      Object.keys(this.item.on).forEach((name: string) => {
        handlers[name] = (...args: any[]) => {
          this.item.on![name].apply(this.itemContext, args);
        };
      });
    }
    return handlers;
  }

  get props() {
    // todo: refactor it!
    const originalProps = this.item.props instanceof Function ? this.item.props(this.model) : this.item.props;

    const resultProps = originalProps ? cloneDeep(originalProps) : { disabled: this.disabled };
    if (originalProps) {
      if (!Object.isFrozen(originalProps)) resultProps.disabled = this.disabled;
      if (originalProps.model) resultProps.model = originalProps.model;
      if (originalProps.item) resultProps.item = originalProps.item;
    }
    return translatedProps(resultProps, this.$t);
  }

  get hidden() {
    if (this.item.hidden instanceof Function) {
      return this.item.hidden(this.model);
    }
    return this.item.hidden;
  }

  get canFocus(): boolean {
    return this.$refs?.control?.focus && !!this.$refs?.control?.focus;
  }

  focusOnControl(): void {
    setTimeout(() => {
      this.$refs?.control?.focus && this.$refs?.control?.focus();
    }, 0);
  }

  labelClickHandler(e: MouseEvent) {
    this.focusOnControl();
  }
}
