
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'CameraLabelItem',
  components: { NButton }
})
export default class CameraLabelItem extends Vue {
  @Prop({ type: String, required: true })
  groupName?: string;

  @Prop({ type: String, required: true })
  name!: string;

  @Prop({ type: String, default: 'short' })
  type!: string;

  @Prop({ type: Boolean, default: true })
  hasIcon!: boolean;

  get icon() {
    return this.hasIcon && 'play';
  }
}
