import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterManager = _resolveComponent("FilterManager")!
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!
  const _component_PageContentLayout = _resolveComponent("PageContentLayout")!

  return (_openBlock(), _createBlock(_component_PageContentLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_FiltersSmall, {
        "has-filter-manager": "",
        "form-layout": _ctx.smallFilterLayout,
        "has-changes": _ctx.module.filter.hasChanges,
        state: _ctx.state,
        "has-report": "",
        modelValue: _ctx.module.filter.current,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.module.filter.current) = $event)),
        onResetFilters: _cache[2] || (_cache[2] = ($event: any) => (_ctx.module.filter.reset())),
        onOpenFilters: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFilterVisible = true))
      }, {
        "filter-manager": _withCtx(() => [
          _createVNode(_component_FilterManager, {
            source: "analytics",
            filterString: _ctx.module.filter.currentCleanAsString,
            onSelect: _ctx.selectFilter,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFilterVisible = true))
          }, null, 8, ["filterString", "onSelect"])
        ]),
        _: 1
      }, 8, ["form-layout", "has-changes", "state", "modelValue"]),
      (_ctx.isFilterVisible)
        ? (_openBlock(), _createBlock(_component_FiltersBig, {
            key: _ctx.module.filter.current.precision,
            "form-layout": _ctx.bigFilterLayout,
            state: _ctx.state,
            "has-report": "",
            modelValue: _ctx.module.filter.current,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.module.filter.current) = $event)),
            onResetFilters: _cache[5] || (_cache[5] = ($event: any) => (_ctx.module.filter.reset())),
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isFilterVisible = false))
          }, null, 8, ["form-layout", "state", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['kyc-chart-container', { 'kyc-chart-container_has-full': _ctx.fullscreenItemType }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentNameByItem(item)), {
            key: `${item.type}-${_ctx.fullscreenItemType}-${_ctx.theme}`,
            item: item,
            filter: _ctx.module.filter.current,
            full: _ctx.fullscreenItemType === item.type,
            onActivate: ($event: any) => (_ctx.activateItem(item)),
            onShowFullscreen: ($event: any) => (_ctx.fullscreenToggle(item)),
            onDownload: ($event: any) => (_ctx.downloadChartImage(item, $event))
          }, null, 8, ["item", "filter", "full", "onActivate", "onShowFullscreen", "onDownload"]))
        }), 128))
      ], 2)
    ]),
    _: 1
  }))
}