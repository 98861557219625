
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
  name: 'NCheckbox'
})
export default class NCheckbox extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled?: boolean;

  @Prop({ type: [String, Number], default: '' })
  readonly label?: string | number;

  @Prop({ type: [String, Number, Boolean], default: false })
  readonly modelValue!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly indeterminate!: boolean;

  isIndeterminated = false;

  created() {
    this.isIndeterminated = this.indeterminate;
  }

  @Watch('modelValue')
  modelValueHandler(v: boolean) {
    this.isIndeterminated = false;
  }

  @Watch('indeterminate')
  indeterminateHandler(v: boolean) {
    this.isIndeterminated = v;
  }

  focused = false;

  handleChange() {
    if (this.disabled) return;
    const newValue = !this.modelValue;
    this.$emit('update:modelValue', newValue);
  }
}
