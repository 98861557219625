export default {
  only_without_bodies: 'Только без изображений силуэтов',
  only_with_bodies: 'Только с изображениями силуэтов',
  submit_and_create_report: 'применить и создать отчет',
  add: 'добавить',
  create_card: 'добавить карточку',
  dark: 'Тёмная тема',
  light: 'Светлая тема',
  off: 'выкл',
  warning: 'предупреждение',
  close_empty_detect_dialog: 'Объекты не найдены. Закрыть',
  removing_confirmation: 'Вы действительно хотите удалить данный файл?',
  activate_all: 'Активировать все',
  deactivate_all: 'Деактивировать все',
  save_all: 'Сохранить все',
  save_current: 'Сохранить',
  delete_all: 'Удалить все',
  delete_current: 'Удалить',
  delete_cards_in_all: 'Удалить карточки во всех',
  delete_cards_in: 'Удалить карточки в текущем',
  open_all: 'Открыть все',
  archive_all: 'Архивировать все',
  language: 'язык',
  unknown: 'неизвестен',
  unknown_cluster: 'неизвестный кластер',
  acknowledge: 'принять',
  loading: 'загрузка',
  live: 'live',
  event: 'событие',
  events: 'события',
  episode: 'эпизод',
  episodes: 'эпизоды',
  total: 'всего',
  total_events: 'всего событий',
  total_episodes: 'всего эпизодов',
  no_data: 'нет данных',
  face: 'лицо',
  faces: 'лица',
  body: 'силуэт',
  bodies: 'силуэты',
  car: 'транспортное средство',
  cars: 'транспортные средства',
  humans: 'люди',
  id: 'ID',
  in_total: 'всего',
  total_of: 'из',
  activate_webcam_for_face_recognition: 'Включите веб-камеру, чтобы войти в систему по лицу',
  activate_webcam: 'Включить веб-камеру',
  wait_face_verification: 'Выполняется проверка лица',
  login_cryptopro: 'Войти по ключу КриптоПро',
  cancel: 'Отмена',
  login: 'Имя пользователя',
  login_enter: 'Введите имя пользователя',
  password: 'Пароль',
  password_desc:
    'Ваш пароль:\n' +
    '- должен содержать минимум 8 символов\n' +
    '- не должен быть только числовым\n' +
    '- не должен соответствовать списку из 20000 распространенных простых паролей\n' +
    '- не должен быть похож на другие атрибуты пользователя\n' +
    '- может содержать только ASCII-символы',
  password_change: 'Сменить пароль',
  password_repeat: 'Повторите пароль',
  password_enter: 'Введите пароль',
  login_with_password: 'Войти с паролем',
  login_with_ad: 'Войти при помощи Active Directory',
  login_ad_error: 'Не удалось войти при помощи Active Directory. Попробуйте ещё раз',
  login_and_password_required: 'Требуется ввести имя пользователя и пароль',
  login_or_password_invalid: 'Проверьте правильность имени пользователя и пароля',
  video_recording_in_progress: 'Выполняется съёмка лица',
  face_recognition_in_progress: 'Идет распознавание лица',
  matched_event: 'событие с совпадением',
  matched_episode: 'эпизод с совпадением',
  unmatched_event: 'событие без совпадения',
  unmatched_episode: 'эпизод без совпадения',
  matches: 'совпадения',
  detectors: 'детекторы',
  camera: 'камера',
  camera_optional: 'Камера (необязательно)',
  cameras: 'камеры',
  camera_type: 'тип камеры',
  camera_group: 'группа камер',
  camera_group_create: 'Новая группа камер',
  camera_groups_not_selected: 'Не выбраны',
  camera_groups: 'группы камер',
  watch_lists: 'списки наблюдения',
  watch_list: 'список наблюдения',
  watch_list_create: 'Новый список наблюдения',
  watch_list_inactive: 'деактивирован',
  watch_list_active: 'Активен',
  attributes: 'атрибуты',
  face_attributes: 'атрибуты лица',
  body_attributes: 'атрибуты силуэта',
  car_attributes: 'атрибуты ТС',
  error: 'ошибка',
  error_loading: 'Ошибка загрузки',
  errors: 'ошибки',
  info: 'сведения',
  connection: 'связь',
  connections: 'связи',
  acknowledged_datetime: 'время подтверждения',
  acknowledged_by: 'подтвердил(а)',
  acknowledged: 'подтверждено',
  acknowledgement: 'подтверждение',
  only_acknowledged: 'только подтвержденные',
  only_unacknowledged: 'только неподтвержденные',
  only_with_matches: 'только совпадения',
  only_without_matches: 'без совпадений',
  only_active: 'активные',
  only_inactive: 'неактивные',
  face_matches: 'совпадения: лица',
  body_matches: 'совпадения: силуэты',
  created: 'создано',
  updated: 'обновлено',
  beige: 'бежевый',
  blue: 'синий',
  brown: 'коричневый',
  cyan: 'голубой',
  gold: 'золотистый',
  grey: 'серый',
  orange: 'оранжевый',
  pink: 'розовый',
  purple: 'пурпурный',
  silver: 'серебристый',
  violet: 'фиолетовый',
  lightblue: 'голубой',
  white: 'белый',
  black: 'черный',
  any: 'любой',
  reset: 'сбросить',
  collapse: 'свернуть',
  settings: 'настройки',
  appearance: 'внешний вид',
  roles: 'роли',
  users: 'пользователи',
  user_create: 'Новый пользователь',
  column_name: 'название',
  column_active: 'активность',
  column_off: 'отсутствует',
  column_view: 'просмотр',
  column_change: 'изменить',
  column_created: 'добавлен',
  column_modified: 'изменён',
  column_status: 'статус',
  column_faces_posted: 'лиц',
  column_cars_posted: 'ТС',
  column_add: 'добавить',
  column_delete: 'удалить',
  column_size: 'размер',
  column_actions: 'действия',
  sessions: 'сессии',
  blocklist_records: 'блокировки',
  license: 'лицензия',
  extensions: 'расширения',
  genetec: 'genetec',
  tools: 'инструменты',
  developer: 'разработчикам',
  verify: 'сравнение',
  documentation_api: 'Документация API',
  preferences: 'настройки',
  build_date: 'дата сборки',
  build_version: 'версия сборки',
  documentation: 'документация',
  logout: 'выход',
  filter_events: 'отфильтровать события',
  search: 'поиск',
  devices: 'Устройства',
  onvif_discovery: 'Обнаружение ONVIF',
  upload: 'Загрузка файла',
  external_detectors: 'Внешние детекторы',
  stream_url_or_onvif: 'URL камеры или IP-адрес ONVIF-устройства',
  ds_next: 'Дальше',
  you_are_adding: 'Вы добавляете {count} устройств',
  permissions: 'права доступа',
  information: 'сведения',
  general: 'основное',
  advanced: 'дополнительное',
  inactivated: 'не активно',
  changed: 'изменено',
  labels: 'ярлыки',
  deduplicate_events_with_interval: 'Убрать дубли событий с интервалом',
  enable_sound_alert: 'Включить звуковые оповещения',
  send_events_to_external_vms: 'Отправка событий сопоставления в VMS',
  dont_create_events: 'Не создавать события',
  require_event_acknowledgement: 'Требовать подтверждения событий',
  confidence_threshold: 'Порог срабатывания',
  comment: 'комментарий',
  color: 'цвет',
  name: 'название',
  name_contains: 'название содержит',
  permission: 'разрешение',
  add_new_role: 'новая роль',
  video_archive_id: 'ID видеоархива',
  count_events: 'количество событий',
  bs_type: 'лучший кадр события',
  bs_type_overall: 'лучший кадр по окончанию трека',
  bs_type_realtime: 'кадры в реальном времени',
  episode_id: 'ID эпизода',
  event_id: 'ID события',
  save: 'сохранить',
  create: 'добавить',
  created_by: 'добавил(а)',
  created_by_user: 'добавил(а)',
  clusters: 'кластеры',
  participants: 'участники',
  sources: 'источники',
  date_and_time: 'дата и время',
  create_report: 'создать отчет',
  reset_filters: 'сбросить фильтры',
  enter_episode_id: 'введите ID эпизода',
  enter_card_name_or_id: 'введите имя карточки или ID',
  enter_video_archive_id: 'введите ID видеоархива',
  enter_event_id: 'введите ID события',
  card_name_or_id: 'имя или ID карточки',
  card_name: 'Имя карточки',
  card_id: 'ID карточки',
  type: 'тип',
  object: 'объект',
  select_camera_group: 'выберите группу камер',
  select_camera: 'выберите камеру',
  choose_make: 'выберите марку',
  choose_model: 'выберите модель',
  select_country: 'выберите страну',
  select_region: 'выберите регион',
  enter_number_plate: 'введите рег. номер',
  enter_number_plate_description: 'Введите номер транспортного средства. Допускается использование "?" для замены любого количества неизвестных символов.',
  drag_and_drop_files: 'Перетащите файлы для загрузки',
  select_file: 'Выберите файл',
  photos: 'Фотографии',
  primary_role: 'основная роль',
  user_inactive: 'деактивирован',
  add_role: 'добавить роль',
  more_filters: 'Все фильтры',
  apply: 'Применить',
  apply_filters: 'Применить фильтры',
  save_selected_filters: 'Сохранить выбранные фильтры',
  photo_upload: 'Загрузить фото',
  take_picture: 'Сделать снимок',
  redo_picture: 'Сделать другой снимок',
  uploader_activate_webcam: 'Включите веб-камеру',
  or: 'или',
  no_photos: 'Нет фотографий',
  uploads: 'загрузки',
  cluster_events: 'События кластера',
  cluster_event: 'Событие кластера',
  cluster_event_mf: '{ count, plural, =0 {0 событий кластера} one {# событие кластера} few {# события кластера} other {# событий кластера} }',
  cant_load_image: 'Не удалось загрузить изображение',
  yes: 'да',
  user_ad_label: 'Пользователь Active Directory',
  ad_group: 'Группа Active Directory',
  latest_device_events: 'Последние события устройства',
  none: 'нет',
  delete_file: 'Удалить файл',
  delete_file_and_data: 'Удалить файл и связанные данные',
  uuid: 'UUID',
  user: 'пользователь',
  device: 'устройство',
  ip: 'IP-адрес',
  status: 'статус',
  last_ping: 'последнее соединение',
  online: 'онлайн',
  offline: 'офлайн',
  computer: 'компьютер',
  phone: 'телефон',
  block: 'блокировать',
  blocked: 'заблокирован',
  license_agreement: 'лицензионное соглашение',
  accept_license_agreement: 'принять лицензионное соглашение',
  proceed: 'продолжить',
  i_accept_license_agreement: 'Я принимаю лицензионное соглашение',
  create_blocklist_record: 'создание блокировки',
  edit_blocklist_record: 'редактирование блокировки',
  reason: 'основание',
  expires: 'истекает',
  uuids: 'UUIDs',
  card_type: 'тип карточки',
  cluster_id: 'кластер ID',
  enter_cluster_id: 'введите кластер ID',
  files: 'Файлы',
  no_files: 'Нет файлов',
  attach_more: 'Прикрепить еще',
  attach_first_one: 'Прикрепить первый',
  enter_filters_name: 'Введите название фильтра',
  acknowledge_all: 'Принять все',
  card: 'Карточка',
  select: 'выбрать',
  filter: 'Фильтр',
  active: 'Активность',
  activate: 'Активировать',
  deactivate: 'Деактивировать',
  delete: 'Удалить',
  action_success: 'Действие выполнено успешно',
  today: 'Сегодня',
  hour_mf: '{ count, plural, =0 {0 часов} one {# час} few {# часа} other {# часов} }',
  day_mf: '{ count, plural, one {# день} few {# дня} other {# дней} }',
  month_mf: '{ count, plural, one {# месяц} few {# месяца} other {# месяцев} }',
  year_mf: '{ count, plural, =0 {0 лет} one {# год} few {# года} other {# лет} }',
  images_mf: '{ count, plural, =0 {# изображений} one {# изображение} other {# изображений}}',
  files_mf: '{ count, plural, =0 {# файлов} one {# файл} few {# файла} other {# файлов}}',
  reset_all: 'сбросить все',
  confidence: 'Уверенность',
  has_face_objects: 'Лица',
  only_without_faces: 'Только без изображений лиц',
  only_with_faces: 'Только с изображениями лиц',
  has_body_objects: 'Силуэты',
  has_car_objects: 'Транспортные средства',
  only_without_cars: 'Только без изображений ТС',
  only_with_cars: 'Только с изображениями ТС',
  filled: 'Заполнение',
  only_filled: 'Только заполненные',
  only_not_filled: 'Только пустые',
  filter_event: 'Фильтровать события',
  new_tool: 'Новый инструмент',
  participant_id: 'ID участника',
  participant: 'Участник',
  only_with_participant: 'Только с участником',
  only_without_participant: 'Только без участника',
  participant_name: 'имя участника',
  enter_participant_name: 'введите имя участника',
  role: 'роль',
  activate_all_selected: 'Активировать выбранные',
  deactivate_all_selected: 'Деактивировать выбранные',
  save_all_selected: 'Сохранить выбранные',
  delete_all_selected: 'Удалить выбранные',
  delete_cards_in_all_selected: 'Удалить карточки в выбранных',
  open_all_selected: 'Открыть выбранные',
  archive_all_selected: 'Архивировать выбранные',
  mute: 'Выключить звук',
  unmute: 'Включить звук',
  user_name: 'Имя пользователя',
  show_events: 'Показать события',
  matched_cluster: 'Кластер с совпадением',
  unmatched_cluster: 'Кластер без совпадения',
  url: 'URL',
  close_group: 'Закрыть вкладки',
  terminate: 'Завершить',
  discard: 'Отменить',
  external_vms: 'Внешние VMS',
  filters: 'фильтры',
  no_items: 'нет элементов',
  restart: 'перезапустить',
  other: 'другое',
  clear: 'очистить',
  camera_id: 'ID камеры',
  camera_status: 'Статус камеры',
  disabled: 'ОТКЛЮЧЕНО',
  video_processing_confirm:
    'При повторной обработке этого видео будут удалены все события и эпизоды, связанные с этим видео. Вы уверены, что хотите снова обработать это видео?',
  delete_all_cards_confirm: 'Вы действительно хотите удалить все карточки из списка(ов) наблюдения? Это может занять некоторое время.',
  delete_all_watch_lists_confirm:
    'Вы уверены что хотите удалить список(ки) наблюдения и все карточки из списка(ов) наблюдения? Это может занять некоторое время.',
  delete_video_confirm:
    'Вы действительно хотите удалить видео? Внимание: видео будет удалено только после удаления всех принадлежащих ему событий, это может занять много времени. ',
  delete_confirm: 'Вы действительно хотите удалить?',
  delete_cameras_confirm: 'Вы действительно хотите удалить камеру(ы)?',
  delete_camera_groups_confirm: 'Вы действительно хотите удалить группу(ы) камер',
  delete_cards_confirm: 'Вы действительно хотите удалить карточку(и)',
  delete_clusters_confirm: 'Вы действительно хотите удалить кластер(ы)',
  delete_users_confirm: 'Вы действительно хотите удалить пользователя(ей)?',
  delete_groups_confirm: 'Вы действительно хотите удалить роль(и)?',
  delete_webhooks_confirm: 'Вы действительно хотите удалить вебхук(и)?',
  delete_external_vms_confirm: 'Вы действительно хотите удалить внешнюю(ие) ВМС(ы)?',
  limit: 'Лимит',
  enter: 'Введите',
  interface: 'интерфейс',
  display_lists_by_default: 'отображать списки по умолчанию',
  confidence_desc:
    'Порог срабатывания применяется для сопоставления обнаруженного объекта (лица, силуэта, ТС) с соответствующим объектом внутренней сущности. Рекомендуемые значения порога:\n' +
    '\n' +
    'лица: 0.63 - низкий, 0.714 - нормальный, 0.77 - высокий;\n' +
    'силуэты: 0.5 - низкий, 0.65 - нормальный, 0.85 - высокий;\n' +
    'ТС: 0.3 - низкий, 0.65 - нормальный, 0.85 - высокий.\n' +
    '\n' +
    'Оптимальным порогом считается нормальный.',
  meta_item_enabled: 'включено',
  meta_item_disabled: 'отключено',
  meta_item_not_found: 'не найден'
};
