import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-m" }
const _hoisted_2 = { class: "video-process-preview-bar__speed" }
const _hoisted_3 = { class: "control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDateTimeLabel = _resolveComponent("NDateTimeLabel")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_ProcessingVideoConfirm = _resolveComponent("ProcessingVideoConfirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NBaseBar, { class: "video-process-preview-bar" }, {
      start: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('videos.processing_speed', 'f')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NSelect, {
            items: _ctx.playSpeedItems,
            "model-value": _ctx.playSpeed,
            "onUpdate:modelValue": _ctx.updateSpeed,
            selectonly: "",
            disabled: _ctx.isActive || !_ctx.modelAcl.update
          }, null, 8, ["items", "model-value", "onUpdate:modelValue", "disabled"])
        ]),
        (_ctx.isActive)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: "secondary",
              icon: "stop",
              onClick: _ctx.processStop,
              loading: _ctx.actionInProgress,
              loader: "circle",
              disabled: !_ctx.modelAcl.update
            }, null, 8, ["onClick", "loading", "disabled"]))
          : (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              type: "secondary",
              icon: "play",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hasVideoBeenProcessed ? (_ctx.showProcessingVideoConfirm = true) : this.processStart)),
              loading: _ctx.actionInProgress,
              loader: "circle",
              disabled: !_ctx.modelAcl.update
            }, null, 8, ["loading", "disabled"]))
      ]),
      middle: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('videos.time', 'f') + ':'), 1),
        _createVNode(_component_NDateTimeLabel, {
          class: "video-process-preview-bar__source-len",
          "model-value": _ctx.sourceTime,
          mode: "time",
          size: "m"
        }, null, 8, ["model-value"])
      ]),
      end: _withCtx(() => [
        _createVNode(_component_NButton, {
          type: "link",
          icon: "search-wrapped",
          onClick: _ctx.openEpisodes
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('videos.episodes', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    (_ctx.showProcessingVideoConfirm)
      ? (_openBlock(), _createBlock(_component_ProcessingVideoConfirm, {
          key: 0,
          targetItems: [_ctx.item],
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showProcessingVideoConfirm = false)),
          onConfirm: _ctx.confirmProcessing
        }, null, 8, ["targetItems", "onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}