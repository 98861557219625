
import NTable from '@/uikit/table/NTable.vue';
import { columns } from './blocklist-table-schema';
import SettingsBar from '@/components/common/SettingsBar.vue';
import BlocklistMultitool from '@/components/common/BlocklistMultitool.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import NForm from '@/uikit/forms/NForm.vue';
import SidebarTab from '@/components/common/sidebar/SidebarTab.vue';
import SidebarHeader from '@/components/common/sidebar/SidebarHeader.vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ListPage from '@/pages/ListPage.vue';
import { PageName, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { ListViewModel } from '@/definitions/view-models';
import SettingsMenu from '@/components/common/settings/SettingsMenu.vue';
import NSidebarMenu from '@/uikit/sidebar-menu/NSidebarMenu.vue';
import { getFilterByType } from '@/pages/blocklist/forms/small-filter';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NInput from '@/uikit/input/NInput.vue';
import EditBlocklistForm from '@/pages/blocklist/forms/EditBlocklistForm.vue';
import { aclModule } from '@/store/acl';
import SessionsAndBlocklistTabs from '@/pages/sessions/tabs/SessionsAndBlocklistTabs.vue';
import NInfiniteScroll from '@/components/cases/NInfiniteScroll.vue';
import { AclModelNames } from '@/pages/sessions/tabs/tabs-helper';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  components: {
    SettingsPageLayout,
    NLoadingCircle,
    SettingsBar,
    SettingsMenu,
    BlocklistMultitool,
    NTable,
    NForm,
    SidebarTab,
    SidebarHeader,
    ListPage,
    NSidebarMenu,
    FiltersSmall,
    NButtonGroup,
    NInput,
    EditBlocklistForm,
    SessionsAndBlocklistTabs,
    NInfiniteScroll
  }
})
export default class BlocklistPage extends Vue {
  hasSidebar = false;
  sortField = '';
  showModalWindow = false;

  get module(): ListViewModel<any, any> {
    pageModule.getPageModule(this.state).aclModelName = AclModelNames.blocklist_records;
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }
  get items() {
    return this.module.items;
  }

  get columns() {
    return columns;
  }

  get selectedItems() {
    return this.module.selectedItems.map((v) => v.item);
  }

  get state(): PageState {
    const tab = 'blacklist';
    return pageModule.getPageStateByTab(PageName.block_list, tab);
  }

  get getSmallFilterLayout() {
    const { pageType } = this.state;
    return getFilterByType(pageType);
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  get sidebarModule() {
    return multisidebarModule;
  }
  activated() {
    this.sidebarModule.contentOverlap = true;
  }
  mounted() {
    this.sidebarModule.contentOverlap = true;
  }
  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }
  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  resetFilters() {
    this.module.resetFilters();
  }

  closeHandler() {
    this.showModalWindow = false;
  }

  handleTableSort(columnName: string) {
    this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = this.sortField;
    this.module.get();
  }

  async saveHandler() {
    await this.module.saveCurrentItem();
    await this.module.get({ resetState: false });
    this.showModalWindow = false;
  }

  async handleTableAction(payload: ITableCellChangePayload) {
    if (payload.type === 'change') {
      this.module.setCurrentItem(payload.row.id);
      this.showModalWindow = true;
    }
  }

  async handleAction(actionName: ItemsActionName) {
    switch (actionName) {
      case ItemsActionNames.SelectAll:
        this.module.selectAllItems();
        break;

      case ItemsActionNames.DeselectAll:
        this.module.deselectAllItems();
        break;

      case ItemsActionNames.DeleteAll:
        await this.module.deleteAllSelectedItems();
        await this.module.get({ resetState: false });
        break;

      case ItemsActionNames.Close:
        await this.module.deleteCurrentItem();
        break;

      default:
        console.warn(`Unknown action "${actionName}"`);
    }
  }

  @Watch('module.filter.current', { deep: true })
  currentFilterHandler(v: any, p: any) {
    this.module.get();
  }

  @Watch('selectedItems')
  selectedItemsHandler(v: any[]) {
    if (v.length) {
      this.module.setCurrentItem(v[v.length - 1].id);
    } else {
      this.hasSidebar = false;
    }
  }

  @Watch('$route.path')
  changeRoutePath(v: string) {
    if (v === '/blocklist-records') {
      this.module.get();
    }
  }
}
