export default {
  count_silhouettes: 'Считать силуэты',
  single_camera: 'по одной камере',
  multi_camera: 'по нескольким камерам',
  clear: 'Очистить',
  information: 'Сведения',
  id: 'ID',
  name: 'Имя',
  info: 'Сведения',
  faces: 'Лица',
  bodies: 'Силуэты',
  cars: 'ТС',
  active: 'Активен',
  add_counter: 'Новый счетчик',
  records: 'Скриншоты',
  roi: 'ROI',
  frame: 'Кадр',
  rect: 'Прямоугольник',
  detect_proximity: 'определение дистанции',
  detect_bodies: 'подсчет силуэтов',
  detect_cars: 'подсчет ТС',
  detect_faces: 'подсчет лиц',
  calibration_time: 'время калибровки',
  calibrate_counter: 'откалибровать счетчик',
  proximity_not_enabled: 'определение дистанции выключено',
  proximity_enabled: 'определение дистанции включено',
  distance: 'дистанция',
  distance_measurement: 'определение дистанции',
  distance_is_not_detected: 'не определена',
  min_distance: 'минимальная дистанция',
  max_distance: 'максимальная дистанция',
  avg_distance: 'средняя дистанция',
  calibration_status__not_calibrated: 'не калиброван',
  calibration_status__awaiting_sync: 'ожидание синхронизации',
  calibration_status__collecting_calibration_data: 'сбор данных для калибровки',
  calibration_status__awaiting_proximity_model: 'создание калибровочного шаблона для измерения дистанции',
  calibration_status__calibrated: 'калиброван',
  calibration_status__calibration_failed: 'не удалось откалибровать',
  stop_calibration: 'удалить калибровку',
  collect_track_data_seconds: 'Продолжительность калибровки (секунды)',
  with_detect_proximity: 'с определением дистанции',
  without_detect_proximity: 'без определения дистанции',
  minimum: 'минимальная',
  maximum: 'максимальная',
  average: 'средняя',
  count_within_interval_seconds: 'Интервал счета, секунды',
  counter: 'Счётчик',
  select_counter: 'Выберите счётчик',
  counter_id: 'ID счётчика',
  counter_record_id: 'ID записи счётчика',
  enter_counter_id: 'Введите ID счётчика',
  enter_counter_record_id: 'Введите ID записи счётчика',
  count_faces: 'Считать лица',
  count_cars: 'Считать транспортные средства',
  save_changes_helper: 'Для активации кнопки калибровки после включения детектора силуэтов сохраните изменения'
};
